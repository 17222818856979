/* eslint-disable import/prefer-default-export */

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyCBVV-BADea7PB_33t1dzx-h3KIpZsdwk0',
    authDomain: 'tavuel506.firebaseapp.com',
    databaseURL: 'https://tavuel506.firebaseio.com',
    projectId: 'tavuel506',
    storageBucket: 'tavuel506.appspot.com',
    messagingSenderId: '536950237048',
    appId: '1:536950237048:web:3c9094800aa0bab8'
};

firebase.initializeApp(firebaseConfig);

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export {
    googleAuthProvider,
    firebase
}
