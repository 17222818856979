import React, { useEffect, useMemo } from "react";
// external component
import {
  Button,
  Container,
  Label,
  Control,
  Field,
  Input,
  Card,
  Icon,
} from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Context
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useElectronic } from "../../context/ElectronicContext";
import {
  addressInitialState,
  expenseFormInitialState,
} from "../../InitialStates/ElectronicContext";
import { useAuth, useModal } from "../../context";
// component
import OptionCard from "../OptionCard";
import { SummaryExpenses } from "./components";
import SelectIva from "../IvaSelect";
import SelectPaymentMethod from "../PaymentComponet/Receiver/PaymentReceiver/SelectPaymentMethod";
import SelectSellCondition from "../PaymentComponet/Receiver/PaymentReceiver/SelectSellCondition";
import SelectCoin from "../PaymentComponet/Receiver/PaymentReceiver/SelectCoin";
import ClientCard from "../ClientCard";
import RegisterReceiver from "../RegisterReceiver";
import TaxSelect from "../TaxSelect/TaxSelect";
import UnitMeasureSelect from "../UnitMeasureSelect/UnitMeasureSelect";
import CabysCodeSelect from "../CabysCodeSelect/CabysCodeSelect";
// Hooks
import useUsers from "../../hooks/useUsers";
import useBuyBill from "../../hooks/useBuyBill";
// Enums
import { PROVIDER_GROUP } from "../../Enums/General";
import {
  BILL_TYPES_URL_PARAMS,
  ElectronicBillTypes,
  TAX_CODES,
} from "../../Enums/ElectronicBill";
// Utils
import { customToast } from "../../utils";
import {
  getDiscountAmout,
  getSubTotal,
  getTaxedAmount,
  getTotal,
} from "../../utils/Operations";
// SCSS
import "./BuyElectronicBill.scss";

function BuyElectronicBill() {
  const {
    LineDetailPlace,
    receiver,
    useCoinDetail,
    infoSummary,
    setInfoSummary,
    summaryId,
    setSummaryId,
    expenseForm,
    setExpenseForm,
    electronicBill,
    handleChangeElectronicBill,
    tariff,
    getAllUnitOfMeasure,
    taxesData,
    setReceiver,
    setAddress,
    setClientGroup,
    resetReceiver,
    isBilling,
    setIsBilling,
  } = useElectronic();

  const { state: authState } = useAuth();

  const { addNewClient, dataClientsGroups, createGroup, refetchGroups } =
    useUsers();
  const { setModalOpen } = useModal();
  const history = useHistory();
  const { handleCreatDraftFEC, handleCreateFEC, handleCreateFECProducts } =
    useBuyBill();
  const {
    currentSymbolCoin,
    toExchangeRate,
    resetDefaultCoin,
    getCoins,
    toDolar,
    currentCoin,
    handleCoinChange,
  } = useCoinDetail;

  const resetProductForm = () => {
    setExpenseForm({
      ...expenseFormInitialState,
    });
    setSummaryId(summaryId + 1);
  };

  const handleChange = (name, value) =>
    setExpenseForm(prev => ({ ...prev, [name]: value }));

  const handleChangeIVAAdapter = (name, tarif_id) =>
    handleChange(
      name,
      tariff.tariff.find(_tariff => _tariff.id === tarif_id),
    );
  const handleChangeTasxAdapter = (name, tax_id) =>
    handleChange(
      name,
      taxesData.taxes.find(_tax => _tax.id === tax_id),
    );

  const handleChangeMeasurementUnitAdapter = (name, mu_id) =>
    handleChange(
      name,
      getAllUnitOfMeasure?.unitsOfMeasurement.find(mu => mu.id === mu_id),
    );

  const isValidProduct = () =>
    expenseForm.quantity > 0 &&
    expenseForm.expense.length !== 0 &&
    expenseForm.amount > 0 &&
    !!expenseForm.tax &&
    !!expenseForm.cabys &&
    isValidBaseTax(expenseForm) &&
    isValidFactor(expenseForm) &&
    isValidIva(expenseForm);

  const isValidIva = form => {
    if (form?.tax?.MH_Code === TAX_CODES.IVA_BIENES_USADOS) return true;
    return !!form.tariff;
  };
  const isValidBaseTax = form => {
    if (form?.tax?.MH_Code !== TAX_CODES.IVA_CALCULO_ESPECIAL) return true;
    return form.baseTax > 0;
  };
  const isValidFactor = form => {
    if (form?.tax.MH_Code !== TAX_CODES.IVA_BIENES_USADOS) return true;
    return form.factor > 1;
  };

  const addNewExpense = () => {
    if (!isValidProduct()) {
      customToast.error(
        "Error al agregar el gasto, revisa que los campos estén llenos correctamente.",
      );
      return;
    }
    const discountAsDecimal = expenseForm.discount / 100 || 0;
    const subTotal =
      getSubTotal(expenseForm.amount, discountAsDecimal) * expenseForm.quantity;
    const tariffAmount =
      getTaxedAmount({
        taxCode: expenseForm.tax.MH_Code,
        subTotal,
        porcentajeTarifa: expenseForm.tariff.Percent,
        baseTax: expenseForm.baseTax || 0,
        factor: expenseForm.factor || 0,
      }) * expenseForm.quantity;
    const discountedAmount =
      getDiscountAmout(expenseForm.amount, discountAsDecimal) *
      expenseForm.quantity;
    setInfoSummary([
      ...infoSummary,
      {
        id: summaryId,
        name: expenseForm.expense,
        quantity: expenseForm.quantity,
        measurmentUnit: expenseForm.measurmentUnit,
        discountPercent: expenseForm.discount || 0,
        tariff: expenseForm.tariff,
        tax: expenseForm.tax,
        factor: expenseForm.factor || 0,
        cabys: expenseForm.cabys,
        unitPrice: toDolar(expenseForm.amount),
        subTotal: toDolar(subTotal),
        baseTax: toDolar(expenseForm.baseTax) || 0,
        tariffAmount: toDolar(tariffAmount),
        disAmount: toDolar(discountedAmount),
        total: toDolar(getTotal(subTotal, tariffAmount)),
      },
    ]);
    resetProductForm();
  };

  useEffect(() => {
    if (!isBilling) {
      resetReceiver();
      resetDefaultCoin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (isBilling) setIsBilling(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBilling],
  );

  const totalsResume = useMemo(
    () =>
      infoSummary.reduce(
        (acc, info) => {
          acc[0].value += parseFloat(info.subTotal);
          acc[1].value += parseFloat(info.disAmount);
          acc[2].value += info.tariffAmount;
          return acc;
        },
        [
          { label: "Subtotal: ", value: 0 },
          { label: "Descuento: ", value: 0 },
          { label: "IVA: ", value: 0 },
        ],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getTaxedAmount, infoSummary],
  );

  useEffect(() => {
    setInfoSummary(prev =>
      prev.map(pr => ({
        ...pr,
        amo: parseFloat(pr.unitPrice),
        dis: parseFloat(pr.dis),
      })),
    );
  }, [getCoins?.coins, expenseForm.typeAmount, setInfoSummary]);

  const handleOpenModal = () => {
    setModalOpen(
      true,
      <RegisterReceiver
        newClient
        FK_User={0}
        handleChangeAddress={() => null}
        handleCreateNewClient={addNewClientAddapter}
        initialReceiver={null}
        setBillingProfile={null}
        setModalOpen={setModalOpen}
        title="Perfil de facturación"
      />,
    );
  };

  const addNewClientAddapter = async profile => {
    const providersGroup = dataClientsGroups?.groups?.find(
      ({ Name_ClientGroup }) => Name_ClientGroup === PROVIDER_GROUP,
    );
    const selectedGroup =
      providersGroup?.id ||
      (
        await createGroup({
          Name_ClientGroup: PROVIDER_GROUP,
          FK_Place: LineDetailPlace.id,
        })
      )?.clientGroup?.id;
    refetchGroups();
    if (!selectedGroup) {
      return toast.error("Ocurrió un error al crear el grupo");
    }
    const newClient = await addNewClient(profile, selectedGroup, "Proveedor");
    const client = newClient?.data?.client;
    const billingProfile = {
      id: client?.BillingProfile?.id,
      Receiver_Id: client?.BillingProfile?.ID_Number,
      Receiver_Name: client?.BillingProfile?.Name,
      Receiver_Email: client?.BillingProfile?.Email,
      Receiver_PhoneCode: client?.BillingProfile?.PhoneCodCountry,
      Receiver_PhoneNumber: client?.BillingProfile?.PhoneNumber,
      Receiver_PersonType: client?.BillingProfile?.MH_PersonType,
      user: { id: client?.BillingProfile?.User.id },
    };
    const address = {
      Province:
        client?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Province,
      Canton: client?.BillingProfile?.MH_Neighborhood?.District?.Canton,
      District: client?.BillingProfile?.MH_Neighborhood?.District,
      Neighborhood: client?.BillingProfile?.MH_Neighborhood,
      Other: client?.BillingProfile?.OtherSigns,
    };

    setAddress(address);
    setClientGroup({ id: 0 });
    return setReceiver(billingProfile);
  };

  const handleChangeCabys = cabys => {
    setExpenseForm(prev => ({
      ...prev,
      cabys,
      tariff: tariff.tariff.find(_tariff => _tariff.Percent === cabys.percent),
    }));
  };

  const isValidPayload = () =>
    receiver?.id &&
    infoSummary?.length > 0 &&
    electronicBill.Sell_Condition &&
    electronicBill.Payment_Method;

  const createDraftBill = async () => {
    const savedProducts = await handleCreateFECProducts({
      FK_User: authState.user.TavuelUser.id,
      productInput: infoSummary,
    });

    const savedDraftBill = await handleCreatDraftFEC(
      savedProducts?.data?.createFECProducts,
    );

    if (!savedDraftBill?.data?.createDraftFEC) {
      toast.error(
        'Ocurrió un error al generar la factura. Se guardó una copia en "Borradores"',
      );
    }

    return savedDraftBill;
  };

  const createPurchaseBill = async () => {
    if (!isValidPayload()) {
      return toast.error("Por favor complete todos los datos");
    }
    const savedDraftBill = await createDraftBill();

    const savedEBill = await handleCreateFEC(
      savedDraftBill?.data?.createDraftFEC?.id,
    );
    if (savedEBill?.data?.createFECBill?.error) {
      return toast.error("Ocurrió un error al guardar la factura electrónica");
    }
    toast.success("Se ah guardado exitosamente la factura electronica");
    return history.push(
      `/ElectronicBillView/${savedEBill.data.createFECBill.id}/created/${BILL_TYPES_URL_PARAMS.BUY_BILL}}`,
    );
  };

  const handleResetProvider = () => {
    resetReceiver();
    setAddress(addressInitialState);
  };

  const validateBill = () => {
    if (infoSummary?.length === 0 || receiver?.id === "" || !receiver?.id)
      return true;

    return false;
  };

  return (
    <div>
      <div>
        <OptionCard
          goBack
          icon="store"
          optional={LineDetailPlace.Name_Place}
          title="Tienda"
        />
        <ClientCard
          icon="user-plus"
          icon2="address-book"
          optional={
            !receiver.Receiver_Id
              ? "Click para buscar (opcional)"
              : `${receiver?.Receiver_Name}, 
                  ${receiver?.Receiver_Id}`
          }
          optional2={
            !receiver.Receiver_Email && !receiver.PhoneNumber
              ? ""
              : `${receiver?.Receiver_Email}, 
                ${receiver?.Receiver_PhoneNumber}`
          }
          title="Proveedor"
          type={ElectronicBillTypes.FacturaElectronicaDeCompra}
        />
        <Container className="mva-1 buttons-proveedor gap-1">
          <Button
            className="p-1"
            color="secondary"
            onClick={() => handleResetProvider()}
          >
            Deselecionar Proveedor
          </Button>
          <Button
            className="p-1"
            color="primary"
            onClick={() => handleOpenModal()}
          >
            Agregar Proveedor
          </Button>
        </Container>
        <Container>
          <Card className="card-summary secondary-content false">
            <div className="card-secondary-content-title flex">
              <div className="card-total-width">
                <h3>Datos de pago</h3>
              </div>
            </div>
            <Container className="container hover-options">
              <div className="content-select flex ">
                <Icon size="medium">
                  <FontAwesomeIcon icon="credit-card" size="lg" />
                </Icon>
                <Field expanded className="format-selects">
                  <SelectPaymentMethod
                    name="Payment_Method"
                    value={electronicBill.Payment_Method}
                    onChange={(name, value) => {
                      handleChangeElectronicBill(name, value);
                      handleChange(name, value);
                    }}
                  />
                </Field>
              </div>
              <div className="flex ">
                {electronicBill?.Payment_Method === "6" && (
                  <Control className="animate__animated animate__fadeIn format-selects content-input">
                    <Label>Especifique el método de pago:</Label>
                    <Input
                      name="OtherPaymentMethod"
                      type="text"
                      value={electronicBill.OtherPaymentMethod}
                      onBlur={({ target: { name, value } }) =>
                        handleChange(name, value)
                      }
                      onChange={e =>
                        handleChangeElectronicBill(
                          e.target.name,
                          e.target.value,
                        )
                      }
                    />
                  </Control>
                )}
              </div>
              <div className="content-select flex ">
                <Icon size="medium">
                  <FontAwesomeIcon icon="coins" size="lg" />
                </Icon>
                <Field expanded className="format-selects">
                  <SelectCoin
                    name="Coin"
                    value={currentCoin}
                    onChange={(name, value) => {
                      handleChangeElectronicBill(name, value);
                      handleChange(name, value);
                      handleCoinChange(value);
                    }}
                  />
                </Field>
              </div>
              <div className="content-select flex ">
                <Icon size="medium">
                  <FontAwesomeIcon icon={["far", "handshake"]} size="lg" />
                </Icon>
                <Field expanded className="format-selects">
                  <SelectSellCondition
                    name="Sell_Condition"
                    value={electronicBill.Sell_Condition}
                    onChange={(name, value) => {
                      handleChangeElectronicBill(name, value);
                      handleChange(name, value);
                    }}
                  />
                </Field>
              </div>
              <div className="flex ">
                {electronicBill?.Sell_Condition === "10" && (
                  <Control className="animate__animated animate__fadeIn format-selects content-input">
                    <Label>Especifique la condición de venta:</Label>
                    <Input
                      name="OtherSellCondition"
                      type="text"
                      value={electronicBill.OtherSellCondition}
                      onBlur={({ target: { name, value } }) =>
                        handleChange(name, value)
                      }
                      onChange={e =>
                        handleChangeElectronicBill(
                          e.target.name,
                          e.target.value,
                        )
                      }
                    />
                  </Control>
                )}
                {electronicBill?.Sell_Condition === "2" && (
                  <Control className="animate__animated animate__fadeIn format-selects content-input">
                    <Label>
                      Indique la cantidad de días del plazo del crédito:
                    </Label>
                    <Input
                      name="CreditPaymentCondition"
                      type="number"
                      value={electronicBill.CreditPaymentCondition}
                      onBlur={({ target: { name, value } }) =>
                        handleChange(name, value)
                      }
                      onChange={e =>
                        handleChangeElectronicBill(
                          e.target.name,
                          e.target.value,
                        )
                      }
                    />
                  </Control>
                )}
              </div>
            </Container>
          </Card>
        </Container>
        <Container className="Container-container ">
          <div className="head-new-expense">
            <p className="title-new-expense all-text">Agregar gasto</p>
          </div>
          <Card />
          <div className="expense_section">
            <Field className="amount-buy flex-grow-1 ">
              <Control expanded>
                <Label>Gasto</Label>
                <Input
                  autoComplete="off"
                  name="expense"
                  placeholder="Gasto"
                  type="string"
                  value={expenseForm.expense}
                  onChange={({ target: { value, name } }) =>
                    handleChange(name, value)
                  }
                />
              </Control>
            </Field>

            <div className="flex flex-row flex-grow-1">
              <Field className="pr-1">
                <Control>
                  <Label>Cantidad</Label>
                  <Input
                    key={summaryId}
                    name="quantity"
                    placeholder="Número de productos"
                    type="number"
                    value={expenseForm.quantity}
                    onChange={({ target: { value, name } }) =>
                      handleChange(name, value)
                    }
                  />
                </Control>
              </Field>
              <UnitMeasureSelect
                className="pr-1 flex-grow-1"
                name="measurmentUnit"
                secondaryMeasureName=""
                secondaryMeasureValue={0}
                value={expenseForm?.measurmentUnit.id || ""}
                onChange={(name, value) =>
                  handleChangeMeasurementUnitAdapter(name, value)
                }
              />
            </div>
            <Field className="amount-buy flex-grow-1">
              <Control expanded>
                <Label>Costo</Label>
                <Input
                  key={summaryId}
                  name="amount"
                  placeholder="0"
                  type="number"
                  value={expenseForm.amount}
                  onChange={({ target: { value, name } }) =>
                    handleChange(name, value)
                  }
                />
              </Control>
            </Field>
            <Field className="discount-buy flex-grow-1">
              <Control expanded>
                <Label>Descuento %</Label>
                <Input
                  name="discount"
                  placeholder="Descuento %"
                  type="number"
                  value={expenseForm.discount}
                  onChange={({ target: { value, name } }) =>
                    handleChange(name, value)
                  }
                />
              </Control>
            </Field>
            <Field className="amount-buy flex-grow-1">
              <TaxSelect
                name="tax"
                value={expenseForm?.tax?.id || ""}
                onChange={(name, value) => handleChangeTasxAdapter(name, value)}
              />
            </Field>
            <Field className="amount-buy flex-grow-1">
              <CabysCodeSelect
                handleChange={handleChangeCabys}
                selected_value={{
                  value: expenseForm.cabys?.value,
                  label: expenseForm.cabys?.label,
                }}
              />
            </Field>
            {expenseForm?.tax?.MH_Code === "08" && (
              <Field className="amount-buy flex-grow-1">
                <Label>Monto Factor:</Label>
                <Input
                  name="factor"
                  placeholder="0"
                  type="number"
                  value={expenseForm.Factor}
                  onChange={({ target: { value, name } }) =>
                    handleChange(name, value)
                  }
                />
              </Field>
            )}
            {expenseForm?.tax?.MH_Code === "07" && (
              <Field className="amount-buy flex-grow-1">
                <Label>Base Imponible:</Label>
                <Input
                  name="baseTax"
                  placeholder="0"
                  type="number"
                  value={expenseForm.baseTax}
                  onChange={({ target: { value, name } }) =>
                    handleChange(name, value)
                  }
                />
              </Field>
            )}
            {!["08"].includes(expenseForm?.tax?.MH_Code) && (
              <Field className="amount-buy flex-grow-1">
                <SelectIva
                  className="select-iva"
                  name="tariff"
                  secondaryIvaName="otherIva"
                  secondaryIvaValue={0}
                  value={expenseForm?.tariff?.id || ""}
                  onChange={handleChangeIVAAdapter}
                />
              </Field>
            )}
          </div>
          <div className="div-button-buy">
            <Button
              className="add-Button all-text buyElectronic-button"
              color="primary"
              disabled={!isValidProduct()}
              size="medium"
              onClick={addNewExpense}
            >
              Agregar gasto
            </Button>
          </div>
        </Container>
      </div>
      <Container className="Container-container ">
        <div className="summary-container">
          <div className="head-summary">
            <div className="fisrt-head">
              <Label>Resumen</Label>
            </div>
          </div>
          <div className="container-expenses-card">
            {infoSummary.length > 0 &&
              infoSummary.map(info => (
                <SummaryExpenses
                  key={info.id}
                  amount={toExchangeRate(info.unitPrice)}
                  discount={toExchangeRate(info.disAmount)}
                  expense={info.name}
                  id={info.id}
                  iva={toExchangeRate(info.tariffAmount).toFixed(3)}
                  measurmentUnit={info.measurmentUnit}
                  quantity={info.quantity}
                  setInfoSummary={setInfoSummary}
                  subTotal={toExchangeRate(info.subTotal)}
                  symbolCoin={currentSymbolCoin}
                  total={toExchangeRate(info.total)}
                />
              ))}
          </div>
          <br />
          <div className="footer-summary-first">
            {totalsResume.map(item => (
              <div key={item.label} className="column-amount">
                <Label>{item.label}</Label>
                <p className="text-amount">
                  {currentSymbolCoin}
                  {toExchangeRate(item.value).toFixed(3)}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="container-total-summary">
          <p className="all-text">Total:</p>
          <div className="container-total-summary-expenses">
            <p className="all-text">
              {currentSymbolCoin}
              {toExchangeRate(
                getTotal(totalsResume[0].value, totalsResume[2].value),
              ).toFixed(3)}
            </p>
          </div>
        </div>
      </Container>
      <Container className="actions-container">
        <Button
          className="container-head-button"
          color="light"
          size="large"
          onClick={() => createDraftBill()}
        >
          Guardar en borrador
        </Button>
        <Button
          className="container-head-button"
          color="primary"
          disabled={validateBill()}
          size="large"
          onClick={createPurchaseBill}
        >
          Generar factura electrónica de compra
        </Button>
      </Container>
    </div>
  );
}

export default BuyElectronicBill;
