import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../context";
import { useElectronic } from "../context/ElectronicContext";
import {
  client,
  CREATE_NEW_CLIENT,
  GET_ALL_CLIENTS_BY_GROUP,
  GET_ALL_GROUPS_BY_PLACE,
  GET_CLIENTS_BY_CRITERIA,
  REGISTER_NEW_CLIENTXGROUP,
  REGISTER_NEW_CLIENT_GROUP,
  TAVUEL_USER_BY_BILLING_PROFILE,
} from "../graphql";

const useUsers = () => {
  const { state: authState } = useAuth();
  const { LineDetailPlace } = useElectronic();
  const [registerGroup] = useMutation(REGISTER_NEW_CLIENT_GROUP);
  const [registerUserOnGroup] = useMutation(REGISTER_NEW_CLIENTXGROUP);
  const [registerClient] = useMutation(CREATE_NEW_CLIENT);

  const [registerReceiverByBillingProfile] = useMutation(
    TAVUEL_USER_BY_BILLING_PROFILE,
  );

  const { data: dataClientsGroups, refetch: refetchGroups } = useQuery(
    GET_ALL_GROUPS_BY_PLACE,
    {
      variables: {
        FK_Place: LineDetailPlace.id,
      },
      skip: !LineDetailPlace.id,
    },
  );

  const createGroup = useCallback(
    async (newGroup, place_id = null) => {
      try {
        const { data } = await registerGroup({
          variables: {
            data: newGroup,
          },
          refetchQueries: [
            {
              query: GET_ALL_GROUPS_BY_PLACE,
              variables: {
                FK_Place: place_id || LineDetailPlace.id,
              },
            },
          ],
        });
        return data;
      } catch (err) {
        toast.error("Error al registrar el grupo");
        return null;
      }
    },
    [registerGroup, LineDetailPlace.id],
  );

  const addNewClient = useCallback(
    async (profile, groupID, type = "Cliente") => {
      try {
        const response = await client.query({
          query: GET_CLIENTS_BY_CRITERIA,
          variables: {
            criteria: profile?.ID_Number,
            FK_Place: LineDetailPlace?.id,
          },
        });
        const clientExist = response?.data?.clients?.find(
          index => index?.ID_Number === profile?.ID_Number,
        );
        if (!clientExist) {
          const { data } = await registerReceiverByBillingProfile({
            variables: {
              FK_Place: LineDetailPlace?.id,
              profile,
            },
          });
          if (data) {
            const registeredUserOnGroup = await registerUserOnGroup({
              variables: {
                data: {
                  FK_User: data?.BillingProfile?.id,
                  FK_ClientGroup: groupID,
                },
              },
              refetchQueries: [
                {
                  query: GET_ALL_CLIENTS_BY_GROUP,
                  variables: {
                    FK_ClientGroup: groupID,
                  },
                },
              ],
            });
            const responseRegisterClient = await registerClient({
              variables: {
                data: {
                  Created_By: authState.user.TavuelUser.id,
                  FK_User: data?.BillingProfile?.id,
                  FK_Place: LineDetailPlace?.id,
                },
              },
              refetchQueries: [
                {
                  query: GET_ALL_CLIENTS_BY_GROUP,
                  variables: {
                    FK_ClientGroup: groupID,
                  },
                },
              ],
            });
            if (responseRegisterClient && registeredUserOnGroup) {
              toast.success(`¡${type} agregado al grupo con éxito!`);
              return responseRegisterClient;
            }
          }
        } else {
          toast.error(
            `Ya existe un usuario con el número de identificación proporcionado. Favor buscar en Clientes o Proveedores existentes`,
          );
        }
      } catch (err) {
        toast.error(`No se pudo agregar el nuevo ${type}.`);
      }
      return null;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      LineDetailPlace.id,
      authState.user.TavuelUser.id,
      registerClient,
      registerGroup,
      registerReceiverByBillingProfile,
    ],
  );
  return { addNewClient, dataClientsGroups, createGroup, refetchGroups };
};

export default useUsers;
