import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
// External components
import { Card, Button, Icon, Container, Image } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useMutation } from "@apollo/client";
import {
  DELETE_PRODUCT_FEATURE,
  GET_PRODUCTS_BY_PLACE_AND_CRITERIA,
} from "../../../../graphql";
// Components
import NewOrEditProduct from "../NewOrEditProduct";
import ConfirmationModal from "../../../ConfirmationModal";
// Context
import { useModal } from "../../../../context";
// Utils
import TransformImages from "../../../../utils/TransformImages";
import { customToast } from "../../../../utils";
import { validateRoute } from "../../../../utils/helpers";
// SCSS
import "./CardProduct.scss";

function CardProduct({ product, refetch }) {
  const [deleteProduct] = useMutation(DELETE_PRODUCT_FEATURE);

  const { setModalOpen } = useModal();

  const getUrl = pro => {
    const route = pro.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const validateInitialRoute = pro => {
    let route = getUrl(pro);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    route = TransformImages(pro, 1);
    return route;
  };

  const handleEdit = () => {
    setModalOpen(
      true,
      <NewOrEditProduct
        product={product}
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Editar Producto"
      />,
    );
  };

  const deletedProduct = useCallback(async () => {
    try {
      const { data } = await deleteProduct({
        variables: {
          id: product?.id,
        },
        refetchQueries: [
          {
            query: GET_PRODUCTS_BY_PLACE_AND_CRITERIA,
            variables: refetch,
          },
        ],
      });
      if (data) {
        customToast.success("¡Producto eliminado con éxito!");
      } else {
        customToast.error("Error al eliminar el producto");
      }
    } catch (err) {
      customToast.error("Error al eliminar el producto");
    }
  }, [deleteProduct, product, refetch]);

  const hanldeDelete = e => {
    e.preventDefault();
    deletedProduct();
    setModalOpen(false);
  };

  const cancelDelete = () => {
    setModalOpen(false);
  };

  const handleConfirmDelete = e => {
    setModalOpen(
      true,
      <ConfirmationModal
        body="¿Está seguro desea borrar este producto? Presione confirmar para eliminar"
        header="¿Desea borrar este producto?"
        onCancel={cancelDelete}
        onConfirm={() => hanldeDelete(e)}
      />,
    );
  };

  useEffect(() => {
    function getDefaultImage(e) {
      e.target.src = validateRoute(product);
    }
    const img1 = document.getElementById("file");
    img1.onerror = getDefaultImage;
  }, [product]);

  return (
    <Card className="CardProductContainer">
      <div className="CardProductBody">
        <div>
          <p>
            <strong>Código producto:</strong> {product.Codes[0]?.FeatureCode}
          </p>
          <p>
            <strong>Nombre producto:</strong> {product.Product?.Name_Product}
          </p>
          <p>
            <strong>Descripción:</strong> {product.Product?.Description_Product}
          </p>
          <p>
            <strong>Código cabys:</strong> {product.ProductPrice?.Cabys?.Code}
          </p>
        </div>
        <div className="CardProductBodyRight">
          <Image.Container size="65px sq.">
            <Image id="file" src={validateInitialRoute(product?.Files)} />
          </Image.Container>
        </div>
      </div>
      <Card />
      <Container className="CardProductConteinerButtons">
        <Button rounded color="primary" onClick={handleEdit}>
          <Icon size="small">
            <FontAwesomeIcon icon="edit" />
          </Icon>
          <span>Editar</span>
        </Button>
        <Button rounded className="delete-button" onClick={handleConfirmDelete}>
          <Icon size="small">
            <FontAwesomeIcon icon="trash-alt" />
          </Icon>
          <span>Eliminar</span>
        </Button>
      </Container>
    </Card>
  );
}

CardProduct.propTypes = {
  product: PropTypes.object.isRequired,
  refetch: PropTypes.object,
};
CardProduct.defaultProps = {
  refetch: {},
};

export default CardProduct;
