import { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Control, Field, Input, Label } from "rbx";
// Context
import { useModal } from "../../../../context";
// SCSS
import "./UserSearch.scss";

function UserSearch({ handleSearch }) {
  const [search, SetSearch] = useState("");

  const { setModalOpen } = useModal();

  const handleCancel = () => setModalOpen(false);

  return (
    <div>
      <div className="search-user">
        <Field>
          <Control>
            <Label textColor="grey-dark">
              Agregar usuario existente por correo electrónico:
            </Label>
            <Input
              autoComplete="off"
              className="searchInput"
              name="search"
              placeholder="Agregar usuario"
              size="large"
              textColor="black"
              type="text"
              value={search}
              onChange={e => SetSearch(e.target.value)}
            />
          </Control>
        </Field>
        <div className="container-buttons">
          <Button className="edit-button" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleSearch(search)}>
            Agregar
          </Button>
        </div>
      </div>
    </div>
  );
}

UserSearch.propTypes = {
  handleSearch: PropTypes.func,
};

UserSearch.defaultProps = {
  handleSearch: e => e,
};

export default UserSearch;
