import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../../utils/ReportUtils";
import { useElectronic } from "../../context/ElectronicContext";

const IvaReportSubsection = ({ data, title, totals, isTotalsSection }) => {
  const { useCoinDetail } = useElectronic();
  const { toExchangeRate } = useCoinDetail;

  return isTotalsSection ? (
    <div className="iva-report-sub-seccion">
      <h2>{title}</h2>
      <div>
        <h3 style={{ margin: "10px 0 0 12px" }}>
          <span style={{ fontWeight: "bold" }}>Total Gravado: </span>
          {formatNumber(toExchangeRate(totals.TotalGrav)?.toFixed(2))}
        </h3>
        <h3 style={{ margin: "10px 0 0 12px" }}>
          <span style={{ fontWeight: "bold" }}>Exento: </span>
          {formatNumber(toExchangeRate(totals.TotalExempt)?.toFixed(2))}
        </h3>
        {totals?.Taxes?.filter(tax => tax.Amount !== 0).map(tax => (
          <p key={tax.Name}>
            {tax.Name} {formatNumber(toExchangeRate(tax.Total)?.toFixed(2))}
            {": "}
            {formatNumber(toExchangeRate(tax.Amount)?.toFixed(2))}
          </p>
        ))}
        <h3 style={{ margin: "10px 0 0 12px" }}>
          <span style={{ fontWeight: "bold" }}>Total impuestos: </span>
          {formatNumber(toExchangeRate(totals.TotalTaxes)?.toFixed(2))}
        </h3>
        <h3 style={{ margin: "10px 0 0 12px" }}>
          <span style={{ fontWeight: "bold" }}>Total parcial: </span>
          {formatNumber(toExchangeRate(totals.TotalNetSell)?.toFixed(2))}
        </h3>
        <h3 style={{ margin: "10px 0 0 12px" }}>
          <span style={{ fontWeight: "bold" }}>Total: </span>
          {formatNumber(toExchangeRate(totals.TotalBill)?.toFixed(2))}
        </h3>
      </div>
    </div>
  ) : (
    <div className="iva-report-sub-seccion">
      <h2>{title}</h2>
      <div className="iva-report-taxes">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Tarifa</th>
              <th>Monto</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter(tax => tax.Total !== 0)
              .map(tariff => (
                <tr>
                  <td>{tariff.Name}</td>
                  <td>
                    {formatNumber(toExchangeRate(tariff.Amount)?.toFixed(2))}
                  </td>
                  <td>
                    {formatNumber(toExchangeRate(tariff.Total)?.toFixed(2))}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ fontWeight: "bold" }}>Total:</span>{" "}
        {formatNumber(toExchangeRate(totals.TotalDataTaxed))}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Exento:</span>{" "}
        {formatNumber(toExchangeRate(totals.TotalDataExempt))}
      </p>
    </div>
  );
};

IvaReportSubsection.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  totals: PropTypes.object.isRequired,
  isTotalsSection: PropTypes.bool,
};

IvaReportSubsection.defaultProps = {
  isTotalsSection: false,
};

export default IvaReportSubsection;
