import React, { useState, useEffect } from "react";
// External components
import {
  Input,
  Column,
  Notification,
  Progress,
  Field,
  Control,
  Label,
  Title,
} from "rbx";
import { useAsyncDebounce } from "react-table";
// Graphql
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_BILL_STATE, GET_PROFORMA_BILL } from "../../graphql";
// Components
import { ConfirmationModal, DraftBill, Loading } from "../../components";
import CustomDatePicker from "../../components/CustomDatePicker";
// Context
import { useModal } from "../../context";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
// SCSS
import "./ProformaPage.scss";
// Helpers
import { customToast } from "../../utils";
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";

function ProformaPage() {
  const [filter, setFilter] = useState({
    name: "",
    date: new Date(),
  });

  const { LineDetailPlace } = useElectronicBill();
  const { setModalOpen } = useModal(false);

  const [deleteBill] = useMutation(UPDATE_BILL_STATE);
  const [getDraftBills, { data: draftBillsData, loading }] =
    useLazyQuery(GET_PROFORMA_BILL);

  const executeQuery = () => {
    getDraftBills({
      variables: {
        eBSearchFormWithClient: {
          place_id: LineDetailPlace.id,
          date: filter.date,
          ClientName: filter.name,
        },
      },
      fetchPolicy: "network-only",
    });
  };

  const debouncedLoad = useAsyncDebounce(() => {
    executeQuery();
  }, 1000);

  const handleChange = (name, value) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    debouncedLoad();
  }, [filter, debouncedLoad, LineDetailPlace]);

  const cancelDelete = () => {
    setModalOpen(false);
  };

  const deleteDraftBill = async id => {
    setModalOpen(false);
    const deleted = await deleteBill({
      variables: {
        id,
        FK_BillState: 3,
      },
      refetchQueries: [
        {
          query: GET_PROFORMA_BILL,
          variables: {
            eBSearchFormWithClient: {
              place_id: LineDetailPlace.id,
              date: filter.date,
              ClientName: filter.name,
            },
          },
        },
      ],
    });
    return !deleted
      ? customToast.success("Ha ocurrido un error al eliminar la factura")
      : customToast.success("Factura eliminada con éxito");
  };

  const handleDelete = bill => {
    setModalOpen(
      true,
      <ConfirmationModal
        body="¿Está seguro desea borrar esta factura? Presione confirmar para eliminar"
        header="¿Desea Borrar esta Factura?"
        onCancel={cancelDelete}
        onConfirm={() => deleteDraftBill(bill.id)}
      />,
    );
  };

  const draftBillsSortedAscendant = sortArrayItemsByDate(
    draftBillsData !== undefined &&
      Array.isArray(draftBillsData.draft_bills) &&
      draftBillsData.draft_bills.length > 0
      ? draftBillsData.draft_bills
      : [],
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "Created_At",
    },
  );

  return (
    <div className="Proforma">
      <div>
        <Title className="text-center">Proforma</Title>
      </div>
      <div className="document-search__header">
        <div className="DSForm">
          <Field className="DSForm__select" kind="group">
            <Control expanded>
              <Label className="label" textColor="grey-dark">
                Nombre del cliente
              </Label>
              <Input
                className="DSForm__generic-input"
                defaultValue={filter.name}
                label="Nombre del cliente"
                name="name"
                value={filter.name}
                onChange={({ target: { value, name } }) =>
                  handleChange(name, value)
                }
              />
            </Control>
          </Field>

          <Field className="DSForm__select" kind="group">
            <Control expanded>
              <Label className="label" textColor="grey-dark">
                Mes y año de emisión
              </Label>
              <CustomDatePicker
                dateFormat="MMMM / yyyy"
                selectedDate={filter.date}
                onChange={date => handleChange("date", date)}
              />
            </Control>
          </Field>
        </div>
      </div>
      {loading && <Progress color="info" size="small" />}
      <div className="proforma-grid">
        {draftBillsSortedAscendant ? (
          draftBillsSortedAscendant.map(bill => (
            <DraftBill bill={bill} onDelete={handleDelete} />
          ))
        ) : (
          <Loading />
        )}
      </div>
      {draftBillsSortedAscendant && draftBillsSortedAscendant.length === 0 && (
        <Column.Group centered>
          <Column size="half">
            <Notification className="no-results" textAlign="centered">
              <h2 className="no-results-text">No se encontraron resultados</h2>
              <h2 className="no-results-text">
                para los parámetros selecionados.
              </h2>
            </Notification>
          </Column>
        </Column.Group>
      )}
    </div>
  );
}

export default ProformaPage;
