import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { Label, Control, Field, Select, Button, Input } from "rbx";
// GraphQL
import { useQuery } from "@apollo/client";
import { GET_INVENTORIES_BY_PLACE } from "../../graphql";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { customToast } from "../../utils";
// SCSS
import "./InventoriesSelect.scss";

function InventoriesSelect({
  value,
  name,
  onChange,
  disabled,
  className,
  style,
  handleAddInventory,
  hanldeEditInventory,
}) {
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState("");
  const [repeatedDescription, setRepeatedDescription] = useState(false);

  const { LineDetailPlace } = useElectronic();

  const { data: inventories } = useQuery(GET_INVENTORIES_BY_PLACE, {
    variables: {
      query: {
        FK_Place: LineDetailPlace?.id,
      },
    },
  });

  useEffect(() => {
    if (value === 0 && inventories?.inventories?.length > 0) {
      onChange(name, inventories?.inventories[0].id);
    }
  }, [inventories, name, onChange, value]);

  const handleChangeDescription = e => {
    setDescription(e);
    const repeatedName = inventories.inventories.find(i => i.Description === e);
    if (repeatedName) {
      setRepeatedDescription(true);
    } else {
      setRepeatedDescription(false);
    }
  };

  const handleSave = e => {
    e.preventDefault();
    if (description === "") {
      customToast.error("Por favor, ingrese el nombre del inventario.");
    } else if (edit) {
      hanldeEditInventory(description);
      setEdit(false);
    } else {
      handleAddInventory(description);
      setAddNew(false);
    }
  };

  const findInventory = id => {
    const result = inventories?.inventories?.find(index => index.id === id);

    return result?.Description;
  };

  return (
    <div 
      className={addNew || edit ? "box-select" : ""}
      style={style}
    >
      <Field>
        <Control expanded>
          <Label>Inventario</Label>
          <div style={{ display: "flex" }}>
            <Select.Container fullwidth>
              <Select
                disabled={disabled}
                name={name}
                
                value={value}
                onChange={e => {
                  onChange(e.target.name, e.target.value);
                  setAddNew(false);
                  setEdit(false);
                }}
              >
                {inventories?.inventories?.map(index => (
                  <Select.Option key={index.id} value={index.id}>
                    {index.Description}
                  </Select.Option>
                ))}
              </Select>
            </Select.Container>
            <Button
              color="secondary"
              style={{ marginLeft: "0.5rem" }}
              onClick={e => {
                setEdit(true);
                setAddNew(false);
                setDescription(findInventory(value));
                handleChangeDescription(findInventory(value));
              }}
            >
              Editar
            </Button>
            <Button
              color="secondary"
              onClick={e => {
                setAddNew(true);
                setEdit(false);
                setDescription("");
                setRepeatedDescription(false);
              }}
            >
              Agregar Nuevo
            </Button>
          </div>
        </Control>
      </Field>
      {(addNew || edit) && (
        <Field id="inventario">
          <Control expanded>
            <Label>Nombre del inventario:</Label>
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  color="dark"
                  name="Name_Product"
                  placeholder="Nombre del inventario"
                  type="text"
                  value={description}
                  onChange={e => handleChangeDescription(e.target.value)}
                />
                {repeatedDescription && (
                  <div className="warning">
                    <Label style={{ color: "white" }}>
                      Este nombre ya fue utilizado en otro inventario
                    </Label>
                  </div>
                )}
              </div>

              <Button
                color="primary"
                style={{ "marginLeft": "0.5rem", "align-items": "end" }}
                onClick={handleSave}
              >
                Guardar
              </Button>
            </div>
          </Control>
        </Field>
      )}
    </div>
  );
}

InventoriesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  handleAddInventory: PropTypes.func.isRequired,
  hanldeEditInventory: PropTypes.func.isRequired,
};

InventoriesSelect.defaultProps = {
  disabled: false,
  className: "",
  style: {}
};

export default InventoriesSelect;
