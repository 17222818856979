import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// External components
import { Card, Container, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "../OptionCard/OptionCard.scss";

function ClientCard({ mainComponent, optional, optional2, type }) {
  const history = useHistory();
  const { setIsBilling } = useElectronic();

  const handleChangeRoute = () => {
    if (type !== ElectronicBillTypes.CreditNote) {
      setIsBilling(true);
      history.push("/electronic-bills/client");
    }
  };
  return (
    <Container className="hover-options" onClick={handleChangeRoute}>
      <Card
        className={
          mainComponent
            ? "card-products flex middle principal-content"
            : "card-summary secondary-content"
        }
      >
        {!mainComponent && type !== "BuyElectronicBill" && (
          <div className="card-secondary-content-title flex">
            <div className="card-total-width">
              <h3>Cliente</h3>
            </div>
          </div>
        )}
        {!mainComponent && type === "BuyElectronicBill" && (
          <div className="card-secondary-content-title flex">
            <div className="card-total-width">
              <h3>Proveedor</h3>
            </div>
          </div>
        )}
        {mainComponent && <h3>Cliente</h3>}
        <div className="content-option flex">
          <Icon size="medium">
            <FontAwesomeIcon icon="user-plus" size="lg" />
          </Icon>
          <div className="flex middle">
            <h4>{optional}</h4>
          </div>
        </div>
        {optional2 !== "" && (
          <div className="content-option2 flex">
            <Icon size="medium">
              <FontAwesomeIcon icon="address-book" size="lg" />
            </Icon>
            <div className="flex middle">
              <h4>{optional2}</h4>
            </div>
          </div>
        )}
      </Card>
    </Container>
  );
}
ClientCard.propTypes = {
  mainComponent: PropTypes.bool,
  optional: PropTypes.string.isRequired,
  optional2: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
ClientCard.defaultProps = {
  mainComponent: false,
};

export default ClientCard;
