import React, { useState } from "react";
// External components
import { Modal, Field, Control, Input, Button, Title, Label } from "rbx";
// Context
import { useAuth } from "../../context/AuthContext";
// Components
import SelectCountry from "../CountrySelect";
// SCSS
import "./createUser.scss";

const CreateUser = () => {
  const [state, setstate] = useState({
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: "",
    Phone_Number: "",
    PhoneCodCountry: "506",
    FK_Coin: 1,
    FK_Country: "52",
  });

  const { handleRegisterPerson, handleLogout } = useAuth();

  const handleChange = (name, value) => {
    setstate(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    handleRegisterPerson(state);
  };

  return (
    <div className="modal-container flex middle">
      <div className="row">
        <Modal.Card className="modal-card">
          <Title
            aling="center"
            className="text-center"
            size={3}
            textColor="white"
          >
            Por favor, complete la información
          </Title>
          <div className="modal-inputs">
            <Field>
              <Control>
                <Input
                  autoComplete="off"
                  name="Given_Name"
                  placeholder="Nombre"
                  type="text"
                  value={state.Given_Name}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <Input
                  autoComplete="off"
                  name="Family_Name"
                  placeholder="Apellido"
                  type="text"
                  value={state.Family_Name}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
            <SelectCountry
              name="FK_Country"
              value={state.FK_Country}
              onChange={handleChange}
            />
            <Field>
              <Control>
                <Input
                  autoComplete="off"
                  name="Phone_Number"
                  placeholder="Teléfono"
                  type="number"
                  value={state.Phone_Number}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>

            <Field>
              <Label textColor="white">Fecha Nacimiento</Label>
              <Control>
                <Input
                  autoComplete="off"
                  name="BirthDate_Person"
                  type="date"
                  value={state.BirthDate_Person}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
          </div>
          <div className="landing-page-buttons">
            <Button color="white" onClick={handleSave}>
              Guardar
            </Button>
          </div>
          <div className="landing-page-footer">
            <Label className="text-center" textColor="white">
              ¿Ya tienes una cuenta?
            </Label>
            <div className="landing-page-buttons">
              <Button
                outlined
                rounded
                className="landing-page-button-secondary"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleLogout();
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp; INICIAR SESIÓN &nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </div>
          </div>
        </Modal.Card>
      </div>
    </div>
  );
};

export default CreateUser;
