import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
// External components
import { Input, Title } from "rbx";
import { registerLocale } from "react-datepicker";
import Es from "date-fns/locale/es";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { GET_EB_BY_CLIENTNAME_AND_DATE } from "../../graphql";
// Components
import { EBResumeCard, Loading } from "../../components";
import SelectType from "./SelectType";
import CustomDatePicker from "../../components/CustomDatePicker";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./CreditDebitNotesPage.scss";
// Helpers
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";

function CreditNotePage() {
  const [filter, setFilter] = useState({
    name: "",
    date: new Date(),
  });

  const documentOptions = [
    "Todos",
    "Facturas Electrónicas",
    "Tiquetes Electrónicos",
  ];
  const [documentTypeFilter, setDocumentTypeFilter] = useState("Todos");

  const history = useHistory();
  const { LineDetailPlace } = useElectronic();

  const [getElectronicBills, { data: ElectronicBills, loading }] = useLazyQuery(
    GET_EB_BY_CLIENTNAME_AND_DATE,
  );

  const handleGetEB = useCallback(() => {
    getElectronicBills({
      variables: {
        eBSearchFormWithClient: {
          place_id: LineDetailPlace.id,
          date: filter.date,
          ClientName: filter.name,
        },
      },
    });
  }, [getElectronicBills, filter, LineDetailPlace.id]);

  const handleChange = (name, value) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  };
  registerLocale("Es", Es);
  useEffect(() => {
    handleGetEB();
  }, [filter, handleGetEB]);

  const creditNotesSortedAscendant = sortArrayItemsByDate(
    ElectronicBills?.getEDByUserNameAndDate,
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "EmitedDay",
    },
  );

  const filterByType = () => {
    if (documentTypeFilter === "Todos") return creditNotesSortedAscendant;
    if (documentTypeFilter === "Facturas Electrónicas") {
      return creditNotesSortedAscendant.filter(
        index => index?.ElectronicBillDocumentType?.id === "1",
      );
    }
    return creditNotesSortedAscendant.filter(
      index => index?.ElectronicBillDocumentType?.id !== "1",
    );
  };

  return (
    <div className="creditNote-page">
      <div className="creditNote-page__row">
        <Input
          className="creditNote-page__search"
          name="name"
          placehlder="codigo"
          value={filter.name}
          onChange={({ target: { name, value } }) => handleChange(name, value)}
        />
        <div>
          <CustomDatePicker
            dateFormat="MMMM / yyyy"
            selectedDate={filter.date}
            onChange={date => setFilter(prev => ({ ...prev, date }))}
          />
        </div>

        <SelectType
          data={documentOptions}
          option={documentTypeFilter}
          onChange={setDocumentTypeFilter}
        />
      </div>
      {loading && <Loading />}
      {ElectronicBills?.getEDByUserNameAndDate?.length > 0 ? (
        <div className="grid">
          {filterByType(creditNotesSortedAscendant).map(item => (
            <EBResumeCard
              key={item.id}
              actions={[
                {
                  text: "Ver notas de crédito",
                  onClick: () =>
                    history.push(`/CreditDebitNote/${item.id}/CreditNotes`),
                },
              ]}
              item={item}
            />
          ))}
        </div>
      ) : (
        !loading && (
          <div className="creditNote-page__message-no-results">
            <Title className="title-center">
              No se han encontrado registros
            </Title>
          </div>
        )
      )}
    </div>
  );
}

export default CreditNotePage;
