import React from "react";
import PropTypes from "prop-types";
// External components
import { Button } from "rbx";
// SCSS
import "./ConfirmationModal.scss";

const ConfirmationModal = ({ header, body, onCancel, onConfirm }) => (
  <div className="confirmation-modal">
    <div className="confirmation-modal__header">
      <h1 className=" confirmation-modal__header-text confirmation-modal__header-text--bold">
        {header}
      </h1>
    </div>
    <div className="confirmation-modal__body">
      <p className=" confirmation-modal__header-text--normal">{body}</p>
    </div>
    <div className="confirmation-modal__footer">
      <Button
        className="confirmation-modal__button"
        color="danger"
        onClick={onConfirm}
      >
        Confirmar
      </Button>
      <Button
        className="confirmation-modal__button"
        color="primary"
        onClick={onCancel}
      >
        Cancelar
      </Button>
    </div>
  </div>
);

ConfirmationModal.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationModal;
