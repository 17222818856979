import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { Select, Control } from "rbx";
import { useQuery } from "@apollo/client";
import { GET_ALL_PAYMENT_METHODS } from "../../../../../graphql";

import "./SelectPaymentMethod.scss";

const SelectPaymentMethod = ({ value, name, onChange, disabled }) => {
  const { data: getPaymentMethodData } = useQuery(GET_ALL_PAYMENT_METHODS);

  return (
    <Control>
      <Select.Container fullwidth>
        <Select
          data-tip
          className="selector"
          data-for="selectPaymentMethod"
          disabled={disabled}
          name={name}
          value={value}
          onChange={e => onChange(e.target.name, e.target.value)}
        >
          {getPaymentMethodData?.paymentMethod?.map(index => (
            <Select.Option key={index.id} value={index.id}>
              {index.Name_PaymentMethod}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
      <ReactTooltip id="selectPaymentMethod" type="info">
        <span>Método de pago</span>
      </ReactTooltip>
    </Control>
  );
};

SelectPaymentMethod.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

SelectPaymentMethod.defaultProps = {
  disabled: false,
};

export default SelectPaymentMethod;
