import React, { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "firebase/storage";
// External components
import ReactCrop from "react-image-crop";
import { Button } from "rbx";
import firebase from "firebase/app";
import { v4 as uuid } from "uuid";
// Components
import Loading from "../Loading";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "react-image-crop/dist/ReactCrop.css";
import "../Cropper/Cropper.scss";

function CropperPlace({
  setFirebaseId,
  setFirebaseURL,
  onClose,
  upImg,
  isProduct,
  isCompany,
}) {
  const [saved, setSaved] = useState(false);
  const [cropView, setCropView] = useState(true);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 4 / 4 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const db = firebase.storage();

  const handleSave = () => {
    setSaved(true);
    saveBlob(previewCanvasRef.current, completedCrop);
  };
  const saveBlob = (canvas, cropped) => {
    // extrae el blob del canvas
    if (!cropped || !canvas) {
      return;
    }

    canvas.toBlob(async blob => {
      const firebaseId = uuid();
      let ref;
      let finalFireBaseId;
      if (process.env.NODE_ENV === "production") {
        if (!isProduct) {
          finalFireBaseId = `places/dev/${firebaseId}`;
        } else if (isCompany) {
          finalFireBaseId = `companies/dev/${firebaseId}`;
        } else {
          finalFireBaseId = `market/dev/products/${firebaseId}`;
        }
        ref = db.ref(finalFireBaseId);
      } else {
        if (!isProduct) {
          finalFireBaseId = `places/${firebaseId}`;
        } else if (isCompany) {
          finalFireBaseId = `companies/dev/${firebaseId}`;
        } else {
          finalFireBaseId = `market/products/${firebaseId}`;
        }
        ref = db.ref(finalFireBaseId);
      }
      const task = ref.put(blob);

      task.on(
        "state_changed",
        snapshot => {},
        error => {
          toast.error(error.message);
        },
        () => {
          resolveURL(ref, finalFireBaseId);
        },
      );
    });
  };

  const resolveURL = async (ref, firebaseId) => {
    // guarda el link de la nueva foto del usuario en la base de datos
    const url = await ref.getDownloadURL();

    if (url !== "") {
      setFirebaseURL(url);
      setFirebaseId(firebaseId);
      toast.success("¡Imagen guardada con éxito!");
      onClose();
    } else {
      toast.error("Link de imagen no válido");
    }
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop1 = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop1.width * pixelRatio * scaleX;
    canvas.height = crop1.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop1.x * scaleX,
      crop1.y * scaleY,
      crop1.width * scaleX,
      crop1.height * scaleY,
      0,
      0,
      crop1.width * scaleX,
      crop1.height * scaleY,
    );
  }, [completedCrop]);
  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="App CropperPhoto-container">
      <div>
        <ReactCrop
          className={!cropView ? "CropperPhoto-hide" : ""}
          crop={crop}
          src={upImg}
          onChange={c => setCrop(c)}
          onComplete={c => setCompletedCrop(c)}
          onImageLoaded={onLoad}
        />
        <canvas
          ref={previewCanvasRef}
          className={cropView ? "CropperPhoto-hide" : ""}
          style={{
            width: Math.round(completedCrop?.width ?? 100),
            height: Math.round(completedCrop?.height ?? 100),
          }}
        />
      </div>

      {saved && <Loading />}

      {!saved && (
        <div className="CropperPhoto-button-container">
          {cropView ? (
            <Button
              color="secondary"
              disabled={!completedCrop?.width || !completedCrop?.height}
              type="button"
              onClick={() => setCropView(false)}
            >
              Recortar
            </Button>
          ) : (
            <Button
              color="secondary"
              disabled={!completedCrop?.width || !completedCrop?.height}
              type="button"
              onClick={() => setCropView(true)}
            >
              Editar
            </Button>
          )}
          {!cropView && (
            <Button
              color="primary"
              disabled={!completedCrop?.width || !completedCrop?.height}
              type="button"
              onClick={handleSave}
            >
              Guardar
            </Button>
          )}
          <Button
            className="CropperPhoto-button"
            color="secondary"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </div>
      )}
    </div>
  );
}
CropperPlace.propTypes = {
  onClose: PropTypes.func,
  upImg: PropTypes.string.isRequired,
  setFirebaseId: PropTypes.func.isRequired,
  setFirebaseURL: PropTypes.func.isRequired,
  isProduct: PropTypes.bool,
  isCompany: PropTypes.bool,
};
CropperPlace.defaultProps = {
  onClose: () => null,
  isProduct: false,
  isCompany: false,
};
export default CropperPlace;
