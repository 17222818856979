import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// External components
import {
  Container,
  Control,
  Field,
  Input,
  Title,
  Button,
  Label,
  Image,
  Icon,
} from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
// Graphql
import { useMutation } from "@apollo/client";
import {
  REGISTER_NEW_PRODUCT,
  EDIT_PRODUCT_FEATURE,
  GET_PRODUCTS_BY_PLACE_AND_CRITERIA,
  CREATE_INVENTORY_BY_PLACE,
  GET_INVENTORIES_BY_PLACE,
  UPDATE_INVENTORY,
} from "../../../../graphql";
// Components
import SelectIva from "../../../IvaSelect";
import SelectUnitMeasure from "../../../UnitMeasureSelect";
import SelectCabysCode from "../../../CabysCodeSelect";
import SelectInventories from "../../../InventoriesSelect";
import CropperPlace from "../../../CropperPlace";
import TaxSelect from "../../../TaxSelect";
// Context
import { useElectronic } from "../../../../context/ElectronicContext";
import { useModal } from "../../../../context";
// Utils
import { validateRoute } from "../../../../utils/helpers";
// SCSS
import "./NewOrEditProduct.scss";

function NewOrEditProduct({ setCloseModal, product, title, refetch }) {
  // States
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const canvas = useRef(null);
  const [files, setFile] = useState();
  const { setModalOpen } = useModal();
  const [isCropper, setIsCropper] = useState(false);
  const [features, setFeatures] = useState({
    CabysCode: product?.ProductPrice?.Cabys?.Code || 0,
    Name_Product: product?.Product?.Name_Product || "",
    Description_Product: product?.Product?.Description_Product || "",
    MeasurementUnit:
      product?.Product?.InventoryDetail?.UnitOfMeasurement?.id || 1,
    otherUnit:
      product?.Product?.InventoryDetail?.UnitOfMeasurement
        ?.Name_UnitOfMeasurement ===
      "Se debe indicar la descripción de la medida a utilizar"
        ? product?.Product?.InventoryDetail?.UnitOfMeasurement?.id
        : "",
    price: product?.ProductPrice?.Price_ProductPrice,
    iva: product?.ProductPrice?.Tariff?.id || 1,
    inventory: product?.Product?.InventoryDetail?.Inventory?.id || 0,
    Clicks_Product: 0,
    Restricted_Product: 0,
    FK_ProductState: 1,
    tax: product?.ProductPrice?.Tax?.id || 1,
  });

  const { LineDetailPlace } = useElectronic();
  const productEdit = {
    cabysName: product?.ProductPrice?.Cabys?.Name || "",
  };

  const {
    CabysCode,
    Name_Product,
    Description_Product,
    MeasurementUnit,
    otherUnit,
    price,
    iva,
    inventory,
    tax,
  } = features;

  const { cabysName } = productEdit;

  // mutation
  const [createdProduct] = useMutation(REGISTER_NEW_PRODUCT);
  const [editProduct] = useMutation(EDIT_PRODUCT_FEATURE);
  const [createdInventory] = useMutation(CREATE_INVENTORY_BY_PLACE);
  const [updatedInventory] = useMutation(UPDATE_INVENTORY);

  const handleCancel = useCallback(() => {
    setCloseModal(false);
  }, [setCloseModal]);

  const handleChange = (name, value) => {
    setFeatures(prev => ({ ...prev, [name]: value }));
  };

  const createProduct = useCallback(async () => {
    try {
      const newFile = files?.Route_File
        ? files
        : {
            Route_File: null,
          };
      const { data } = await createdProduct({
        variables: {
          product: { ...features },
          FK_Place: LineDetailPlace.id,
          file: newFile,
        },
      });
      if (data.product.Name_Product) {
        toast.success("¡Producto registrado con éxito!");
        handleCancel();
      } else {
        toast.error("Error al registrar el producto");
      }
    } catch (err) {
      toast.error("Error al registrar el producto");
    }
  }, [files, createdProduct, features, LineDetailPlace, handleCancel]);

  const editProductFeature = useCallback(async () => {
    try {
      const validatePriceChanges = () =>
        features?.price !== product?.ProductPrice?.Price_ProductPrice ||
        features?.iva !== product?.ProductPrice?.Tariff?.id
          ? product?.ProductPrice?.id
          : null;

      const newFile = files?.Route_File
        ? files
        : {
            Route_File: null,
          };

      const ProductPrice = {
        id: validatePriceChanges(),
        Price_ProductPrice: features?.price,
        FK_Tariff: features?.iva,
      };
      const Inventory_Detail = {
        id: product?.Product?.InventoryDetail?.id,
        FK_UnitOfMeasurement: features?.MeasurementUnit,
      };
      const ProductEdit = {
        id: product?.Product?.id,
        Name_Product: features?.Name_Product,
        Description_Product: features?.Description_Product,
      };

      const { data } = await editProduct({
        variables: {
          Product_Price: ProductPrice,
          InventoryDetail: Inventory_Detail,
          Product: ProductEdit,
          FeatureId: product?.id,
          InventoryId: inventory,
          file: newFile,
        },
        refetchQueries: [
          {
            query: GET_PRODUCTS_BY_PLACE_AND_CRITERIA,
            variables: refetch,
          },
        ],
      });

      if (data.productFeature) {
        toast.success("¡Producto actualizado con éxito!");
        handleCancel();
      } else {
        toast.error("Error al actualizar el producto");
      }
    } catch (err) {
      toast.error("Error al actualizar el producto");
    }
  }, [features, product, editProduct, inventory, refetch, handleCancel, files]);

  const createInventory = useCallback(
    async Description => {
      try {
        const { data } = await createdInventory({
          variables: {
            FK_Place: LineDetailPlace.id,
            Description,
          },
          refetchQueries: [
            {
              query: GET_INVENTORIES_BY_PLACE,
              variables: {
                query: {
                  FK_Place: LineDetailPlace?.id,
                },
              },
            },
          ],
        });
        if (data.inventory) {
          handleChange("inventory", data.inventory?.id);
          toast.success("Inventario registrado con éxito!");
        } else {
          toast.error("Error al registrar el inventario");
        }
      } catch (err) {
        toast.error("Error al registrar el inventario");
      }
    },
    [createdInventory, LineDetailPlace],
  );

  const editInventory = useCallback(
    async Description => {
      try {
        const { data } = await updatedInventory({
          variables: {
            inventory: {
              id: inventory,
              Description,
            },
          },
          refetchQueries: [
            {
              query: GET_INVENTORIES_BY_PLACE,
              variables: {
                query: {
                  FK_Place: LineDetailPlace?.id,
                },
              },
            },
          ],
        });
        if (data.inventory) {
          handleChange("inventory", data.inventory?.id);
          toast.success("Inventario actualizado con éxito!");
        } else {
          toast.error("Error al actualizar el inventario");
        }
      } catch (err) {
        toast.error("Error al actualizar el inventario");
      }
    },
    [updatedInventory, inventory, LineDetailPlace],
  );
  const isValidProduct = () =>
    features.CabysCode !== "0" &&
    features.Name_Product !== "" &&
    features.Description_Product !== "" &&
    price > 0;

  const handleSubmit = async e => {
    e.preventDefault();

    if (!isValidProduct()) {
      toast.error("Debe completar los datos solicitados.");
      return;
    }
    if (!product) {
      createProduct();
      return;
    }
    editProductFeature();
  };

  const handleSelectChange = newValue => {
    setFeatures(prev => ({ ...prev, CabysCode: newValue.label }));
  };

  const validateImgRoute = () => {
    if (firebaseURL === "") {
      return validateRoute(product);
    }
    return firebaseURL;
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSelectClick = e => {
    e.preventDefault();
    document.querySelector("#fileSelectorImg").click();
  };

  useEffect(() => {
    if (upImg !== "" && !open) {
      setIsCropper(true);
      setOpen(true);
    }
  }, [open, setModalOpen, upImg]);

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setFile({
        Route_File: firebaseURL,
        FirebaseId: firebaseID,
      });
    }
  }, [firebaseURL, firebaseID]);

  return (
    <Container>
      {!isCropper ? (
        <Container>
          <Title>{title}</Title>
          <div className=" title-center">
            <input
              accept="image/*"
              className="input-file"
              id="fileSelectorImg"
              type="file"
              onChange={hadleOnSelectFile}
            />
            <div className="profilepic">
              <Image.Container size="64px sq.">
                <Image
                  className="profilepic__image"
                  height="150"
                  src={validateImgRoute()}
                  width="150"
                />
              </Image.Container>
              <div className="profilepic__content">
                <Icon className="hover-table-options ">
                  <FontAwesomeIcon
                    icon="camera"
                    size="1x"
                    onClick={handleSelectClick}
                  />
                </Icon>
              </div>
            </div>
          </div>
          <SelectCabysCode
            defaultValue={{
              value: cabysName || "",
              label: CabysCode || "Buscar...",
            }}
            handleChange={handleSelectChange}
          />
          <Field>
            <Control>
              <SelectInventories
                className="select-iva"
                handleAddInventory={createInventory}
                hanldeEditInventory={editInventory}
                name="inventory"
                style={{
                  display: !product ? "none" : "block",
                }}
                value={inventory}
                onChange={handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>Nombre del producto</Label>
              <Input
                color="dark"
                name="Name_Product"
                placeholder="Nombre del producto"
                type="text"
                value={Name_Product}
                onChange={({ target: { value, name } }) =>
                  handleChange(name, value)
                }
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>Descripción del producto</Label>
              <Input
                color="dark"
                name="Description_Product"
                placeholder="Descripción del producto"
                type="text"
                value={Description_Product}
                onChange={({ target: { value, name } }) =>
                  handleChange(name, value)
                }
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <SelectUnitMeasure
                className="select-iva"
                name="MeasurementUnit"
                secondaryMeasureName="otherUnit"
                secondaryMeasureValue={otherUnit}
                value={MeasurementUnit}
                onChange={handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>Precio Unitario</Label>
              <Input
                color="dark"
                name="price"
                placeholder="0"
                type="number"
                value={price}
                onChange={({ target: { value, name } }) =>
                  handleChange(name, value)
                }
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <SelectIva
                className="select-iva"
                name="iva"
                value={iva}
                onChange={handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <TaxSelect
                className="select-iva"
                name="tax"
                value={tax}
                onChange={handleChange}
              />
            </Control>
          </Field>
          <Container className="newProductButtons">
            <Button
              className="edit-button"
              size="medium"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              disabled={!isValidProduct()}
              size="medium"
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          </Container>
        </Container>
      ) : (
        <CropperPlace
          isProduct
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setIsCropper(false)}
        />
      )}
    </Container>
  );
}

NewOrEditProduct.propTypes = {
  setCloseModal: PropTypes.func,
  product: PropTypes.object,
  title: PropTypes.string.isRequired,
  refetch: PropTypes.object,
};

NewOrEditProduct.defaultProps = {
  setCloseModal: e => e,
  product: undefined,
  refetch: {},
};

export default NewOrEditProduct;
