import React from "react";
import PropTypes from "prop-types";
// SCSS
import "./Divider.scss";

const Divider = ({ type }) => <div className={`divider divider--${type}`} />;

Divider.propTypes = {
  type: PropTypes.oneOf(["transparent", "shadow"]),
};
Divider.defaultProps = { type: "" };

export default Divider;
