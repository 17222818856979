import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Title,
  Button,
  Field,
  Control,
  Label,
  Input,
  Table,
  Checkbox,
} from "rbx";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_SUBMODULES_BY_MODULE } from "../../../../graphql/submodule/submodule.queries";
import {
  CREATE_ROL_WITH_SUBMODULE,
  UPDATE_ROL_WITH_SUBMODULE,
} from "../../../../graphql/role/role.mutation";
import { useElectronic } from "../../../../context/ElectronicContext";
import { GET_ROLE_PERMISSION_BY_ROLE } from "../../../../graphql";
import "./NewOrEditRole.scss";

function NewOrEditRole({
  setCloseModal,
  title,
  refetch,
  componentMode,
  editRole,
}) {
  const isCreateMode = componentMode === "create";

  const [nameRole, setNameRole] = useState("");
  const [submodules, setSubmodules] = useState([]);

  const { LineDetailPlace } = useElectronic();
  // mutation
  const [createRoleWithSubmodules] = useMutation(CREATE_ROL_WITH_SUBMODULE);
  const [updateRoleWithSubmodules] = useMutation(UPDATE_ROL_WITH_SUBMODULE);

  // queries
  const { data: permissionsSubmodulesByRole, refetch: refetchPermissions } =
    useQuery(GET_ROLE_PERMISSION_BY_ROLE, {
      variables: {
        FK_Role: editRole.id,
      },
    });

  const { data: submodulesByModule } = useQuery(GET_SUBMODULES_BY_MODULE, {
    variables: {
      FK_Module: 11,
    },
  });
  useEffect(() => {
    if (isCreateMode) {
      if (submodulesByModule?.getSubmodulesByModule) {
        const newSubmodules = submodulesByModule?.getSubmodulesByModule.map(
          role => ({
            id: role.id,
            Name_Submodule: role.Name_Submodule,
            Route_Submodule: role.Route_Submodule,
            access: false,
            actions: {
              read: false,
              create: false,
              edit: false,
              delete: false,
              all: false,
            },
          }),
        );
        setSubmodules(newSubmodules);
      }
    } else {
      setNameRole(editRole.Name_Role);
      if (submodulesByModule?.getSubmodulesByModule) {
        const newSubmodules = submodulesByModule?.getSubmodulesByModule.map(
          role => ({
            id: role.id,
            Name_Submodule: role.Name_Submodule,
            Route_Submodule: role.Route_Submodule,
            access: getAcccess(
              permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
              role.id,
            ),
            actions: {
              read: haveSubmoduleAction(
                "1",
                permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
                role.id,
              ),
              create: haveSubmoduleAction(
                "2",
                permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
                role.id,
              ),
              edit: haveSubmoduleAction(
                "3",
                permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
                role.id,
              ),
              delete: haveSubmoduleAction(
                "4",
                permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
                role.id,
              ),
              all: false,
            },
          }),
        );
        setSubmodules(newSubmodules);
      }
    }
    return () => {};
  }, [
    componentMode,
    editRole,
    isCreateMode,
    permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
    submodulesByModule,
  ]);

  const getAcccess = (permissions, sub) => {
    const access = permissions?.find(
      permission => permission.FK_Submodule === sub,
    );
    return !!access;
  };

  const haveSubmoduleAction = (FK_Permission, permissions, FK_Submodule) => {
    const action = permissions?.find(
      permission =>
        permission.FK_Submodule === FK_Submodule &&
        (permission.FK_Permission === FK_Permission ||
          permission.FK_Permission === "5"),
    );
    return !!action;
  };
  const handleChangeSubmodule = pos => {
    const updatedCheckedState = submodules.map((item, index) =>
      index === pos
        ? {
            id: item.id,
            Name_Submodule: item.Name_Submodule,
            Route_Submodule: item.Route_Submodule,
            access: !item.access,
            actions: {
              read: false,
              create: false,
              edit: false,
              delete: false,
              all: false,
            },
          }
        : item,
    );
    setSubmodules(updatedCheckedState);
  };

  const handleChangePermissionSubmodule = (pos, event) => {
    const updatedCheckedState = submodules.map((item, index) =>
      index === pos
        ? {
            id: item.id,
            Name_Submodule: item.Name_Submodule,
            Route_Submodule: item.Route_Submodule,
            access: item.access,
            actions: actionsChange(item.actions, event.target.name),
          }
        : item,
    );
    setSubmodules(updatedCheckedState);
  };

  const actionsChange = (actionsObject, action) => {
    const actions = actionsObject;
    actions[action] = !actionsObject[action];
    return actions;
  };

  const handleCancel = useCallback(() => {
    setCloseModal(false);
  }, [setCloseModal]);

  const createRole = useCallback(async () => {
    try {
      const { data } = await createRoleWithSubmodules({
        variables: {
          role: {
            Name_Role: nameRole,
            Value_Role: 1,
            FK_Place: LineDetailPlace.id,
          },
          submodules,
        },
      });
      if (data) {
        toast.success("¡Rol registrado con éxito!");
        handleCancel();
        refetch();
      } else {
        toast.error("Error al registrar el Rol");
      }
    } catch (err) {
      toast.error("Error al registrar el Rol");
    }
  }, [
    createRoleWithSubmodules,
    nameRole,
    LineDetailPlace.id,
    submodules,
    handleCancel,
    refetch,
  ]);

  const updateRole = useCallback(async () => {
    try {
      const { data } = await updateRoleWithSubmodules({
        variables: {
          role: {
            Name_Role: nameRole,
            Value_Role: 1,
            FK_Place: LineDetailPlace.id,
            id: editRole.id,
          },
          submodules,
        },
      });
      if (data.role) {
        toast.success("¡Rol actualizado con éxito!");
        handleCancel();
        refetch();
        refetchPermissions();
      } else {
        toast.error("Error al actualizar el rol");
      }
    } catch (err) {
      toast.error("Error al actualizar el rol");
    }
  }, [
    LineDetailPlace.id,
    editRole.id,
    handleCancel,
    nameRole,
    refetch,
    refetchPermissions,
    submodules,
    updateRoleWithSubmodules,
  ]);

  const handleSaveAndUpdateRole = async e => {
    e.preventDefault();
    if (nameRole === "") {
      toast.error("Debe completar los datos solicitados.");
    } else if (isCreateMode) {
      createRole();
    } else {
      updateRole();
      refetch();
    }
  };

  useEffect(() => {
    refetchPermissions();
  }, [refetchPermissions]);

  return (
    <Container>
      <Title className="profile-page-title">{title}</Title>
      <Container>
        <Field>
          <Control>
            <Label>Nombre del rol</Label>
            <Input
              color="dark"
              name="nameRole"
              placeholder="Nombre del rol"
              type="text"
              value={nameRole}
              onChange={e => setNameRole(e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>¿A cuáles módulos tiene acceso el rol?</Label>
            <div className="container-table-rol">
              <Table bordered fullwidth hoverable narrow striped>
                <Table.Head>
                  <Table.Row>
                    <Table.Heading className="table-head">
                      <abbr className="table-head">Nombre del módulo</abbr>
                    </Table.Heading>
                    <Table.Heading className="table-head">
                      <abbr className="table-head">Acceso al módulo</abbr>
                    </Table.Heading>
                    <Table.Heading className="table-head">
                      <abbr className="table-head">Leer</abbr>
                    </Table.Heading>
                    <Table.Heading className="table-head">
                      <abbr className="table-head">Crear</abbr>
                    </Table.Heading>
                    <Table.Heading className="table-head">
                      <abbr className="table-head">Editar</abbr>
                    </Table.Heading>
                    <Table.Heading className="table-head">
                      <abbr className="table-head">Eliminar</abbr>
                    </Table.Heading>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {submodules?.map((item, index) => (
                    <Table.Row key={item.id}>
                      <Table.Cell className="table-item">
                        {item.Name_Submodule}
                      </Table.Cell>
                      <Table.Cell>
                        <Control>
                          <Label>
                            <Checkbox
                              checked={item.access}
                              className="check"
                              name="access"
                              value={item.access}
                              onChange={e => handleChangeSubmodule(index)}
                            />
                            ¿Tiene acceso?
                          </Label>
                        </Control>
                      </Table.Cell>
                      {item.access && (
                        <React.Fragment>
                          <Table.Cell className="check">
                            <Control>
                              <Label>
                                <Checkbox
                                  checked={item.actions.read}
                                  className="check"
                                  name="read"
                                  value={item.actions.read}
                                  onChange={e =>
                                    handleChangePermissionSubmodule(index, e)
                                  }
                                />
                              </Label>
                            </Control>
                          </Table.Cell>
                          <Table.Cell className="check">
                            <Control>
                              <Label>
                                <Checkbox
                                  checked={item.actions.create}
                                  className="check"
                                  name="create"
                                  value={item.actions.create}
                                  onChange={e =>
                                    handleChangePermissionSubmodule(index, e)
                                  }
                                />
                              </Label>
                            </Control>
                          </Table.Cell>
                          <Table.Cell className="check">
                            <Control>
                              <Label>
                                <Checkbox
                                  checked={item.actions.edit}
                                  className="check"
                                  name="edit"
                                  value={item.actions.edit}
                                  onChange={e =>
                                    handleChangePermissionSubmodule(index, e)
                                  }
                                />
                              </Label>
                            </Control>
                          </Table.Cell>
                          <Table.Cell className="check">
                            <Control>
                              <Label>
                                <Checkbox
                                  checked={item.actions.delete}
                                  className="check"
                                  name="delete"
                                  value={item.actions.delete}
                                  onChange={e =>
                                    handleChangePermissionSubmodule(index, e)
                                  }
                                />
                              </Label>
                            </Control>
                          </Table.Cell>
                        </React.Fragment>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </Control>
        </Field>
      </Container>
      <Container className="newProductButtons">
        <Button className="edit-button" size="medium" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button color="primary" size="medium" onClick={handleSaveAndUpdateRole}>
          {isCreateMode ? "Guardar" : "Actualizar"}
        </Button>
      </Container>
    </Container>
  );
}

NewOrEditRole.propTypes = {
  setCloseModal: PropTypes.func,
  title: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  componentMode: PropTypes.string.isRequired,
  editRole: PropTypes.object,
};

NewOrEditRole.defaultProps = {
  setCloseModal: e => e,
  editRole: { id: 0 },
};

export default NewOrEditRole;
