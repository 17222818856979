import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// External components
import { Card, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useMutation } from "@apollo/client";
import { UPDATE_TEMP_CLIENT } from "../../../../../graphql/bill/bill.mutation";
// Context
import { useElectronic } from "../../../../../context/ElectronicContext";
// SCSS
import "./ClientCard.scss";

function ClientCard({ clientList }) {
  const { setReceiver, setAddress, electronicBill, setClientGroup } =
    useElectronic();
  const history = useHistory();

  const [updateClient] = useMutation(UPDATE_TEMP_CLIENT);

  const selectIcon = TypeId => {
    if (TypeId === "1") {
      return "user";
    }
    return "building";
  };

  const handleSelectClient = newValue => {
    const billingProfile = {
      id: newValue.id,
      Receiver_Id: newValue.ID_Number,
      Receiver_Name: newValue.Name,
      Receiver_Email: newValue.Email,
      Receiver_PhoneCode: newValue.PhoneCodCountry,
      Receiver_PhoneNumber: newValue.PhoneNumber,
      Receiver_PersonType: newValue.MH_PersonType,
      user: { id: newValue.User.id },
    };

    const address = {
      Province: newValue.MH_Neighborhood.District.Canton.Province,
      Canton: newValue.MH_Neighborhood.District.Canton,
      District: newValue.MH_Neighborhood.District,
      Neighborhood: newValue.MH_Neighborhood,
      Other: newValue.OtherSigns,
    };

    if (electronicBill.id) {
      updateClient({
        variables: {
          id: electronicBill.id,
          FK_User: newValue.User.id,
        },
      });
    }

    setAddress(address);
    setReceiver(billingProfile);
    setClientGroup({ id: 0 });
    history.goBack();
  };

  const getAddres = client =>
    `${client.BillingProfile.MH_Neighborhood?.District?.Canton?.Province?.Name},
     ${client.BillingProfile.MH_Neighborhood?.District?.Canton?.Name}, 
     ${client.BillingProfile.MH_Neighborhood?.District.Name}`;

  return (
    <div className="grid">
      {clientList?.map(client => (
        <Card
          key={client.BillingProfile.id}
          className="card-content principal-container hover-table-options"
          onClick={() => {
            handleSelectClient(client.BillingProfile);
          }}
        >
          <div key={client.BillingProfile.id} className="client-header">
            <div className="client-details">
              <h3>{client.BillingProfile.Name}</h3>
              <h4>{client.BillingProfile.MH_PersonType?.Name_PersonType}</h4>
              <h4>{client.BillingProfile.ID_Number}</h4>
            </div>
            <h4>{getAddres(client)}</h4>
          </div>
          <div className="principal-container client-footer">
            <Icon size="medium">
              <FontAwesomeIcon
                icon={selectIcon(client.BillingProfile.MH_PersonType?.id)}
                size="4x"
              />
            </Icon>
          </div>
        </Card>
      ))}
    </div>
  );
}
ClientCard.defaultProps = {
  clientList: [],
};
ClientCard.propTypes = {
  clientList: PropTypes.array,
};

export default ClientCard;
