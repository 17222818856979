import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
// Components
import Inventories from "../../components/Inventories";
// Context
import { useElectronic } from "../../context/ElectronicContext";

const InventoryRequireRoute = ({ component: Component, path, ...rest }) => {
  const { LineDetailPlace } = useElectronic();
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      path={path}
    >
      {LineDetailPlace.id === 0 ? <Inventories /> : <Component />}
    </Route>
  );
};

InventoryRequireRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default InventoryRequireRoute;
