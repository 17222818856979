import React from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control, Label } from "rbx";
// SCSS
import "../CountrySelect/CountrySelect.scss";

const MultiSelect = ({ value, name, onChange, label, data, className }) => (
  <Control className={className}>
    {label && <Label>{label}</Label>}
    <Select.Container fullwidth>
      <Select
        className="multi-selector"
        name={name}
        value={value}
        onChange={e => onChange(e.target.name, e.target.value)}
      >
        {data?.map(d => (
          <Select.Option
            key={d.Id}
            style={d?.Styles && d.Styles(d)}
            value={d.Id}
          >
            {d.Name}
          </Select.Option>
        ))}
      </Select>
    </Select.Container>
  </Control>
);

MultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

MultiSelect.defaultProps = {
  label: null,
  value: "",
  className: "",
};

export default MultiSelect;
