import React from "react";
import PropTypes from "prop-types";
// External components
import { Column } from "rbx";

const PersonSection = ({ person }) =>
  person ? (
    <React.Fragment>
      <Column.Group>
        <Column>
          <h2>
            <small>Nombre: </small>
            {person.Name}{" "}
          </h2>
          <h2>
            <small>Nombre Comercial: </small>
            {person.ComercialName || "--"}
          </h2>
          <h2>
            <small>Telefono: </small>
            {person?.PhoneNumber || "---"}{" "}
          </h2>
          <h2>
            <small>Distrito: </small>
            {person.MH_District?.Name || "---"}{" "}
          </h2>
        </Column>
        <Column>
          <h2>
            <small>Cedula: </small>
            {person.ID_Number || "--"}
          </h2>
          <h2>
            <small>E-mail: </small>
            {person.Email}
          </h2>
          <h2>
            <small>Provincia: </small>
            {person?.MH_Province?.Name || "---"}
          </h2>
          <h2>
            <small>Canton: </small>
            {person.MH_Canton.Name || "--"}
          </h2>
          <h2>
            <small>Barrio: </small>
            {person.MH_Neighborhood.Name || "---"}
          </h2>
        </Column>
      </Column.Group>
      <h2>
        <small>Otras señas:</small> {person.OtherSigns || "  --"}
      </h2>
      <br />
    </React.Fragment>
  ) : (
    ""
  );

PersonSection.propTypes = {
  person: PropTypes.object.isRequired,
};

export default PersonSection;
