import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// External components
import { Card, Image, Input } from "rbx";
// Components
import ProfileBillWrapper from "../ProfileBillWrapper";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { validateRoute } from "../../utils/helpers";
// Hooks
import usePlaces from "../../hooks/usePlaces";
// SCSS
import "../../styles/index.scss";
import "./Inventories.scss";

const INITIAL_STATE = {
  user: {
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: "",
    FK_Country: "",
    Phone_Number: "",
    Email: "",
  },
  BillingProfile: null,
};

const Inventories = ({ setModalClose, onClickFunction, bill }) => {
  const [billing, setBilling] = useState(INITIAL_STATE);
  const [searchQuery, setSearchQuery] = useState("");

  const { LineDetailPlace, setLineDetailPlace, setLineDetail, resetReceiver } =
    useElectronic();
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();
  const isSecurityResetUrl = window.location?.pathname.includes(
    "/security/resetPassword/",
  );

  const history = useHistory();
  const user = authState.user.TavuelUser;

  const { GetPLacesByUserIdAndModuleId } = usePlaces();

  const [loading, dataPlaces, error] = GetPLacesByUserIdAndModuleId(user?.id);

  const setBillingProfile = BillingProfile =>
    setBilling(prv => ({ ...prv, BillingProfile }));

  useEffect(() => {
    if (
      authState?.user?.TavuelUser?.BillingProfile === null &&
      !isSecurityResetUrl
    ) {
      createElectronicBillProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createElectronicBillProfile = () => {
    setModalOpen(
      true,
      <ProfileBillWrapper
        initialReceiver={billing?.BillingProfile}
        setBillingProfile={setBillingProfile}
        setModalOpen={setModalOpen}
      />,
    );
  };

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const handleSelectPlace = id => {
    if (onClickFunction) return onClickFunction(id, bill?.id);
    return selectPlace(id);
  };

  const selectPlace = id => {
    let placeSelected;
    let isSamePlace = false;

    if (id !== LineDetailPlace.id) {
      placeSelected = dataPlaces?.places.find(placeItem => placeItem.id === id);
    } else {
      placeSelected = LineDetailPlace.id;
      isSamePlace = true;
    }

    if (placeSelected) {
      if (!isSamePlace) {
        setLineDetailPlace(placeSelected);
        setLineDetail([]);
        resetReceiver();
        setModalClose(false);
      }
      if (
        isStringIncludedIn(window.location?.pathname, [
          "/store",
          "/inventories",
        ])
      )
        history.goBack();
    } else if (placeSelected.length > 0 && LineDetailPlace.id === 0) {
      setLineDetailPlace(placeSelected[0]);
      setLineDetail([]);
      resetReceiver();
    }
  };

  const isStringIncludedIn = (lineToSearchIn, linesToSearch) => {
    for (let i = 0; i < linesToSearch.length; i += 1) {
      if (lineToSearchIn.includes(linesToSearch[i])) return true;
    }
    return false;
  };

  const filterPlace = places =>
    places?.filter(inventory =>
      inventory.Name_Place.trim()
        .split(" ")
        .toLocaleString()
        .replaceAll(",", "")
        .toLocaleUpperCase()
        .includes(
          searchQuery
            .trim()
            .split(" ")
            .toLocaleString()
            .replaceAll(",", "")
            .toLocaleUpperCase(),
        ),
    );

  return (
    <div>
      {LineDetailPlace.id !== 0 && (
        <div>
          <h1 className="title-center">{LineDetailPlace.Name_Place}</h1>
        </div>
      )}
      <div>
        <Input
          className="search-bar"
          placeholder="Buscar..."
          value={searchQuery}
          onChange={({ target: { value } }) => setSearchQuery(value)}
        />
        <hr />
      </div>
      <div className="invetories-container">
        {filterPlace(dataPlaces?.places)?.map(placeItem => (
          <div
            key={placeItem.id}
            className="hover-table-options principal-container invetories-container__invetory"
            role="button"
            tabIndex={0}
            onClick={() => handleSelectPlace(placeItem.id)}
            onKeyDown={() => null}
          >
            <Card
              key={placeItem.id}
              className={` card-content ${
                placeItem.id === LineDetailPlace?.id && "selected-place"
              }`}
            >
              <div className="title-product">
                <h2>{placeItem.Name_Place}</h2>
              </div>
              <div className="flex">
                <div className="content-right">
                  <div className="product-img ">
                    <Image.Container size="32px sq.">
                      <Image src={validateRoute(placeItem)} />
                    </Image.Container>
                  </div>
                </div>
                <div
                  className="flex middle "
                  style={{ display: "inline-block" }}
                >
                  <b className="detail-product ellipsis three-lines">
                    {placeItem?.Code || "Sin código"}
                  </b>
                  <p className="detail-product ellipsis three-lines">
                    {placeItem.Description_Place}{" "}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

Inventories.propTypes = {
  setModalClose: PropTypes.func,
  onClickFunction: PropTypes.func,
  bill: PropTypes.object,
};

Inventories.defaultProps = {
  setModalClose: e => e,
  onClickFunction: null,
  bill: {},
};

export default Inventories;
