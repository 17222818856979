import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
// External components
import { Card, Button, Icon, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
// Graphql
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_GROUPS_BY_PLACE,
  REGISTER_NEW_CLIENT_GROUP,
  UPDATE_CLIENT_GROUP,
  DELETE_CLIENT_GROUP,
} from "../../graphql";
import ClientGroupRegister from "../ClientGroupRegister";
// Utils
import { customToast as toast } from "../../utils";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./ClientsGroupsXPlace.scss";
import "../ProductCard/ProductCard.scss";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

function ClientsGroupsXPlace() {
  const { LineDetailPlace } = useElectronic();
  const history = useHistory();
  const { setModalOpen } = useModal();

  const {
    loading,
    data: dataClientsGroups,
    error,
  } = useQuery(GET_ALL_GROUPS_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });
  const [registerGroup] = useMutation(REGISTER_NEW_CLIENT_GROUP);
  const [updateGroup] = useMutation(UPDATE_CLIENT_GROUP);
  const [deleteGroup] = useMutation(DELETE_CLIENT_GROUP);
  const { setModalOpen: setDeleteConfirmationModal } = useModal();
  const { permissions } = useAuth();

  const { haveActionPermission, noAccessMessage } = permissions;

  const canRead = haveActionPermission("Read", "/clients");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Clientes"));
    history.push(`/`);
  }
  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const handleSelectGroup = group => {
    history.push(
      `/clients-groups/groups/${LineDetailPlace.id}/${LineDetailPlace.Name_Place}/clients/${group?.id}/${group?.Name_ClientGroup}/${LineDetailPlace.id}`,
    );
  };
  const register = useCallback(
    async newGroup => {
      try {
        setModalOpen(false);
        const { data } = await registerGroup({
          variables: {
            data: newGroup,
          },
          refetchQueries: [
            {
              query: GET_ALL_GROUPS_BY_PLACE,
              variables: {
                FK_Place: LineDetailPlace.id,
              },
            },
          ],
        });
        if (data) {
          toast.success("¡Grupo registrado con éxito!");
        }
      } catch (err) {
        toast.error("Error al registrar el grupo");
      }
    },
    [registerGroup, setModalOpen, LineDetailPlace.id],
  );
  const update = useCallback(
    async newGroup => {
      try {
        setModalOpen(false);
        const { data } = await updateGroup({
          variables: {
            data: newGroup,
          },
          refetchQueries: [
            {
              query: GET_ALL_GROUPS_BY_PLACE,
              variables: {
                FK_Place: LineDetailPlace.id,
              },
            },
          ],
        });
        if (data) {
          toast.success("¡Grupo editado con éxito!");
        }
      } catch (err) {
        toast.error("Error al editar el grupo");
      }
    },
    [setModalOpen, updateGroup, LineDetailPlace.id],
  );
  const handleAddGroup = () => {
    setModalOpen(
      true,
      <ClientGroupRegister
        placeId={LineDetailPlace.id}
        registerGroup={register}
        setModalOpen={setModalOpen}
      />,
    );
  };

  const handleEditGroup = (id, name_group, description_group) => {
    setModalOpen(
      true,
      <ClientGroupRegister
        descriptionGroup={description_group}
        groupId={id}
        nameGroup={name_group}
        placeId={LineDetailPlace.id}
        setModalOpen={setModalOpen}
        updateGroup={update}
      />,
    );
  };

  const handleDeleteGroup = async id => {
    setDeleteConfirmationModal(
      true,
      <ConfirmationModal
        body="¿Desea eliminar este usuario de tu grupo?"
        header="¿Eliminar usuario?"
        onCancel={() => setDeleteConfirmationModal(false)}
        onConfirm={async () => {
          try {
            const { data } = await deleteGroup({
              variables: {
                id,
              },
              refetchQueries: [
                {
                  query: GET_ALL_GROUPS_BY_PLACE,
                  variables: {
                    FK_Place: LineDetailPlace.id,
                  },
                },
              ],
            });
            if (data) {
              toast.success("¡Grupo eliminado con éxito!");
            }
          } catch (err) {
            toast.error("Error al eliminar el grupo");
          } finally {
            setDeleteConfirmationModal(false);
          }
        }}
      />,
    );
  };

  return (
    <div>
      <div>
        <Title className="title-center"> Grupos</Title>
      </div>
      {haveActionPermission("Create", "/clients") && (
        <div className="groups-header ">
          <Button color="primary" onClick={handleAddGroup}>
            Nuevo grupo
          </Button>
        </div>
      )}
      <div className="grid">
        {Array.isArray(dataClientsGroups?.groups) &&
          dataClientsGroups?.groups?.length > 0 &&
          dataClientsGroups?.groups?.map(groupItem => (
            <div
              key={groupItem.id}
              className="hover-table-options principal-container"
            >
              <Card
                key={groupItem.id}
                className="card-content-group groups-content"
              >
                <div
                  className="group_information"
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSelectGroup(groupItem)}
                  onKeyDown={() => null}
                >
                  <div className="title-product">
                    <h2>{groupItem.Name_ClientGroup}</h2>
                  </div>
                  <div className="flex">
                    <div className="content-right">
                      <div className="product-img ">
                        <Icon size="large">
                          <FontAwesomeIcon
                            className="hover-table-options"
                            icon="users"
                            size="3x"
                          />
                        </Icon>
                      </div>
                    </div>
                    <div className="flex middle ">
                      <p className="detail-product ellipsis three-lines">
                        {groupItem.Description_ClientGroup}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  {haveActionPermission("Delete", "/clients") && (
                    <div>
                      <Icon
                        data-tip
                        className="hover-table-options icon-cancel"
                        data-delay-show="500"
                        data-for="ButtonDelete"
                      >
                        <FontAwesomeIcon
                          icon="times"
                          size="1x"
                          onClick={() => handleDeleteGroup(groupItem.id)}
                        />
                      </Icon>
                      <ReactTooltip id="ButtonDelete" type="info">
                        <span>Elimina el grupo</span>
                      </ReactTooltip>
                    </div>
                  )}
                  {haveActionPermission("Edit", "/clients") && (
                    <div>
                      <Icon
                        data-tip
                        className="hover-table-options icon-edit"
                        data-delay-show="500"
                        data-for="ButtonEdit"
                      >
                        <FontAwesomeIcon
                          icon="edit"
                          size="1x"
                          onClick={() =>
                            handleEditGroup(
                              groupItem.id,
                              groupItem.Name_ClientGroup,
                              groupItem.Description_ClientGroup,
                            )
                          }
                        />
                      </Icon>
                      <ReactTooltip id="ButtonEdit" type="info">
                        <span>Edita la información del grupo</span>
                      </ReactTooltip>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          ))}
      </div>
      {dataClientsGroups?.groups?.length === 0 && (
        <div>
          <Title className="title-center">
            Aún no hay grupos registrados para {LineDetailPlace.Name_Place}.
          </Title>
        </div>
      )}
    </div>
  );
}

ClientsGroupsXPlace.propTypes = {};

ClientsGroupsXPlace.defaultProps = {};

export default ClientsGroupsXPlace;
