import React from "react";
import PropTypes from "prop-types";
// External components
import { Card, Image } from "rbx";
// Context
import { useAuth } from "../../../../context";
// Utils
import TransformImages from "../../../../utils/TransformImages";

function ReportProductCard({ productList }) {
  const { formatAmount } = useAuth();

  const getUrl = product => {
    const route = product.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const validateRoute = product => {
    let route = getUrl(product);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    route = TransformImages(product, 1);
    return route;
  };

  const getDetails = product => {
    let details = null;
    product.Details?.forEach(item => {
      if (item?.Value_ProductFeatureDetail !== null) {
        details = !details
          ? item.Value_ProductFeatureDetail
          : `${details} / ${item.Value_ProductFeatureDetail}`;
      }
    });
    return details;
  };

  return (
    <div className="grid">
      {productList.map(product => (
        <div key={product.id} className=" principal-container">
          <Card className="principal-container card-content">
            <div className="content-left">
              <div className="product-header title-product ">
                <h2 className="title-product ellipsis one-line ">
                  {product?.Product?.Name_Product}
                </h2>
                <h3 className="title-product ellipsis one-line ">
                  {product?.Product?.Description_Product}
                </h3>
                <div>
                  <p className="details-product ellipsis one-line">
                    {getDetails(product)}
                  </p>
                  <p>
                    {
                      product?.Product?.InventoryDetail?.UnitOfMeasurement
                        ?.Symbol
                    }{" "}
                    {formatAmount(product?.ProductPrice?.Price_ProductPrice)}
                  </p>
                  <p>Cabys: {product?.ProductPrice?.Cabys?.Code}</p>
                  <p>Imp: {product?.ProductPrice?.Tariff?.Percent}%</p>
                </div>
              </div>
            </div>
            <div className="content-right ">
              <Image.Container size="65px sq.">
                <Image src={validateRoute(product?.Files)} />
              </Image.Container>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}
ReportProductCard.propTypes = {
  productList: PropTypes.array,
};
ReportProductCard.defaultProps = {
  productList: [],
};

export default ReportProductCard;
