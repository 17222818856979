import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { Input, Button, Tag, Delete, Notification } from "rbx";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_CLIENTS_BY_PLACE } from "../../graphql";
// Utils
import { customToast as toast, validateEmail } from "../../utils";
// SCSS
import "./SendBillScreen.scss";

function SendBillScreen({
  place,
  handleCloseModal,
  billingEmail,
  handleSend,
  receptorRequired,
}) {
  const [receivers, setReceivers] = useState([]);
  const [searchForm, setSearchForm] = useState("");

  const { loading, data: clientsByIdCompany } = useQuery(GET_CLIENTS_BY_PLACE, {
    variables: {
      FK_Place: place?.id,
    },
  });

  useEffect(() => {
    if (billingEmail) {
      setReceivers([billingEmail]);
    }
  }, [billingEmail]);

  const addClientEmail = (email = searchForm) => {
    if (
      email.length === 0 ||
      receivers.find(receiver_email => receiver_email === email) ||
      !validateEmail(email)
    )
      return;
    setReceivers(prev => [...prev, email]);
    setSearchForm("");
  };

  const removeClientEmail = email => {
    setReceivers(prev => [
      ...prev.filter(receiver_email => receiver_email !== email),
    ]);
  };

  const SendEmails = async () => {
    try {
      await handleSend(receivers);
      handleCloseModal();
    } catch (e) {
      toast.error("Error al enviar el email");
    }
  };

  return (
    <div className="send-bill-screen">
      <h1 className="send-bill-screen__tittle">Enviar factura por correo</h1>
      <hr />
      <h2>Receptores</h2>
      <div className="send-bill-screen__receiver-container">
        {receivers.map(client => (
          <Tag key={client} color="primary" size="medium">
            {client}
            <Delete size="medium" onClick={() => removeClientEmail(client)} />
          </Tag>
        ))}
        {!receivers.length && <p>Seleccion o agrege un receptor</p>}
      </div>

      <hr />
      <div className="send-bill-screen__search-container">
        <Input
          placeholder="Correo del receptor..."
          type="email"
          value={searchForm}
          onChange={({ target: { value } }) => setSearchForm(value)}
        />
        <Button
          color="primary  send-bill-screen__search-button"
          onClick={() => addClientEmail()}
        >
          Agregar
        </Button>
      </div>
      <div className="send-bill-screen__client-container">
        {clientsByIdCompany?.client
          ?.filter(
            _client =>
              _client.BillingProfile.Email.includes(searchForm) &&
              !receivers.includes(_client.BillingProfile.Email),
          )
          ?.map(_client => (
            <Notification
              key={_client.id}
              className="send-bill-item"
              onClick={() => addClientEmail(_client.BillingProfile.Email)}
            >
              <h3>{_client.BillingProfile.Name}</h3>
              <h3>{_client.BillingProfile.ID_Number}</h3>
              <h3>{_client.BillingProfile.Email}</h3>
            </Notification>
          ))}
      </div>
      {!loading &&
        clientsByIdCompany?.client?.filter(_client =>
          _client.BillingProfile.Email.includes(searchForm),
        ).length === 0 && (
          <Notification className="send-bill-screen__tittle">
            No se encontrarion Clientes
          </Notification>
        )}
      {loading && (
        <Notification className="send-bill-screen__tittle">
          Cargando...
        </Notification>
      )}

      <div className="send-bill-screen__actions-container">
        <Button
          color="primary"
          disabled={receptorRequired && !receivers.length}
          onClick={() => SendEmails()}
        >
          Enviar
        </Button>
        <Button color="danger" onClick={() => handleCloseModal()}>
          Cerrar
        </Button>
      </div>
    </div>
  );
}
SendBillScreen.propTypes = {
  place: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  billingEmail: PropTypes.string,
  handleSend: PropTypes.func.isRequired,
  receptorRequired: PropTypes.bool,
};
SendBillScreen.defaultProps = {
  billingEmail: null,
  receptorRequired: false,
};

export default SendBillScreen;
