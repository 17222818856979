import { useQuery } from "@apollo/client";
import { GET_PERMISSIONS_BY_USER_ID } from "../graphql";

function usePermissions(userId = 0) {
  const { data, refetch } = useQuery(GET_PERMISSIONS_BY_USER_ID, {
    variables: {
      FK_User: userId,
    },
  });

  const isSuperAdmin = !!data?.getPermissionsByUserId.find(
    p => p.Role?.Name_Role === "Superadmin",
  );

  const isSuperAdminOrHaveAccess = route => {
    if (route === "/profile") return true;
    if (route === "/notifications") return true; // delete this when we add the notification module to dev's database or the needed to
    const queryParams = window.location?.pathname;
    const isResetPassword =
      queryParams.includes("/resetPassword") && route === "/security";
    const haveAccess = data?.getPermissionsByUserId.find(p => {
      const permissions = p.Permissions;
      return permissions.find(per =>
        isResetPassword ? true : per.Submodule.Route_Submodule.includes(route),
      );
    });
    return !!(isSuperAdmin || haveAccess);
  };

  const haveActionPermission = (action, route) => {
    const havePermission = data?.getPermissionsByUserId.find(p => {
      const permissions = p.Permissions;
      const pp = permissions.find(per => {
        const actionName = per.ActionName;
        return (
          (actionName === "All" || actionName === action) &&
          per.Submodule.Route_Submodule.includes(route)
        );
      });
      return pp;
    });
    return !!(isSuperAdmin || havePermission);
  };

  const noAccessMessage = (action, module) =>
    `El usuario no tiene permiso de "${action}" ${module}. Consulte con el Administrador`;

  return {
    isSuperAdminOrHaveAccess,
    isSuperAdmin,
    haveActionPermission,
    refetch,
    noAccessMessage,
  };
}

export default usePermissions;
