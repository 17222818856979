import { useQuery } from "@apollo/client";
import { Card, Select } from "rbx";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import { GET_ALL_BILLS_WITH_BILL_CONFIGURATION } from "../../graphql";
import useElectronicBill from "../../hooks/useElectronicBill";
import ScheduledBill from "../ScheduledBill/ScheduledBill";
import {
  BILL_CONFIGURATION_FREQUENCY_TEXT,
  RESULT_BILL_CONFIGURATION_EVENTS,
} from "../../Enums/BillConfiguration";
import { GET_BILLCONFIGURATION_EVENTS } from "../../graphql/billConfigurationEvents";

const optionFrequency = Object.entries(BILL_CONFIGURATION_FREQUENCY_TEXT);

function ScheduledBillSearch() {
  const { LineDetailPlace } = useElectronicBill();
  const { pathname } = useLocation();

  const [scheduledBillSearchState, setScheduledBillSearchState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    frequency: "1",
  });

  const handleChangeScheduledBillSearch = (name, value) => {
    setScheduledBillSearchState(prev => ({ ...prev, [name]: value }));
  };

  const getActiveStatePendings = path => path === "pendings";
  const getActiveStateComplete = path => path === "complete";
  const getActiveStateFailed = path => path === "failed";

  const formatStartDate = () =>
    `${scheduledBillSearchState.startDate.toISOString().slice(0, 10)} 00:00:00`;
  const formatEndDate = () =>
    `${scheduledBillSearchState.endDate.toISOString().slice(0, 10)} 00:00:00`;

  const { data: BillConfiguration } = useQuery(
    GET_ALL_BILLS_WITH_BILL_CONFIGURATION,
    {
      variables: {
        ConfigurationBillInput: {
          where: {
            equals: {
              FK_Place: LineDetailPlace.id,
              Frequency: scheduledBillSearchState.frequency,
              // The 2 is to only take the part of the route after the last /
              Active: getActiveStatePendings(pathname.split("/")[2]),
            },
            between: {
              columnName: "date",
              value: [formatStartDate(), formatEndDate()],
            },
          },
        },
      },
    },
  );

  const { data: SuccessBillsConfiguration } = useQuery(
    GET_BILLCONFIGURATION_EVENTS,
    {
      variables: {
        FK_Place: LineDetailPlace.id,
        Frequency: scheduledBillSearchState.frequency,
        Active: getActiveStateComplete(pathname.split("/")[2]),
        Result: RESULT_BILL_CONFIGURATION_EVENTS.SUCCESS,
        startDate: formatStartDate(),
        endDate: formatEndDate(),
      },
    },
  );

  const { data: FailedBillsConfiguration } = useQuery(
    GET_BILLCONFIGURATION_EVENTS,
    {
      variables: {
        FK_Place: LineDetailPlace.id,
        Frequency: scheduledBillSearchState.frequency,
        Active: getActiveStateFailed(pathname.split("/")[2]),
        Result: RESULT_BILL_CONFIGURATION_EVENTS.FAILED,
        startDate: formatStartDate(),
        endDate: formatEndDate(),
      },
    },
  );

  return (
    <div>
      <Card className="card-summary secondary-content scheduled-bill-container">
        <div className="card-secondary-content-title flex">
          <h3>Buscar facturas programadas</h3>
        </div>
        <div className="custom-label">
          <div>
            <h3>Fecha de inicio</h3>
            <ReactDatePicker
              showFullMonthYearPicker
              className="date-picker has-text-centered"
              dateFormat="dd/MM/yyyy"
              selected={scheduledBillSearchState.startDate}
              onChange={newDate => {
                handleChangeScheduledBillSearch("startDate", newDate);
              }}
            />
          </div>
          <div>
            <h3>Fecha final</h3>
            <ReactDatePicker
              showFullMonthYearPicker
              className="date-picker has-text-centered"
              dateFormat="dd/MM/yyyy"
              selected={scheduledBillSearchState.endDate}
              onChange={newDate => {
                handleChangeScheduledBillSearch("endDate", newDate);
              }}
            />
          </div>
          <div>
            <h3>Frecuencia</h3>
            <Select.Container fullwidth>
              <Select
                name="Frequency"
                style={{
                  width: "200px",
                  height: "40px",
                  border: "solid 1px grey",
                  borderRadius: "0.6rem",
                }}
                value={scheduledBillSearchState.frequency}
                onChange={e => {
                  handleChangeScheduledBillSearch("frequency", e.target.value);
                }}
              >
                {optionFrequency?.map(option => (
                  <Select.Option key={option[0]} value={option[0]}>
                    {option[1]}
                  </Select.Option>
                ))}
              </Select>
            </Select.Container>
          </div>
        </div>
      </Card>
      <ScheduledBill
        BillConfiguration={BillConfiguration}
        FailedBillsConfiguration={FailedBillsConfiguration}
        SuccessBillsConfiguration={SuccessBillsConfiguration}
      />
    </div>
  );
}

export default ScheduledBillSearch;
