import { useState } from "react";

import {
  generateElectronicBillsCSVData,
  getD104Data,
} from "../utils/CSVHelper";
import { client, GET_ELECTRONICS_BILLS_NEW } from "../graphql";
import { useElectronic } from "../context/ElectronicContext";
import { calculateTaxes, customToast } from "../utils";

const taxes_initialState = [
  {
    id: "1",
    Name: "Tarifa general 13%",
    Code: "08",
    Percent: "13",
    Amount: 0,
    Total: 0,
  },
  {
    id: "2",
    Name: "Tarifa 0% (Exento)",
    Code: "01",
    Percent: "0",
    Amount: 0,
    Total: 0,
  },
  {
    id: "3",
    Name: "Tarifa reducida 1%",
    Code: "02",
    Percent: "1",
    Amount: 0,
    Total: 0,
  },
  {
    id: "4",
    Name: "Tarifa reducida 2%",
    Code: "03",
    Percent: "2",
    Amount: 0,
    Total: 0,
  },
  {
    id: "5",
    Name: "Tarifa reducida 4%",
    Code: "04",
    Percent: "4",
    Amount: 0,
    Total: 0,
  },
  {
    id: "6",
    Name: "Transitorio 0%",
    Code: "05",
    Percent: "0",
    Amount: 0,
    Total: 0,
  },
  {
    id: "7",
    Name: "Transitorio 4%",
    Code: "06",
    Percent: "4",
    Amount: 0,
    Total: 0,
  },
  {
    id: "8",
    Name: "Transitorio 8%",
    Code: "07",
    Percent: "8",
    Amount: 0,
    Total: 0,
  },
];
function UseCreateReport() {
  const [dataCSV, setDataCSV] = useState([]);
  const [electronicBills, setElectronicBills] = useState([]);
  const [isLoadingBills, setIsLoadingBills] = useState(false);
  const { useCoinDetail, LineDetailPlace } = useElectronic();
  const { toExchangeRate } = useCoinDetail;
  const [services, setServices] = useState([...taxes_initialState]);
  const [merch, setMerch] = useState([...taxes_initialState]);
  const [filterBills, setFilterBills] = useState([]);
  const [emitterList, setEmitter] = useState([]);
  const [totals, setTotals] = useState({
    Taxes: [...taxes_initialState],
    TotalGrav: 0,
    TotalExempt: 0,
    TotalTaxes: 0,
    TotalNetSell: 0,
    TotalBill: 0,
    TotalServicesTaxed: 0,
    TotalServicesExempt: 0,
    TotalMerchTaxed: 0,
    TotalMerchExempt: 0,
  });

  const getEBValuesOnCurrency = ({ data, headers }) => {
    const newData = data.map(bill => ({
      ...bill,
      totalTaxed: toExchangeRate(bill?.totalTaxed),
      totalExempt: toExchangeRate(bill?.totalExempt),
      totalExonerated: toExchangeRate(bill?.totalExonerated),
      totalDiscount: toExchangeRate(bill?.totalDiscount),
      totalNet: toExchangeRate(bill?.totalNet),
      totalIVA: toExchangeRate(bill?.totalIVA),
      totalBill: toExchangeRate(bill?.totalBill),
    }));
    return {
      data: newData,
      headers,
    };
  };

  const { data: dataToCSV, headers: headersToCSV } = getEBValuesOnCurrency(
    generateElectronicBillsCSVData(electronicBills),
  );

  const handleGetElectronicBills = async (date, billsType) => {
    setIsLoadingBills(true);
    const resp = await client.query({
      query: GET_ELECTRONICS_BILLS_NEW,
      variables: {
        getElectronicBillsInput: {
          where: {
            equals: {
              FK_OwnerPlace: LineDetailPlace.id,
              BillFlowType: billsType,
            },
            inMonth: date,
          },
        },
      },
      fetchPolicy: "no-cache",
    });
    const ebs = getValidBillsByReportType(
      resp?.data?.getElectronicBillsNew?.ElectronicBills || [],
      billsType,
    );

    if (resp?.data?.getElectronicBillsNew?.ElectronicBills?.length === 0) {
      customToast.warning(
        "¡No hay facturas registradas para el mes y año seleccionado!",
      );
    }
    setElectronicBills(ebs);
    setFilterBills(ebs);
    generateReport(ebs);
    setIsLoadingBills(false);
  };

  const isIncomeReport = reportType => reportType === 2;

  const getValidBillsByReportType = (data, reportType) => {
    if (isIncomeReport(reportType)) {
      return data.filter(bill =>
        ["TE", "FE"].includes(bill?.ElectronicBillDocumentType?.Value),
      );
    }
    return data;
  };
  const countEmitters = name => {
    let repeat = 0;
    if (emitterList.length !== 0) {
      emitterList.forEach(emitter => {
        if (emitter.Emitter === name) {
          repeat += 1;
        }
      });
    }
    return repeat;
  };

  const createEmitter = (emitt, company, id) => {
    const newEmitter = { Emitter: emitt, Company: company, Id: id };
    emitterList.push(newEmitter);
  };
  const isService = symbol =>
    symbol.toLowerCase() === "sp".toLowerCase() ||
    symbol.toLowerCase() === "spe".toLowerCase() ||
    symbol.toLowerCase() === "os".toLowerCase() ||
    symbol.toLowerCase() === "st".toLowerCase();

  const handleGetTaxes = (detail, eBill) => {
    const unit =
      detail?.ProductFeature?.Product?.InventoryDetail?.UnitOfMeasurement
        ?.Symbol;

    const price = detail?.Quantity * detail?.Price;
    // filter  by code of Tariff
    return taxes_initialState
      .filter(
        index =>
          index.Code === detail?.ProductFeature?.ProductPrice?.Tariff?.Code,
      )
      .reduce(
        (acc, tax) => {
          const taxDef = {
            name: isService(unit) ? "services" : "merch",
            currentValue: [...taxes_initialState],
          };
          const taxes = calculateTaxes(
            price,
            detail?.ProductFeature?.ProductPrice?.Tariff?.Percent,
          );

          const merchData = getTaxesMerch(
            taxDef.currentValue,
            tax.Code,
            taxes,
            price,
          );

          const totalsData = getTotals(totals, tax.Code, taxes, price);

          return {
            ...acc,
            [taxDef.name]: [...acc[taxDef.name], ...merchData],
            totals: {
              ...acc.totals,
              ...totalsData,
            },
          };
        },
        { totals: [], services: [], merch: [] },
      );

    // set states
  };

  const handleAddEmitter = eBill => {
    if (countEmitters(eBill?.Place?.Name_Place) === 0) {
      createEmitter(
        eBill?.Place?.Name_Place,
        eBill?.Place?.Company?.Description_Company,
        eBill?.Place?.Company?.User?.Person?.DocumentId_Person,
      );
    }
  };
  const getResumedData = ebs =>
    ebs.reduce(
      (acc, eBill) => {
        handleAddEmitter(eBill);
        acc.TotalGrav += eBill.TotalTaxed;
        acc.TotalExempt += eBill.TotalExempt;
        acc.TotalTaxes += eBill.TotalTaxes;
        acc.TotalNetSell += eBill.TotalNetSell;
        acc.TotalBill += eBill.TotalBill;
        acc.TotalServicesTaxed += eBill.TotalServicesTaxed;
        acc.TotalServicesExempt += eBill.TotalServicesExempt;
        acc.TotalMerchTaxed += eBill.TotalGoodsTaxed;
        acc.TotalMerchExempt += eBill.TotalGoodsExempt;

        return acc;
      },

      {
        TotalGrav: 0,
        TotalExempt: 0,
        TotalTaxes: 0,
        TotalNetSell: 0,
        TotalBill: 0,
        TotalServicesTaxed: 0,
        TotalServicesExempt: 0,
        TotalMerchTaxed: 0,
        TotalMerchExempt: 0,
      },
    );

  const sumTaxes = (currentTaxes, taxesToAdd) =>
    currentTaxes?.map(currentTax => {
      const tax = taxesToAdd?.find(taxToAdd => taxToAdd?.id === currentTax?.id);
      return tax
        ? {
            ...currentTax,
            Amount: currentTax?.Amount + tax?.Amount,
            Total: currentTax?.Total + tax?.Total,
          }
        : currentTax;
    });
  const sumTotalTaxes = (currentTaxes, taxesToAdd) => ({
    ...currentTaxes,
    Taxes: currentTaxes?.Taxes?.map(currentTax => {
      const tax = taxesToAdd?.Taxes?.find(
        taxToAdd => taxToAdd?.id === currentTax?.id,
      );

      return tax
        ? {
            ...currentTax,
            Amount: currentTax?.Amount + tax?.Amount,
            Total: currentTax?.Total + tax?.Total,
          }
        : currentTax;
    }),
  });

  const generateReport = ebs => {
    // restart initialState
    setServices([...taxes_initialState]);
    setMerch([...taxes_initialState]);

    const algo = ebs.reduce(
      (acc, eBill) => {
        const a = eBill.ElectronicBillDetail.reduce(
          (billsAcc, detail) => {
            const {
              totals: t,
              services: s,
              merch: m,
            } = handleGetTaxes(detail, eBill);
            return {
              totals: sumTotalTaxes(billsAcc.totals, t),
              services: sumTaxes(billsAcc.services, s),
              merch: sumTaxes(billsAcc.merch, m),
            };
          },
          {
            totals: { Taxes: [...taxes_initialState] },
            services: [...taxes_initialState],
            merch: [...taxes_initialState],
          },
        );

        return {
          totals: sumTotalTaxes(acc.totals, a.totals),
          services: sumTaxes(acc.services, a.services),
          merch: sumTaxes(acc.merch, a.merch),
        };
      },
      {
        totals: { Taxes: [...taxes_initialState] },
        services: [...taxes_initialState],
        merch: [...taxes_initialState],
      },
    );

    setTotals(algo.totals);
    setServices(algo.services);
    setMerch(algo.merch);
    const totalsToSet = { ...algo.totals, ...getResumedData(ebs) };

    setTotals(prev => ({
      ...prev,
      ...totalsToSet,
    }));
    getD104Data(setDataCSV, algo.services, algo.merch, totalsToSet);
  };

  const getTaxesMerch = (currentMerch, code, AmountValue, totalValue) =>
    currentMerch.map(mer =>
      mer.Code === code
        ? {
            ...mer,
            Amount: mer.Amount + AmountValue,
            Total: mer.Total + totalValue,
          }
        : mer,
    );

  const getTotals = (currentTotals, code, AmountValue, totalValue) => ({
    ...currentTotals,
    Taxes: currentTotals?.Taxes?.map(tax =>
      tax.Code === code
        ? {
            ...tax,
            Amount: tax.Amount + AmountValue,
            Total: tax.Total + totalValue,
          }
        : tax,
    ),
  });

  const getChartData = () => {
    const billsGroup = [];

    filterBills.forEach(bill => {
      const data = { total: toExchangeRate(bill?.TotalBill) };
      const exists = billsGroup.find(y => {
        const d1 = new Date(y?.EmitedDay);
        d1.setMinutes(d1.getMinutes() + new Date().getTimezoneOffset());
        const d2 = new Date(bill?.EmitedDay);
        d2.setMinutes(d2.getMinutes() + new Date().getTimezoneOffset());
        return d1.getDate() === d2.getDate();
      });

      if (!exists) {
        billsGroup.push({
          EmitedDay: bill?.EmitedDay,
          amount: [data],
        });
      } else {
        exists.amount.push(data);
      }
    });

    const sortedArray = billsGroup.sort(
      (a, b) => new Date(a.EmitedDay) - new Date(b.EmitedDay),
    );

    const dataChart = sortedArray.map(bill => {
      const newDate = new Date(bill?.EmitedDay);
      newDate.setMinutes(newDate.getMinutes() + new Date().getTimezoneOffset());
      let total = 0;
      bill?.amount.forEach(index => {
        total += index?.total;
      });

      return {
        date: `${newDate.getDate()}/${
          newDate.getMonth() + 1
        }/${newDate.getFullYear()}`,
        amount: total,
      };
    });

    return dataChart;
  };

  return {
    services,
    totals,
    merch,
    dataToCSV,
    headersToCSV,
    handleGetElectronicBills,
    dataCSV,
    setDataCSV,
    filterBills,
    setFilterBills,
    electronicBills,
    isLoadingBills,
    setElectronicBills,
    setTotals,
    emitterList,
    setEmitter,
    getChartData,
    generateReport,
  };
}

export default UseCreateReport;
