/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_TAXES = gql`
query allTaxes{
  taxes: getAllTaxes{
      id
      Description_Tax
      MH_Code
    }
}
`;