import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Control, Field, Icon, Input, Label, Title } from "rbx";
// Components
import SelectCountry from "../CountrySelect";
import BooleanInput from "../BooleanInput";
// scss
import "./Register.scss";

function Register({
  handleChangeRegister,
  setLoginSection,
  stateUserReg,
  handleChangeAcceptTerms,
  loginSection,
}) {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  return (
    <div style={{ width: "100%" }}>
      <Title className="landing-page-title">Registro</Title>
      <div className="landing-page-inputs">
        <Field>
          <Control>
            <Input
              required
              autoComplete="off"
              name="Email"
              placeholder="Correo electrónico"
              size="large"
              type="email"
              value={stateUserReg.Email}
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
          </Control>
        </Field>

        <Field>
          <Control>
            <Input
              autoComplete="off"
              name="Given_Name"
              placeholder="Nombre del encargado(a)"
              size="large"
              type="text"
              value={stateUserReg.Given_Name}
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Input
              autoComplete="off"
              name="Family_Name"
              placeholder="Apellidos del encargado(a)"
              size="large"
              type="text"
              value={stateUserReg.Family_Name}
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
          </Control>
        </Field>

        <Field>
          <Control>
            <SelectCountry
              name="FK_Country"
              value={stateUserReg.FK_Country}
              onChange={handleChangeRegister}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Input
              autoComplete="off"
              name="Phone_Number"
              placeholder="Número de teléfono"
              size="large"
              type="number"
              value={stateUserReg.Phone_Number}
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label className="label" textColor="white">
              Fecha de nacimiento:
            </Label>
            <Input
              name="BirthDate_Person"
              type="date"
              value={stateUserReg.BirthDate_Person}
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
          </Control>
        </Field>
        {!showPassword.password ? (
          <Field className="input-password">
            <Control className="input">
              <Input
                autoComplete="new-password"
                name="Password"
                placeholder="Contraseña (Mínimo 8 caracteres)"
                size="large"
                type="password"
                value={stateUserReg.Password}
                onChange={e =>
                  handleChangeRegister(e.target.name, e.target.value)
                }
              />
            </Control>
            <Icon className="icon" color="info">
              <FontAwesomeIcon
                icon="eye-slash"
                onClick={() =>
                  setShowPassword(prev => ({ ...prev, password: true }))
                }
              />
            </Icon>
          </Field>
        ) : (
          <Field className="input-password">
            <Control className="input">
              <Input
                autoComplete="new-password"
                name="Password"
                placeholder="Contraseña (Mínimo 8 caracteres)"
                size="large"
                type="text"
                value={stateUserReg.Password}
                onChange={e =>
                  handleChangeRegister(e.target.name, e.target.value)
                }
              />
            </Control>
            <Icon className="icon" color="info">
              <FontAwesomeIcon
                icon="eye"
                onClick={() =>
                  setShowPassword(prev => ({ ...prev, password: false }))
                }
              />
            </Icon>
          </Field>
        )}
        {!showPassword.confirmPassword ? (
          <Field className="input-password">
            <Control className="input">
              <Input
                autoComplete="off"
                name="PasswordConfirm"
                placeholder="Confirme la contraseña"
                size="large"
                type="password"
                value={stateUserReg.PasswordConfirm}
                onChange={e =>
                  handleChangeRegister(e.target.name, e.target.value)
                }
              />
            </Control>
            <Icon className="icon" color="info">
              <FontAwesomeIcon
                icon="eye-slash"
                onClick={() =>
                  setShowPassword(prev => ({ ...prev, confirmPassword: true }))
                }
              />
            </Icon>
          </Field>
        ) : (
          <Field className="input-password">
            <Control className="input">
              <Input
                autoComplete="off"
                name="PasswordConfirm"
                placeholder="Confirme la contraseña"
                size="large"
                type="text"
                value={stateUserReg.PasswordConfirm}
                onChange={e =>
                  handleChangeRegister(e.target.name, e.target.value)
                }
              />
            </Control>
            <Icon className="icon" color="info">
              <FontAwesomeIcon
                icon="eye"
                onClick={() =>
                  setShowPassword(prev => ({ ...prev, confirmPassword: false }))
                }
              />
            </Icon>
          </Field>
        )}
        <Field>
          <Control className="accept-terms control-input">
            <BooleanInput
              className="ckbox"
              name="AcceptTerms"
              value={stateUserReg.AcceptTerms}
              onChange={(name, value) =>
                handleChangeAcceptTerms(name, value ? 1 : 0)
              }
            />
            <Label className="text" textColor="white">
              Acepto Términos y Condiciones
            </Label>
          </Control>
        </Field>
      </div>
      <div className="landing-page-buttons">
        <Button
          rounded
          className="landing-page-button"
          size="large"
          type="submit"
        >
          <span>REGÍSTRATE GRATIS</span>
        </Button>
      </div>

      <div className="landing-page-footer">
        <p>¿Ya tienes una cuenta?</p>
        <div className="landing-page-buttons">
          <Button
            outlined
            rounded
            className="landing-page-button-secondary"
            type="button"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setLoginSection(!loginSection);
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp; INICIAR SESIÓN &nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        </div>
      </div>
    </div>
  );
}

Register.propTypes = {
  handleChangeRegister: PropTypes.func.isRequired,
  stateUserReg: PropTypes.object.isRequired,
  setLoginSection: PropTypes.func.isRequired,
  handleChangeAcceptTerms: PropTypes.func.isRequired,
  loginSection: PropTypes.bool.isRequired,
};

export default Register;
