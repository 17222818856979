import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// External components
import { Button, Card } from "rbx";
import { toast } from "react-toastify";
// GraphQL
import { client_EB_api } from "../../graphql/client";
import { RESEND_EMAIL } from "../../graphql";
// Hooks
import useCoinDetails from "../../hooks/useCoinDetails";
// SCSS
import "./DraftBill.scss";

function DraftBill({ bill, onDelete }) {
  const history = useHistory();
  const { toLocalExchangeRate } = useCoinDetails();

  const handleDelete = () => {
    onDelete(bill);
  };

  const handleEdit = () => {
    history.push(`/electronic-bills/${bill.id}/Draft`);
  };

  const handleSend = async () => {
    if (!bill || !bill.User || !bill.User.Person || !bill.User.Person.Email) {
      toast.error("Error al buscar el email!");
      return;
    }

    const { data } = await client_EB_api.mutate({
      mutation: RESEND_EMAIL,
      variables: {
        id: bill.id,
        to: bill.User.Person.Email,
      },
    });

    if (data.response.length === 0 || data.response[0] === false) {
      toast.error("Error al enviar el email!");
      return;
    }

    toast.success("Email enviado con éxito!");
  };

  return (
    <Card className="card-content">
      <p className="draft-bill__detail-text draft-bill__detail-text--bold">
        Fecha de Edición:{" "}
        <small className="draft-bill__detail-text">
          {new Date(bill.Updated_At).toLocaleString()}
        </small>
      </p>
      <p className="draft-bill__detail-text draft-bill__detail-text--bold">
        Cliente:{" "}
        <small className="draft-bill__detail-text">
          {bill?.User?.Person?.Name_Person}
        </small>
      </p>
      <p className="draft-bill__detail-text draft-bill__detail-text--bold">
        Total:{" "}
        <small className="draft-bill__detail-text">
          {`${bill?.Coin?.Symbol_Coin} ${toLocalExchangeRate(
            bill.Total_Bill,
            bill.Coin,
          )}`}
        </small>
      </p>
      <p className="draft-bill__detail-text draft-bill__detail-text--bold">
        No. Productos:{" "}
        <small className="draft-bill__detail-text">{bill.Detail?.length}</small>
      </p>

      <div className="draft-bill__footer">
        <Button
          className="draft-bill__button"
          color="primary"
          disabled={!bill?.User}
          onClick={handleSend}
        >
          Enviar
        </Button>
        <Button
          className="draft-bill__button"
          color="secondary"
          onClick={handleEdit}
        >
          Editar
        </Button>
        <Button className="delete-button" onClick={() => handleDelete(bill)}>
          Eliminar
        </Button>
      </div>
    </Card>
  );
}
DraftBill.propTypes = {
  bill: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DraftBill;
