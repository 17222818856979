/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_BILLING_PROFILE_BY_CRITERIA = gql`
  query getBillingProfile($criteria: String) {
    BillingProfile: findBillingProfileByCriteria(criteria: $criteria) {
      id
      Name
      Email
      ID_Number
      PhoneCodCountry
      PhoneNumber
      OtherSigns
      User {
        id
      }
      MH_PersonType {
        id
        Name_PersonType
      }
      MH_Neighborhood {
        id
        Name
        District {
          id
          Name
          Canton {
            id
            Name
            Province {
              id
              Name
            }
          }
        }
      }
    }
  }
`;

export const GET_BILLING_PROFILE_BY_DOCUMENT_ID = gql`
  query getProfile($DocumentId_Person: String) {
    userByDocumentId_Person: billingProfileByDocumentId_Person(
      DocumentId_Person: $DocumentId_Person
    ) {
      id
      MH_PersonType {
        id
      }
      ID_Number
      Name
      Email
      PhoneCodCountry
      PhoneNumber
      MH_Neighborhood {
        id
        Name
        District {
          id
          Name
          Canton {
            id
            Name
            Province {
              id
              Name
            }
          }
        }
      }
      OtherSigns
    }
  }
`;

export const DEFAULT_BILLING_PROFILE = gql`
  query defaultBillingProfile {
    BillingProfile: defaultBillingProfile {
      id
      Name
      MH_PersonType {
        id
        Name_PersonType
        Code
      }
      ID_Number
      IDForeign
      ComercialName
      Email
      PhoneCodCountry
      PhoneNumber
      MH_Province {
        id
        Name
        id_MH
      }
      MH_Canton {
        id
        Name
        id_MH
      }
      MH_District {
        id
        Name
        id_MH
      }
      MH_Neighborhood {
        id
        Name
        id_MH
      }
      OtherSigns
      OtherSignsForeign
      MH_User {
        idUser
        fullName
        userName
        email
        pwd
        about
        country
        FileEB {
          idFile
          md5
          name
          timestamp
          size
          idUser
          fileType
          type
        }
        Session {
          idSession
          idUser
          sessionKey
          ip
          lastAccess
        }
        MH_User
        MH_Password
      }
      Created_At
      Updated_At
    }
  }
`;
