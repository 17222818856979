import React from "react";
import PropTypes from "prop-types";
// External components
import { Card, Icon, Generic } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
// Components
import { ClientEdit } from "./components";
// Context
import { useAuth, useModal } from "../../context";
// SCSS
import "../PaymentComponet/Receiver/ClientReceiver/ClientCard/ClientCard.scss";
import "../../pages/IncomePage/IncomePage.scss";
import "../ProductCard/ProductCard.scss";
import "./ClientsCard.scss";

function ClientsCard({ clientList, setClientList, editClient, deleteClient }) {
  const { permissions } = useAuth();
  const { haveActionPermission } = permissions;

  const { setModalOpen } = useModal();
  const selectIcon = TypeId => {
    if (TypeId === "1") {
      return "user";
    }
    return "building";
  };

  const handleSelectClient = clientSelected => {
    if (!haveActionPermission("Edit", "/products")) return;
    setModalOpen(
      true,
      <ClientEdit
        clientList={clientList}
        clientSelected={clientSelected}
        editClient={editClient}
        phoneCodCountry={clientSelected?.BillingProfile?.PhoneCodCountry}
        setModalOpen={setModalOpen}
      />,
    );
  };
  return (
    <div className="grid">
      {clientList
        ?.filter(item => item?.BillingProfile !== null)
        .map(client => (
          <Card
            key={client?.BillingProfile?.id}
            className="card-content-client principal-container hover-table-options"
          >
            <Generic
              key={client?.BillingProfile?.id}
              className="client-header"
              onClick={() => handleSelectClient(client)}
            >
              <div className="client-details">
                <h3>{client?.BillingProfile?.Name}</h3>
                <h4>
                  {client?.BillingProfile?.MH_PersonType?.Name_PersonType}
                </h4>
                <h4>{client?.BillingProfile?.ID_Number}</h4>
              </div>
              <h4>{client?.BillingProfile?.Email}</h4>
            </Generic>
            <Generic
              className="principal-container client-footer"
              onClick={() => handleSelectClient(client)}
            >
              <Icon size="medium">
                <FontAwesomeIcon
                  icon={selectIcon(client?.BillingProfile?.MH_PersonType?.id)}
                  size="4x"
                />
              </Icon>
            </Generic>
            <div className="card-checkbox">
              <Icon
                data-tip
                className="hover-table-options icon-cancel"
                data-delay-show="500"
                data-for="ButtonDelete"
              >
                <FontAwesomeIcon
                  icon="times"
                  size="1x"
                  onClick={() => deleteClient(client?.id)}
                />
              </Icon>
              <ReactTooltip id="ButtonDelete" type="info">
                <span>Eliminar cliente del grupo</span>
              </ReactTooltip>
            </div>
          </Card>
        ))}
    </div>
  );
}
ClientsCard.defaultProps = {
  clientList: [],
  setClientList: () => {},
};
ClientsCard.propTypes = {
  clientList: PropTypes.array,
  setClientList: PropTypes.func,
  editClient: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
};

export default ClientsCard;
