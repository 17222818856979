import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Container, Input, Control, Field, Table, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customToast as toast } from "../../../../../utils";
import MultiSelect from "../../../../MultiSelect";
import "../../../../../styles/index.scss";

const Discounts = ({
  product,
  setModalOpen,
  list,
  setDataTable,
  coinDetails,
}) => {
  const [key, setKey] = useState(1);
  const [cont, setCont] = useState(false);
  const [discount, setDiscount] = useState({
    Type: "1",
    Amount: "",
  });
  const [discountsList, setDiscountsList] = useState([]);

  const { currentSymbolCoin, toExchangeRate } = coinDetails;
  const totalPrice = toExchangeRate(product.total);

  useEffect(() => {
    product?.discountAmount?.forEach(item => {
      if (item !== 0) {
        setDiscountsList(discounts => [
          ...discounts,
          {
            Percent: ((item / totalPrice) * 100).toFixed(1),
            Amount: item,
            key,
          },
        ]);
        setKey(k => key + 1);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const types = [
    { Name: "Porcentaje", Id: "1" },
    { Name: "Monto", Id: "2" },
  ];

  const handleDelete = line => {
    const filterList = discountsList.filter(item => item !== line);
    setDiscountsList(filterList.map((item, i) => ({ ...item, key: i + 1 })));
    setKey(filterList.length + 1);
    if (cont === true) {
      setCont(false);
    }
  };
  const clear = () => {
    setDiscount({
      Type: "1",
      Amount: "",
    });
  };
  const handleChange = (name, value) => {
    setDiscount(prev => ({ ...prev, [name]: value }));
  };
  const handleSave = () => {
    const amountsDiscount = discountsList.map(item => item.Percent);
    const amounts = discountsList.map(item => item.Amount);
    setDataTable(
      list.map(item =>
        product.id === item.id
          ? {
              ...item,
              discount: amountsDiscount,
              discountAmount: amounts,
            }
          : item,
      ),
    );
    if (amountsDiscount.length === 0) {
      setDataTable(
        list.map(item =>
          product.id === item.id
            ? {
                ...item,
                discountAmount: [0],
                discount: [0],
              }
            : item,
        ),
      );
    }
    setModalOpen(false, "");
    toast.success("¡Descuentos registrados con éxito!", {
      toastId: "discount",
    });
  };
  const handleAdd = () => {
    let totalsAmount = 0;
    discountsList.forEach(item => {
      totalsAmount += parseFloat(item.Percent, 10);
    });

    if (discount.Type === "" || discount.Amount === "") {
      toast.error("Debe completar todos los datos requeridos");
    } else if (discount.Amount === 0) {
      toast.error("El valor proporcionado debe ser mayor a 0");
    } else if (
      discount.Type === "1" &&
      (discount.Amount === 0 || discount.Amount > 100)
    ) {
      toast.error("El valor del porcentaje debe ser entre 0 y 100");
    } else {
      if (discount.Type === "1") {
        // Percent
        const amount = (totalPrice * discount.Amount) / 100;
        if (parseFloat(discount.Amount, 10) + totalsAmount > 100) {
          toast.error(
            "La suma de los montos de los descuentos exceden el precio unitario.",
          );
        } else {
          setDiscountsList(discounts => [
            ...discounts,
            { Percent: discount.Amount, Amount: amount.toFixed(3), key },
          ]);
        }
      } else {
        // Amount
        const percent = (parseFloat(discount.Amount) * 100) / totalPrice;
        if (percent > 100) {
          toast.error(
            "El monto no puede exceder el precio unitario del producto",
          );
        } else {
          const amount = (totalPrice * percent) / 100;

          if (percent + totalsAmount > 100) {
            toast.error(
              "La suma de los montos de los descuentos exceden el precio unitario.",
            );
          } else {
            setDiscountsList(discounts => [
              ...discounts,
              { Amount: amount.toFixed(3), Percent: percent.toFixed(1), key },
            ]);
          }
        }
      }

      setKey(key + 1);
      clear();
      validateTotalDiscounts();
    }
  };

  const validateTotalDiscounts = () => {
    if (discountsList.length === 5) {
      setCont(true);
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div>
          <h3 className="primary-title is-primary"> Descuentos</h3>
          <Field>
            <Control>
              <MultiSelect
                data={types}
                label="Seleccione:"
                name="Type"
                value={discount?.Type}
                onChange={handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Input
                name="Amount"
                type="number"
                value={discount?.Amount}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <div className="container-button">
            <Button
              rounded
              color="secondary"
              disabled={cont}
              type="Button"
              onClick={handleAdd}
            >
              Agregar Descuento
            </Button>
          </div>

          <div>
            <Table bordered fullwidth hoverable narrow striped>
              <Table.Head>
                <Table.Row>
                  <Table.Heading>
                    <abbr className="head-text">#</abbr>
                  </Table.Heading>
                  <Table.Heading>
                    <abbr className="head-text">Porcentaje</abbr>
                  </Table.Heading>
                  <Table.Heading>
                    <abbr className="head-text">Monto</abbr>
                  </Table.Heading>
                  <Table.Heading>
                    <abbr className="head-text">Eliminar</abbr>
                  </Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {discountsList?.map(line => (
                  <Table.Row key={line.key}>
                    <Table.Heading>{line.key} </Table.Heading>
                    <Table.Cell>{line?.Percent}%</Table.Cell>
                    <Table.Cell>
                      {currentSymbolCoin} {parseFloat(line?.Amount).toFixed(3)}
                    </Table.Cell>
                    <Table.Cell>
                      <Icon
                        className="hover-table-options"
                        size="small"
                        onClick={() => handleDelete(line)}
                      >
                        <FontAwesomeIcon icon="trash-alt" />
                      </Icon>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <div className="container-button">
            <Button
              rounded
              className="button-styles"
              color="primary"
              type="Button"
              onClick={handleSave}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

Discounts.propTypes = {
  product: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setDataTable: PropTypes.func.isRequired,
  coinDetails: PropTypes.func.isRequired,
};

export default Discounts;
