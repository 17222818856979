import React from "react";
import { Switch, Route } from "react-router-dom";
import ClientReceiver from "./Receiver/ClientReceiver";
import PaymentComponent from "./PaymentComponent";
import Inventories from "../Inventories";
import Detail from "./detail";

const PaymentComponentRouter = (props) => (
  <Switch>
    <Route path="/electronic-bills/details">
      <Detail />
    </Route>
    <Route path="/electronic-bills/inventories">
      <Inventories />
    </Route>
    <Route path="/electronic-bills/client">
      <ClientReceiver />
    </Route>
    <Route path="/electronic-bills/:id?/:billType?">
      <PaymentComponent />
    </Route>
  </Switch>
);

export default PaymentComponentRouter;
