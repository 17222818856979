/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
// External components
import { useDropzone } from "react-dropzone";
import { Card, Button, Icon, Progress, Container } from "rbx";
import * as converter from "xml-js";
import { Promise as bromise } from "bluebird";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Hooks
import useZoho from "../../hooks/useEmail";
import useElectronicBill from "../../hooks/useElectronicBill";
// Components
import BooleanInput from "../BooleanInput";
import ProfileBillWrapper from "../ProfileBillWrapper";
import BillUploadDetail from "../BillUploadDetail";
import Inventories from "../Inventories";
import { ZohoSearch, AccountManagement, NewAccount } from "./components";
// Utils
import { formatCurrency, customToast as toast, chunk } from "../../utils";
import { arrayBill, formatDateString, toZohoMonths } from "../../utils/helpers";
import getImageEmail from "../../utils/GetImageEmail";
// Enum
import EMAIL_PROVIDERS from "../../Enums/EmailToken";
// SCSS
import "./ExpensesUpload.scss";

const INITIAL_STATE = {
  user: {
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: "",
    FK_Country: "",
    Phone_Number: "",
    Email: "",
  },
  BillingProfile: null,
};

function ExpensesUpload() {
  const [expenses, setExpenses] = useState([]);
  const [allSelected, setAllSelected] = useState(true);
  const [checkZoho, setCheckZoho] = useState(false);
  const [billing, setBilling] = useState(INITIAL_STATE);
  const [startZohoEmail, setStartZohoEmail] = useState("1");
  const [numberPageZoho, setNumberPageZoho] = useState(1);
  const [quantityEmails, setQuantityEmails] = useState("Todas");
  const [isLoadingBills, setIsLoadingBills] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [nextPage, setNextPage] = useState("");

  const { getEmails, checkTokenEmail, getTokenEmail, getEmailsAccounts } =
    useZoho();
  const { LineDetailPlace, dates, accountSelected, setAccountSelected } =
    useElectronic();

  const { handleElectronicBillsXML } = useElectronicBill();

  const { state } = useAuth();
  const { setModalOpen } = useModal();
  const history = useHistory();
  const { setModalOpen: setDetailModalOpen } = useModal();
  const { setModalOpen: setManagentAccountsModalOpen } = useModal();

  const { user } = state;

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const server = queryParams.get("accounts-server");

  const handleSelectAllClick = () => {
    // evento del botón "Seleccionar todas"
    // Selecciona todas las facturas en el estado expenses
    setExpenses(prev =>
      prev.map(expense => ({ ...expense, selected: !allSelected })),
    );
    setAllSelected(prev => !prev);
  };

  const handleSelect = (name, value) => {
    // Selecciona la factura indicada en el estado expenses
    setExpenses(prev =>
      prev.map(expense =>
        expense.Clave._text === name
          ? { ...expense, selected: value }
          : expense,
      ),
    );
  };

  const setBillingProfile = BillingProfile =>
    setBilling(prv => ({ ...prv, BillingProfile }));

  useEffect(() => {
    const createElectronicBillProfile = () => {
      setModalOpen(
        true,
        <ProfileBillWrapper
          initialReceiver={billing?.BillingProfile}
          setBillingProfile={setBillingProfile}
          setModalOpen={setModalOpen}
        />,
      );
    };
    if (state?.user?.TavuelUser?.BillingProfile === null) {
      createElectronicBillProfile();
    }
  }, [state, billing, setModalOpen]);

  const saveZohoToken = useCallback(async () => {
    const getTypeMail = () => {
      if (code.includes("M.R3_BAY")) {
        return parseInt(EMAIL_PROVIDERS.Outlook, 10);
      }
      return server
        ? parseInt(EMAIL_PROVIDERS.Zoho, 10)
        : parseInt(EMAIL_PROVIDERS.Gmail, 10);
    };

    const selectPlace = async (FK_Place, bill) => {
      setModalOpen(false);
      const response = await getTokenEmail(
        user.googleAuthId,
        code,
        getTypeMail(),
        FK_Place,
      );
      if (!response?.data?.email) {
        toast.error("Error al iniciar sesión");
        return;
      }
      setAccountSelected(response?.data?.email);
      toast.success("¡Sesión iniciada con éxito!");
    };

    try {
      setModalOpen(
        true,
        <Inventories
          setModalClose={setModalOpen}
          onClickFunction={selectPlace}
        />,
        "No se seleccionó un lugar para poder finalizar el inicio de sesión.",
        true,
      );
    } catch (err) {
      toast.error("Error al iniciar sesión");
    }
  }, [
    getTokenEmail,
    user.googleAuthId,
    code,
    server,
    setModalOpen,
    setAccountSelected,
  ]);

  useEffect(() => {
    if (code) {
      saveZohoToken();
      history.push("upload-expenses");
    }
  }, [code, history, saveZohoToken]);

  const allAccounts = useCallback(async () => {
    try {
      const res = await getEmailsAccounts(user.googleAuthId);
      setAccounts(res?.data?.data);
    } catch (err) {
      toast.error(err);
    }
  }, [getEmailsAccounts, user.googleAuthId]);

  useEffect(() => {
    user?.googleAuthId && allAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCleanBills = () => {
    setExpenses([]);
    setStartZohoEmail("1");
    setNumberPageZoho(1);
  };

  const isValidXMLType = type => /text\/(xml)/.test(type);

  const isAlreadyUploaded = useCallback(
    facturaJSON =>
      expenses
        .map(({ Clave: { _text } }) => _text)
        .includes(facturaJSON.FacturaElectronica.Clave._text),
    [expenses],
  );

  const removeDuplicatedXMLByKey = xmls =>
    xmls.reduce(
      (acc, xml) => {
        if (!acc.dataKeys.includes(xml?.FacturaElectronica?.Clave?._text)) {
          acc.data.push(xml);
          acc.dataKeys.push(xml.FacturaElectronica?.Clave?._text);
        }
        return acc;
      },
      { data: [], dataKeys: [] },
    );

  const xmlFilesToJson = async files => {
    const data = await Promise.allSettled(
      files.map(
        file =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = ({ target: { result } }) => {
              try {
                const bill = resolve(
                  JSON.parse(
                    converter.xml2json(result, { compact: true, spaces: 4 }),
                  ),
                );
                resolve(bill);
              } catch (e) {
                reject(e);
              }
            };
            fileReader.onerror = reject;
            fileReader.readAsText(file);
          }),
      ),
    );

    const succses = await data
      .filter(({ status }) => status === "fulfilled")
      .map(({ value }) => value);

    const failed = data.filter(({ status }) => status === "rejected").length;
    failed && toast.error(`No se lograron abrir ${failed} documentos`);

    return succses;
  };

  const readXml = async files => {
    const uniqueXmls = removeDuplicatedXMLByKey(files).data;

    uniqueXmls.forEach(facturaJSON => {
      if (!validationIdEquals(facturaJSON)) {
        toast.error(
          "Verifique que todas las facturas coincidan con su perfil de facturación",
        );
        return;
      }
      if (isAlreadyUploaded(facturaJSON)) {
        toast.error(
          `La factura ...${facturaJSON.FacturaElectronica.Clave._text.slice(
            -11,
          )} ya fue subida anteriormente `,
        );
        return;
      }
      setExpenses(prev => [
        ...prev,
        {
          ...facturaJSON?.FacturaElectronica,
          selected: true,
          isOutOfDateRange: facturaJSON?.isOutOfDateRange,
        },
      ]);
    });
  };

  const validationIdEquals = dataJSON => {
    try {
      if (state?.user?.TavuelUser?.UserBillingProfile) {
        const { ID_Number } = state?.user?.TavuelUser?.UserBillingProfile;
        const { Numero: DocumentId_Receptor } =
          dataJSON.FacturaElectronica?.Receptor?.Identificacion;
        return ID_Number === DocumentId_Receptor._text;
      }
      return null;
    } catch (err) {
      return false;
    }
  };

  const onDrop = async Files =>
    readXml(
      await xmlFilesToJson(Files.filter(file => isValidXMLType(file.type))),
    );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ".xml" });

  const handleSave = async () => {
    // funcion para guardar facturas
    if (LineDetailPlace.id === 0) {
      toast.error("No se ha seleccionado un Negocio");
      return;
    }

    const request = chunk(expenses, 5).map(async xChucnk =>
      handleElectronicBillsXML(
        state.user.TavuelUser,
        xChucnk,
        LineDetailPlace.id,
        1,
      ),
    );

    const messages = await bromise.all(request, { concurrency: 3 }).reduce(
      (finalMessages, current) => {
        const { response, message, success } = current;
        if (response === "errors") {
          finalMessages.errors.push(...message);
        }
        if (response === "error") {
          return Object.assign(finalMessages, { error: message });
        }
        if (success) {
          return Object.assign(finalMessages, { success });
        }
        return finalMessages;
      },
      { errors: [], sucess: undefined, error: null },
    );

    messages.success && toast.success(messages.success);
    messages.errors.forEach(bill => {
      if (bill !== "") {
        toast.error(
          `La factura:
        ${bill},
        ya se encuentra registrada,
        favor revisar las facturas`,
        );
      }
    });

    messages.error && toast.error(messages.error);
  };

  const searchDocuemnts = async start => {
    setIsLoadingBills(true);
    const { data: isTokenValid } = await checkTokenEmail(
      accountSelected?.id,
      parseInt(accountSelected?.type, 10),
    );
    if (!isTokenValid.zoho) {
      setCheckZoho(false);
    }
    const { data } = await getEmails(
      accountSelected?.id,
      accountSelected?.type === EMAIL_PROVIDERS.Zoho
        ? toZohoMonths(dates.startDate.value)
        : dates.startDate.value.replaceAll("-", "/"),
      accountSelected?.type === EMAIL_PROVIDERS.Zoho
        ? toZohoMonths(dates.endDate.value)
        : dates.endDate.value.replaceAll("-", "/"),
      quantityEmails,
      start,
      nextPage,
      parseInt(accountSelected?.type, 10),
    );
    const xmls = data.data.map(email => getXMLFromEmailFiles(email.files));
    if (accountSelected?.type === EMAIL_PROVIDERS.Gmail && xmls?.length > 0) {
      setNextPage(data.data[0].nextPage.toString());
    }
    readXml(xmls.flat());
    setIsLoadingBills(false);
  };

  const getXMLFromEmailFiles = emailFiles =>
    emailFiles
      .map(file => {
        try {
          return JSON.parse(
            converter.xml2json(
              accountSelected.type === EMAIL_PROVIDERS.Gmail
                ? decodeBase64(file.content)
                : file.content,
              { compact: true },
            ),
          );
        } catch (e) {
          return { error: true };
        }
      })
      .filter(xmlJSON => !xmlJSON.error && !xmlJSON.MensajeHacienda);

  const decodeBase64 = str => {
    const response = str.replace(/_/g, "/").replace(/-/g, "+"); // important line
    return atob(response);
  };

  const handleNextZohoEmail = () => {
    setStartZohoEmail(
      (parseInt(startZohoEmail, 10) + parseInt(quantityEmails, 10)).toString(),
    );

    searchDocuemnts(
      (parseInt(startZohoEmail, 10) + parseInt(quantityEmails, 10)).toString(),
    );
    setNumberPageZoho(numberPageZoho + 1);
  };

  const getCssImage = () => {
    const namesCss = {
      1: "img-zoho",
      2: "img-gmail",
      3: "img-outlook",
    };
    return namesCss[accountSelected?.type];
  };

  return (
    <React.Fragment>
      {expenses?.length === 0 ? (
        <div className="profile-page-container">
          {accountSelected.id !== 0 ? (
            <div className="container-switch-email">
              <div className="switch-email">
                <Container
                  className="btn-switch"
                  onClick={() => {
                    setManagentAccountsModalOpen(
                      true,
                      <AccountManagement
                        openModal={setManagentAccountsModalOpen}
                      />,
                    );
                  }}
                >
                  <img
                    alt="icon"
                    className={getCssImage()}
                    src={getImageEmail(accountSelected.type)}
                  />
                  <p>{accountSelected?.email}</p>
                </Container>
              </div>
            </div>
          ) : (
            <div className="container-switch-email">
              <div className="switch-email">
                <Container
                  className="btn-switch"
                  onClick={() => {
                    accounts?.length > 0
                      ? setManagentAccountsModalOpen(
                          true,
                          <AccountManagement
                            openModal={setManagentAccountsModalOpen}
                          />,
                        )
                      : setManagentAccountsModalOpen(
                          true,
                          <NewAccount
                            flow
                            openModal={setManagentAccountsModalOpen}
                          />,
                        );
                  }}
                >
                  <Icon>
                    <FontAwesomeIcon icon="envelope" size="lg" />
                  </Icon>
                  <p>Iniciar Sesión</p>
                </Container>
              </div>
            </div>
          )}
          {accountSelected.id !== 0 && (
            <ZohoSearch
              isLogged={checkZoho}
              nextPage={nextPage}
              quantityEmails={quantityEmails}
              setIsLogged={setCheckZoho}
              setNextPage={setNextPage}
              setQuantityEmails={setQuantityEmails}
              setXmls={readXml}
            />
          )}
          <div
            {...getRootProps({
              className: `dropzone
                  ${isDragAccept && "dropzoneAccept"}
                  ${isDragReject && "dropzoneReject"}`,
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Suelte los archivos aquí...</p>
            ) : (
              <p>
                Arrastre los archivos .xml aquí, o click para seleccionar
                archivos
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="principal-container">
          <div>
            <div className="card-header-button">
              <Button color="secondary" onClick={handleCleanBills}>
                Limpiar pantalla
              </Button>
              <Button color="secondary" onClick={handleSelectAllClick}>
                {!allSelected ? "Seleccionar todas" : "Deseleccionar todas"}
              </Button>
              <Button color="primary" onClick={handleSave}>
                Guardar
              </Button>
            </div>
            <div
              className={
                quantityEmails !== "Todas"
                  ? "buttons-zoho-more"
                  : "hide-buttons-zoho-more"
              }
            >
              <div className="zoho-card">
                <img
                  alt="icon"
                  className={
                    accountSelected.type === EMAIL_PROVIDERS.Zoho
                      ? "img-zoho-expense"
                      : "img-gmail-expense"
                  }
                  src={getImageEmail(accountSelected.type)}
                />
              </div>
              <div className="zoho-number-page">{numberPageZoho}</div>
              <Button
                color="primary"
                disabled={expenses.length !== parseInt(quantityEmails, 10)}
                onClick={() => handleNextZohoEmail()}
              >
                Cargar más
              </Button>
            </div>
            {isLoadingBills && (
              <Progress className="progress-bar" color="info" size="small" />
            )}
            <div className="grid">
              {expenses.map(expense => {
                const [dateOfIssueFormattedDate, dateOfIssueFormattedTime] =
                  formatDateString(expense?.FechaEmision?._text);

                return (
                  <Card key={expense?.Clave?._text} className="card-content">
                    <div className="body-card">
                      <div className="content-invoice">
                        <p>
                          Emisor:{" "}
                          <span className="has-text-weight-bold">
                            {expense?.Emisor?.NombreComercial?._text}
                          </span>
                          &nbsp;
                        </p>
                        <div className="card-checkbox">
                          <BooleanInput
                            name={expense?.Clave._text}
                            value={expense?.selected}
                            onChange={(name, value) =>
                              handleSelect(name, value ? 1 : 0)
                            }
                          />
                        </div>
                      </div>
                      <p>
                        Cédula:&nbsp;
                        <span className="has-text-weight-bold">
                          {expense?.Emisor?.Identificacion?.Numero?._text}
                          &nbsp;
                        </span>
                      </p>
                      <p>
                        Moneda:&nbsp;
                        <span className="has-text-weight-bold">
                          {expense?.ResumenFactura?.CodigoTipoMoneda
                            ?.CodigoMoneda?._text || "CRC"}
                          &nbsp;
                        </span>
                      </p>
                      <p>
                        Total:&nbsp;
                        <span className="has-text-weight-bold">
                          {formatCurrency(
                            parseFloat(
                              expense?.ResumenFactura?.TotalVentaNeta?._text,
                              10,
                            ),
                            expense?.ResumenFactura?.CodigoTipoMoneda
                              ?.CodigoMoneda?._text || "CRC",
                          )}
                        </span>
                        &nbsp;
                      </p>
                    </div>

                    <div className="content-invoice mt-1 mb-2">
                      <div className="date-container">
                        Fecha:&nbsp;
                        <div className="has-text-weight-bold">
                          {`${dateOfIssueFormattedDate} ${dateOfIssueFormattedTime}`}
                        </div>
                        {expense?.isOutOfDateRange && (
                          <div style={{ marginLeft: "5px" }}>
                            <Icon color="warning">
                              <FontAwesomeIcon
                                data-tip
                                data-for="xmlInfo"
                                icon="info-circle"
                              />
                            </Icon>
                            <ReactTooltip
                              effect="solid"
                              id="xmlInfo"
                              place="bottom"
                              type="dark"
                            >
                              <span>
                                Fecha de Factura fuera del rango seleccionado
                              </span>
                            </ReactTooltip>
                          </div>
                        )}
                        &nbsp;
                      </div>
                      <Button
                        className="btn-animate"
                        color="secondary"
                        onClick={() =>
                          setDetailModalOpen(
                            true,
                            <BillUploadDetail
                              electronicDocument={arrayBill(expense)}
                            />,
                          )
                        }
                      >
                        Ver detalle
                      </Button>
                    </div>
                  </Card>
                );
              })}
            </div>
            <div className="profile-page-container">
              <div
                {...getRootProps({
                  className: `dropzone
                  ${isDragAccept && "dropzoneAccept"}
                  ${isDragReject && "dropzoneReject"}`,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Suelte los archivos aquí...</p>
                ) : (
                  <p>
                    Arrastre los archivos .xml aquí, o click para seleccionar
                    archivos
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ExpensesUpload;
