import React from "react";
import { useHistory } from "react-router-dom";
// External components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Button, Card, Generic, Icon, Image } from "rbx";
// Graphql
import { useQuery } from "@apollo/client";
import client from "../../../graphql/client";
import { GET_USERS_BY_PLACE, GET_USER_BY_EMAIL } from "../../../graphql";
// Components
import AddNewUser from "./AddNewUser/AddNewUser";
import UserSearch from "./UserSearch";
// Helper
import { isEmptyImage } from "../../../utils/helpers";
// Context
import { useAuth, useModal } from "../../../context";
import { useElectronic } from "../../../context/ElectronicContext";
// SCSS
import "./Users.scss";

function Users() {
  const { setModalOpen } = useModal();

  const { LineDetailPlace } = useElectronic();

  const { data: usersByPlace, refetch } = useQuery(GET_USERS_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  const { permissions, state } = useAuth();
  const history = useHistory();

  const { haveActionPermission, noAccessMessage } = permissions;

  const canRead = haveActionPermission("Read", "/security");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Usuarios"));
    history.push(`/`);
  }

  const handleNewRole = e => {
    e.preventDefault();
    setModalOpen(
      true,
      <AddNewUser
        componentMode="create"
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Agregar usuario"
      />,
    );
  };

  const handleUpdateUser = user => {
    if (!haveActionPermission("Edit", "/security")) return;
    const newUserRoles = { ...user };
    const filteRolesByPlace = user.Roles?.filter(
      r => r?.Place.id === LineDetailPlace.id,
    );
    newUserRoles.Roles = filteRolesByPlace;
    setModalOpen(
      true,
      <AddNewUser
        componentMode="edit"
        editUser={newUserRoles}
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Actualizar rol de usuario"
      />,
    );
  };

  const handleSearchUserByEmail = async search => {
    try {
      if (search?.trim()?.length === 0 || !search) {
        toast.error("Debe completar los datos solicitados.");
        return;
      }
      const res = await client.query({
        query: GET_USER_BY_EMAIL,
        variables: {
          criteria: search,
        },
        fetchPolicy: "network-only",
      });
      if (res?.data?.user?.length === 0) {
        toast.error("No se encontró ningún usuario.");
        return;
      }
      const userExists = usersByPlace?.user?.find(
        user => user?.FirebaseUser?.Email === res?.data?.user[0].Email,
      );
      if (userExists) {
        toast.error("El usuario ya se encuentra agregado.");
        return;
      }
      setModalOpen(
        true,
        <AddNewUser
          componentMode="UserExists"
          editUser={res?.data?.user[0]}
          refetch={refetch}
          setCloseModal={setModalOpen}
          title="Agregar usuario"
        />,
      );
    } catch (err) {
      toast.error("Error al buscar usuario");
    }
  };

  const handleAddUser = e => {
    e.preventDefault();
    setModalOpen(true, <UserSearch handleSearch={handleSearchUserByEmail} />);
  };

  return (
    <div>
      {haveActionPermission("Create", "/security") && (
        <div className="headerButtons">
          <Button
            className="button"
            color="primary"
            size="medium"
            onClick={e => handleNewRole(e)}
          >
            Agregar nuevo usuario
          </Button>
          <Button
            className="button"
            color="primary"
            size="medium"
            onClick={e => handleAddUser(e)}
          >
            Agregar usuario existente
          </Button>
        </div>
      )}
      <div className="grid">
        {usersByPlace?.user?.map(
          user =>
            user?.id !== state?.user?.TavuelUser.id && (
              <Card
                key={user?.id}
                className="card-content-client principal-container hover-table-options"
                onClick={() => handleUpdateUser(user)}
              >
                <Generic key={user?.FirebaseUser?.id} className="client-header">
                  <div className="client-details">
                    <h3>{user?.FirebaseUser?.Name}</h3>
                  </div>
                  <h4>{user?.FirebaseUser?.Email}</h4>
                </Generic>
                <Generic className="principal-container user-footer">
                  {isEmptyImage(user?.FirebaseUser?.Picture) ? (
                    <Icon size="medium">
                      <FontAwesomeIcon icon="user" size="4x" />
                    </Icon>
                  ) : (
                    <Image.Container className="picture-container">
                      <Image src={user?.FirebaseUser?.Picture} />
                    </Image.Container>
                  )}
                </Generic>
              </Card>
            ),
        )}
      </div>
    </div>
  );
}

export default Users;
