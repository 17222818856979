import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control, Label } from "rbx";
// Graphql
import { client_EB_api } from "../../graphql/client";
import { GET_ALL_CANTONS } from "../../graphql";
// SCSS
import "../CountrySelect/CountrySelect.scss";

function CantonSelect({
  value,
  name,
  onChange,
  label,
  disabled,
  className,
  province,
}) {
  const [cantons, setCantons] = useState([]);

  const getCantons = useCallback(async () => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_CANTONS,
      variables: {
        FK_Province: province,
      },
    });
    setCantons(data.cantons);
  }, [province]);

  useEffect(() => {
    getCantons();
  }, [getCantons]);

  return (
    <Control className={className}>
      <Label>{label}</Label>
      <Select.Container fullwidth>
        <Select
          className="selector"
          disabled={disabled}
          name={name}
          value={value}
          onChange={e => onChange(e.target.name, e.target.value)}
        >
          {cantons?.map(canton => (
            <Select.Option key={canton.id} value={canton.id}>
              {canton.Name}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
}

CantonSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  province: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

CantonSelect.defaultProps = {
  disabled: false,
  className: "",
};

export default CantonSelect;
