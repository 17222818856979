import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
// External components
import { Label, Control, Field, Input, Select } from "rbx";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_UNIT_OF_MEASURE } from "../../graphql";
// Utils
import { sortBy } from "../../utils";

function UnitMeasureSelect({
  value,
  name,
  secondaryMeasureValue,
  secondaryMeasureName,
  onChange,
  disabled,
  className,
}) {
  const [measureSelected] = useState(value);

  const { data: getAllUnitOfMeasure } = useQuery(GET_ALL_UNIT_OF_MEASURE);

  const handleChange = (measureName, measureValue) => {
    onChange(measureName, measureValue);
  };
  useEffect(() => {
    if (value !== undefined || !getAllUnitOfMeasure?.unitsOfMeasurement) return;
    onChange(name, getAllUnitOfMeasure.unitsOfMeasurement[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllUnitOfMeasure, value]);

  const options = useMemo(() => {
    if (Array.isArray(getAllUnitOfMeasure?.unitsOfMeasurement)) {
      return sortBy(
        getAllUnitOfMeasure?.unitsOfMeasurement,
        "Name_UnitOfMeasurement",
      );
    }
    return [];
  }, [getAllUnitOfMeasure?.unitsOfMeasurement]);

  return (
    <Field className={className}>
      <Control expanded>
        <Label>Unidad de medida</Label>
        <Select.Container fullwidth>
          <Select
            className="input-height"
            disabled={disabled}
            name={name}
            value={value}
            onChange={e => handleChange(e.target.name, e.target.value)}
          >
            {options?.map(measurementUnit => (
              <Select.Option
                key={measurementUnit.id}
                value={measurementUnit.id}
              >
                {`${measurementUnit.Name_UnitOfMeasurement}`}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
        {measureSelected?.Symbol === "Otros" && (
          <div>
            <Label>Especifique la unidad de medida</Label>
            <Input
              autoComplete="off"
              disabled={disabled}
              name={secondaryMeasureName}
              placeholder="Otra Unidad de medida"
              type="text"
              value={secondaryMeasureValue}
              onChange={e => onChange(e.target.name, e.target.value)}
            />
          </div>
        )}
      </Control>
    </Field>
  );
}

UnitMeasureSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  secondaryMeasureValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  secondaryMeasureName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

UnitMeasureSelect.defaultProps = {
  disabled: false,
  className: "",
};
export default UnitMeasureSelect;
