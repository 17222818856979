import PropTypes from "prop-types";
// Components
import BooleanInput from "../../../BooleanInput";
// Utils
import { formatCurrency } from "../../../../utils";
import { formatDateString } from "../../../../utils/helpers";
import { EXPENSE_CARD_SELECTION_STATUS } from "../../ExpensesManagement";

const ExpenseCard = ({ item, handleSelect }) => {
  const [emitedDayFormatedDate] = formatDateString(item?.EmitedDay);

  const [acceptanceDateFormatedDate] = formatDateString(
    item?.AcceptanceDate ?? item?.EmitedDay,
  );

  return (
    <div key={item?.id} className="card-content">
      <div className="body-card">
        <div className="content-invoice">
          <p>
            Consecutivo de documento:&nbsp;
            <span className="bold-text">{item?.InternConsecutive}</span>
          </p>
          <div className="card-checkbox">
            <BooleanInput
              name={item?.id}
              value={item?.selected}
              onChange={(name, value) =>
                handleSelect(
                  name,
                  value
                    ? EXPENSE_CARD_SELECTION_STATUS.IS_SELECTED
                    : EXPENSE_CARD_SELECTION_STATUS.IS_NOT_SELECTED,
                )
              }
            />
          </div>
        </div>
        <p>
          Consecutivo de aceptación:&nbsp;
          <span className="bold-text">
            {item?.AcceptanceDocument?.InternConsecutive
              ? item?.AcceptanceDocument?.InternConsecutive
              : 0}
          </span>
        </p>
        <p>
          Emisor: &nbsp;&nbsp;
          <span className="bold-text">{item?.Place?.Name_Place} </span>
        </p>
        <p>
          Cédula: &nbsp;&nbsp;
          <span className="bold-text">
            {item?.Place?.Company?.User?.Person?.DocumentId_Person}{" "}
          </span>
        </p>
        <p className="text-centered">
          Fecha de emisión: &nbsp;&nbsp;
          <span className="bold-text">{emitedDayFormatedDate}</span>
        </p>
        <p className="text-centered">
          Fecha de aceptación: &nbsp;&nbsp;
          <span className="bold-text">
            {acceptanceDateFormatedDate ?? emitedDayFormatedDate}
          </span>
        </p>
        <p>
          Moneda:
          <span className="bold-text">{item?.Coin?.Name_Coin}&nbsp;</span>
          &nbsp;
        </p>
        <p>
          Total: &nbsp;
          <span className="bold-text">
            {formatCurrency(
              parseFloat(item.TotalBill, 10),
              item?.Coin?.Code_Coin ? item?.Coin?.Code_Coin : "CRC",
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

ExpenseCard.propTypes = {
  item: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
};
export default ExpenseCard;
