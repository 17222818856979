import { useState } from "react";
// External component
import { Container } from "rbx";
// Component
import Input from "../Common/Input/Input";
import Select from "../Common/Select/Select";
// SCSS
import "./BillingProfile.scss";

function BillingProfile() {
  const [profile] = useState();
  const handleSubmit = () => {};

  return (
    <Container className="form-inputs">
      <form className="evenly" onSubmit={handleSubmit}>
        <Select className="billing-input" label="Tipo identificación" />
        <Input
          className="billing-input"
          label="Identificación"
          value={profile?.Name_Person}
        />

        <Input
          className="billing-input"
          label="Nombre"
          value={profile?.Name_Person}
        />

        <Input
          className="billing-input"
          label="Apellidos"
          value={profile?.Name_Person}
        />
      </form>
    </Container>
  );
}

export default BillingProfile;
