import React, { useCallback, useEffect, useState } from "react";
// External components
import { Button, Label, Progress, Column } from "rbx";
import { CSVLink } from "react-csv";
import { registerLocale } from "react-datepicker";
import Es from "date-fns/locale/es";
// Graphql
import { useMutation } from "@apollo/client";
import {
  ELECTRONIC_BILLS_DELETE,
  GET_ELECTRONICS_BILLS_NEW,
  UPDATE_EB,
} from "../../graphql";
// Components
import ExpansionPanel from "../ExpansionPanel";
import ExpansionPanelHeader from "../ExpansionPanelHeader";
import Inventories from "../Inventories";
import CoinSelector from "../CoinSelector";
import CustomDatePicker from "../CustomDatePicker";
import BarChartComponent from "../BarChartComponent";
import IvaReportSubsections from "../IvaReportSubsections";
import ConfirmationModal from "../ConfirmationModal";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
import UseCreateReport from "../../hooks/UseCreateReport";
// Utils
import { customToast as toast } from "../../utils";
import { d104Headers, getD104Data } from "../../utils/CSVHelper";
// SCSS
import "./IVAReports.scss";

function IVAReport() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [previusDate, setPreviusDate] = useState(new Date());
  const [previusPlace, setPreviusPlace] = useState(new Date());

  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const { LineDetailPlace, useCoinDetail } = useElectronic();
  const { toExchangeRate, currentSymbolCoin } = useCoinDetail;

  const [deleteElectronicBillById] = useMutation(ELECTRONIC_BILLS_DELETE);
  const [updateElectronicBill] = useMutation(UPDATE_EB);

  const [emitterList, setEmitter] = useState([]);
  const {
    dataToCSV,
    headersToCSV,
    handleGetElectronicBills,
    dataCSV,
    merch,
    services,
    filterBills,
    setFilterBills,
    isLoadingBills,
    electronicBills,
    setElectronicBills,
    totals,
    setTotals,
    getChartData,
    setDataCSV,
    generateReport,
  } = UseCreateReport();

  registerLocale("Es", Es);

  const initialStates = () => {
    setElectronicBills([]);
    setFilterBills([]);
    setEmitter([]);
    setTotals({
      TotalGrav: 0,
      TotalExempt: 0,
      TotalTaxes: 0,
      TotalNetSell: 0,
      TotalBill: 0,
      TotalServicesTaxed: 0,
      TotalServicesExempt: 0,
      TotalMerchTaxed: 0,
      TotalMerchExempt: 0,
    });
  };

  const handleDeleteElectronicBill = bill => {
    const isDeleted = deleteElectronicBillById({
      variables: {
        id: bill.id,
      },
      // si ven esto, es que olvide cambiar esta parter xd
      refetchQueries: [
        {
          query: GET_ELECTRONICS_BILLS_NEW,
          variables: {
            getElectronicBillsInput: {
              where: {
                equals: {
                  FK_OwnerPlace: LineDetailPlace.id,
                  BillFlowType: 1,
                },
                inMonth: selectedDate,
              },
            },
          },
        },
      ],
    });
    if (isDeleted) {
      toast.success("Factura eliminada con éxito");
      setFilterBills(
        filterBills.filter(filterBill => filterBill.id !== bill.id),
      );
      generateReport(
        filterBills.filter(filterBill => filterBill.id !== bill.id),
      );
      setModalOpen(false);
    } else {
      toast.error("Ocurrió un error al eliminar la factura");
    }
    //
  };

  const cancelDelete = () => {
    setModalOpen(false);
  };

  const handleConfirmDelete = bill => {
    setModalOpen(
      true,
      <ConfirmationModal
        body="¿Está seguro desea borrar esta factura? Presione confirmar para eliminar"
        header="¿Desea Borrar esta Factura?"
        onCancel={cancelDelete}
        onConfirm={() => handleDeleteElectronicBill(bill)}
      />,
    );
  };

  useEffect(() => {
    initialStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LineDetailPlace.id]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!LineDetailPlace?.id)
      return toast.warning("Por favor seleccione un lugar.");
    if (authState.user.TavuelUser.id && selectedDate) {
      if (
        previusDate !== selectedDate ||
        previusPlace !== LineDetailPlace?.id
      ) {
        initialStates();
        handleGetElectronicBills(selectedDate, 1);
        setPreviusPlace(LineDetailPlace?.id);
        return setPreviusDate(selectedDate);
      }
    }
    return null;
  };

  const handleUpdateDataCSV = () => {
    const nServices = services.map(service => ({
      ...service,
      Amount: toExchangeRate(service.Amount),
      Total: toExchangeRate(service.Total),
    }));
    const nMerch = merch.map(m => ({
      ...m,
      Amount: toExchangeRate(m.Amount),
      Total: toExchangeRate(m.Total),
    }));
    const nTotals = {
      ...totals,
      TotalBill: toExchangeRate(totals.TotalBill),
      TotalExempt: toExchangeRate(totals.TotalExempt),
      TotalGrav: toExchangeRate(totals.TotalGrav),
      TotalMerchExempt: toExchangeRate(totals.TotalMerchExempt),
      TotalMerchTaxed: toExchangeRate(totals.TotalMerchTaxed),
      TotalNetSell: toExchangeRate(totals.TotalNetSell),
      TotalServicesExempt: toExchangeRate(totals.TotalServicesExempt),
      TotalServicesTaxed: toExchangeRate(totals.TotalServicesTaxed),
      TotalTaxes: toExchangeRate(totals.TotalTaxes),
    };
    getD104Data(setDataCSV, nServices, nMerch, nTotals);
  };

  const reasingPlace = useCallback(
    async (placeId, ebillId) => {
      try {
        setModalOpen(false);
        if (ebillId && placeId) {
          const { data } = await updateElectronicBill({
            variables: {
              ebAttributes: {
                Id: ebillId,
                FK_OwnerPlace: placeId,
              },
            },
          });

          if (data.updateElectronicBill) {
            toast.success("Factura trasladada correctamente");
            handleGetElectronicBills(selectedDate, 1);
          } else toast.error("Hubo un error al intentar trasladar la factura");
        }
      } catch (err) {
        toast.error("Error al trasladar la factura");
      }
    },
    [
      handleGetElectronicBills,
      selectedDate,
      setModalOpen,
      updateElectronicBill,
    ],
  );

  const filterBillsByEmisor = Emitt => {
    setFilterBills(electronicBills.filter(b => b.Place.Name_Place === Emitt));
  };

  return (
    <div>
      <form className="ml-1 mr-1" onSubmit={handleSubmit}>
        <Column.Group vcentered>
          <Column narrow>
            <CustomDatePicker
              dateFormat="MMMM / yyyy"
              selectedDate={selectedDate}
              onChange={date => setSelectedDate(date)}
            />
          </Column>
          <Column size="2">
            <CoinSelector key="coin-selector" />
          </Column>
          <Column>
            <Button rounded color="primary" pull="right" type="submit">
              Generar Reporte
            </Button>
            {electronicBills.length !== 0 && (
              <div>
                <CSVLink
                  className="mt-3"
                  data={dataCSV}
                  filename={`Reporte D-104 (${
                    selectedDate.getMonth() + 1
                  }-${selectedDate.getFullYear()})`}
                  headers={d104Headers()}
                  style={{
                    paddingRight: 15,
                    color: "#01234c",
                    cursor: "pointer",
                    display: "block",
                    textAlign: "end",
                  }}
                  target="_blank"
                  onClick={handleUpdateDataCSV}
                >
                  Descargar CSV
                </CSVLink>
                <CSVLink
                  data={dataToCSV}
                  filename={`Lista de facturas (${
                    selectedDate.getMonth() + 1
                  }-${selectedDate.getFullYear()})`}
                  headers={headersToCSV}
                  style={{
                    paddingRight: 15,
                    color: "#01234c",
                    cursor: "pointer",
                    display: "block",
                    textAlign: "end",
                  }}
                  target="_blank"
                >
                  Descargar Lista de Facturas Electrónicas
                </CSVLink>
              </div>
            )}
          </Column>
        </Column.Group>
      </form>
      {isLoadingBills && (
        <Progress className="progress-bar" color="info" size="small" />
      )}
      {!isLoadingBills && electronicBills?.length !== 0 && (
        <div className="iva-chart-container mt-3">
          <BarChartComponent
            angle={60}
            data={getChartData()}
            dataXKey="date"
            dataYKey="amount"
          />
        </div>
      )}
      <h1>Resumen D-104</h1>
      <p>Declaración Jurada del Impuesto al Valor Agregado &nbsp; &nbsp;</p>
      {electronicBills?.length !== 0 && (
        <IvaReportSubsections
          summarizedData={{
            services,
            merch,
          }}
          symbolCoin={currentSymbolCoin}
          totals={totals}
        />
      )}
      <div>
        <h1>Emisores</h1>
        <p>Filtros</p>
        {electronicBills?.length !== 0 && (
          <div className="mt-2">
            {emitterList?.map((emitter, index) => (
              <div
                key={`${emitter.Emitter}-${index.toString()}`}
                className="iva-report-sub-seccion"
                role="button"
                tabIndex="0"
                onClick={e => {
                  filterBillsByEmisor(emitter.Emitter);
                }}
                onKeyDown={e => {
                  filterBillsByEmisor(emitter.Emitter);
                }}
              >
                <h3>Emisor: {emitter.Emitter}</h3>
                <p>Comercial: {emitter.Company}</p>
                <p>Ced: {emitter.Id}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {filterBills?.length !== 0 && (
        <div className="box">
          <Label className="iva-report-title-bill">Facturas</Label>

          {filterBills.map(bill => (
            <div
              key={bill?.InternConsecutive}
              className="iva-report-sub-seccion-bill"
            >
              <div className="columns is-mobile">
                <div className="column is-three-fifths is-mobile">
                  <p className="is-text-breaker">{bill?.Place?.Name_Place}</p>
                  <p className="is-text-breaker">{bill?.InternConsecutive}.</p>
                  <p className="is-text-breaker"> {bill?.Key}.</p>
                </div>
                <div className="column is-two-fifth">
                  <Button
                    className="button is-primary is-pulled-right"
                    color="primary"
                    onClick={() => handleConfirmDelete(bill)}
                  >
                    Eliminar
                  </Button>
                  <Button
                    className="button is-primary is-pulled-right"
                    color="secondary"
                    onClick={() =>
                      setModalOpen(
                        true,
                        <div className="place-selector__modal-container">
                          <Inventories
                            bill={bill}
                            setModalClose={setModalOpen}
                            onClickFunction={reasingPlace}
                          />{" "}
                        </div>,
                      )
                    }
                  >
                    Trasladar
                  </Button>
                </div>
              </div>

              <b>Moneda: {currentSymbolCoin}</b>
              <ExpansionPanelHeader bill={bill} />
              <ExpansionPanel bill={bill} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default IVAReport;
