import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Card } from "rbx";
// Utils
import { calculateTaxes, formatNumber } from "../../utils/ReportUtils";
import { useElectronic } from "../../context/ElectronicContext";

function ExpansionPanel({ bill }) {
  const [isActive, setIsActive] = useState(false);
  const { useCoinDetail } = useElectronic();
  const { toExchangeRate } = useCoinDetail;

  const calculateTotal = (Quantity, price, percent) => {
    const total = Quantity * price + calculateTaxes(Quantity * price, percent);
    return total;
  };

  return (
    <div className="accordion-item">
      <div
        key={bill.InternConsecutive}
        className="accordion-title"
        role="button"
        tabIndex="0"
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => setIsActive(!isActive)}
      >
        <div className="iva-report-sub-bill">
          <p>Líneas de detalle</p>
        </div>
        <div>{isActive ? "-" : "+"}</div>
      </div>
      {isActive && (
        <div className="accordion-content">
          <div className="grid">
            {bill.ElectronicBillDetail.map(detail => (
              <Card key={detail.id} className="card-content">
                <h2>{detail?.ProductFeature?.Product?.Name_Product}</h2>
                <p>
                  Código: &nbsp;
                  {detail?.ProductFeature?.Codes[0]?.FeatureCode},
                </p>
                <p>
                  {detail.Quantity}(
                  {
                    detail?.ProductFeature?.Product?.InventoryDetail
                      ?.UnitOfMeasurement?.Symbol
                  }
                  ) x &nbsp;
                  {formatNumber(toExchangeRate(detail?.Price?.toFixed(2)))}
                </p>
                <p>
                  {formatNumber(
                    toExchangeRate(
                      calculateTaxes(
                        detail.Quantity * detail?.Price,
                        detail?.ProductFeature?.ProductPrice?.Tariff?.Percent,
                      ).toFixed(2),
                    ),
                  )}
                  &nbsp; (
                  {detail?.ProductFeature?.ProductPrice?.Tariff?.Percent}
                  %)
                </p>
                <p>
                  Total:&nbsp;
                  {formatNumber(
                    toExchangeRate(
                      calculateTotal(
                        detail.Quantity,
                        detail?.Price,
                        detail?.ProductFeature?.ProductPrice?.Tariff?.Percent,
                      ).toFixed(2),
                    ),
                  )}
                </p>
              </Card>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
ExpansionPanel.propTypes = {
  bill: PropTypes.object.isRequired,
};

export default ExpansionPanel;
