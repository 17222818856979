import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Field, Control, Input, Title, Label, Icon, Image } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_PLACE, GET_ACTIVITIES_BY_ID_MH } from "../../graphql";
// Components
import SelectActivityCode from "../ActivityCodeSelect";
import CropperPlace from "../CropperPlace";
import Loading from "../Loading";
// Context
import { useModal } from "../../context";
// Utils
import { customToast as toast, decrypt, encrypt } from "../../utils";
import { validateRoute } from "../../utils/helpers";
// SCSS
import "./PlacesUpdate.scss";
import "../../pages/ProfilePage/ProfilePage.scss";

function PlacesUpdate({ place, refetch, setPlaceSelected }) {
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const [passIsViible, setPassIsVisible] = useState(false);
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const [newPlace, setPlace] = useState({
    id: place?.id,
    Code: place?.Code,
    Name_Place: place?.Name_Place,
    Description_Place: place?.Description_Place,
    FK_Company: place?.Company?.id,
    State_Place: place?.State_Place,
    Gmail: place?.Gmail,
    Gmail_App: place?.Gmail_App ? decrypt(place?.Gmail_App) : place?.Gmail_App,
  });

  const { setModalOpen } = useModal();

  const canvas = useRef(null);

  const { loading, data: getActivityData } = useQuery(GET_ACTIVITIES_BY_ID_MH, {
    variables: {
      id_MH: place?.Code,
    },
  });
  const [updatePlace] = useMutation(UPDATE_PLACE);

  const validateImgRoute = () => {
    if (firebaseURL === "") {
      return validateRoute(place);
    }
    return firebaseURL;
  };

  const handleChange = (name, value) => {
    setPlace(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = newValue => {
    setPlace(prev => ({ ...prev, Code: newValue.value }));
  };

  const update = useCallback(async () => {
    try {
      const address = {
        id: null,
      };
      const { data } = await updatePlace({
        variables: {
          place: { ...newPlace, Gmail_App: encrypt(newPlace.Gmail_App) },
          address,
        },
      });
      if (data?.place) {
        toast.success("¡Lugar actualizado con éxito!");
        refetch();
        setPlaceSelected(false);
      }
    } catch (err) {
      toast.error("Error al actualizar el lugar");
    }
  }, [updatePlace, newPlace, refetch, setPlaceSelected]);

  const handleSubmit = e => {
    e.preventDefault();
    if (newPlace.Name_Place !== "" && newPlace.Description_Place !== "") {
      update();
    } else {
      toast.error("Debe completar los datos solicitados.");
    }
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSelectClick = e => {
    e.preventDefault();
    document.querySelector("#fileSelectorImg").click();
  };

  const handleCancel = () => {
    setPlaceSelected(false);
  };

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpen(
        true,
        <CropperPlace
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setModalOpen(false)}
        />,
      );
      setOpen(true);
    }
  }, [open, setModalOpen, upImg]);

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setPlace(prev => ({
        ...prev,
        Files: [
          {
            Route_File: firebaseURL,
            FirebaseId: firebaseID,
            State_File: "New",
            FileType: {
              id: 2,
            },
          },
        ],
      }));
    }
  }, [firebaseURL, firebaseID]);

  return (
    <div>
      <form className="profile-page-container" onSubmit={handleSubmit}>
        <div>
          <Title className="profile-page-title">Actualización de Lugares</Title>

          <div className={loading && "places-loading"}>
            {loading && (
              <div>
                <Loading />
              </div>
            )}
            {!loading && (
              <div className="places-container">
                <div className=" title-center">
                  <input
                    accept="image/*"
                    className="input-file"
                    id="fileSelectorImg"
                    type="file"
                    onChange={hadleOnSelectFile}
                  />
                  <div className="profilepic">
                    <Image.Container size="64px sq.">
                      <Image
                        className="profilepic__image"
                        height="150"
                        src={validateImgRoute()}
                        width="150"
                      />
                    </Image.Container>
                    <div className="profilepic__content">
                      <Icon className="hover-table-options ">
                        <FontAwesomeIcon
                          icon="camera"
                          size="1x"
                          onClick={handleSelectClick}
                        />
                      </Icon>
                    </div>
                  </div>
                </div>
                <Field kind="group">
                  <Control expanded>
                    <Label textColor="grey-dark">Compañía:</Label>
                    <Input
                      disabled
                      placeholder="Compañía"
                      type="text"
                      value={place?.Company?.Name_Company}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </Control>
                </Field>
                <Field kind="group">
                  <Control expanded>
                    <Label textColor="grey-dark">Nombre:</Label>
                    <Input
                      name="Name_Place"
                      placeholder="Nombre"
                      type="text"
                      value={newPlace?.Name_Place}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </Control>
                </Field>
                <Field kind="group">
                  <Control expanded>
                    <Label textColor="grey-dark">Descripción:</Label>
                    <Input
                      name="Description_Place"
                      placeholder="Descripción"
                      type="text"
                      value={newPlace?.Description_Place}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </Control>
                </Field>
                {getActivityData?.activity ? (
                  <SelectActivityCode
                    defaultValue={{
                      value: getActivityData?.activity?.id_MH,
                      label: getActivityData?.activity?.Name,
                    }}
                    handleChange={handleSelectChange}
                  />
                ) : (
                  <SelectActivityCode
                    defaultValue={{ label: "Buscar...", value: "" }}
                    handleChange={handleSelectChange}
                  />
                )}
                <Field kind="group">
                  <Control expanded>
                    <Label textColor="grey-dark">Correo Gmail:</Label>
                    <Input
                      name="Gmail"
                      placeholder="Correo Gmail"
                      type="email"
                      value={newPlace.Gmail}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </Control>
                </Field>
                <Field kind="group">
                  <Control expanded>
                    <Label textColor="grey-dark">
                      Contraseña de aplicación Gmail:
                    </Label>
                    <div
                      className={` places places__password-container ${
                        passIsViible
                          ? "places__password-container-off"
                          : "places__password-container-on"
                      }`}
                    >
                      <Input
                        name="Gmail_App"
                        placeholder="Contraseña de aplicación Gmail"
                        type={passIsViible ? "text" : "password"}
                        value={newPlace.Gmail_App}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                      <FontAwesomeIcon
                        icon="eye"
                        size="2x"
                        onClick={() => setPassIsVisible(!passIsViible)}
                      />
                    </div>
                  </Control>
                </Field>
                <div className="profile-page-button">
                  <Button className="cancel-button" onClick={handleCancel}>
                    Cancelar
                  </Button>
                  <Button rounded color="primary" type="submit">
                    Guardar
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

PlacesUpdate.propTypes = {
  place: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  setPlaceSelected: PropTypes.func.isRequired,
};

export default PlacesUpdate;
