/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "./CoinSelector.scss";
// import { useQuery } from "@apollo/client";
// import { GET_ALL_COINS } from "../../graphql";
import { useElectronic } from "../../context/ElectronicContext";
import SelectComponent from "../SelectComponent";

const CoinSelector = () => {
  const [coinOptions, setCoinOptions] = useState([]);
  const [selectedCoinOption, setSelectedCoinOption] = useState(null);

  const { useCoinDetail } = useElectronic();
  const {
    handleCoinChange,
    getCoinsData: coinsData,
    currentCoin,
  } = useCoinDetail;

  useEffect(() => {
    let coins = coinsData?.coins?.map(coin => ({
      id: coin.id,
      label: coin.Code_Coin,
    }));
    coins = coins.filter(coin => coin.id !== "4");
    setCoinOptions(coins);
    const currentGlobalCoin = coins.find(coin => coin.id === currentCoin);
    currentGlobalCoin
      ? setSelectedCoinOption(currentGlobalCoin)
      : changeCoinBySelected(coins[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinsData]);

  const changeCoinBySelected = selectionValue => {
    handleCoinChange(selectionValue.id);
    setSelectedCoinOption(selectionValue);
  };

  const getIconLink = value =>
    `https://wise.com/public-resources/assets/flags/rectangle/${value}.png`;

  return selectedCoinOption ? (
    <SelectComponent
      getIcon={getIconLink}
      options={coinOptions}
      selected={selectedCoinOption}
      onChange={changeCoinBySelected}
    />
  ) : (
    <div>Cargando...</div>
  );
};

export default CoinSelector;
