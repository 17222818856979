import React from "react";
import PropTypes from "prop-types";
// External components
import { Button, Control, Label, Field } from "rbx";
// Components
import { Input, Select } from "../Common";
import SelectDocumentType from "../DocumentTypeSelect/DocumentTypeSelect";
import CustomDatePicker from "../CustomDatePicker";
// SCSS
import "./DocumentSearchForm.scss";
import "react-datepicker/dist/react-datepicker.css";
import { DOCUMENT_SEARCH_MODULES } from "../../Enums/ElectronicBill";

function DocumentSearchForm({ documentForm, toggleState, onChange, onSearch }) {
  const handleChange = (name, value) => {
    onChange(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="DSForm">
      <Input
        className="DSForm__generic-input"
        defaultValue={documentForm.User_Name}
        label="Nombre del usuario"
        name="User_Name"
        value={documentForm.User_Name}
        onChange={({ target: { value, name } }) => handleChange(name, value)}
      />
      <Input
        className="DSForm__generic-input"
        defaultValue={documentForm.User_ID}
        label="Cédula del usuario"
        name="User_ID"
        type="string"
        value={documentForm.User_ID}
        onChange={({ target: { value, name } }) => handleChange(name, value)}
      />
      <div
        style={{
          display:
            toggleState === DOCUMENT_SEARCH_MODULES.BILLS ||
            toggleState === DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS_EXPENSES
              ? "none"
              : "",
        }}
      >
        <SelectDocumentType
          className="DSForm__select"
          defaultValue={documentForm?.Document_Type}
          label="Tipo de Documento"
          name="Document_Type"
          selected_value={documentForm?.Document_Type}
          onChange={handleChange}
        />
      </div>
      <div
        style={{
          display: toggleState === DOCUMENT_SEARCH_MODULES.BILLS ? "none" : "",
        }}
      >
        <Select
          className="DSForm__select"
          label="Estado del Documento"
          name="Document_State"
          options={[
            { value: 1, label: "Aceptado" },
            { value: 2, label: "Aceptado parcialmente" },
            { value: 3, label: "Rechazado" },
            { value: null, label: "Cualquiera" },
          ]}
          placeholder="Estado"
          value={documentForm.Document_State}
          onChange={({ value }) => handleChange("Document_State", value)}
        />
      </div>
      <Field className="DSForm__select" kind="group">
        <Control expanded>
          <Label className="label" textColor="grey-dark">
            Mes y año de emisión
          </Label>
          <CustomDatePicker
            dateFormat="MMMM / yyyy"
            placeholderText="Seleccione un Mes"
            selectedDate={documentForm.EmitedDay}
            onChange={date => handleChange("EmitedDay", date)}
          />
        </Control>
      </Field>
      <Control className="spacing">
        <Label>&nbsp;</Label>
        <Button
          className="DSForm__search-button"
          color="primary"
          onClick={onSearch}
        >
          Buscar
        </Button>
      </Control>
    </div>
  );
}

DocumentSearchForm.propTypes = {
  documentForm: PropTypes.object.isRequired,
  toggleState: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

DocumentSearchForm.defaultProps = {
  toggleState: 0,
};

export default DocumentSearchForm;
