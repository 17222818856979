import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
// External components
import { Card, Checkbox, Container, Icon, Image, Input, Label } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AliceCarousel from "react-alice-carousel";
// Graphql
import { useMutation } from "@apollo/client";
import { DELETE_DRAFT_DETAIL, UPDATE_DETAILL_QUANTITY } from "../../graphql";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./ProductCard.scss";
import "react-alice-carousel/lib/alice-carousel.css";
// Utils
import {
  getDetails,
  hadleAddDiscounts,
  handleCheck,
  handleDecrement,
  handleDeleteProduct,
  handleIncrement,
  handleProductList,
} from "../../utils/ProductUtils";
import TransformImages from "../../utils/TransformImages";
import {
  BILL_TYPES_URL_PARAMS,
  ElectronicBillTypes,
} from "../../Enums/ElectronicBill";

function ProductCard({ list, setDataTable, selectables, carousel, type }) {
  const [updateQuantity] = useMutation(UPDATE_DETAILL_QUANTITY);
  const [deleteDetail] = useMutation(DELETE_DRAFT_DETAIL);
  const { setModalOpen } = useModal();
  const { electronicBill, useCoinDetail } = useElectronic();
  const { currentSymbolCoin, toExchangeRate } = useCoinDetail;
  const { isSideBarOpen } = useAuth();
  const [caro_key, setCaro_key] = useState("caro_key");
  const { billType } = useParams();

  useEffect(() => {
    setTimeout(() => {
      setCaro_key(prev => (prev === "caro_key" ? "1" : "caro_key"));
    }, 300);
  }, [isSideBarOpen]);

  const getUrl = product => {
    const route = product.url?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const validateRoute = product => {
    let route = getUrl(product);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    route = TransformImages(product.url, 1);
    return route;
  };

  const getStyle = () => {
    if (carousel) return "carousel-item";
    return " principal-container";
  };

  const handleChangeTax = (value, product) => {
    if (value < 99999.9999) {
      if (parseFloat(value).toString().split(".")[1]?.length) {
        if (parseFloat(value).toString().split(".")[1]?.length < 5) {
          handleProductList(product.id, "BaseTax", setDataTable, list, value);
        } else {
          return;
        }
      }
      handleProductList(product.id, "BaseTax", setDataTable, list, value);
    }
  };

  const cardList = productList =>
    productList.map(product => (
      <div key={product.id} className={getStyle()}>
        {selectables && (
          <div className="selectables">
            <Checkbox
              checked={product.checked || false}
              className="checkbox"
              disabled={
                !product.valid ||
                (product.checked && type === ElectronicBillTypes.DebitNote)
              }
              label="text"
              name="test"
              size={4}
              onChange={e =>
                handleCheck(product.id, !product.checked, setDataTable, list)
              }
            />
            <Label disabled={!product.valid} style={{ color: "#ffffff" }}>
              {(product.valid &&
                (product.checked ? " Seleccionado" : " No seleccionado")) ||
                " No seleccionable"}
            </Label>
          </div>
        )}

        <Card className="principal-container product-card">
          <div className="content-left">
            <div className="product-header title-product ">
              <h3 className="title-product ellipsis one-line ">
                {product?.description !== " "
                  ? product?.description
                  : "Sin nombre"}
              </h3>
              <div>
                <p className="details-product ellipsis one-line">
                  {getDetails(product) || "Sin detalles"}
                </p>
              </div>
            </div>

            {product?.tax?.MH_Code === "07" && (
              <div className="product-base ">
                <Label className="details-product">Base Imponente:</Label>
                <Input
                  type="number"
                  value={product?.BaseTax || 0}
                  onChange={e => handleChangeTax(e.target.value, product)}
                />
              </div>
            )}
            {product?.tax?.MH_Code === "08" && (
              <div className="product-base ">
                <Label>Monto Factor:</Label>
                <Input
                  type="number"
                  value={product?.Factor || 0}
                  onChange={e =>
                    handleProductList(
                      product.id,
                      "Factor",
                      setDataTable,
                      list,
                      e.target.value,
                    )
                  }
                />
              </div>
            )}

            <div className="product-footer ">
              <div className="product-info title-product ">
                <div
                  className="products-buttons"
                  disabled={type === ElectronicBillTypes.DebitNote}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    billType !== BILL_TYPES_URL_PARAMS.DEBIT_NOTE &&
                      handleDecrement(
                        product.id,
                        product.quantity,
                        setDataTable,
                        list,
                        selectables,
                        updateQuantity,
                        electronicBill,
                      );
                  }}
                  onKeyDown={() => null}
                >
                  <h2>-</h2>
                </div>
                &nbsp;&nbsp;&nbsp;
                <h2>{product.quantity}</h2>&nbsp;&nbsp;&nbsp;
                <div
                  className="products-buttons"
                  disabled={type === ElectronicBillTypes.DebitNote}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleIncrement(
                      product?.id,
                      setDataTable,
                      list,
                      selectables,
                      updateQuantity,
                      electronicBill,
                    );
                  }}
                  onKeyDown={() => null}
                >
                  <h2>+</h2>
                </div>
                &nbsp;&nbsp;&nbsp;
              </div>
              <div>
                <h2>
                  {currentSymbolCoin}
                  {toExchangeRate(parseFloat(product.total)).toFixed(3)}
                </h2>
              </div>
            </div>
            {type !== ElectronicBillTypes.DebitNote && (
              <div className="product-footer">
                <div className="principal-container details-product">
                  <h4>Desc.</h4>&nbsp;
                  <Icon
                    className="hover-table-options items-center"
                    disabled={type === ElectronicBillTypes.DebitNote}
                    size="small"
                    onClick={() =>
                      hadleAddDiscounts(
                        product,
                        setModalOpen,
                        useCoinDetail,
                        list,
                        setDataTable,
                      )
                    }
                  >
                    <FontAwesomeIcon icon="edit" size="1x" />
                  </Icon>
                  &nbsp;&nbsp;<h4>Imp.</h4>&nbsp;
                  <b>+ {product?.Tariff?.Percent}% IVA</b>
                  {/*
                  This code should remain commented out for now.
                  <Icon
                    className="hover-table-options items-center"
                    disabled={type === ElectronicBillTypes.DebitNote}
                    size="small"
                    onClick={() =>
                      hadleAddTaxes(
                        product,
                        setModalOpen,
                        useCoinDetail,
                        list,
                        setDataTable,
                        getTaxesData,
                      )
                    }
                  >
                    <FontAwesomeIcon icon="edit" size="1x" />
                  </Icon> */}
                </div>
              </div>
            )}
          </div>
          <div className={carousel ? "content-image" : "content-rigth"}>
            <Image.Container>
              <Image src={validateRoute(product)} />
            </Image.Container>
          </div>
          {!selectables && (
            <Icon className="hover-table-options icon-cancel">
              <FontAwesomeIcon
                disabled={type === ElectronicBillTypes.DebitNote}
                icon="times"
                size="1x"
                onClick={() =>
                  handleDeleteProduct(
                    product,
                    setDataTable,
                    list,
                    selectables,
                    deleteDetail,
                  )
                }
              />
            </Icon>
          )}
        </Card>
      </div>
    ));

  const getOrderList = () =>
    Array.isArray(list)
      ? list.sort((a, b) => Number(b.checked) - Number(a.checked))
      : [];

  return !carousel ? (
    <div className="grid">{cardList(list)}</div>
  ) : (
    <Container className="carousel-container">
      <AliceCarousel
        key={caro_key}
        infinite
        mouseTracking
        controlsStrategy="alternate"
        items={cardList(getOrderList(list))}
        responsive={responsive}
      />
    </Container>
  );
}
ProductCard.propTypes = {
  setDataTable: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  selectables: PropTypes.bool,
  carousel: PropTypes.bool,
  type: PropTypes.string,
};
ProductCard.defaultProps = {
  selectables: false,
  carousel: false,
  type: "",
};

export default ProductCard;
