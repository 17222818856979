import React, { useState } from "react";
import { format } from "date-fns";
// External components
import { Container, Card, Label, Button, Icon, Field } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { client_EB_api } from "../../graphql/client";
import { GET_ELECTRONICS_BILLS_NEW, GET_MANY_PDFS } from "../../graphql";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Components
import Loading from "../Loading";
import Paginated from "../Paginated";
import CustomDatePicker from "../CustomDatePicker";
// Helpers
import { downloadBase64File } from "../../utils/helpers";
import { ElectronicBillErrors } from "../../Enums/Errors";
import { customToast } from "../../utils";
// SCSS
import "./ElectronicBillReport.scss";
import useElectronicBill from "../../hooks/useElectronicBill";
// hooks
import UseCreateReport from "../../hooks/UseCreateReport";

function ElectronicBillReport() {
  const { handleGetElectronicBills } = UseCreateReport();
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const { LineDetailPlace, setLineDetailPlace } = useElectronic();
  const { getBillByElectronicBill } = useElectronicBill();

  const [getElectronicBillsNew, { data: electronicBillsData, loading }] =
    useLazyQuery(GET_ELECTRONICS_BILLS_NEW);

  const castElectronicBillsForTable = electronicBills =>
    electronicBills.map(electronicBill => ({
      ...electronicBill,
      Bill: getBillByElectronicBill(electronicBill),
    }));

  const getDates = async () => {
    getElectronicBillsNew({
      variables: {
        getElectronicBillsInput: {
          where: {
            equals: {
              FK_OwnerPlace: LineDetailPlace.id,
              FK_Place: LineDetailPlace.id,
            },
            inMonth: selectedMonth,
          },
          orderBy: [
            {
              columnName: "EmitedDay",
              order: "DESC",
            },
          ],
        },
      },
    });
  };

  const handleSelectStore = () => {
    setLineDetailPlace({ id: 0 });
  };

  const handleSubmit = () => {
    getDates();
    handleGetElectronicBills(selectedMonth, 2);
  };

  const columns = [
    {
      id: "Created_At",

      Header: "Fecha",
      accessor: "Created_At",
      Cell: ({ cell }) =>
        cell?.value && cell?.value !== "-1"
          ? format(new Date(cell.value), "MM/dd/yyyy")
          : "--",
      width: "150px",
    },
    {
      id: "Name_Person",
      Header: "Cliente",
      width: "300px",
      accessor: "Bill.User.Person.Name_Person",
    },

    {
      id: "TotalBill",
      Header: "Total",
      accessor: "Bill.Total_Bill",
      width: "auto",
    },
  ];

  const handleDownloadPDF = async billItem => {
    const { data } = await client_EB_api.query({
      query: GET_MANY_PDFS,
      variables: {
        ids: billItem?.id,
        place_id: LineDetailPlace.id,
      },
    });
    if (data.downloadManyPDF[0].payload) {
      const { payload, params, type } = data.downloadManyPDF[0];
      downloadBase64File(payload, `${params[0]}.${type}`);
      return;
    }
    if (!data.downloadManyPDF[0].error) {
      return;
    }
    const { error } = data.downloadManyPDF[0];
    if (error === ElectronicBillErrors.NOT_FOUND) {
      customToast.error("El archivo no se encontró");
      return;
    }
    customToast.error("Error desconocido");
  };
  const getExtraComponent = row => (
    <Icon
      className="hover-table-options"
      size="small"
      onClick={() => handleDownloadPDF(row.original)}
    >
      <FontAwesomeIcon icon="download" />
    </Icon>
  );

  return (
    <div>
      <Container>
        <Card className="card-date">
          <Label>Buscar facturas electrónicas por su fecha</Label>
          <div className="container-date">
            <Field className="container-input-date-month" kind="group">
              <div>
                <Label className="label" textColor="grey-dark">
                  Mes y año de emisión
                </Label>
                <CustomDatePicker
                  dateFormat="MMMM / yyyy"
                  selectedDate={selectedMonth}
                  onChange={date => setSelectedMonth(date)}
                />
              </div>
            </Field>

            <Card className="card-store" onClick={handleSelectStore}>
              <div className="card-header-store">
                <p className="card-header-text">Tienda</p>
              </div>
              <div className="card-body-store">
                <div className="card-store-icone">
                  <Icon size="small">
                    <FontAwesomeIcon icon="store" size="lg" />
                  </Icon>
                </div>
                <div className="card-store-information">
                  <p>{LineDetailPlace.Name_Place}</p>
                </div>
              </div>
            </Card>
          </div>
          <div className="container-search-button">
            <Button className="button-search-date" onClick={handleSubmit}>
              Buscar
            </Button>
          </div>
        </Card>
      </Container>
      {loading && <Loading />}

      <Container>
        <Card className="container-all-bills">
          {electronicBillsData?.getElectronicBillsNew?.ElectronicBills.length >
          0 ? (
            <Paginated
              columns={columns}
              data={castElectronicBillsForTable(
                electronicBillsData.getElectronicBillsNew?.ElectronicBills,
              )}
              getExtraComponent={getExtraComponent}
            />
          ) : (
            <h2>Sin facturas que mostrar</h2>
          )}
        </Card>
      </Container>
    </div>
  );
}

export default ElectronicBillReport;
