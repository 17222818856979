import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Control, Field, Input, Title, Container } from "rbx";
// Components
import { customToast } from "../../utils";
import CustomLoading from "../CustomLoading";
// Styles
import "./ForgotPassword.scss";
import { useAuth } from "../../context";

const ForgotPassword = ({ setModalOpen }) => {
  const [email, setEmail] = useState("");
  const { handleSendEmailReset, handleCheckEmailAuth } = useAuth();
  const [isloading, setIsloading] = useState(false);

  const handleClick = async () => {
    if (!email) {
      return customToast.error(
        "Por favor, ingrese un correo electrónico válido.",
      );
    }
    const user = await handleCheckEmailAuth(email);
    if (!user) {
      // if not found a user
      return customToast.error(
        "No se encontró un usuario asociado al correo electrónico suministrado.",
      );
    }
    // send email
    setIsloading(true);
    const isSent = await handleSendEmailReset(email, user?.UID);
    setIsloading(isSent);
    if (isSent) {
      customToast.success(
        "Se ha enviando un correo electrónico a la dirección suministrada.",
      );
      return setModalOpen(false);
    }
    return true;
  };

  return !isloading ? (
    <React.Fragment>
      <Container className="password-forgot">
        <div className="password-forgot-input">
          <Title className="password-forgot-title">
            Restablecer contraseña
          </Title>
          <Field>
            <Control>
              <Input
                placeholder="Correo electrónico"
                size="large"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Control>
          </Field>
        </div>
        <br />
        <div className="landing-page-buttons">
          <Button
            rounded
            className="landing-page-button login-buttons"
            size="large"
            type="button"
            onClick={handleClick}
          >
            &nbsp;&nbsp;&nbsp;&nbsp; Restablecer &nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        </div>
      </Container>
    </React.Fragment>
  ) : (
    <CustomLoading
      centered
      vCentered
      errorTexts={["Lo sentimos", "Ha ocurrido un error validando los datos"]}
      loadingState={isloading ? "loading" : "warning"}
      loadingTexts={["Enviando email", "Por favor, espera..."]}
      warningTexts={["Algo inesperado ha ocurrido"]}
    />
  );
};

ForgotPassword.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
};

export default ForgotPassword;
