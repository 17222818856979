import React from "react";
import { Switch, Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
// External components
import { Container } from "rbx";
// Context
import { useAuth } from "../context";
import { ModalProvider } from "../context/ModalContext";
import { NotificationsProvider } from "../context/NotificationsContext";
import { useElectronic } from "../context/ElectronicContext";
// Helper Route
import { InventoryRequiredRoute } from "../routes";
// Components
import {
  Sidebar,
  CreateUser,
  ClientsMaintenance,
  SendingBill,
  ResetForgotPassword,
} from "../components";
// Pages
import { LandingPage } from "./LandingPage";
import HomePage from "./HomePage";
import ExternalLoginPage from "./ExternalLoginPage";
import ControlUserRolPage from "./ControlUserRolPage";
import IncomePage from "./IncomePage";
import ExpensesPage from "./ExpensesPage";
import ReportsPage from "./ReportsPage";
import ClientsPage from "./ClientsPage";
import ProfilePage from "./ProfilePage";
import ElectronicBillsPage from "./ElectronicBillsPage";
import ProformaPage from "./ProformaPage";
import ClientsGroupPage from "./ClientsGroupsPage";
import PlacesPage from "./PlacesPage";
import BuyElectronicBillsPage from "./BuyElectronicBillsPage";
import SecurityPage from "./Security/SecurityPage";
import ResetPasswordPage from "./ResetPasswordPage/ResetPasswordPage";
import ProductsPage from "./ProductsPage";
import EBViewPage from "./EBViewPage";
import ExportationBillPage from "./ExportationBillPage";
import DocumentSearchPage from "./DocumentSearchPage";
import CreditDebitNotesPage from "./CreditDebitNotesPage";
import CreditNotes from "./CreditNotes";
import NotificationsPage from "./NotificationsPage";
import ScheduledBillSearchPage from "./ScheduledBillSearchPage";

const Pages = props => {
  const {
    state: authState,
    handleLogout,
    permissions,
    isSideBarOpen,
    setIsSideBarOpen,
  } = useAuth();

  const { setLineDetailPlace, setAccountSelected } = useElectronic();

  const { isSuperAdminOrHaveAccess } = permissions;

  const handleFullLogOut = () => {
    setLineDetailPlace({ id: 0 });
    setAccountSelected({ id: 0 });
    handleLogout();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    debounce: 500,
    onIdle: handleFullLogOut,
  });

  return authState.isLoggedIn ? (
    <div className="app-container">
      {authState.user.TavuelUser ? (
        <ModalProvider>
          <NotificationsProvider>
            <main
              className={`container ${isSideBarOpen ? "open" : ""}`}
              role="presentation"
              onClick={() =>
                window.innerWidth < 900 ? setIsSideBarOpen(false) : null
              }
              onKeyDown={() =>
                window.innerWidth < 900 ? setIsSideBarOpen(false) : null
              }
            >
              <Sidebar
                isSideBarOpen={isSideBarOpen}
                setIsSideBarOpen={setIsSideBarOpen}
              />
              <div
                className={[
                  "section-container",
                  "main-page-container",
                  isSideBarOpen && "open",
                ]
                  .filter(Boolean)
                  .join(" ")}
              >
                <Container fluid>
                  <Switch>
                    {isSuperAdminOrHaveAccess("/reports") && (
                      <Route path="/reports">
                        <ReportsPage />
                      </Route>
                    )}
                    {isSuperAdminOrHaveAccess("/clients") && (
                      <InventoryRequiredRoute
                        component={ClientsPage}
                        path="/clients"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/incomes") && (
                      <Route path="/incomes">
                        <IncomePage />
                      </Route>
                    )}
                    {isSuperAdminOrHaveAccess("/expenses") && (
                      <InventoryRequiredRoute
                        component={ExpensesPage}
                        path="/expenses"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/places") && (
                      <Route path="/places">
                        <PlacesPage />
                      </Route>
                    )}
                    {isSuperAdminOrHaveAccess("/products") && (
                      <InventoryRequiredRoute
                        component={ProductsPage}
                        path="/products"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/clients") && (
                      <InventoryRequiredRoute
                        component={ClientsGroupPage}
                        path="/clients-groups"
                      />
                    )}
                    <Route path="/profile">
                      <ProfilePage />
                    </Route>
                    {isSuperAdminOrHaveAccess("/notifications") && (
                      <Route path="/notifications">
                        <NotificationsPage />
                      </Route>
                    )}
                    {isSuperAdminOrHaveAccess("/security") && (
                      <Route path="/security">
                        <SecurityPage />
                      </Route>
                    )}
                    {isSuperAdminOrHaveAccess("/clients-maintenance") && (
                      <Route path="/clients-maintenance">
                        <ClientsMaintenance />
                      </Route>
                    )}
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <InventoryRequiredRoute
                        component={ElectronicBillsPage}
                        path="/electronic-bills"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <InventoryRequiredRoute
                        component={BuyElectronicBillsPage}
                        path="/buy-electronic-bills"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <InventoryRequiredRoute
                        component={ExportationBillPage}
                        path="/ExportationBill"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <Route path="/CreditDebitNote/:id/CreditNotes">
                        <CreditNotes />
                      </Route>
                    )}
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <InventoryRequiredRoute
                        component={CreditDebitNotesPage}
                        path="/CreditDebitNote"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <InventoryRequiredRoute
                        component={ProformaPage}
                        path="/Proforma"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <InventoryRequiredRoute
                        component={DocumentSearchPage}
                        path="/DocumentSearch"
                      />
                    )}
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <InventoryRequiredRoute
                        component={ScheduledBillSearchPage}
                        path="/ScheduledBill"
                      />
                    )}
                    <Route path="/send-bill">
                      <SendingBill />
                    </Route>
                    <Route path="/control-user-role">
                      <ControlUserRolPage />
                    </Route>
                    {isSuperAdminOrHaveAccess("/electronic-bills") && (
                      <Route path="/ElectronicBillView/:id/:viewType/:billType">
                        <EBViewPage />
                      </Route>
                    )}
                    <Route path="/">
                      <HomePage />
                    </Route>
                  </Switch>
                </Container>
              </div>
            </main>
          </NotificationsProvider>
        </ModalProvider>
      ) : (
        <CreateUser />
      )}
    </div>
  ) : (
    <ModalProvider>
      <Container fluid>
        <Switch>
          <Route path="/resetPassword/:token">
            <ResetPasswordPage />
          </Route>
          <Route path="/forgot-password/:token">
            <ResetForgotPassword />
          </Route>
          <Route path="/external-login/:token/:uid/:id">
            <ExternalLoginPage />
          </Route>
          <Route>
            <LandingPage />
          </Route>
        </Switch>
      </Container>
    </ModalProvider>
  );
};

Pages.propTypes = {};

export default Pages;
