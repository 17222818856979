import { Card } from "rbx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./ScheduledBill.scss";
import { BILL_CONFIGURATION_FREQUENCY_TEXT } from "../../Enums/BillConfiguration";

function ScheduledBill({
  BillConfiguration,
  FailedBillsConfiguration,
  SuccessBillsConfiguration,
}) {
  const history = useHistory();
  const formatDate = bill => {
    const date = new Date(bill);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  return (
    <div className="scheduledbill-flex">
      {BillConfiguration?.getAllBillsWithBillConfiguration?.map(bill => (
        <Card key={bill.id} className="card-content">
          <div
            role="button"
            tabIndex={0}
            onClick={() => history.push(`/electronic-bills/${bill?.id}/Draft`)}
            onKeyDown={null}
          >
            <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
              <b>Cliente: </b>
              <small className="draft-bill__detail-text">
                {bill?.User?.Person?.Name_Person}
              </small>
            </p>
            <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
              <b>Fecha de envío: </b>
              <small className="draft-bill__detail-text">
                {formatDate(bill?.BillConfiguration?.date)}
              </small>
            </p>
            <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
              <b>Periocidad: </b>
              <small className="draft-bill__detail-text">
                {
                  BILL_CONFIGURATION_FREQUENCY_TEXT[
                    bill?.BillConfiguration?.Frequency
                  ]
                }
              </small>
            </p>
          </div>
        </Card>
      ))}

      {SuccessBillsConfiguration?.getBillsConfigurationEvents?.map(
        successBillsConfiguration => (
          <Card key={successBillsConfiguration.id} className="card-content">
            <div
              role="button"
              tabIndex={0}
              onClick={() =>
                history.push(
                  `/electronic-bills/${successBillsConfiguration?.Bill?.id}/Draft`,
                )
              }
              onKeyDown={null}
            >
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Cliente: </b>
                <small className="draft-bill__detail-text">
                  {successBillsConfiguration?.Bill?.User?.Person?.Name_Person}
                </small>
              </p>
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Enviada: </b>
                <small className="draft-bill__detail-text">
                  {formatDate(successBillsConfiguration?.Execution_Time)}
                </small>
              </p>
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Periocidad: </b>
                <small className="draft-bill__detail-text">
                  {
                    BILL_CONFIGURATION_FREQUENCY_TEXT[
                      successBillsConfiguration?.BillConfiguration?.Frequency
                    ]
                  }
                </small>
              </p>
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Tiempo de ejecución del proceso: </b>
                <small className="draft-bill__detail-text">
                  {successBillsConfiguration?.Time_Execute_Process}
                </small>
              </p>
            </div>
          </Card>
        ),
      )}

      {FailedBillsConfiguration?.getBillsConfigurationEvents?.map(
        failedBillsConfiguration => (
          <Card key={failedBillsConfiguration.id} className="card-content">
            <div
              role="button"
              tabIndex={0}
              onClick={() =>
                history.push(
                  `/electronic-bills/${failedBillsConfiguration?.Bill?.id}/Draft`,
                )
              }
              onKeyDown={null}
            >
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Cliente: </b>
                <small className="draft-bill__detail-text">
                  {failedBillsConfiguration?.Bill?.User?.Person?.Name_Person}
                </small>
              </p>
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Fecha de envío fallido: </b>
                <small className="draft-bill__detail-text">
                  {formatDate(failedBillsConfiguration?.Execution_Time)}
                </small>
              </p>
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Periocidad: </b>
                <small className="draft-bill__detail-text">
                  {
                    BILL_CONFIGURATION_FREQUENCY_TEXT[
                      failedBillsConfiguration?.BillConfiguration?.Frequency
                    ]
                  }
                </small>
              </p>
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Tiempo de ejecución del proceso: </b>
                <small className="draft-bill__detail-text">
                  {failedBillsConfiguration?.Time_Execute_Process}
                </small>
              </p>
              <p className="scheduled-bill__detail-text draft-bill__detail-text--bold">
                <b>Mensaje de error: </b>
                <small className="draft-bill__detail-text">
                  {failedBillsConfiguration?.Error_Message}
                </small>
              </p>
            </div>
          </Card>
        ),
      )}
    </div>
  );
}

ScheduledBill.propTypes = {
  BillConfiguration: PropTypes.object,
  FailedBillsConfiguration: PropTypes.object,
  SuccessBillsConfiguration: PropTypes.object,
};
ScheduledBill.defaultProps = {
  BillConfiguration: {},
  FailedBillsConfiguration: {},
  SuccessBillsConfiguration: {},
};

export default ScheduledBill;
