/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_PRODUCTS_FEATURE_BY_INVENTORY = gql`
  query getByInventory($query: ProductFeatureByInventoryInput) {
    products: featuresByInventory(query: $query) {
      count: Count
      Features {
        id
        Details {
          id
          Value_ProductFeatureDetail
        }
        ProductPrice {
          id
          Price_ProductPrice
          Cabys {
            id
            Code
            Name
          }
          Tariff {
            id
            Name
            Code
            Percent
          }
          Tax {
            id
            Description_Tax
            MH_Code
          }
        }

        Product {
          id
          Name_Product
          Description_Product
          ProductState {
            State
          }
        }
        Files {
          id
          Route_File
        }
      }
    }
  }
`;

export const GET_PRODUCTS_FEATURE_BY_PLACE = gql`
  query getByPlace($id: ID!, $criteria: String) {
    products: findFeaturesByPlace(id: $id, criteria: $criteria) {
      id
      Codes {
        id
        FeatureCode
      }
      Details {
        id
        Value_ProductFeatureDetail
      }
      ProductPrice {
        id
        Price_ProductPrice
        Cabys {
          id
          Code
          Name
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Tax {
          id
          Description_Tax
          MH_Code
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        ProductState {
          State
        }
      }
      Files {
        id
        Route_File
      }
      InventoryDetail {
        UnitOfMeasurement {
          id
          Name_UnitOfMeasurement
          Symbol
        }
      }
    }
  }
`;

export const GET_RECENT_PRODUCTS_FEATURE_BY_USER_AND_MODULE = gql`
  query getRecentFeaturesByUserAndModule(
    $id: ID!
    $origin: String
    $place: ID!
    $limit: Int
    $offset: Int
  ) {
    features: getRecentFeaturesByUserAndModule(
      id: $id
      origin: $origin
      place: $place
      limit: $limit
      offset: $offset
    ) {
      id
      Codes {
        id
        FeatureCode
      }
      Details {
        id
        Value_ProductFeatureDetail
      }
      ProductPrice {
        id
        Price_ProductPrice
        Cabys {
          id
          Code
          Name
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Tax {
          id
          Description_Tax
          MH_Code
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        InventoryDetail {
          id
          UnitOfMeasurement {
            id
            Name_UnitOfMeasurement
            Symbol
          }
        }
        ProductState {
          State
        }
      }
      Files {
        id
        Route_File
        Created_At
        Updated_At
      }
    }
  }
`;

export const GET_PRODUCTS_FEATURE_BY_USER_AND_CRITERIA = gql`
  query getByUserAndCrieteria(
    $id: ID!
    $origin: String
    $place: ID!
    $criteria: String
    $limit: Int
    $offset: Int
  ) {
    features: getFeaturesByUserAndCrieteria(
      id: $id
      origin: $origin
      place: $place
      criteria: $criteria
      limit: $limit
      offset: $offset
    ) {
      id
      Codes {
        id
        FeatureCode
      }
      Details {
        id
        Value_ProductFeatureDetail
      }
      ProductPrice {
        id
        Price_ProductPrice
        Cabys {
          id
          Code
          Name
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Tax {
          id
          Description_Tax
          MH_Code
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        InventoryDetail {
          id
          Inventory {
            id
          }
          UnitOfMeasurement {
            id
            Name_UnitOfMeasurement
            Symbol
          }
        }
        ProductState {
          State
        }
      }
      Files {
        id
        Route_File
      }
    }
  }
`;

export const GET_PRODUCTS_BY_PLACE_AND_CRITERIA = gql`
  query findFeaturesByPlace($id: ID!, $criteria: String) {
    products: findFeaturesByPlace(id: $id, criteria: $criteria) {
      id
      Codes {
        id
        FeatureCode
      }
      Details {
        id
        Value_ProductFeatureDetail
      }
      ProductPrice {
        id
        Price_ProductPrice
        Cabys {
          id
          Code
          Name
          Percent
        }
        Tariff {
          id
          Name
          Code
          Percent
        }
        Tax {
          id
          Description_Tax
          MH_Code
        }
      }
      Product {
        id
        Name_Product
        Description_Product
        ProductState {
          State
        }
        InventoryDetail {
          id
          Inventory {
            id
          }
          UnitOfMeasurement {
            id
            Name_UnitOfMeasurement
            Symbol
          }
        }
      }
      Files {
        id
        Route_File
        Created_At
        Updated_At
      }
    }
  }
`;
