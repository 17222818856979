import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Control, Field, Input, Title, Label } from "rbx";
// GraphQL
import { useQuery } from "@apollo/client";
import { getAllCountries } from "../../../../graphql";
// Components
import SelectPersonType from "../../../PersonTypeSelect";
import SelectNeighborhood from "../../../NeighborhoodSelect";
import SelectDistrict from "../../../DistrictSelect";
import SelectCanton from "../../../CantonSelect";
import SelectProvince from "../../../ProvinceSelect";
import { PhoneInput } from "../../../Common";
// SCSS
import "./ClientEdit.scss";

function ClientEdit({
  clientSelected,
  clientList,
  phoneCodCountry,
  setModalOpen,
  editClient,
}) {
  const [clientState, setClientState] = useState({
    MH_PersonType: clientSelected?.BillingProfile?.MH_PersonType?.id,
    FK_User: clientSelected?.BillingProfile?.User?.id,
    ID_Number: clientSelected?.BillingProfile?.ID_Number,
    Name: clientSelected?.BillingProfile?.Name,
    Email: clientSelected?.BillingProfile?.Email,
    PhoneCodCountry: phoneCodCountry,
    PhoneNumber: clientSelected?.BillingProfile?.PhoneNumber,
    MH_Province:
      clientSelected?.BillingProfile?.MH_Neighborhood?.District?.Canton
        ?.Province?.id,
    MH_Canton:
      clientSelected?.BillingProfile?.MH_Neighborhood?.District?.Canton?.id,
    MH_District: clientSelected?.BillingProfile?.MH_Neighborhood?.District?.id,
    MH_Neighborhood: clientSelected?.BillingProfile?.MH_Neighborhood?.id,
    OtherSigns: clientSelected?.BillingProfile?.OtherSigns,
  });

  const { data: getCountryData } = useQuery(getAllCountries);

  const countries = getCountryData?.countries || [];

  const handleChange = (name, value) => {
    setClientState(prev => ({ ...prev, [name]: value }));
  };

  const handleUpdate = e => {
    e.preventDefault();
    editClient(clientSelected?.BillingProfile?.id, clientState);
  };

  return (
    <div>
      <form className="edit-container" onSubmit={handleUpdate}>
        <Title className="title-center">Editar información de cliente</Title>
        <Field>
          <Control>
            <SelectPersonType
              label="Tipo de identificación:"
              name="MH_PersonType"
              value={clientState?.MH_PersonType}
              onChange={handleChange}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>Identificación: </Label>
            <Input
              required
              name="ID_Number"
              placeholder="Número de identificación"
              type="number"
              value={clientState?.ID_Number}
              onChange={e => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>Nombre: </Label>
            <Input
              required
              name="Name"
              placeholder="Nombre"
              type="text"
              value={clientState?.Name}
              onChange={e => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>Correo electrónico: </Label>
            <Input
              required
              name="Email"
              placeholder="Correo electrónico"
              type="email"
              value={clientState?.Email}
              onChange={e => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <PhoneInput
              code={clientState?.PhoneCodCountry}
              codeKey="PhoneCodCountry"
              countries={countries}
              label="Número telefónico:"
              phone={clientState?.PhoneNumber}
              phoneKey="PhoneNumber"
              onChange={handleChange}
            />
          </Control>
        </Field>
        {clientState?.MH_District && clientState?.MH_Neighborhood && (
          <Field>
            <Control>
              <SelectNeighborhood
                district={clientState?.MH_District}
                label="Barrio:"
                name="MH_Neighborhood"
                value={clientState?.MH_Neighborhood}
                onChange={handleChange}
              />
            </Control>
          </Field>
        )}
        {clientState?.MH_District && clientState?.MH_Canton && (
          <Field>
            <Control>
              <SelectDistrict
                canton={clientState?.MH_Canton}
                label="Distrito:"
                name="MH_District"
                value={clientState?.MH_District}
                onChange={handleChange}
              />
            </Control>
          </Field>
        )}

        {clientState?.MH_Province && clientState?.MH_Canton && (
          <Field>
            <Control>
              <SelectCanton
                label="Cantón:"
                name="MH_Canton"
                province={clientState?.MH_Province}
                value={clientState?.MH_Canton}
                onChange={handleChange}
              />
            </Control>
          </Field>
        )}
        {clientState?.MH_Province && (
          <Field>
            <Control>
              <SelectProvince
                label="Provincia:"
                name="MH_Province"
                value={clientState?.MH_Province}
                onChange={handleChange}
              />
            </Control>
          </Field>
        )}

        <Field>
          <Control>
            <Label>Otras señas: </Label>
            <Input
              required
              name="OtherSigns"
              placeholder="Otras señas:"
              type="text"
              value={clientState?.OtherSigns}
              onChange={e => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <div className="principal-container">
          <Button
            className="cancel-button"
            type="button"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Guardar cambios
          </Button>
        </div>
      </form>
    </div>
  );
}
ClientEdit.propTypes = {
  setModalOpen: PropTypes.func,
  clientSelected: PropTypes.object,
  clientList: PropTypes.array,
  phoneCodCountry: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editClient: PropTypes.func.isRequired,
};

ClientEdit.defaultProps = {
  setModalOpen: () => null,
  clientSelected: "",
  phoneCodCountry: 506,
  clientList: [],
};

export default ClientEdit;
