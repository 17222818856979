import React from "react";
import { Switch, Route } from "react-router-dom";
import SelectCompany from "../CompanySelect";
import PlacesList from "./PlacesList";

const PlacesListRouter = props => (
  <Switch>
    <Route path="/places/new-place/companies">
      <SelectCompany />
    </Route>
    <Route path="/places">
      <PlacesList />
    </Route>
  </Switch>
);

export default PlacesListRouter;
