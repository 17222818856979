/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

// External component
import { components } from "react-select";
import { Control, Label } from "rbx";
// Graphql
import { useApolloClient } from "@apollo/client";
import { GET_ACTIVITIES_BY_CRITERIA } from "../../graphql";
// SCSS
import "./ActivityCodeSelect.scss";

function ActivityCodeSelect({ handleChange, defaultValue }) {
  const client = useApolloClient();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const CustomOption = value => {
    const { data } = value;
    const option = data;
    return (
      <components.Option {...value}>
        {option && (
          <div className="option-container option-item">
            <div className="option-content-left">
              <div className="product-header">
                <p>
                  <b>{option.value}</b>
                </p>
                <p>{option.label}</p>
              </div>
            </div>
          </div>
        )}
      </components.Option>
    );
  };

  const loadOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_ACTIVITIES_BY_CRITERIA,
      variables: {
        criteria: inputValue,
      },
    });

    const activities = data?.activities;
    const result = Array.isArray(activities)
      ? activities.map(item => ({
          value: item.id_MH,
          label: item.Name,
        }))
      : [];
    setDefaultOptions(result);
    return result;
  };

  return (
    <Control expanded>
      <Label>Actividad:</Label>
      <AsyncSelect
        cacheOptions
        components={{ Option: CustomOption }}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}
        loadOptions={loadOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Buscar..."
        styles={{
          menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: provided => ({
            ...provided,
            display: "none",
          }),
          container: provided => ({
            fontSize: 13,
          }),
          singleValue: provided => ({
            ...provided,
            top: "38%",
          }),
          control: provided => ({
            ...provided,
            "minHeight": 30,
            "height": 36,
            "width": "100%",
            "minWidth": 180,
            "borderRadius": "4px",
            "border": "0.1rem solid #01234c",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: "pointer",
            },
          }),
          placeholder: provided => ({ ...provided, top: "38%" }),
          menu: provided => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
            width: "auto",
          }),
          menuList: provided => ({
            ...provided,
            display: "flex",
            flexWrap: "wrap",
            fontSize: 12,
            zIndex: 999,
            width: "auto",
          }),
        }}
        onChange={handleChange}
      />
    </Control>
  );
}

ActivityCodeSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object.isRequired,
};

export default ActivityCodeSelect;
