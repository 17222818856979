/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ROLE_PERMISSION_BY_ROLE = gql`
  query getRolePermissionSubmoduleByRole($FK_Role: ID!) {
    getRolePermissionSubmoduleByRole(FK_Role: $FK_Role) {
      id
      FK_Role
      FK_Submodule
      FK_Permission
    }
  }
`;

export const GET_PERMISSIONS_BY_USER_ID = gql`
  query getPermissionsByUserId($FK_User: ID!) {
    getPermissionsByUserId(FK_User: $FK_User) {
      id
      FK_User
      FK_Role
      FK_GeneralState
      Role {
        id
        Name_Role
      }
      Permissions {
        id
        FK_Submodule
        Submodule {
          id
          Name_Submodule
          Route_Submodule
        }
        FK_Permission
        FK_Role
        ActionName
      }
    }
  }
`;
