import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
// External components
import { Box, Button, Column } from "rbx";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// GraphQL
import { useApolloClient } from "@apollo/client";
import { client_EB_api } from "../../graphql/client";
import {
  GET_MANY_PDFS,
  GET_EB_BY_ID,
  RESEND_EMAIL,
  GET_CREDIT_NOTES,
} from "../../graphql";
// Components
import { Table } from "../../components/DataTable/components";
import { CustomLoading, PersonSection, SendBillScreen } from "../../components";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
// Context
import { useElectronic } from "../../context/ElectronicContext";
import { useModal } from "../../context";
// Enums
import DEFAULT_COLUMNS from "./Enums/Columns";
import { ElectronicBillErrors } from "../../Enums/Errors";
import {
  BILL_TYPES_URL_PARAMS,
  ELECTRONIC_BILLS_DOCUMENT_TYPES,
} from "../../Enums/ElectronicBill";
// Utils
import { downloadBase64File } from "../../utils/helpers";
// SCSS
import "./EBViewPage.scss";
import { normalizeEBillDetails } from "./EBviewHelper";

function EBViewPage() {
  const [electronicBillForReview, setElectronicBillForReview] = useState();
  const { id, viewType, billType } = useParams();
  const { useCoinDetail, LineDetailPlace, resetElectronicBill } =
    useElectronic();
  const { getElectronicBillForReview, formatBillDetailToTable } =
    useElectronicBill();
  const history = useHistory();
  const { toExchangeRate, currentSymbolCoin, resetDefaultCoin } = useCoinDetail;
  const { setModalOpen } = useModal();

  const checkBillType = useCallback(
    (billTypeEnumType: BILL_TYPES_URL_PARAMS) => billType === billTypeEnumType,
    [billType],
  );
  // const isDebitNote = () => checkBillType(BILL_TYPES_URL_PARAMS.DEBIT_NOTE);
  const isCreditNote = () => checkBillType(BILL_TYPES_URL_PARAMS.CREDIT_NOTE);
  const isBuyElectronicBill = () =>
    checkBillType(BILL_TYPES_URL_PARAMS.BUY_BILL);

  const getCreditNote = async bill_id => {
    const resp = await client_EB_api.query({
      query: GET_CREDIT_NOTES,
      variables: {
        whereParams: [["CreditNote_view.id", "=", bill_id]],
      },
    });
    if (!resp?.data?.getCreditNotesNew) {
      return null;
    }
    const temp = {
      ...resp.data.getCreditNotesNew[0],
      details: resp.data.getCreditNotesNew[0].CreditNoteDetails,
    };
    delete temp.CreditNoteDetails;
    return temp;
  };

  const selectGetBillFunction = useCallback(() => {
    if (checkBillType(BILL_TYPES_URL_PARAMS.DEBIT_NOTE)) {
      return async e =>
        normalizeEBillDetails(await getElectronicBillForReview(e));
    }
    if (checkBillType(BILL_TYPES_URL_PARAMS.CREDIT_NOTE)) {
      return getCreditNote;
    }
    if (checkBillType(BILL_TYPES_URL_PARAMS.BUY_BILL)) {
      return async e =>
        normalizeEBillDetails(await getElectronicBillForReview(e));
    }
    return async e =>
      normalizeEBillDetails(await getElectronicBillForReview(e));
  }, [checkBillType, getElectronicBillForReview]);

  const fetchElectronicBillForReview = useCallback(async () => {
    if (!id) return;
    const getEB = selectGetBillFunction();
    const resp = await getEB(id);
    if (!resp) return;
    setElectronicBillForReview(resp);
  }, [id, selectGetBillFunction]);

  useEffect(() => {
    if (electronicBillForReview) return;
    fetchElectronicBillForReview();
  }, [electronicBillForReview, fetchElectronicBillForReview]);
  const client = useApolloClient();

  const getElectronicBillById = async idEB => {
    const electronicBill = await client.query({
      query: GET_EB_BY_ID,
      variables: {
        id: idEB,
      },
      fetchPolicy: "no-cache",
    });
    return electronicBill;
  };

  const handleSendEmails = async receivers => {
    let idElectronicBill = 0;
    const features = [];

    const electronicBill = await getElectronicBillById(id);

    let creditNote = null;
    idElectronicBill = electronicBill?.data?.EBill?.Bill?.id;

    if (
      electronicBill?.data?.EBill?.ElectronicBillDocumentType?.id ===
      `${ELECTRONIC_BILLS_DOCUMENT_TYPES.CREDIT_NOTE}`
    ) {
      creditNote = await getElectronicBillById(
        electronicBill?.data?.EBill?.ReferencedDocument?.id,
      );
      idElectronicBill = creditNote?.data?.EBill?.Bill?.id;
      electronicBill?.data?.EBill?.ElectronicBillDetail.map(detail =>
        creditNote?.data?.EBill?.ElectronicBillDetail.map(
          creditDetail =>
            detail.ProductFeature.id === creditDetail.ProductFeature.id &&
            features.push(detail.ProductFeature.id),
        ),
      );
    }

    await client_EB_api.mutate({
      mutation: RESEND_EMAIL,
      variables: {
        id: idElectronicBill,
        idElectronicBillFeatures: features,
        to: receivers,
      },
    });
    toast.success("Email enviado con éxito!");
  };

  const openSendEmailModal = () => {
    setModalOpen(
      true,
      <SendBillScreen
        receptorRequired
        billingEmail={receptor?.Email}
        handleCloseModal={() => setModalOpen(false)}
        handleSend={handleSendEmails}
        place={LineDetailPlace}
      />,
    );
  };

  const receptor =
    electronicBillForReview?.Bill?.User?.UserBillingProfile ||
    electronicBillForReview?.ReferencedDocument?.Bill?.User?.UserBillingProfile;

  const handleDownload = async () => {
    const { data } = await client_EB_api.query({
      query: GET_MANY_PDFS,
      variables: {
        ids: electronicBillForReview?.id,
        place_id: LineDetailPlace?.id,
      },
    });
    if (data.downloadManyPDF[0].payload) {
      const { payload, params, type } = data.downloadManyPDF[0];
      downloadBase64File(payload, `${params[0]}.${type}`);
      return;
    }
    if (!data.downloadManyPDF[0].error) {
      return;
    }
    const { error } = data.downloadManyPDF[0];
    if (error === ElectronicBillErrors.NOT_FOUND) {
      toast.error("El archivo no se encontró");
      return;
    }
    toast.error("Error desconocido");
  };

  const handleNewElectronicBill = () => {
    resetElectronicBill();
    resetDefaultCoin();
    history.push("/electronic-bills");
  };

  const handleNewBuyElectronicBill = () => {
    resetElectronicBill();
    resetDefaultCoin();
    history.push("/buy-electronic-bills");
  };
  const productsFormattedToTable = electronicBillForReview?.details?.map(
    (productFormatted, index) =>
      formatBillDetailToTable(
        {
          ...productFormatted,
          index: index + 1,
        },
        currentSymbolCoin,
      ),
  );
  const totalAmounts = useMemo(() => {
    const amounts = checkBillType(BILL_TYPES_URL_PARAMS.CREDIT_NOTE)
      ? electronicBillForReview
      : electronicBillForReview?.Bill;

    return {
      ...amounts,
      TotalExempt: amounts?.TotalGoodsExempt + amounts?.TotalServicesExempt,
      TotalExonerated:
        amounts?.TotalGoodsExonerated + amounts?.TotalServicesExonerated,
      TotalTaxed: amounts?.TotalTaxed,
      TotalNetSell: amounts?.SubTotal_Bill - amounts?.Discount,
      TotalSell: amounts?.TotalBill,
    };
  }, [checkBillType, electronicBillForReview]);

  return electronicBillForReview ? (
    <Box className="bill">
      <h1>Resumen de factura electrónica</h1>
      <br />
      <h2>
        <small>Clave: </small>
        {`  ${electronicBillForReview.Key}`}
      </h2>
      <Column.Group>
        <Column>
          <h2>
            <small>Consecutivo: </small>
            {`  ${electronicBillForReview?.InternConsecutive || "--"}`}
          </h2>
          <h2>
            <small>Condición de venta: </small>
            {`${
              electronicBillForReview?.SellConditionMH?.Name_SellCondition ||
              "--"
            } `}
          </h2>
          <h2>
            <small>Tipo de documento: </small>{" "}
            {electronicBillForReview.ElectronicBillDocumentType?.Description ||
              ""}{" "}
          </h2>
          <h2>
            <small>Moneda: </small>
            {electronicBillForReview.Coin.Name_Coin}
          </h2>
        </Column>
        <Column>
          <h2>Plazo crédito: --- </h2>
          <h2>
            <small>
              Fecha{!electronicBillForReview.EmitedDay && " (Tentativa)"}:{" "}
            </small>
            {electronicBillForReview.EmitedDay
              ? new Date(electronicBillForReview.EmitedDay).toLocaleDateString()
              : new Date().toLocaleDateString().concat(" Verificando...")}
          </h2>
          <h2>
            <small>Medio de pago: </small>{" "}
            {` ${electronicBillForReview.PaymentMethodMH?.Name_PaymentMethod}`}
          </h2>
          <h2>
            <small>Tipo de cambio: </small>
            {(1 / electronicBillForReview.Coin.Buy_Coin).toFixed(2)}
          </h2>
        </Column>
      </Column.Group>
      <h1 className="bill__sub-header">Datos emisor</h1>
      <PersonSection
        person={electronicBillForReview?.Place.Company.User.UserBillingProfile}
      />
      {receptor && (
        <React.Fragment>
          <h1 className="bill__sub-header">Datos Receptor</h1>
          <PersonSection person={receptor} />{" "}
        </React.Fragment>
      )}
      <h1 className="bill__sub-header">Detalles</h1>

      <Table columns={DEFAULT_COLUMNS} data={productsFormattedToTable} />
      <br />
      <h1 className="bill__sub-header">Totales</h1>
      <Column.Group>
        <Column>
          <h2>
            <small>Descuento total: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.Discount || 0).toFixed(2)}
          </h2>
          <h2>
            <small>Total exento: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalExempt || 0).toFixed(2)}
          </h2>
          <h2>
            <small>Total exonerado: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalExonerated || 0).toFixed(2)}
          </h2>
          <h2>
            <small>Total exento en productos: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalGoodsExempt || 0).toFixed(2)}
          </h2>
          <h2>
            <small>Total exonerado en productos: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalGoodsExonerated || 0).toFixed(
                2,
              )}
          </h2>
          <h2>
            <small>Total de productos con impuesto: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalGoodsTaxed || 0).toFixed(2)}
          </h2>
        </Column>

        <Column>
          <h2>
            <small>Total de la venta: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalSell || 0).toFixed(2)}
          </h2>
          <h2>
            <small>Total de servicios exempto: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalServicesExempt || 0).toFixed(2)}
          </h2>
          <h2>
            <small>Total de servicio exonerado: </small>
            {currentSymbolCoin +
              toExchangeRate(
                totalAmounts?.TotalServicesExonerated || 0,
              ).toFixed(2)}
          </h2>
          <h2>
            <small>Total impuestos de servicio: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalServicesTaxed || 0).toFixed(2)}
          </h2>
          <h2>
            <small>Total de impuestos : </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalTaxed || 0).toFixed(2)}
          </h2>
          <h2>
            <small>Total de venta neta: </small>
            {currentSymbolCoin +
              toExchangeRate(totalAmounts?.TotalNetSell || 0).toFixed(2)}
          </h2>
        </Column>
      </Column.Group>
      <div className="bill__button-container">
        <h1>
          <small> Total de la factura: </small>
          {currentSymbolCoin +
            toExchangeRate(totalAmounts?.TotalBill).toFixed(2)}
        </h1>

        <Button
          className="bill__print-button"
          color="secondary"
          onClick={() => handleDownload(electronicBillForReview)}
        >
          Descargar factura
        </Button>
        {isCreditNote() && (
          <Button
            className="bill__print-button"
            color="secondary"
            onClick={() => history.push(`/electronic-bills/${id}/DebitNote`)}
          >
            <h3> &nbsp; Crear nota de débito</h3>
          </Button>
        )}
        {viewType === "created" && isBuyElectronicBill() && (
          <Button
            className="bill__print-button"
            color="secondary"
            onClick={() => handleNewBuyElectronicBill()}
          >
            Realizar otra factura
          </Button>
        )}
        {viewType === "created" && !isCreditNote() && !isBuyElectronicBill() && (
          <Button
            className="bill__print-button"
            color="secondary"
            onClick={() => handleNewElectronicBill()}
          >
            Realizar otra factura
          </Button>
        )}
        {id && viewType === "view" && (
          <Button
            className="bill__print-button"
            color="primary"
            onClick={() =>
              openSendEmailModal(
                id,
                setModalOpen,
                receptor?.Email,
                LineDetailPlace,
                getElectronicBillById,
              )
            }
          >
            <FontAwesomeIcon icon="envelope" />
            <h3> &nbsp; Enviar correo</h3>
          </Button>
        )}
      </div>
    </Box>
  ) : (
    <CustomLoading
      centered
      vCentered
      errorTexts={["Lo sentimos", "Ha ocurrido un error validando los datos"]}
      loadingState={!electronicBillForReview ? "loading" : "warning"}
      loadingTexts={["Recuperando información", "Por favor, espera..."]}
      warningTexts={["Algo inesperado ha ocurrido"]}
    />
  );
}

EBViewPage.propTypes = {};

EBViewPage.defaultProps = {};
export default EBViewPage;
