import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// External components
import { Card, Image, Title, Button } from "rbx";
import { toast } from "react-toastify";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_COMPANIES } from "../../graphql";
// Context
import { useAuth } from "../../context";
// Components
import PlacesUpdate from "../PlacesUpdate";
import PlacesRegister from "../PlacesRegister/PlacesRegister";
import CompanyRegister from "../CompanyRegister/CompanyRegister";
// Utils
import { validateRoute } from "../../utils/helpers";
// Hooks
import usePlaces from "../../hooks/usePlaces";
// SCSS
import "../Inventories/Inventories.scss";
import "./PlacesList.scss";

function PlacesList() {
  const [placeSelected, setPlaceSelected] = useState();
  const [companySelected, setCompanySelected] = useState();
  const [company, setCompany] = useState();
  const [addNewPlace, setAddNewPlace] = useState(false);
  const { state, permissions } = useAuth();
  const user = state.user.TavuelUser;
  const { GetPLacesByUserIdAndModuleId } = usePlaces();
  const history = useHistory();

  const { isSuperAdmin, haveActionPermission, noAccessMessage } = permissions;

  const canRead = haveActionPermission("Read", "/places");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Lugares"));
    history.push(`/`);
  }

  const [loading, dataPlaces, error, refetch] = GetPLacesByUserIdAndModuleId(
    user?.id,
  );

  const { data: getCompanyData, refetch: refetchCompany } = useQuery(
    GET_ALL_COMPANIES,
    {
      variables: {
        id: user?.id,
      },
    },
  );

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  useEffect(() => {
    setCompany(getCompanyData?.companies[0]);
    if (getCompanyData?.companies.length === 0) {
      setCompany(dataPlaces?.places[0].Company);
    }
  }, [dataPlaces?.places, getCompanyData, getCompanyData?.companies]);

  const handleSelectPlace = place => {
    if (!haveActionPermission("Edit", "/places")) return;
    setPlaceSelected(place);
  };
  const handleSelectCompany = comp => {
    if (!isSuperAdmin) return;
    setCompanySelected(comp);
  };

  const handleAddPlace = () => {
    setAddNewPlace(true);
  };

  return (
    <div>
      {!placeSelected && !companySelected && !addNewPlace && (
        <div>
          <div>
            <Title className="title-center"> Seleccione un lugar</Title>
          </div>
          {haveActionPermission("Create", "/places") && (
            <Button
              className="title-center mt-1"
              color="primary"
              onClick={handleAddPlace}
            >
              Registrar Nuevo Lugar
            </Button>
          )}
          <div className="container-company">
            <div className="grid">
              {Array.isArray(dataPlaces?.places) &&
                dataPlaces?.places.length > 0 &&
                dataPlaces?.places?.map(placeItem => (
                  <div
                    key={placeItem.id}
                    className="hover-table-options principal-container"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleSelectPlace(placeItem)}
                    onKeyDown={() => null}
                  >
                    <Card key={placeItem.id} className=" card-content">
                      <div className="title-product">
                        <h2>{placeItem.Name_Place}</h2>
                      </div>

                      <div className="flex">
                        <div className="content-right">
                          <div className="product-img ">
                            <Image.Container size="32px sq.">
                              <Image src={validateRoute(placeItem)} />
                            </Image.Container>
                          </div>
                        </div>

                        <div className="flex middle ">
                          <p className="detail-product ellipsis three-lines">
                            {placeItem.Description_Place}{" "}
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
            </div>
            <div className="company">
              <div>
                <Title className="title-center" style={{ color: "white" }}>
                  {" "}
                  Compañía
                </Title>
              </div>
              <div
                key={company?.id}
                className="hover-table-options principal-container"
                role="button"
                tabIndex={0}
                onKeyDown={() => null}
              >
                <Card
                  key={company?.id}
                  className=" card-content"
                  onClick={() => handleSelectCompany(company)}
                >
                  <div className="title-product">
                    <h2>{company?.Name_Company}</h2>
                  </div>

                  <div className="flex">
                    <div className="content-right">
                      <div className="product-img ">
                        <Image.Container size="32px sq.">
                          <Image src={validateRoute(company)} />
                        </Image.Container>
                      </div>
                    </div>

                    <div className="flex middle ">
                      <p className="detail-product ellipsis three-lines">
                        {company?.Description_Company}{" "}
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      )}
      {placeSelected && (
        <PlacesUpdate
          place={placeSelected}
          refetch={refetch}
          setPlaceSelected={setPlaceSelected}
        />
      )}
      {companySelected && (
        <CompanyRegister
          company={company}
          refetchCompany={refetchCompany}
          setCompanySelected={setCompanySelected}
        />
      )}
      {addNewPlace && (
        <PlacesRegister
          companyId={company?.id}
          companyName={company?.Name_Company}
          refetch={refetch}
          setAddNewPlace={setAddNewPlace}
        />
      )}
    </div>
  );
}

PlacesList.propTypes = {};

PlacesList.defaultProps = {};

export default PlacesList;
