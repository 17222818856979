import React, { useEffect } from "react";
import { Title } from "rbx";
import { useHistory } from "react-router-dom";
import { useElectronic } from "../../../context/ElectronicContext";
import LineDetail from "./LineDetail";

const Detail = () => {
  const { lineDetail, setLineDetail, LineDetailPlace } = useElectronic();
  const history = useHistory();
  useEffect(() => {
    if (!LineDetailPlace?.id || LineDetailPlace.id === 0) {
      history.push("/electronic-bills");
    }
  });

  return (
    <div>
      <div>
        <Title className="title-center">Detalle de productos</Title>
        <div className="bottom-content">
          <LineDetail
            LineDetailPlace={LineDetailPlace}
            goBack={history.goBack}
            lineDetail={lineDetail}
            setLineDetail={setLineDetail}
          />
          {}
        </div>
      </div>
    </div>
  );
};

Detail.propTypes = {};

export default Detail;
