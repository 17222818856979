import React from "react";
import PropTypes from "prop-types";
import "firebase/storage";
// Context
import { useAuth } from "../../../../context";
// Components
import RegisterReceiver from "../../../RegisterReceiver";

function ProfileBill({ initialReceiver, setBillingProfile, setModalOpen }) {
  // states
  const { state: authState } = useAuth();

  return (
    <div>
      <RegisterReceiver
        FK_User={authState?.user?.TavuelUser?.id}
        handleChangeAddress={() => null}
        initialReceiver={initialReceiver}
        setBillingProfile={setBillingProfile}
        setModalOpen={setModalOpen}
        title="Perfil de facturación"
      />
    </div>
  );
}

ProfileBill.propTypes = {
  initialReceiver: PropTypes.any,
  setModalOpen: PropTypes.func,
  setBillingProfile: PropTypes.func.isRequired,
};
ProfileBill.defaultProps = {
  initialReceiver: null,
  setModalOpen: () => null,
};

export default ProfileBill;
