import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
// External components
import { Container, Tab, Title } from "rbx";
// components
import Inventories from "../../components/Inventories";
import Users from "../../components/Security/Users/Users";
import Roles from "../../components/Security/Roles/Roles";
import ResetPassword from "../../components/ResetPassword";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./Security.scss";
import "../../styles/index.scss";

function SecurityPage() {
  const [toggleState, setToggleState] = useState(1);

  const { LineDetailPlace } = useElectronic();
  const location = useLocation();

  const isSecurityResetUrl = window.location?.pathname.includes(
    "/security/resetPassword/",
  );

  useEffect(() => {
    const routes = [
      { route: "/security/roles", value: 1 },
      { route: "/security/users", value: 2 },
    ];
    setToggleState(
      routes.find(index => index.route === location.pathname)?.value || 1,
    );
  }, [location.pathname]);

  return (
    <div>
      {LineDetailPlace.id === 0 && !isSecurityResetUrl ? (
        <Inventories />
      ) : (
        <form className="profile-page-container">
          <div>
            <Title className="profile-page-title">Seguridad</Title>
            <div className="mb-5">
              <Tab.Group>
                <Tab
                  as={Link}
                  className={toggleState === 1 ? "active" : "tabs"}
                  to="/security/roles"
                >
                  Roles
                </Tab>
                <Tab
                  as={Link}
                  className={toggleState === 2 ? "active" : "tabs"}
                  to="/security/users"
                >
                  Usuarios
                </Tab>
              </Tab.Group>
            </div>
            <Container fluid>
              <Switch>
                <Route path="/security/roles">
                  <Roles />
                </Route>
                <Route path="/security/users">
                  <Users />
                </Route>
                <Route path="/security/resetPassword/:token">
                  <ResetPassword />
                </Route>
                <Route path="/security">
                  <Roles />
                </Route>
              </Switch>
            </Container>
          </div>
        </form>
      )}
    </div>
  );
}

SecurityPage.propTypes = {};

export default SecurityPage;
