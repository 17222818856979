import React from "react";
import { Title, Label, Card, Icon, Button } from "rbx";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CREATE_NEW_CLIENT,
  DELETE_BILLING_CLIENT,
  GET_ALL_CLIENTS_BY_GROUP,
  GET_ALL_GROUPS_BY_PLACE,
} from "../../../../graphql";
import SelectProfile from "../SelectProfile";
import { customToast as toast } from "../../../../utils";
import { useAuth } from "../../../../context";
import ClientCard from "./ClientCard";
import { useElectronic } from "../../../../context/ElectronicContext";
import "./ClientReceiver.scss";

const ClientReceiver = () => {
  const { state: authState } = useAuth();
  const { LineDetailPlace, clientGroup, setClientGroup } = useElectronic();
  const [registerClient] = useMutation(CREATE_NEW_CLIENT);
  const [deleteBillingClient] = useMutation(DELETE_BILLING_CLIENT);

  const { data: dataClientsGroups } = useQuery(GET_ALL_GROUPS_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  const [getClientsByIdGroup, { data: clientsByIdGroup, loading, error }] =
    useLazyQuery(GET_ALL_CLIENTS_BY_GROUP);

  const getClients = async id => {
    getClientsByIdGroup({
      variables: {
        FK_ClientGroup: id,
      },
    });
  };

  const handleSaveNewClient = async idUser => {
    try {
      const result = await registerClient({
        variables: {
          data: {
            Created_By: authState.user.TavuelUser.id,
            FK_User: idUser,
            FK_Company: LineDetailPlace.Company.id,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_CLIENTS_BY_GROUP,
            variables: {
              FK_ClientGroup: clientGroup?.id,
            },
          },
        ],
      });
      if (result) {
        toast.success("¡Cliente agregado al grupo con éxito!");
      }
    } catch (err) {
      toast.error("Error al agregar el cliente");
    }
  };

  const deleteFavoriteClient = async idUser => {
    try {
      const result = await deleteBillingClient({
        variables: {
          FK_User: idUser,
          FK_Company: LineDetailPlace?.Company?.id,
        },
        refetchQueries: [
          {
            query: GET_ALL_CLIENTS_BY_GROUP,
            variables: {
              FK_ClientGroup: clientGroup?.id,
            },
          },
        ],
      });
      if (result) {
        toast.success("¡Cliente favorito eliminado!");
      }
    } catch (err) {
      toast.error("Error al eliminar el cliente");
    }
  };

  const handleSelectGroup = groupItem => {
    setClientGroup(groupItem);
    getClients(groupItem?.id);
  };

  return (
    <div>
      <Title className="title-center">Cliente</Title>
      <SelectProfile
        clientList={clientsByIdGroup?.clients}
        deleteFavoriteClient={deleteFavoriteClient}
        handleSaveNewClient={handleSaveNewClient}
      />
      {clientGroup.id !== 0 && clientsByIdGroup?.clients ? (
        <div className="container-info-group">
          <Label className="title-center">
            Grupo {clientGroup.Name_ClientGroup}
          </Label>
          <Button
            className="prev"
            color="link"
            role="button"
            size="small"
            onClick={() => setClientGroup({ id: 0 })}
          >
            <Icon>
              <FontAwesomeIcon icon="arrow-left" />
            </Icon>
          </Button>
          <div className="list-container">
            {clientsByIdGroup?.clients?.length !== 0 && !loading && !error ? (
              <ClientCard clientList={clientsByIdGroup?.clients} />
            ) : (
              <div className="container-info-group">
                <Label className="title-center">
                  Grupo {clientGroup.Name_ClientGroup} no tiene clientes
                  registrados.
                </Label>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="container-info-group">
          <div>
            <Title className="title-center">Grupos</Title>
          </div>
          <div>
            {dataClientsGroups?.groups?.length > 0 ? (
              <div className="grid">
                {Array.isArray(dataClientsGroups?.groups) &&
                  dataClientsGroups?.groups?.length > 0 &&
                  dataClientsGroups?.groups?.map(groupItem => (
                    <div
                      key={groupItem.id}
                      className="hover-table-options principal-container"
                    >
                      <Card
                        key={groupItem.id}
                        className="card-content-group groups-content"
                      >
                        <div
                          className="group_information"
                          role="button"
                          tabIndex={0}
                          onClick={() => handleSelectGroup(groupItem)}
                          onKeyDown={() => null}
                        >
                          <div className="title-product">
                            <h2>{groupItem.Name_ClientGroup}</h2>
                          </div>
                          <div className="flex">
                            <div className="content-right">
                              <div className="product-img ">
                                <Icon size="large">
                                  <FontAwesomeIcon
                                    className="hover-table-options"
                                    icon="users"
                                    size="3x"
                                  />
                                </Icon>
                              </div>
                            </div>
                            <div className="flex middle ">
                              <p className="detail-product ellipsis three-lines">
                                {groupItem.Description_ClientGroup}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="container-info-group">
                <Title className="title-center">
                  Aún no hay grupos registrados para{" "}
                  {LineDetailPlace.Name_Place}.
                </Title>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ClientReceiver.propTypes = {};

export default ClientReceiver;
