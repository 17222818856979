import React, { useState, useEffect, useCallback } from "react";
// External components
import { Button, Field, Control, Input, Title, Label, Icon, Image } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import {
  CountrySelect,
  ChangePassword,
  Cropper,
  MHData,
  RegisterReceiver,
  CoinSelect,
} from "../../components";
// Context
import { useAuth, useModal } from "../../context";
// Utils
import { combine, customToast as toast, validateEmail } from "../../utils";
import { CONTACT_TYPE } from "../../utils/contact";
// SCSS
import "./ProfilePage.scss";
import { client_EB_api } from "../../graphql/client";
import { DEFAULT_BILLING_PROFILE } from "../../graphql";

const INITIAL_STATE = {
  user: {
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: "",
    FK_Country: "",
    Phone_Number: "",
    Email: "",
  },
  BillingProfile: null,
};

const ProfilePage = () => {
  const {
    state: authState,
    handleUpdateUser,
    handleUpdatePicture,
    handleUpdateCoin,
  } = useAuth();
  const [state, setState] = useState(INITIAL_STATE);
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const { setModalOpen } = useModal();
  const [stateChangePassword, setStateChangePassword] = useState(false);
  const [defaultCoin, setDefaultCoin] = useState(
    authState.user.TavuelUser?.DefaultCoin?.id || 1,
  );

  if (authState.user.signInProvider !== "password" && !stateChangePassword) {
    setStateChangePassword(true);
  }

  const updateCoin = useCallback(async () => {
    try {
      await handleUpdateCoin(defaultCoin);
    } catch (err) {
      toast.error("Error al actualizar la moneda");
    }
  }, [handleUpdateCoin, defaultCoin]);

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    if (state.user.Email === "") {
      toast.error("El Correo Electrónico es obligatorio");
      return;
    }
    if (!validateEmail(state.user.Email)) {
      toast.error("El correo electrónico no tiene la estructura correcta.");
      return;
    }
    if (state.user.Phone_Number.length < 8) {
      toast.error("El número de teléfono debe contener como mínimo 8 dígitos.");
      return;
    }
    handleUpdateUser(state.user);
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const hadleChangePassword = e => {
    e.preventDefault();
    setModalOpen(true, <ChangePassword onClose={() => setModalOpen(false)} />);
  };

  const handleChangeCoin = (name, value) => {
    if (name === "FK_Coin") {
      setDefaultCoin(value);
    }
  };
  const handleSelectClick = e => {
    e.preventDefault();
    document.querySelector("#fileSelectorImg").click();
  };

  const handleUpdateImage = useCallback(
    async (url, firebaseId) => {
      try {
        setModalOpen(false);
        await handleUpdatePicture(url, firebaseId);
        toast.success("¡Imagen actualizada con éxito!");
      } catch (err) {
        toast.error("Error al registrar la imagen");
      }
    },
    [handleUpdatePicture, setModalOpen],
  );

  const handleChange = data => setState(prev => combine(prev, data));

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpen(
        true,
        <Cropper
          setUpImg={setUpImg}
          upImg={upImg}
          onClose={() => setModalOpen(false)}
          onSaveImage={handleUpdateImage}
        />,
      );
      setOpen(true);
    }
  }, [handleUpdateImage, open, setModalOpen, upImg]);
  const setBillingProfile = BillingProfile =>
    setState(prv => ({ ...prv, BillingProfile }));

  const getBilling = async () => {
    const {
      data: { BillingProfile },
    } = await client_EB_api.query({
      query: DEFAULT_BILLING_PROFILE,
    });
    setBillingProfile(BillingProfile);
  };

  useEffect(() => {
    setDefaultCoin(authState.user.TavuelUser?.DefaultCoin?.id);
  }, [authState.user.TavuelUser?.DefaultCoin?.id]);

  useEffect(() => {
    if (authState?.user?.TavuelUser?.Person) {
      const {
        Name_Person = "",
        Lastname_Person = "",
        BirthDate_Person = "",
        Country: { id: FK_Country },
        Email = "",
        Contacts,
      } = authState?.user.TavuelUser.Person;

      handleChange({
        user: {
          Given_Name: Name_Person,
          Family_Name: Lastname_Person,
          BirthDate_Person: new Date(BirthDate_Person || new Date())
            ?.toISOString()
            ?.substring(0, 10),
          FK_Country,
          Phone_Number:
            Contacts.find(
              ({ ContactType: { id } }) => id === CONTACT_TYPE.PHONE,
            )?.Data_Contact || "",
          Email,
        },
      });

      getBilling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.user?.TavuelUser?.id]); // Dependency array must be like this to avoid wrong re-rendering

  return (
    <div>
      <form className="profile-page-container" onSubmit={handleSubmit}>
        <div>
          <Title className="profile-page-title">Perfil de usuario</Title>

          <div className="form-inputs">
            <div className=" title-center">
              <div className="profile-page-preview">
                <input
                  accept="image/*"
                  className="input-file"
                  id="fileSelectorImg"
                  type="file"
                  onChange={hadleOnSelectFile}
                />
                <Image.Container size="64px sq.">
                  <Image src={authState?.user?.photoURL} />
                </Image.Container>
                <Icon className="hover-table-options ">
                  <FontAwesomeIcon
                    icon="edit"
                    size="1x"
                    onClick={handleSelectClick}
                  />
                </Icon>
              </div>
            </div>
            <Field kind="group">
              <Control expanded>
                <Label textColor="grey-dark">Nombre</Label>
                <Input
                  placeholder="Nombre"
                  type="text"
                  value={state.user.Given_Name}
                  onChange={({ target: { value } }) =>
                    handleChange({ user: { Given_Name: value } })
                  }
                />
              </Control>
            </Field>
            <Field kind="group">
              <Control expanded>
                <Label textColor="grey-dark">Apellidos</Label>
                <Input
                  placeholder="Apellidos"
                  type="text"
                  value={state.user.Family_Name}
                  onChange={({ target: { value } }) =>
                    handleChange({ user: { Family_Name: value } })
                  }
                />
              </Control>
            </Field>

            <Field kind="group">
              <Control expanded>
                <Label textColor="grey-dark">Correo electrónico</Label>
                <Input
                  placeholder="Correo electrónico"
                  type="email"
                  value={state.user.Email || ""}
                  onChange={({ target: { value } }) =>
                    handleChange({ user: { Email: value } })
                  }
                />
              </Control>
            </Field>
            <Field kind="group">
              <Control expanded>
                <CountrySelect
                  label="País"
                  textColor="black"
                  value={state.user.FK_Country || 52}
                  onChange={(_, FK_Country) =>
                    handleChange({ user: { FK_Country } })
                  }
                />
              </Control>
            </Field>
            <Field kind="group">
              <Control expanded>
                <Label className="label" textColor="grey-dark">
                  Número de teléfono
                </Label>
                <Input
                  placeholder="Número de teléfono"
                  type="number"
                  value={state.user.Phone_Number || ""}
                  onChange={({ target: { value } }) =>
                    handleChange({ user: { Phone_Number: value } })
                  }
                />
              </Control>
            </Field>
            <Control>
              <Label className="label" textColor="grey-dark">
                Fecha de nacimiento:
              </Label>
              <Input
                type="date"
                value={state.user.BirthDate_Person}
                onChange={({ target: { value } }) =>
                  handleChange({ user: { BirthDate_Person: value } })
                }
              />
            </Control>
            <div className="profile-page-button">
              <Button
                rounded
                color="secondary"
                disabled={stateChangePassword}
                id="changePass"
                onClick={hadleChangePassword}
              >
                Cambiar Contraseña
              </Button>

              <Button rounded color="primary" type="submit">
                Guardar
              </Button>
            </div>
            <Field kind="group">
              <CoinSelect
                label="Moneda:"
                name="FK_Coin"
                value={defaultCoin}
                onChange={handleChangeCoin}
              />
            </Field>
            <div className="profile-page-button">
              <Button
                rounded
                color="secondary"
                id="changePass"
                type="button"
                onClick={updateCoin}
              >
                Actualizar moneda
              </Button>
            </div>
          </div>
        </div>
      </form>
      <RegisterReceiver
        FK_User={authState?.user?.TavuelUser?.id}
        handleChangeAddress={() => null}
        initialReceiver={state?.BillingProfile}
        setBillingProfile={setBillingProfile}
        setModalOpen={() => null}
        title="Perfil de facturación"
      />

      <MHData
        billingProfile={state.BillingProfile}
        setFileEB={FileEB =>
          setState({
            ...state,
            BillingProfile: {
              ...state.BillingProfile,
              MH_User: { ...state.BillingProfile.MH_User, FileEB },
            },
          })
        }
        setMHUser={MH_User =>
          setState({
            ...state,
            BillingProfile: { ...state.BillingProfile, MH_User },
          })
        }
        setModalOpen={setModalOpen}
      />
    </div>
  );
};

ProfilePage.propTypes = {};

export default ProfilePage;
