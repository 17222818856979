import React from "react";
import { useHistory } from "react-router-dom";
// External components
import { Card, Image, Title } from "rbx";
// Context
import { useAuth } from "../../context";
// Utils
import { validateRoute } from "../../utils/helpers";
// Hooks
import usePlaces from "../../hooks/usePlaces";
// SCSS
import "../Inventories/Inventories.scss";

function ClientsMaintenance() {
  const { state } = useAuth();
  const history = useHistory();
  const user = state.user.TavuelUser;

  const { GetPLacesByUserIdAndModuleId } = usePlaces();

  const [loading, dataPlaces, error] = GetPLacesByUserIdAndModuleId(user?.id);

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const handleSelectPlace = place => {
    history.push(
      `/clients-maintenance/clients-list/${place?.id}/${place?.Name_Place}/${place?.Company?.id}`,
    );
  };

  return (
    <div>
      <div>
        <Title className="title-center">Seleccione un lugar</Title>
      </div>

      <div className="grid">
        {Array.isArray(dataPlaces?.places) &&
          dataPlaces?.places.length > 0 &&
          dataPlaces?.places?.map(placeItem => (
            <div
              key={placeItem.id}
              className="hover-table-options principal-container"
              role="button"
              tabIndex={0}
              onClick={() => handleSelectPlace(placeItem)}
              onKeyDown={() => null}
            >
              <Card key={placeItem.id} className=" card-content">
                <div className="title-product">
                  <h2>{placeItem.Name_Place}</h2>
                </div>

                <div className="flex">
                  <div className="content-right">
                    <div className="product-img ">
                      <Image.Container size="32px sq.">
                        <Image src={validateRoute(placeItem)} />
                      </Image.Container>
                    </div>
                  </div>

                  <div className="flex middle ">
                    <p className="detail-product ellipsis three-lines">
                      {placeItem.Description_Place}{" "}
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
}

ClientsMaintenance.propTypes = {};

ClientsMaintenance.defaultProps = {};

export default ClientsMaintenance;
