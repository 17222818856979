import React, { useState } from "react";
// External components
import { Button } from "rbx";
import { toast } from "react-toastify";
// GraphQL
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_EB, GET_ELECTRONICS_BILLS_NEW } from "../../graphql";
// Context
import { useAuth } from "../../context";
// Enums
import { ElectronicBillAcceptanceStates } from "../../Enums/ElectronicBill";
// Components
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import MultiSelect from "../MultiSelect";

import "./DocumentAcceptance.scss";
import { LandingPage } from "../../pages/LandingPage";
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";
import { useElectronic } from "../../context/ElectronicContext";
import ElectronicBillExpenseCard from "../ElectronicBillExpenseCard/ElectronicBillExpenseCard";

function DocumentsAcceptance() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentElectronicBills, setCurrentElectronicBills] = useState([]);
  const [initialElectronicBills, setInitialElectronicBills] = useState([]);
  const [globalMultiselectState, setGlobalMultiselectState] = useState(
    ElectronicBillAcceptanceStates.ACCEPTED,
  );

  const { state: authState } = useAuth();
  const { LineDetailPlace } = useElectronic();

  const [updateElectronicBill] = useMutation(UPDATE_EB);

  const [
    getElectronicBillsByDate,
    {
      data: responseGetElectronicBillsByDate,
      refetch: refetchGetElectronicBillsByDate,
      loading,
    },
  ] = useLazyQuery(GET_ELECTRONICS_BILLS_NEW, {
    onCompleted: response => {
      setInitialElectronicBills(
        response?.getElectronicBillsNew?.ElectronicBills,
      );
      setCurrentElectronicBills(
        response?.getElectronicBillsNew?.ElectronicBills,
      );
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const handleChangeSelect = (name, value, id) =>
    setCurrentElectronicBills(
      responseGetElectronicBillsByDate?.getElectronicBillsNew?.ElectronicBills.map(
        electronicBill =>
          electronicBill.id === id
            ? { ...electronicBill, FK_AcceptanceState: parseInt(value, 10) }
            : electronicBill,
      ),
    );

  const handleSearch = async () => {
    if (selectedDate.getFullYear() > new Date().getFullYear())
      toast.error("El año debe ser igual o anterior al actual en curso");

    if (authState.user.TavuelUser.id && selectedDate) {
      handleGetElectronicBillsByDate();
    }
  };

  const handleSaveEB = async ebAttributes => {
    const { id, FK_AcceptanceState } = currentElectronicBills.find(
      item => item.id === ebAttributes.id,
    );

    const { data } = await updateElectronicBill({
      variables: {
        ebAttributes: {
          Id: parseInt(id, 10),
          FK_AcceptanceState:
            FK_AcceptanceState ?? ElectronicBillAcceptanceStates.ACCEPTED,
        },
      },
    });

    if (data.updateElectronicBill) {
      toast.success("Factura actualizada correctamente");
      refetchGetElectronicBillsByDate();
    } else toast.error("Hubo un error al intentar actualizar la factura");
  };

  const handleSaveAllEBAcceptanceState = async () => {
    const electronicBillsAbleToChange =
      initialElectronicBills.length > 0
        ? initialElectronicBills.filter(
            initialElectronicBill =>
              initialElectronicBill.FK_AcceptanceState ===
                ElectronicBillAcceptanceStates.NO_STATUS ||
              initialElectronicBill.FK_AcceptanceState ===
                ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED,
          )
        : [];

    if (
      electronicBillsAbleToChange.some(
        electronicBill =>
          electronicBill.FK_AcceptanceState ===
          ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED,
      ) &&
      parseInt(globalMultiselectState, 10) ===
        ElectronicBillAcceptanceStates.REJECTED
    ) {
      toast.error("¡No se pueden rechazar facturas parcialmente aceptadas!");
      return;
    }

    const updateElectronicBillsPromises = electronicBillsAbleToChange.map(
      initialElectronicBill =>
        updateElectronicBill({
          variables: {
            ebAttributes: {
              Id: parseInt(initialElectronicBill.id, 10),
              FK_AcceptanceState: globalMultiselectState,
            },
          },
        }),
    );

    const responsePromises = await Promise.all(updateElectronicBillsPromises);

    if (
      responsePromises.every(
        responseItem => responseItem?.data?.updateElectronicBill,
      )
    ) {
      toast.success("Facturas actualizadas correctamente");
      refetchGetElectronicBillsByDate();
    } else toast.error("Hubo un error al intentar actualizar las facturas");
  };

  const handleGetElectronicBillsByDate = async () => {
    if (LineDetailPlace.id === 0) {
      toast.error("Debe seleccionar un lugar antes de buscar facturas.");
      return;
    }

    await getElectronicBillsByDate({
      variables: {
        getElectronicBillsInput: {
          where: {
            equals: {
              FK_OwnerPlace: LineDetailPlace.id,
              BillFlowType: "1",
            },
            inMonth: selectedDate,
          },
        },
      },
    });
  };

  const getMultiselectItemStyles = multiSelectItem =>
    multiSelectItem.initialElectronicBillAcceptanceState ===
      ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED &&
    multiSelectItem.Id === ElectronicBillAcceptanceStates.REJECTED
      ? { display: "none" }
      : { display: "block" };

  const multiSelectData = [
    {
      Id: ElectronicBillAcceptanceStates.ACCEPTED,
      Name: "Aceptado",
      Styles: multiSelectItem => getMultiselectItemStyles(multiSelectItem),
    },
    {
      Id: ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED,
      Name: "Aceptado parcial",
      Styles: multiSelectItem => getMultiselectItemStyles(multiSelectItem),
    },
    {
      Id: ElectronicBillAcceptanceStates.REJECTED,
      Name: "Rechazado",
      Styles: multiSelectItem => getMultiselectItemStyles(multiSelectItem),
    },
  ];

  const currentElectronicBillsSortedAscendant = sortArrayItemsByDate(
    currentElectronicBills,
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "EmitedDay",
    },
  );

  return (
    <React.Fragment>
      <div className="flex document-search__header-container">
        <div className="document-search__header">
          <h2 style={{ marginBottom: "16px" }}>Buscar por mes y año</h2>
          <CustomDatePicker
            className="custom-date-picker--reports"
            dateFormat="MMMM / yyyy"
            selectedDate={selectedDate}
            onChange={date => setSelectedDate(date)}
          />
          <Button
            outlined
            className="btn-animate"
            color="primary"
            style={{ marginTop: 16 }}
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </div>

        <div>
          <h2>Actualizar estado de aceptación de las facturas que no tienen</h2>
          <MultiSelect
            className="multiselect-item"
            data={multiSelectData}
            name="is_accepted"
            value={globalMultiselectState}
            onChange={(name, value) => {
              setGlobalMultiselectState(value);
            }}
          />

          <Button
            outlined
            className="btn-animate"
            color="primary"
            onClick={handleSaveAllEBAcceptanceState}
          >
            Guardar todas las Facturas electrónicas
          </Button>
        </div>
      </div>

      <div className="grid" style={{ padding: "1rem 0" }}>
        {Array.isArray(currentElectronicBillsSortedAscendant) &&
        currentElectronicBillsSortedAscendant.length > 0 ? (
          currentElectronicBillsSortedAscendant?.map(currentElectronicBill => {
            const initialElectronicBill = initialElectronicBills.find(
              initialEB => initialEB.id === currentElectronicBill.id,
            );

            return (
              <React.Fragment>
                <ElectronicBillExpenseCard
                  actions={{
                    handleSaveEB,
                    handleChange: handleChangeSelect,
                  }}
                  currentElectronicBill={currentElectronicBill}
                  initialElectronicBill={initialElectronicBill}
                  multiSelectData={multiSelectData}
                />
              </React.Fragment>
            );
          })
        ) : (
          <h2>¡No se encontraron documentos!</h2>
        )}
        {loading && <LandingPage />}
      </div>
    </React.Fragment>
  );
}

export default DocumentsAcceptance;
