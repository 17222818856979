import React, { useEffect } from "react";
import PropTypes from "prop-types";
// External components
import { Box, Column } from "rbx";
// Component
import PersonSection from "../PersonSection";
import { Table } from "../DataTable/components";
// Enums
import DEFAULT_COLUMNS from "./Enums/Columns";
// Hooks
import useMHConfigData from "../../hooks/useMHConfigData";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import formatCurrency from "../../utils/formatCurrency";
// Initial States
import { billUploadDetailEmptyState } from "../../InitialStates/BillUploadDetail";

function BillUploadDetail({ electronicDocument }) {
  const { data: emiterData, getMHData: getMHEmisorData } = useMHConfigData();
  const { data: receptorData, getMHData: getMHReceptorData } =
    useMHConfigData();
  const {
    useCoinDetail: { getCoinsData },
    getAllPaymentMethods,
    getAllSellCondition,
  } = useElectronic();

  const selectedCoin =
    getCoinsData?.coins?.find(
      X =>
        X?.Code_Coin ===
        electronicDocument?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda
          ._text,
    ) ?? billUploadDetailEmptyState.Coin;

  const sellConditionSelected = getAllSellCondition?.sellConditions?.find(
    sellCondition =>
      sellCondition.id_MH === electronicDocument?.CondicionVenta._text,
  );

  const paymentMethodSelected = getAllPaymentMethods?.paymentMethod?.find(
    paymentMethod =>
      paymentMethod.id_MH === electronicDocument?.MedioPago._text,
  );

  const parsePersonToBillingProfile = (person, ubicationData) => ({
    Name: person?.Nombre?._text,
    ID_Number: person?.Identificacion?.Numero?._text,
    Email: person?.CorreoElectronico?._text || "",
    ComercialName: person?.NombreComercial?._text || "",
    PhoneNumber: `+${person?.Telefono?.CodigoPais._text}-${person?.Telefono?.NumTelefono?._text}`,
    MH_Province: { Name: ubicationData?.getMHPresentationData?.Province?.Name },
    MH_District: { Name: ubicationData?.getMHPresentationData?.District?.Name },
    MH_Canton: { Name: ubicationData?.getMHPresentationData?.Canton?.Name },
    MH_Neighborhood: { Name: "" },
    OtherSigns: person?.Ubicacion?.OtrasSenas?._text || "",
  });

  const getProductsData = DetalleServicio =>
    DetalleServicio?.LineaDetalle?.map(
      (
        {
          NumeroLinea,
          Cantidad,
          UnidadMedida,
          Detalle,
          PrecioUnitario,
          MontoTotal,
          MontoTotalLinea,
        },
        index,
      ) => ({
        Linea: `${NumeroLinea?._text}`,
        Name: `${Detalle?._text}`,
        Quantity: `${Cantidad?._text}`,
        PrecioUnitario: formatCurrency(
          selectedCoin?.Symbol_Coin,
          parseFloat(PrecioUnitario?._text),
        ),
        Monto: formatCurrency(
          selectedCoin?.Symbol_Coin,
          parseFloat(MontoTotalLinea?._text),
        ),
        Descuento: formatCurrency(selectedCoin?.Symbol_Coin, 0),
      }),
    );

  const getBillResume = ResumenFactura => ({
    TotalDiscount: ResumenFactura.TotalDescuentos?._text,
    TotalExempt: ResumenFactura.TotalExento?._text,
    TotalExonerado: ResumenFactura.TotalExonerado?._text,
    TotalGoodsExempt: ResumenFactura.TotalMercanciasExentas?._text,
    TotalGoodsExonerated: ResumenFactura.TotalMercExonerada?._text,
    TotalGoodsTaxed: ResumenFactura.TotalMercanciasGravadas?._text,
    TotalSell: ResumenFactura.TotalVenta?._text,
    TotalServicesExempt: ResumenFactura.TotalServExentos?._text,
    TotalServicesExonerated: ResumenFactura.TotalServExonerado?._text,
    TotalServicesTaxed: ResumenFactura.TotalServGravados?._text,
    TotalTaxed: ResumenFactura.TotalGravado?._text,
    TotalNetSell: ResumenFactura.TotalVentaNeta?._text,
    TotalBill: ResumenFactura.TotalComprobante?._text,
    TotalTaxes: ResumenFactura.TotalImpuesto?._text,
  });

  const bill = getBillResume(electronicDocument.ResumenFactura);

  useEffect(() => {
    if (!electronicDocument) return;
    getMHEmisorData(
      "",
      electronicDocument?.Emisor?.Ubicacion?.Canton._text,
      electronicDocument?.Emisor?.Ubicacion?.Distrito._text,
      electronicDocument?.Emisor?.Ubicacion?.Provincia._text,
    );
    getMHReceptorData(
      "",
      electronicDocument?.Receptor?.Ubicacion?.Canton._text,
      electronicDocument?.Receptor?.Ubicacion?.Distrito._text,
      electronicDocument?.Receptor?.Ubicacion?.Provincia._text,
    );
  }, [getMHEmisorData, electronicDocument, getMHReceptorData]);

  return (
    <Box>
      <h1>Resumen de factura electrónica</h1>
      <br />
      <h2 className="break-text">
        <small>Clave: </small>
        {`  ${electronicDocument.Clave._text}`}
      </h2>
      <Column.Group>
        <Column>
          <h2>Consecutivo: {electronicDocument.NumeroConsecutivo._text}</h2>
          <h2>
            <small>Condición de Venta: </small>
            {`${sellConditionSelected?.Name_SellCondition || "--"} `}
          </h2>
          <h2>
            <small>Tipo de Documento: </small>{" "}
            {billUploadDetailEmptyState.DocumentType}
          </h2>
          <h2>
            <small>Moneda: </small>

            {selectedCoin?.Code_Coin || "no encontrada"}
          </h2>
        </Column>
        <Column>
          <h2>Plazo Crédito: --- </h2>
          <h2>
            <small>Fecha: </small>
            {new Date(
              electronicDocument.FechaEmision._text.toString(),
            ).toLocaleDateString() || "---"}
          </h2>
          <h2>
            <small>Medio de Pago: </small>{" "}
            {` ${paymentMethodSelected?.Name_PaymentMethod || "no encontrada"}`}
          </h2>
          <h2>
            Tipo de cambio:{" "}
            {electronicDocument?.ResumenFactura?.CodigoTipoMoneda?.TipoCambio
              ._text ?? billUploadDetailEmptyState.Exchange}
          </h2>
        </Column>
      </Column.Group>
      <h1 className="bill__sub-header">Datos Emisor</h1>
      <PersonSection
        person={parsePersonToBillingProfile(
          electronicDocument.Emisor,
          emiterData,
        )}
      />

      {electronicDocument.Receptor && (
        <React.Fragment>
          <h1 className="bill__sub-header">Datos Receptor</h1>
          <PersonSection
            person={parsePersonToBillingProfile(
              electronicDocument.Receptor,
              receptorData,
            )}
          />{" "}
        </React.Fragment>
      )}
      <h1 className="bill__sub-header">Detalles</h1>
      <Table
        columns={DEFAULT_COLUMNS}
        data={getProductsData(electronicDocument.DetalleServicio)}
      />
      <br />
      <h1 className="bill__sub-header">Totales</h1>
      <Column.Group>
        <Column>
          <h2>
            <small>Total Descuento: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalDiscount),
            )}
          </h2>
          <h2>
            <small>Total Exento: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalExempt),
            )}
          </h2>
          <h2>
            <small>Total Exoneraado: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalExonerated),
            )}
          </h2>
          <h2>
            <small>Total de Productos Exento: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalGoodsExempt),
            )}
          </h2>
          <h2>
            <small>Total de Productos Exonerado : </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalGoodsExonerated),
            )}
          </h2>
          <h2>
            <small>Total de Productos Gravado: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalGoodsTaxed),
            )}
          </h2>
        </Column>

        <Column>
          <h2>
            <small>Total de la Venta: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalSell),
            )}
          </h2>
          <h2>
            <small>Total de Servicios Exento: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalServicesExempt),
            )}
          </h2>
          <h2>
            <small>Total de Servicio Exonerado: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalServicesExonerated),
            )}
          </h2>
          <h2>
            <small>Total de Servicio Gravado: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalServicesTaxed),
            )}
          </h2>
          <h2>
            <small>Total de Impuestos : </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalTaxes),
            )}
          </h2>
          <h2>
            <small>Total de Venta Neta: </small>
            {formatCurrency(
              selectedCoin?.Symbol_Coin,
              parseFloat(bill.TotalNetSell),
            )}
          </h2>
        </Column>
      </Column.Group>
      <div className="bill__button-container">
        <h1>
          <small> Total de la Factura: </small>
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalBill),
          )}
        </h1>
      </div>
    </Box>
  );
}

BillUploadDetail.propTypes = {
  electronicDocument: PropTypes.object.isRequired,
};
export default BillUploadDetail;
