import React from "react";
import PropTypes from "prop-types";
// External components
import { Icon, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import CardAccount from "../CardAccount";
import AccountManagement from "../AccountManagement";
// SCSS
import "./NewAccount.scss";
import {
  EmailConnectors,
  emailLoginFunction,
} from "../../../../utils/MailConnector";

function NewAccount({ flow, openModal }) {
  const emailType = [...EmailConnectors];

  const handleEmailLogIn = account => {
    const login = emailLoginFunction[account.type];
    window.location.href = login();
  };

  const handleIcons = () => {
    if (!flow) openModal(true, <AccountManagement />);
    if (flow) openModal(false, <NewAccount />);
  };

  return (
    <div>
      <div className="container-button-new-account">
        <div className={!flow ? "hide-icon" : "icon-left"}>
          <Icon>
            <FontAwesomeIcon icon="arrow-left" onClick={handleIcons} />
          </Icon>
        </div>
        <Title className="title-new-account">
          Seleccione el proveedor de email
        </Title>
        <div className={flow ? "hide-icon" : "icon-right"}>
          <Icon>
            <FontAwesomeIcon icon="times" onClick={handleIcons} />
          </Icon>
        </div>
      </div>
      <div>
        {emailType.map(email => (
          <CardAccount
            key={email.email}
            account={email}
            handleChange={handleEmailLogIn}
          />
        ))}
      </div>
    </div>
  );
}

NewAccount.propTypes = {
  flow: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default NewAccount;
