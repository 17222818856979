import React from "react";
import PropTypes from "prop-types";
// External components
import { Card, Container, Control, Field, Icon, Input, Label } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useMutation } from "@apollo/client";
import {
  UPDATE_BILL_PAYMENT_METHOD,
  UPDATE_BILL_SELL_CONDITION,
  UPDATE_TEMP_BILL_FK,
} from "../../graphql";
// Components
import SelectPaymentMethod from "../PaymentComponet/Receiver/PaymentReceiver/SelectPaymentMethod";
import SelectSellCondition from "../PaymentComponet/Receiver/PaymentReceiver/SelectSellCondition";
import SelectCoin from "../PaymentComponet/Receiver/PaymentReceiver/SelectCoin";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";
// SCSS
import "../OptionCard/OptionCard.scss";

function PaymentDataCard({ mainComponent, type }) {
  const [updateBillField] = useMutation(UPDATE_TEMP_BILL_FK);
  const [updateBillPaymentMethod] = useMutation(UPDATE_BILL_PAYMENT_METHOD);
  const [updateBillSellCondition] = useMutation(UPDATE_BILL_SELL_CONDITION);

  const { electronicBill, handleChangeElectronicBill, useCoinDetail } =
    useElectronic();
  const { currentCoin, handleCoinChange } = useCoinDetail;

  const disabled = type === ElectronicBillTypes.CreditNote;

  const updateValues = (name, value) => {
    if (!electronicBill.id) return;
    if (name === "Coin") {
      updateBillField({
        variables: {
          id: electronicBill.id,
          value,
          field: "FK_Coin",
        },
      });
      return;
    }
    if (name === "Payment_Method" || name === "OtherPaymentMethod") {
      handlePaymentChange(name, value);
      return;
    }
    if (
      name === "Sell_Condition" ||
      name === "OtherSellCondition" ||
      name === "CreditPaymentCondition"
    ) {
      handleSellConditionChange(name, value);
    }
  };

  const handlePaymentChange = (name, value) => {
    handleChangeElectronicBill(name, value);
    if (name === "Payment_Method") {
      if (value === "6") {
        updateBillPaymentMethod({
          variables: {
            id: electronicBill.id,
            FK_PaymentMethod: value,
            Payment_Detail: electronicBill.OtherPaymentMethod,
          },
        });
        return;
      }

      updateBillPaymentMethod({
        variables: {
          id: electronicBill.id,
          FK_PaymentMethod: value,
          Payment_Detail: null,
        },
      });
      return;
    }

    updateBillPaymentMethod({
      variables: {
        id: electronicBill.id,
        FK_PaymentMethod: electronicBill.Payment_Method,
        Payment_Detail: electronicBill[name],
      },
    });
  };

  const handleSellConditionChange = (name, value) => {
    handleChangeElectronicBill(name, value);
    if (name === "Sell_Condition") {
      if (value === "2" || value === "10") {
        updateBillSellCondition({
          variables: {
            id: electronicBill.id,
            FK_SellCondition: value,
            SellCondition_Detail:
              value === "2"
                ? electronicBill.CreditPaymentCondition
                : electronicBill.OtherSellCondition,
          },
        });
        return;
      }
      updateBillSellCondition({
        variables: {
          id: electronicBill.id,
          FK_SellCondition: value,
          SellCondition_Detail: null,
        },
      });
    } else {
      updateBillSellCondition({
        variables: {
          id: electronicBill.id,
          FK_SellCondition: electronicBill.Sell_Condition,
          SellCondition_Detail: electronicBill[name],
        },
      });
    }
  };

  return (
    <Container>
      <Card
        className={
          mainComponent
            ? "card-products flex middle principal-content"
            : "card-summary secondary-content payment-container"
        }
      >
        {!mainComponent && (
          <div className="card-secondary-content-title flex">
            <div className="card-total-width">
              <h3>Datos de pago</h3>
            </div>
          </div>
        )}
        {mainComponent && <h3>Datos de pago</h3>}
        <div className="content-select flex ">
          <Icon size="medium">
            <FontAwesomeIcon icon="credit-card" size="lg" />
          </Icon>
          <Field expanded className="format-selects">
            <SelectPaymentMethod
              disabled={disabled}
              name="Payment_Method"
              value={electronicBill.Payment_Method}
              onChange={(name, value) => {
                handleChangeElectronicBill(name, value);
                updateValues(name, value);
              }}
            />
          </Field>
        </div>
        <div className="flex ">
          {electronicBill?.Payment_Method === "6" && (
            <Control className="animate__animated animate__fadeIn format-selects content-input">
              <Label>Especifique el método de pago:</Label>
              <Input
                disabled={disabled}
                name="OtherPaymentMethod"
                type="text"
                value={electronicBill.OtherPaymentMethod}
                onBlur={({ target: { name, value } }) =>
                  updateValues(name, value)
                }
                onChange={e =>
                  handleChangeElectronicBill(e.target.name, e.target.value)
                }
              />
            </Control>
          )}
        </div>
        <div className="content-select flex ">
          <Icon size="medium">
            <FontAwesomeIcon icon="coins" size="lg" />
          </Icon>
          <Field expanded className="format-selects">
            <SelectCoin
              disabled={disabled}
              name="Coin"
              value={currentCoin}
              onChange={(name, value) => {
                handleChangeElectronicBill(name, value);
                updateValues(name, value);
                handleCoinChange(value);
              }}
            />
          </Field>
        </div>
        <div className="content-select flex ">
          <Icon size="medium">
            <FontAwesomeIcon icon={["far", "handshake"]} size="lg" />
          </Icon>
          <Field expanded className="format-selects">
            <SelectSellCondition
              disabled={disabled}
              name="Sell_Condition"
              value={electronicBill.Sell_Condition}
              onChange={(name, value) => {
                handleChangeElectronicBill(name, value);
                updateValues(name, value);
              }}
            />
          </Field>
        </div>
        <div className="flex ">
          {electronicBill?.Sell_Condition === "10" && (
            <Control className="animate__animated animate__fadeIn format-selects content-input">
              <Label>Especifique la condición de venta:</Label>
              <Input
                disabled={disabled}
                name="OtherSellCondition"
                type="text"
                value={electronicBill.OtherSellCondition}
                onBlur={({ target: { name, value } }) =>
                  updateValues(name, value)
                }
                onChange={e =>
                  handleChangeElectronicBill(e.target.name, e.target.value)
                }
              />
            </Control>
          )}
          {electronicBill?.Sell_Condition === "2" && (
            <Control className="animate__animated animate__fadeIn format-selects content-input">
              <Label>Indique la cantidad de días del plazo del crédito:</Label>
              <Input
                disabled={disabled}
                name="CreditPaymentCondition"
                type="number"
                value={electronicBill.CreditPaymentCondition}
                onBlur={({ target: { name, value } }) =>
                  updateValues(name, value)
                }
                onChange={e =>
                  handleChangeElectronicBill(e.target.name, e.target.value)
                }
              />
            </Control>
          )}
        </div>
      </Card>
    </Container>
  );
}

PaymentDataCard.propTypes = {
  mainComponent: PropTypes.bool,
  type: PropTypes.string.isRequired,
};
PaymentDataCard.defaultProps = {
  mainComponent: false,
};
export default PaymentDataCard;
