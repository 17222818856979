export const ElectronicBillTypes = {
  CreditNote: "Nota de crédito",
  DebitNote: "Nota de débito",
  FacturaElectronica: "ElectronicBill",
  FacturaElectronicaDeCompra: "BuyElectronicBill",
};
export const Conf = {
  NC: "NC",
};

export const ElectronicBillAcceptanceStates = {
  ACCEPTED: 1,
  PARTIALLY_ACCEPTED: 2,
  REJECTED: 3,
  NO_STATUS: null,
};
export const TAX_CODES = {
  IVA: "01",
  IMPUESTO_SELECTIVO_CONSUMO: "02",
  IMPUESTO_UNICO_COMBUSTIBLES: "03",
  IMPUESTO_BEBIDAS_ALCOHOLICAS: "04",
  IMPUESTO_BEBIDAS_Y_JABON: "05",
  IMPUESTO_TABACO: "06",
  IVA_CALCULO_ESPECIAL: "07",
  IVA_BIENES_USADOS: "08",
  IMPUESTO_CEMENTO: "12",
  OTROS_IMPUESTOS: "99",
};
export const TARIFF_CODES = {
  EXENT: "01",
  REDUCED_TARIFF_1: "02",
  REDUCED_TARIFF_2: "03",
  REDUCED_TARIFF_4: "04",
  TRANSITORY_0: "05",
  TRANSITORY_4: "06",
  TRANSITORY_8: "07",
  GENERAL_RATE: "08",
};

export const DOCUMENT_SEARCH_MODULES = {
  ELECTRONIC_BILLS: 1,
  BILLS: 2,
  ELECTRONIC_BILLS_EXPENSES: 3,
};

export const ELECTRONIC_BILL_FLOW_TYPES = {
  EXPENSES: 1,
  INCOMES: 2,
};

export const ELECTRONIC_BILLS_DOCUMENT_TYPES = {
  ELECTRONIC_BILL: 1,
  DEBIT_NOTE: 2,
  CREDIT_NOTE: 3,
  ELECTRONIC_TICKET: 4,
  CONFIRMATION_ELECTRONIC_DOCUMENT: 5,
  PARTIAL_CONFIRMATION_ELECTRONIC_DOCUMENT: 6,
  REJECTION_DOCUMENT: 7,
  BUY_ELECTRONIC_BILL: 8,
};
export const ELECTRONIC_BILL_XML_TYPES = {
  FE: "FacturaElectronica",
  TE: "TiqueteElectronico",
  CN: "NotaCreditoElectronica",
  DN: "NotaDebitoElectronica",
  FEC: "FacturaElectronicaCompra",
  FEE: "FacturaElectronicaExportacion",
};

export const BILL_TYPES_URL_PARAMS = {
  CREDIT_NOTE: "CreditNote",
  DEBIT_NOTE: "DebitNote",
  BUY_BILL: "BuyBill",
  DRAFT: "Draft",
  ELECTRONIC_BILL: "ElectronBill",
};
