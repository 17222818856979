import { Card, Select } from "rbx";
import ReactDatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "./ScheduledBillCard.scss";
import PropTypes from "prop-types";
import { useElectronic } from "../../context/ElectronicContext";

function ScheduledBillCard({ id, updateValues }) {
  const { scheduledBill, handleChangeScheduledBill } = useElectronic();

  const optionFrequency = [
    { id: 1, name: "Diario" },
    { id: 2, name: "Semanal" },
    { id: 3, name: "Mensual" },
  ];

  const getNavigatorLanguage = () => {
    const { language } = navigator;
    return language[0].toUpperCase() + language[1];
  };

  return (
    <Card className="card-summary secondary-content scheduled-bill-container">
      <div className="card-secondary-content-title flex">
        <div className="card-total-width">
          <h3>Programar factura</h3>
        </div>
      </div>
      <div className="custom-label">
        <div>
          <h3>Fecha:</h3>
          <ReactDatePicker
            showFullMonthYearPicker
            className="date-picker has-text-centered"
            dateFormat="dd/MM/yyyy"
            locale={getNavigatorLanguage()}
            selected={scheduledBill.date}
            onChange={newDate => {
              const day = newDate.getDate();
              const month = newDate.getMonth() + 1;
              const year = newDate.getFullYear();
              const hours = newDate.getHours();
              const minutes = newDate.getMinutes();
              handleChangeScheduledBill({
                date: newDate,
                Year: year,
                Day: day,
                Month: month,
              });
              updateValues(
                "date",
                `${year}-${month}-${day} ${hours}:${minutes}:00`,
              );
              updateValues("Day", day);
              updateValues("Month", month);
              updateValues("Year", year);
            }}
          />
        </div>
        <div className="custom-border">
          <h3>Hora:</h3>
          <TimePicker
            className="time-picker has-text-centered"
            clearIcon={null}
            clockIcon={null}
            format="h : mm a"
            hourAriaLabel="Hour"
            name="Hour"
            openClockOnFocus={false}
            value={scheduledBill.Hour}
            onChange={time => {
              const partsHour = time.split(":");
              handleChangeScheduledBill({
                Hour: time,
                Hours: partsHour[0],
                Minute: partsHour[1],
              });
              updateValues("Hour", partsHour[0]);
              updateValues("Minute", partsHour[1]);
            }}
          />
        </div>
        <div>
          <h3>Frecuencia:</h3>
          <Select.Container fullwidth>
            <Select
              name="Frequency"
              style={{
                width: "200px",
                height: "40px",
                border: "solid 1px grey",
                borderRadius: "0.6rem",
              }}
              value={scheduledBill.Frequency}
              onChange={e => {
                handleChangeScheduledBill({ [e.target.name]: e.target.value });
                updateValues("Frequency", e.target.value);
              }}
            >
              {optionFrequency?.map(option => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Select.Container>
        </div>
      </div>
    </Card>
  );
}

ScheduledBillCard.propTypes = {
  id: PropTypes.string,
  updateValues: PropTypes.func.isRequired,
};

ScheduledBillCard.defaultProps = {
  id: null,
};

export default ScheduledBillCard;
