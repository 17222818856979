import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { MHUser, P12 as P12Upload } from "./components";

function MHData({ setModalOpen, billingProfile, setFileEB, setMHUser }) {
  const openP12Settings = e => {
    e.preventDefault();
    setModalOpen(
      true,
      <P12Upload
        MH_User={billingProfile?.MH_User}
        onChange={setFileEB}
        onClose={() => setModalOpen(false)}
      />,
    );
  };

  const openMHUser = e => {
    e.preventDefault();
    setModalOpen(
      true,
      <MHUser
        billingProfile={billingProfile}
        onChange={setMHUser}
        onClose={() => setModalOpen(false)}
      />,
    );
  };

  return (
    billingProfile && (
      <form>
        <div>
          <Title className="profile-page-title">
            P12 y Credenciales Ministerio Hacienda
          </Title>
          <div className="form-inputs">
            <div className="profile-page-button">
              {billingProfile && (
                <Button
                  rounded
                  color="secondary"
                  id="p12-button"
                  onClick={openMHUser}
                >
                  Datos de hacienda
                </Button>
              )}

              {billingProfile?.MH_User && (
                <Button
                  rounded
                  className="m-0"
                  color="secondary"
                  id="p12-button"
                  onClick={openP12Settings}
                >
                  Subir P12
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    )
  );
}

MHData.propTypes = {
  setModalOpen: PropTypes.func,
  setFileEB: PropTypes.func,
  setMHUser: PropTypes.func,
  billingProfile: PropTypes.any,
};

MHData.defaultProps = {
  setModalOpen: state => null,
  setFileEB: state => null,
  setMHUser: state => null,
  billingProfile: null,
};

export default MHData;
