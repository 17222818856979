import React from "react";
// External components
import { Title } from "rbx";
// Components
import { ClientsGroupsXPlace } from "../../components";

const ClientsPage = () => (
  <div className="report-items">
    <Title className="text-center">Clientes</Title>
    <React.Fragment>
      <ClientsGroupsXPlace />
    </React.Fragment>
  </div>
);
export default ClientsPage;
