import React from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control, Label } from "rbx";
// Graphql
import { useQuery } from "@apollo/client";
import { getAllCountries } from "../../graphql";
// SCSS
import "./CountrySelect.scss";

function CountrySelect({
  value,
  name,
  onChange,
  label,
  disabled,
  className,
  textColor,
}) {
  const { data: getCountryData } = useQuery(getAllCountries);

  return (
    <Control className={className}>
      <Label>{label}</Label>
      <Select.Container fullwidth>
        <Select
          className="selector"
          disabled={disabled}
          name={name}
          textColor={textColor}
          value={value}
          onChange={e => onChange(e.target.name, e.target.value)}
        >
          {getCountryData?.countries?.map(country => (
            <Select.Option
              key={country.id}
              textColor={textColor}
              value={country.id}
            >
              +{country.PhoneCode_Country} ({country.Name_Country})
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
}

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  textColor: PropTypes.string,
};

CountrySelect.defaultProps = {
  name: "",
  disabled: false,
  className: "",
  textColor: "",
};

export default CountrySelect;
