// export { default } from './BuyElectronicBill';

import React from "react";
import { Switch, Route } from "react-router-dom";
import Inventories from "../Inventories";
import ClientReceiver from "../PaymentComponet/Receiver/ClientReceiver";
import BuyElectronicBill from "./BuyElectronicBill";

const BuyElectronicBillRoute = () => (
  <Switch>
    <Route path="/buy-electronic-bills/store">
      <Inventories />
    </Route>
    <Route path="/buy-electronic-bills/client">
      <ClientReceiver />
    </Route>
    <Route path="/buy-electronic-bills">
      <BuyElectronicBill />
    </Route>
  </Switch>
);

export default BuyElectronicBillRoute;
