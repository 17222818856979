/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ELECTRONICS_BILLS = gql`
  query getElectronicBills(
    $FK_User: ID!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    getElectronicBillsUser: getElectronicBills(
      FK_User: $FK_User
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      Key
      InternConsecutive
      Coin {
        id
        Name_Coin
        Symbol_Coin
        Buy_Coin
      }
      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id

            Person {
              id
              DocumentId_Person
              Name_Person
              Lastname_Person
            }
          }
        }
      }
      Bill {
        id
        Delivery {
          User {
            id
            Person {
              id
              Name_Person
              Lastname_Person
            }
            Password_User
            NickName_User
          }
        }
        Details_Bill
        SubTotal_Bill
        Total_Bill
        Distance_Bill
        TotalDelivery
        DeliveryTax
        Services
        Discount
        Tax
      }
      User {
        id
        Person {
          id
          Name_Person
        }
      }
      Exchange
      TotalBill
      TotalDiscount
      TotalExempt
      TotalExonerated
      TotalTaxed
      TotalTaxes
      TotalGoodsExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalServicesExonerated
      TotalServicesExempt
      TotalServicesTaxed
      TotalSell
      TotalNetSell
      ElectronicBillDetail {
        id
        Quantity
        ProductFeature {
          id
          Codes {
            id
            FeatureCode
          }

          ProductPrice {
            id
            Price_ProductPrice
            Tariff {
              Name
              Code
              Percent
            }
            Cabys {
              id
              Name
              Code
              Percent
            }
          }
          Product {
            id
            Name_Product

            InventoryDetail {
              id
              UnitOfMeasurement {
                id
                Name_UnitOfMeasurement
                Symbol
              }
            }
          }
        }
      }
    }
  }
`;
export const ELECTRONIC_BILLS_EXIST = gql`
  query electronicBillExist($electronicBills: [ElectronicBillContInput]) {
    electronicBillExist(electronicBills: $electronicBills)
  }
`;

export const GET_ELECTRONICS_BILLS_BY_DATE_AND_ACCEPTED = gql`
  query getEExpenseByMonthAndYearAndAccepted($date: DateTime, $place_id: ID) {
    expenses: getEExpenseByMonthAndYearAndAccepted(
      date: $date
      place_id: $place_id
    ) {
      id
      Key
      AcceptanceDate
      ElectronicBillDocumentType {
        Description
        Value
        id
      }
      AcceptanceDocument {
        id
      }
      PaymentMethod
      FK_User
      BillFlowType
      Created_At
      EmitedDay
      InternConsecutive
      FK_AcceptanceState
      Coin {
        id
        Name_Coin
        Symbol_Coin
        Buy_Coin
      }
      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id

            Person {
              id
              DocumentId_Person
              Name_Person
              Lastname_Person
            }
          }
        }
      }
      Bill {
        id
        Delivery {
          User {
            id
            Person {
              id
              Name_Person
              Lastname_Person
            }
            Password_User
            NickName_User
          }
        }
        Details_Bill
        SubTotal_Bill
        Total_Bill
        Distance_Bill
        TotalDelivery
        DeliveryTax
        Services
        Discount
        Tax
      }
      User {
        id
        Person {
          id
          Name_Person
        }
      }
      Exchange
      TotalBill
      TotalDiscount
      TotalExempt
      TotalExonerated
      TotalTaxed
      TotalTaxes
      TotalGoodsExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalServicesExonerated
      TotalServicesExempt
      TotalServicesTaxed
      TotalSell
      TotalNetSell
      ElectronicBillDetail {
        id
        Quantity
        Price
        ProductFeature {
          id
          Codes {
            id
            FeatureCode
          }

          ProductPrice {
            id
            Price_ProductPrice
            Tariff {
              Name
              Code
              Percent
            }
            Cabys {
              id
              Name
              Code
              Percent
            }
          }
          Product {
            id
            Name_Product

            InventoryDetail {
              id
              UnitOfMeasurement {
                id
                Name_UnitOfMeasurement
                Symbol
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ELECTRONICS_BILLS_BY_DATE = gql`
  query getEBsbyDate($eBSearchForm: EBSearchForm) {
    getEBExpenseByYearAndMonth(eBSearchForm: $eBSearchForm) {
      id
      Key
      ElectronicBillDocumentType {
        Description
        Value
        id
      }
      PaymentMethod
      FK_User
      BillFlowType
      Created_At
      EmitedDay
      InternConsecutive
      FK_AcceptanceState
      Coin {
        id
        Name_Coin
        Symbol_Coin
        Buy_Coin
      }
      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id

            Person {
              id
              DocumentId_Person
              Name_Person
              Lastname_Person
            }
          }
        }
      }
      Bill {
        id
        Delivery {
          User {
            id
            Person {
              id
              Name_Person
              Lastname_Person
            }
            Password_User
            NickName_User
          }
        }
        Details_Bill
        SubTotal_Bill
        Total_Bill
        Distance_Bill
        TotalDelivery
        DeliveryTax
        Services
        Discount
        Tax
      }
      User {
        id
        Person {
          id
          Name_Person
        }
      }
      Exchange
      TotalBill
      TotalDiscount
      TotalExempt
      TotalExonerated
      TotalTaxed
      TotalTaxes
      TotalGoodsExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalServicesExonerated
      TotalServicesExempt
      TotalServicesTaxed
      TotalSell
      TotalNetSell
      ElectronicBillDetail {
        id
        Quantity
        Price
        ProductFeature {
          id
          Codes {
            id
            FeatureCode
          }

          ProductPrice {
            id
            Price_ProductPrice
            Tariff {
              Name
              Code
              Percent
            }
            Cabys {
              id
              Name
              Code
              Percent
            }
          }
          Product {
            id
            Name_Product

            InventoryDetail {
              id
              UnitOfMeasurement {
                id
                Name_UnitOfMeasurement
                Symbol
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_EB_BY_CLIENTNAME_AND_DATE = gql`
  query getEDByUserNameAndDate(
    $eBSearchFormWithClient: EBSearchFormWithClient
  ) {
    getEDByUserNameAndDate(eBSearchFormWithClient: $eBSearchFormWithClient) {
      Place {
        Name_Place
      }
      id
      Key
      InternConsecutive
      CreditNotes {
        id
        Key
        TotalSell
        TotalBill
        ElectronicBillDetail {
          id
          FK_ProductFeature
          Quantity
          Price
        }
      }
      DebitNotes {
        id
        Key
        ElectronicBillDetail {
          id
          FK_ProductFeature
          Quantity
          Price
        }
      }
      Coin {
        id
        Name_Coin
        Symbol_Coin
        Code_Coin
        Buy_Coin
      }

      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id
            Person {
              id
              DocumentId_Person
              Name_Person
              Lastname_Person
            }
          }
        }
      }
      Bill {
        id
        Details_Bill
        SubTotal_Bill
        Total_Bill
        Distance_Bill
        TotalDelivery
        DeliveryTax
        Services
        Discount
        Tax
        User {
          id
          Person {
            Name_Person
            DocumentId_Person
          }
        }
      }
      User {
        id
        Person {
          id
          Name_Person
        }
      }
      EmitedDay
      Exchange
      TotalBill
      TotalDiscount
      TotalExempt
      TotalExonerated
      TotalTaxed
      TotalTaxes
      TotalGoodsExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalServicesExonerated
      TotalServicesExempt
      TotalServicesTaxed
      TotalSell
      TotalNetSell
      ElectronicBillDocumentType {
        id
        id_MH
        Description
        Value
      }
    }
  }
`;

export const GET_EB_BY_ID = gql`
  query getEBByID($id: ID!) {
    EBill: getEBByID(id: $id) {
      id
      Key
      InternConsecutive
      Payment_Detail
      SellCondition_Detail
      PaymentMethod
      SellCondition
      Coin {
        id
        Name_Coin
        Symbol_Coin
        Buy_Coin
      }
      Receiver {
        id
        UserBillingProfile {
          ID_Number
          Email
          Name
          PhoneNumber
        }
      }

      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id

            Person {
              id
              DocumentId_Person
              Name_Person
              Lastname_Person
            }
          }
        }
      }
      Bill {
        id
        Detail {
          Total_BillDetail
          Quantity_BillDetail
          UnitPrice
          ProductFeature {
            id
            Product {
              id
              Name_Product
              Description_Product
            }
            ProductPrice {
              Price_ProductPrice
              Cabys {
                Percent
              }
              Discount {
                Quantity
                Prices
              }
            }
          }
        }
      }
      User {
        id
        Person {
          id
          Name_Person
        }
      }
      Exchange
      TotalBill
      TotalDiscount
      TotalExempt
      TotalExonerated
      TotalTaxed
      TotalTaxes
      TotalGoodsExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalServicesExonerated
      TotalServicesExempt
      TotalServicesTaxed
      TotalSell
      TotalNetSell
      ElectronicBillDocumentType {
        id
        id_MH
        Description
        Value
      }
      ReferencedDocument {
        id
        ElectronicBillDocumentType {
          id
          id_MH
          Description
          Value
        }
        id
        Receiver {
          id
          UserBillingProfile {
            ID_Number
            Email
            Name
            PhoneNumber
          }
        }
      }
      ElectronicBillDetail {
        id
        Quantity
        ProductFeature {
          id
          InventoryDetail {
            Inventory {
              Cellar {
                Place {
                  id
                  Name_Place
                  Company {
                    id
                  }
                }
              }
            }
          }
          Codes {
            id
            FeatureCode
          }
          Details {
            id
            Value_ProductFeatureDetail
          }
          ProductPrice {
            id
            Price_ProductPrice
            Cabys {
              id
              Code
              Name
            }
            Tariff {
              id
              Name
              Code
              Percent
            }
            Tax {
              id
              Description_Tax
              MH_Code
            }
          }
          Product {
            id
            Name_Product
            Description_Product
            ProductState {
              State
            }
          }
          Files {
            id
            Route_File
          }
        }
      }
    }
  }
`;

export const GET_EB_FOR_NOTES = gql`
  query getEBByID($id: ID!) {
    bill: getEBByID(id: $id) {
      ReferencedDocument {
        id
        ElectronicBillDocumentType {
          id
          id_MH
          Description
          Value
        }
        id
        Receiver {
          id
          UserBillingProfile {
            ID_Number
            Email
            Name
            PhoneNumber
          }
        }
        Bill {
          id
          Detail {
            Total_BillDetail
            Quantity_BillDetail
            UnitPrice
            ProductFeature {
              id
              Product {
                id
                Name_Product
                Description_Product
              }
              ProductPrice {
                Price_ProductPrice
                Cabys {
                  Percent
                }
                Discount {
                  Quantity
                  Prices
                }
              }
            }
          }
        }
      }
      CreditNotes {
        id
        Place {
          Name_Place
        }
        Coin {
          id
          Name_Coin
          Symbol_Coin
          Buy_Coin
        }
        EmitedDay
        Key
        TotalSell
        TotalBill
        ElectronicBillDetail {
          id
          FK_ProductFeature
          Quantity
          Price
        }
        DebitNotes {
          id
          Key
          ElectronicBillDetail {
            id
            FK_ProductFeature
            Quantity
            Price
          }
        }
      }
      id
      Key
      InternConsecutive
      Payment_Detail
      SellCondition_Detail
      PaymentMethod
      SellCondition
      Coin {
        id
        Name_Coin
        Symbol_Coin
        Buy_Coin
      }
      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id
            Person {
              id
              DocumentId_Person
              Name_Person
              Lastname_Person
            }
          }
        }
      }
      User {
        id
        Person {
          id
          Name_Person
        }
      }

      Exchange
      TotalBill
      TotalDiscount
      TotalExempt
      TotalExonerated
      TotalTaxed
      TotalTaxes
      TotalGoodsExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalServicesExonerated
      TotalServicesExempt
      TotalServicesTaxed
      TotalSell
      TotalNetSell
      ElectronicBillDetail {
        id
        Quantity
        FK_ProductFeature
        Price
        ProductFeature {
          id
          InventoryDetail {
            Inventory {
              Cellar {
                Place {
                  id
                  Name_Place
                  Company {
                    id
                  }
                }
              }
            }
          }
          Codes {
            id
            FeatureCode
          }
          Details {
            id
            Value_ProductFeatureDetail
          }
          ProductPrice {
            id
            Price_ProductPrice
            Cabys {
              id
              Code
              Name
            }
            Tariff {
              id
              Name
              Code
              Percent
            }
            Tax {
              id
              Description_Tax
              MH_Code
            }
          }
          Product {
            id
            Name_Product
            Description_Product
            ProductState {
              State
            }
          }
          Files {
            id
            Route_File
          }
        }
      }
      Receiver {
        id
        UserBillingProfile {
          ID_Number
          Email
          Name
          PhoneNumber
        }
      }
    }
  }
`;
export const GET_EB_FOR_REVIEW = gql`
  query getEBByID($id: ID!) {
    bill: getEBByID(id: $id) {
      EmitedDay
      ReferencedDocument {
        id
        Receiver {
          id
          UserBillingProfile {
            ID_Number
            Email
            Name
            PhoneNumber
          }
        }
        ReferencedDocument {
          Bill {
            TotalGoodsExonerated
            TotalDiscount
            TotalExempt
            TotalExonerated
            TotalGoodsExempt
            TotalGoodsTaxed
            TotalNetSell
            TotalSell
            TotalServicesExempt
            TotalServicesExonerated
            TotalServicesTaxed
            TotalTaxed
            TotalTaxes
            Total_Bill
            Discount
            SubTotal_Bill
            Tax
            Detail {
              UnitPrice
              SubTotal_BillDetail
              Total_BillDetail
              Quantity_BillDetail
              ProductFeature {
                id
                Product {
                  Description_Product
                  Name_Product
                }
              }
            }
          }
        }
      }
      ElectronicBillDocumentType {
        Description
        Value
        id
      }

      Bill {
        TotalGoodsExonerated
        TotalDiscount
        TotalExempt
        TotalExonerated
        TotalGoodsExempt
        TotalGoodsTaxed
        TotalNetSell
        TotalSell
        TotalServicesExempt
        TotalServicesExonerated
        TotalServicesTaxed
        TotalTaxed
        TotalTaxes
        Total_Bill
        Discount
        SubTotal_Bill
        Tax
        Detail {
          id
          UnitPrice
          ProductFeature {
            id
            Product {
              Description_Product
              Name_Product
            }
          }
          Total_BillDetail
          Quantity_BillDetail
          SubTotal_BillDetail
        }
        User {
          UserBillingProfile {
            Name
            ComercialName
            Email
            ID_Number
            PhoneNumber
            OtherSigns
            MH_Province {
              Name
            }
            MH_Canton {
              Name
            }
            MH_District {
              Name
            }
            MH_Neighborhood {
              Name
            }
          }
        }
      }
      BillFlowTypeMH {
        id
        Name_BillType
      }
      PaymentMethodMH {
        id
        Name_PaymentMethod
      }
      SellConditionMH {
        id
        Name_SellCondition
      }

      ReferencedDocument {
        BillFlowTypeMH {
          id
          Name_BillType
        }
        PaymentMethodMH {
          id
          Name_PaymentMethod
        }
        SellConditionMH {
          id
          Name_SellCondition
        }

        Bill {
          TotalGoodsExonerated
          TotalDiscount
          TotalExempt
          TotalExonerated
          TotalGoodsExempt
          TotalGoodsTaxed
          TotalNetSell
          TotalSell
          TotalServicesExempt
          TotalServicesExonerated
          TotalServicesTaxed
          TotalTaxed
          TotalTaxes
          Detail {
            UnitPrice
            id
            ProductFeature {
              id
              Product {
                Description_Product
              }
            }
            Total_BillDetail
            Quantity_BillDetail
            SubTotal_BillDetail
          }
          User {
            UserBillingProfile {
              Name
              ComercialName
              Email
              ID_Number
              PhoneNumber
              OtherSigns
              MH_Province {
                Name
              }
              MH_Canton {
                Name
              }
              MH_District {
                Name
              }
              MH_Neighborhood {
                Name
              }
            }
          }
        }
        CreditNotes {
          id
          Key
          TotalSell
          TotalBill
          ElectronicBillDetail {
            id
            FK_ProductFeature
            Quantity
            Price
          }
        }
        DebitNotes {
          id
          Key
          ElectronicBillDetail {
            id
            FK_ProductFeature
            Quantity
            Price
          }
        }

        id
        Key
        InternConsecutive
        Payment_Detail
        SellCondition_Detail
        PaymentMethod
        SellCondition
        Coin {
          id
          Name_Coin
          Symbol_Coin
          Buy_Coin
        }
        Place {
          id
          Name_Place
          Code
          Company {
            id
            Name_Company
            Description_Company
            User {
              id
              UserBillingProfile {
                Name
                ComercialName
                Email
                ID_Number
                PhoneNumber
                OtherSigns
                MH_Province {
                  Name
                }
                MH_Canton {
                  Name
                }
                MH_District {
                  Name
                }
                MH_Neighborhood {
                  Name
                }
              }
            }
          }
        }
        Exchange
        TotalBill
        TotalDiscount
        TotalExempt
        TotalExonerated
        TotalTaxed
        TotalTaxes
        TotalGoodsExonerated
        TotalGoodsExempt
        TotalGoodsTaxed
        TotalServicesExonerated
        TotalServicesExempt
        TotalServicesTaxed
        TotalSell
        TotalNetSell
        ElectronicBillDetail {
          id
          Quantity
          FK_ProductFeature
          Price
          ProductFeature {
            id
            InventoryDetail {
              Inventory {
                Cellar {
                  Place {
                    id
                    Name_Place
                    Company {
                      id
                    }
                  }
                }
              }
            }
            Codes {
              id
              FeatureCode
            }
            Details {
              id
              Value_ProductFeatureDetail
            }
            ProductPrice {
              id
              Price_ProductPrice
              Cabys {
                id
                Code
                Name
              }
              Tariff {
                id
                Name
                Code
                Percent
              }
              Tax {
                id
                Description_Tax
                MH_Code
              }
            }
            Product {
              id
              Name_Product
              Description_Product
              ProductState {
                State
              }
            }
            Files {
              id
              Route_File
            }
          }
        }
      }
      CreditNotes {
        id
        Key
        TotalSell
        TotalBill
        ElectronicBillDetail {
          id
          FK_ProductFeature
          Quantity
          Price
        }
      }
      DebitNotes {
        id
        Key
        ElectronicBillDetail {
          id
          FK_ProductFeature
          Quantity
          Price
        }
      }
      id
      Key
      InternConsecutive
      Payment_Detail
      SellCondition_Detail
      PaymentMethod
      SellCondition
      Coin {
        id
        Name_Coin
        Symbol_Coin
        Buy_Coin
      }
      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id
            UserBillingProfile {
              Name
              ComercialName
              Email
              ID_Number
              PhoneNumber
              OtherSigns
              MH_Province {
                Name
              }
              MH_Canton {
                Name
              }
              MH_District {
                Name
              }
              MH_Neighborhood {
                Name
              }
            }
          }
        }
      }
      User {
        id
        Person {
          id
          Name_Person
        }
      }
      Exchange
      TotalBill
      TotalDiscount
      TotalExempt
      TotalExonerated
      TotalTaxed
      TotalTaxes
      TotalGoodsExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalServicesExonerated
      TotalServicesExempt
      TotalServicesTaxed
      TotalSell
      TotalNetSell
      ElectronicBillDetail {
        id
        Quantity
        FK_ProductFeature
        Price
        ProductFeature {
          id
          InventoryDetail {
            Inventory {
              Cellar {
                Place {
                  id
                  Name_Place
                  Company {
                    id
                  }
                }
              }
            }
          }
          Codes {
            id
            FeatureCode
          }
          Details {
            id
            Value_ProductFeatureDetail
          }
          ProductPrice {
            id
            Price_ProductPrice
            Cabys {
              id
              Code
              Name
            }
            Tariff {
              id
              Name
              Code
              Percent
            }
            Tax {
              id
              Description_Tax
              MH_Code
            }
          }
          Product {
            id
            Name_Product
            Description_Product
            ProductState {
              State
            }
          }
          Files {
            id
            Route_File
          }
        }
      }
    }
  }
`;
export const GET_EB_BY_FILTERS = gql`
  query getEBILL($filters: EBSearchFormWithFilters) {
    Ebills: getEBByFilters(filters: $filters) {
      Place {
        Name_Place
      }
      id
      Key
      InternConsecutive
      Coin {
        id
        Name_Coin
        Symbol_Coin
        Code_Coin
      }
      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id
            Person {
              id
              DocumentId_Person
              Name_Person
              Lastname_Person
            }
          }
        }
      }
      Bill {
        id
        Details_Bill
        SubTotal_Bill
        Total_Bill
        Distance_Bill
        TotalDelivery
        DeliveryTax
        Services
        Discount
        Tax
        Created_At
        User {
          id
          Person {
            Name_Person
            DocumentId_Person
          }
        }
      }
      Receiver {
        id
        Person {
          id
          Name_Person
          DocumentId_Person
        }
      }
      User {
        id
        Person {
          id
          Name_Person
        }
      }
      EmitedDay
      Exchange
      TotalBill
      TotalDiscount
      TotalExempt
      TotalExonerated
      TotalTaxed
      TotalTaxes
      TotalGoodsExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalServicesExonerated
      TotalServicesExempt
      TotalServicesTaxed
      TotalSell
      TotalNetSell
      ElectronicBillDocumentType {
        id
        Description
      }
      BillFlowTypeMH {
        id
        Name_BillType
      }
    }
  }
`;

export const GET_BILLS_BY_STATE_AND_PLACE_AND_DATE_RANGE = gql`
  query getBills($getBillsNewInput: getBillsNewInput) {
    getBills(getBillsNewInput: $getBillsNewInput) {
      ... on Error {
        error
      }
      ... on getBillsNew {
        Bills {
          id
          Created_At
          OrderDate_Bill
          SubTotal_Bill
          Total_Bill
          TotalDelivery
          DeliveryTax
          Tax
          FreeShipping
          FK_SellCondition
          FK_PaymentMethod
          FK_Coin
          EB {
            Key
            Response
          }
          Place {
            Name_Place
            Phone {
              Data_Contact
            }
            Gmail
            Code
            Address {
              Name_Address
              Description_Address
            }
            Company {
              User {
                Person {
                  Name_Person
                  Lastname_Person
                  Email
                  Phone
                }
              }
            }
          }
          Detail {
            UnitPrice
            Total_BillDetail
            Quantity_BillDetail
            ProductFeature {
              Product {
                id
                Name_Product
                Description_Product
              }
              ProductPrice {
                Price_ProductPrice
                Cabys {
                  Percent
                }
                Discount {
                  Quantity
                  Prices
                }
              }
            }
          }
          User {
            Person {
              id
              DocumentId_Person
              Name_Person
              Lastname_Person
              Email
              Phone
            }
            UserBillingProfile {
              Name
              PhoneNumber
              ID_Number
              Email
              PhoneCodCountry
              MH_Province {
                Name
              }
              MH_Canton {
                Name
              }
              MH_District {
                Name
              }
              MH_Neighborhood {
                Name
              }
              OtherSigns
              OtherSignsForeign
            }
          }
        }
      }
    }
  }
`;

export const GET_EMMITED_DOCUMENT = gql`
  query getEByDate(
    $date: String
    $place_id: ID
    $page: Int
    $take: Int
    $orderOption: orderOption
  ) {
    getEBByDateAndPlace(
      date: $date
      place_id: $place_id
      take: $take
      page: $page
      orderOption: $orderOption
    ) {
      ... on Error {
        error
      }
      ... on documentSearchSuccessResponse {
        pagination {
          rowCount
          pageCount
          pageSize
          page
        }
        data {
          id
          Key
          EmitedDay
          Response
          TotalBill
          ElectronicBillDocumentType {
            id
            Description
          }
        }
      }
    }
  }
`;
export const GET_MANY_PDFS = gql`
  query getManyPDF($ids: [ID]!, $place_id: ID!) {
    downloadManyPDF(ids: $ids, place_id: $place_id) {
      ... on NotFoundError {
        error
        searchedParam
      }
      ... on PdfResult {
        params
        payload
        type
      }
    }
  }
`;

export const GET_ELECTRONICS_BILLS_NEW = gql`
  query getElectronicBillsNew(
    $getElectronicBillsInput: getElectronicBillsNewInput
  ) {
    getElectronicBillsNew(getElectronicBillsInput: $getElectronicBillsInput) {
      ... on Error {
        error
      }
      ... on getElectronicBillsNew {
        ElectronicBills {
          FK_AcceptanceState
          BillFlowType
          id
          Key
          Response
          ElectronicBillDocumentType {
            Description
            Value
            id
          }
          ReferencedDocument {
            id
            Bill {
              id
              User {
                id
                Person {
                  id
                  Name_Person
                }
              }
            }
            ReferencedDocument {
              id
              Bill {
                id
                User {
                  id
                  Person {
                    id
                    Name_Person
                  }
                }
              }
            }
          }
          PaymentMethod
          FK_User
          BillFlowType
          Created_At
          EmitedDay
          InternConsecutive
          FK_AcceptanceState
          Coin {
            id
            Name_Coin
            Symbol_Coin
            Code_Coin
          }
          Place {
            id
            Name_Place
            Code
            Company {
              id
              Name_Company
              Description_Company
              User {
                id

                Person {
                  id
                  DocumentId_Person
                  Name_Person
                  Lastname_Person
                }
              }
            }
          }
          Bill {
            id
            User {
              id
              Person {
                id
                Name_Person
                DocumentId_Person
              }
            }
            Delivery {
              User {
                id
                Person {
                  id
                  Name_Person
                  Lastname_Person
                }
                Password_User
                NickName_User
              }
            }
            Details_Bill
            SubTotal_Bill
            Total_Bill
            Distance_Bill
            TotalDelivery
            DeliveryTax
            Services
            Discount
            Tax
          }
          User {
            id
            Person {
              id
              Name_Person
            }
          }
          Exchange
          TotalBill
          TotalDiscount
          TotalExempt
          TotalExonerated
          TotalTaxed
          TotalTaxes
          TotalGoodsExonerated
          TotalGoodsExempt
          TotalGoodsTaxed
          TotalServicesExonerated
          TotalServicesExempt
          TotalServicesTaxed
          TotalSell
          TotalNetSell
          ElectronicBillDetail {
            id
            Quantity
            Price
            ProductFeature {
              id
              Codes {
                id
                FeatureCode
              }

              ProductPrice {
                id
                Price_ProductPrice
                Tariff {
                  Name
                  Code
                  Percent
                }
                Cabys {
                  id
                  Name
                  Code
                  Percent
                }
              }
              Product {
                id
                Name_Product

                InventoryDetail {
                  id
                  UnitOfMeasurement {
                    id
                    Name_UnitOfMeasurement
                    Symbol
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
