import { format } from "date-fns";

const DEFAULT_COLUMNS = [
  {
    Header: "Número de Documento",
    id: "key ",
    type: "String",
    accessor: "Key",
  },
  {
    Header: "Tipo",
    id: "FK_ElectronicBillDocumentType",
    type: "Text",
    accessor: "ElectronicBillDocumentType.Description",
  },

  {
    Header: "Estado Del Documento (hacienda)",
    id: "Response",
    type: "Text",
    accessor: "Response",
  },
  {
    id: "EmitedDay",
    Header: "Fecha de emisión",
    accessor: "EmitedDay",
    Cell: ({ cell }) =>
      cell?.value && cell?.value !== "-1"
        ? format(new Date(cell.value), "MM/dd/yyyy")
        : "--",
  },
  {
    Header: "Total",
    id: "TotalBill",
    accessor: "Total_Bill",
  },
];
export default DEFAULT_COLUMNS;
