import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Field, Control, Input, Title, Label, Icon, Image } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  REGISTER_NEW_PLACE_AND_USER_ROLE,
  GET_PLACE_BY_NAME,
} from "../../graphql";
// Context
import { useModal, useAuth } from "../../context";
// Components
import SelectActivityCode from "../ActivityCodeSelect";
import CropperPlace from "../CropperPlace";
import UserToPlace from "../UserToPlace";
// Utils
import { customToast as toast, encrypt } from "../../utils";
// SCSS
import "../../pages/ProfilePage/ProfilePage.scss";

function PlacesRegister({ companyId, companyName, refetch, setAddNewPlace }) {
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const [newPlace, setPlace] = useState({
    Name_Place: "",
    Description_Place: "",
    State_Place: "1",
    FK_Company: parseInt(companyId, 10),
    Code: 0,
    Files: null,
    Contacts: [],
    PlaceTags: [],
    Gmail: "",
    Gmail_App: "",
  });

  const { state } = useAuth();
  const user = state.user.TavuelUser;
  const { setModalOpen } = useModal();

  const canvas = useRef(null);

  const [getplaceByName, { data: placebyName }] =
    useLazyQuery(GET_PLACE_BY_NAME);
  const [registerPlace] = useMutation(REGISTER_NEW_PLACE_AND_USER_ROLE);

  const validateRoute = () => {
    if (firebaseURL === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    return firebaseURL;
  };

  const handleChange = (name, value) => {
    setPlace(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = newValue => {
    setPlace(prev => ({ ...prev, Code: newValue.value }));
  };

  const register = useCallback(async () => {
    try {
      const defaultAddress = {
        Name_Address: "new place",
        Description_Address: "new place",
        State_Address: 3,
      };

      const { data } = await registerPlace({
        variables: {
          place: { ...newPlace, Gmail_App: encrypt(newPlace.Gmail_App) },
          address: defaultAddress,
          FK_User: user?.id,
          Origin: "Conta-Fast",
        },
      });
      if (data?.place) {
        refetch();
        toast.success("¡Lugar registrado con éxito!");
      } else {
        await getplaceByName({
          variables: {
            name: newPlace?.Name_Place,
          },
        });
        if (placebyName?.place?.Company?.id) {
          setIsOpen(true);
        }
      }
    } catch (err) {
      toast.error("Error al registrar el lugar");
    }
  }, [
    registerPlace,
    newPlace,
    user?.id,
    refetch,
    getplaceByName,
    placebyName?.place?.Company?.id,
  ]);

  const handleSubmit = e => {
    e.preventDefault();
    // eslint-disable-next-line no-debugger
    if (newPlace.Name_Place !== "" && newPlace.Description_Place !== "") {
      register();
      setAddNewPlace(false);
    } else {
      toast.error("Debe completar los datos solicitados.");
    }
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSelectClick = e => {
    e.preventDefault();
    document.querySelector("#fileSelectorImg").click();
  };

  const handleCancel = () => {
    setAddNewPlace(false);
  };

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpen(
        true,
        <CropperPlace
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setModalOpen(false)}
        />,
      );
      setOpen(true);
    }
  }, [open, setModalOpen, upImg]);

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setPlace(prev => ({
        ...prev,
        Files: [
          {
            Route_File: firebaseURL,
            FirebaseId: firebaseID,
            State_File: "New",
            FileType: {
              id: 2,
            },
          },
        ],
      }));
    }
  }, [firebaseURL, firebaseID]);

  useEffect(() => {
    if (placebyName?.place?.id) {
      if (placebyName?.place?.Company?.id) {
        setIsOpen(true);
      }
    }
  }, [placebyName]);

  useEffect(() => {
    if (isOpen) {
      setModalOpen(
        true,
        <UserToPlace
          place={placebyName?.place}
          onClose={() => {
            setModalOpen(false);
          }}
        />,
      );
      setIsOpen(false);
    }
  }, [placebyName?.place, isOpen, setModalOpen]);

  return (
    <div>
      <form className="profile-page-container" onSubmit={handleSubmit}>
        <div>
          <Title className="profile-page-title">Registro de Lugares</Title>
          <div className=" title-center">
            <input
              accept="image/*"
              className="input-file"
              id="fileSelectorImg"
              type="file"
              onChange={hadleOnSelectFile}
            />
            <div className="profilepic">
              <Image.Container size="64px sq.">
                <Image
                  className="profilepic__image"
                  height="150"
                  src={validateRoute()}
                  width="150"
                />
              </Image.Container>
              <div className="profilepic__content">
                <Icon className="hover-table-options ">
                  <FontAwesomeIcon
                    icon="camera"
                    size="1x"
                    onClick={handleSelectClick}
                  />
                </Icon>
              </div>
            </div>
          </div>
          <Field kind="group">
            <Control expanded>
              <Label textColor="grey-dark">Compañía:</Label>
              <Input
                disabled
                placeholder="Compañía"
                type="text"
                value={companyName}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label textColor="grey-dark">Nombre:</Label>
              <Input
                name="Name_Place"
                placeholder="Nombre"
                type="text"
                value={newPlace.Name_Place}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label textColor="grey-dark">Descripción:</Label>
              <Input
                name="Description_Place"
                placeholder="Descripción"
                type="text"
                value={newPlace.Description_Place}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <SelectActivityCode
            defaultValue={{ label: "Buscar...", value: "" }}
            handleChange={handleSelectChange}
          />
          <Field kind="group">
            <Control expanded>
              <Label textColor="grey-dark">Correo Gmail:</Label>
              <Input
                name="Gmail"
                placeholder="Correo Gmail"
                type="email"
                value={newPlace.Gmail}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label textColor="grey-dark">
                Contraseña de aplicación Gmail:
              </Label>
              <Input
                name="Gmail_App"
                placeholder="Contraseña de aplicación Gmail"
                type="text"
                value={newPlace.Gmail_App}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <div className="profile-page-button">
            <Button rounded className="cancel-button" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button rounded color="primary" type="submit">
              Guardar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

PlacesRegister.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  companyName: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  setAddNewPlace: PropTypes.func.isRequired,
};

export default PlacesRegister;
