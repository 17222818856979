import React from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control } from "rbx";
// SCSS
import "../CreditDebitNotesPage.scss";

function SelectType({ data, onChange, option }) {
  return (
    <Control expanded>
      <Select.Container fullwidth>
        <Select
          className="datePicker-creditnote"
          value={option}
          onChange={e => onChange(e.target.value)}
        >
          {data?.map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
}

SelectType.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  option: PropTypes.string.isRequired,
};

SelectType.defaultProps = {};

export default SelectType;
