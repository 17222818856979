import React from "react";
import { Switch, Route } from "react-router-dom";
import ClientList from "./components/ClientsList";
import ClientsMaintenance from "./ClientsMaintenance";

const ClientsMaintenanceRouter = props => (
  <Switch>
    <Route path="/clients-maintenance/clients-list/:placeId/:placeName/:companyId">
      <ClientList />
    </Route>
    <Route path="/clients-maintenance">
      <ClientsMaintenance />
    </Route>
  </Switch>
);

export default ClientsMaintenanceRouter;
