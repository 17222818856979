import TransformImages from "./TransformImages";

export const withStopPropagation = (e, fn) => {
  e.stopPropagation();
  fn();
};
export const getUrl = item => {
  const route = item?.Files?.at(-1) || item?.url?.at(-1);
  return route?.Route_File || "";
};

export const validateRoute = item => {
  const route = getUrl(item);
  if (route === "") {
    return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
  }
  TransformImages(item?.Files || item?.url, 1);
  return route;
};

export const downloadBase64File = (contentBase64, fileName) => {
  const linkSource = `data:application/pdf;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
};
export const toZohoMonths = date => {
  const temporalDate = new Date(`${date} `).toDateString().split(" "); // Wed Sep 21 2022
  return `${temporalDate[2]}-${temporalDate[1]}-${temporalDate[3]}`;
};

export const arrayBill = bills => {
  if (bills.DetalleServicio?.LineaDetalle.constructor === Array) {
    return bills;
  }
  return {
    ...bills,
    DetalleServicio: { LineaDetalle: [bills.DetalleServicio.LineaDetalle] },
  };
};

export const isEmptyImage = url => !!url?.includes("emptyImg");

export const SORT_ARRAY_ITEMS_BY_DATE_MODES = {
  ASCENDANT: "ASCENDANT",
  DESCENDANT: "DESCENDANT",
};

export const sortArrayItemsByDate = (array, options) => {
  const { mode, targetKeyName } = options;

  const result = Array.isArray(array)
    ? array
        .slice()
        .sort(
          (firstComparisonElement, secondComparisonElement) =>
            (mode === SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT &&
              new Date(secondComparisonElement[targetKeyName]) -
                new Date(firstComparisonElement[targetKeyName])) ||
            (mode === SORT_ARRAY_ITEMS_BY_DATE_MODES.DESCENDANT &&
              new Date(firstComparisonElement[targetKeyName]) -
                new Date(secondComparisonElement[targetKeyName])),
        )
    : [];

  return result;
};

export const formatDateString = date =>
  new Date(date).toISOString().match(/(\d+(\d+[:-])+\d+)/g);

export const removeTypeName = item => {
  const temp_item = { ...item };
  delete temp_item.__typename;
  return temp_item;
};
export const removeFirstCharIfZero = value => {
  if (value === "") return "0";
  if (value[0] !== "0") return value;
  return value.slice(1) || value;
};
