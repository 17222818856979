import React, { useState } from "react";
import PropTypes from "prop-types";
import "firebase/storage";
// External components
import { Button, Field, Control, Input, Title, Label } from "rbx";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "../../pages/ProfilePage/ProfilePage.scss";

function ClientGroupRegister({
  setModalOpen,
  placeId,
  groupId,
  registerGroup,
  updateGroup,
  nameGroup,
  descriptionGroup,
}) {
  // states
  const [informationGroup, setInformationGroup] = useState({
    Name_ClientGroup: nameGroup,
    Description_ClientGroup: descriptionGroup,
    FK_Place: placeId,
    id: groupId,
  });

  const handleChange = (name, value) => {
    setInformationGroup(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (informationGroup?.id !== 0) {
      if (
        informationGroup.Name_ClientGroup !== "" &&
        informationGroup.Description_ClientGroup !== "" &&
        informationGroup?.FK_Place &&
        informationGroup?.id
      ) {
        updateGroup(informationGroup);
      } else {
        toast.error(
          "Debe completar toda la información solicitada para realizar los cambios.",
        );
      }
    } else if (
      informationGroup.Name_ClientGroup !== "" &&
      informationGroup.Description_ClientGroup !== "" &&
      informationGroup?.FK_Place
    ) {
      registerGroup({
        Name_ClientGroup: informationGroup.Name_ClientGroup,
        Description_ClientGroup: informationGroup.Description_ClientGroup,
        FK_Place: informationGroup?.FK_Place,
      });
    } else {
      toast.error(
        "Debe completar toda la información solicitada para el registro.",
      );
    }
  };

  const handleCancel = () => {
    setModalOpen(false, "");
  };

  return (
    <div>
      <form className="profile-page-container" onSubmit={handleSubmit}>
        <div>
          {groupId === 0 ? (
            <Title className="profile-page-title">Registrar nuevo grupo</Title>
          ) : (
            <Title className="profile-page-title">Editar grupo</Title>
          )}
          <Field kind="group">
            <Control expanded>
              <Label textColor="grey-dark">Nombre: </Label>
              <Input
                required
                name="Name_ClientGroup"
                placeholder="Nombre"
                type="text"
                value={informationGroup.Name_ClientGroup}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label textColor="grey-dark">Descripción:</Label>
              <Input
                required
                name="Description_ClientGroup"
                placeholder="Descripción"
                type="text"
                value={informationGroup.Description_ClientGroup}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <div className="profile-page-button">
            <Button rounded className="cancel-button" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button rounded color="primary" type="submit">
              Guardar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

ClientGroupRegister.propTypes = {
  setModalOpen: PropTypes.func,
  placeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registerGroup: PropTypes.func,
  updateGroup: PropTypes.func,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nameGroup: PropTypes.string,
  descriptionGroup: PropTypes.string,
};
ClientGroupRegister.defaultProps = {
  setModalOpen: () => null,
  registerGroup: () => null,
  updateGroup: () => null,
  placeId: "",
  groupId: 0,
  nameGroup: "",
  descriptionGroup: "",
};

export default ClientGroupRegister;
