import { useState } from "react";
// External components
import { Button, Container } from "rbx";
import { registerLocale } from "react-datepicker";
import Es from "date-fns/locale/es";
import { CSVLink } from "react-csv";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { GET_ELECTRONICS_BILLS_BY_DATE_AND_ACCEPTED } from "../../graphql";
// Components
import { ExpenseCard } from "./components";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
// Context
import { useAuth } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { customToast } from "../../utils";
import { generateElectronicBillsCSVData } from "../../utils/CSVHelper";
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";
import { LandingPage } from "../../pages/LandingPage";
// SCSS
import "./ExpensesManagement.scss";

export const EXPENSE_CARD_SELECTION_STATUS = {
  IS_SELECTED: true,
  IS_NOT_SELECTED: false,
};

function ExpensesManagement() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [expenses, setExpenses] = useState([]);

  const { state: authState } = useAuth();
  const { LineDetailPlace } = useElectronic();

  registerLocale("Es", Es);

  const [getElectronicBillsAccepted, { loading }] = useLazyQuery(
    GET_ELECTRONICS_BILLS_BY_DATE_AND_ACCEPTED,
    {
      onCompleted: response =>
        setExpenses(
          response?.expenses.map(expense => ({ ...expense, selected: true })),
        ),
    },
    {
      fetchPolicy: "network-only",
    },
  );

  const handleGetElectronicBills = async () => {
    await getElectronicBillsAccepted({
      variables: {
        place_id: LineDetailPlace.id,
        date: selectedDate,
      },
    });
  };

  const handleSubmit = () => {
    if (authState.user.TavuelUser.id && selectedDate) {
      if (LineDetailPlace.id !== 0) {
        handleGetElectronicBills();
      } else {
        customToast.warning("Seleccione un lugar, por favor.");
      }
    }
  };

  const { data: dataToCSV, headers: headersToCSV } =
    generateElectronicBillsCSVData(
      expenses !== undefined && expenses?.length > 0
        ? expenses.filter(exp => exp.selected)
        : [],
    );

  const handleDownloadCSV = () => {
    const selected = expenses.filter(exp => exp.selected);
    if (!selected?.length) {
      customToast.warning(
        "Debe seleccionar un gasto para realizar la descarga.",
      );
      return false;
    }
    return selected;
  };

  const handleSelect = (id, value) => {
    // Selecciona la factura indicada en el estado expenses
    setExpenses(prev =>
      prev.map(expense =>
        expense.id === id ? { ...expense, selected: value } : expense,
      ),
    );
  };

  const expensesSortedAscendant = sortArrayItemsByDate(expenses, {
    mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
    targetKeyName: "EmitedDay",
  });

  return (
    <Container>
      <h1>Administración de gastos</h1>
      <div className="expenses-management">
        <div>
          <CustomDatePicker
            className="mr-1"
            dateFormat="MMMM / yyyy"
            selectedDate={selectedDate}
            onChange={date => setSelectedDate(date)}
          />
        </div>

        <Button color="primary" onClick={handleSubmit}>
          Buscar
        </Button>
        {expenses?.length > 0 && (
          <CSVLink
            data={dataToCSV}
            filename="Lista de gastos aceptados.csv"
            headers={headersToCSV}
            style={{
              color: "#01234c",
              cursor: "pointer",
              display: "block",
              marginLeft: "5px",
              paddingTop: "5px",
            }}
            target="_blank"
            onClick={handleDownloadCSV}
          >
            Descargar Lista de Facturas
          </CSVLink>
        )}
      </div>
      <div className="expenses-management-title">
        <h2>
          Facturas de:{" "}
          {new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
            selectedDate,
          )}
          /{selectedDate.getFullYear()}:
        </h2>
      </div>

      <div className="grid">
        {loading && expenses?.length === 0 && <LandingPage />}
        {expenses?.length === 0 && !loading ? (
          <h2>¡No se encontraron Egresos!</h2>
        ) : (
          expensesSortedAscendant?.map(item => (
            <ExpenseCard
              key={item?.id}
              handleSelect={handleSelect}
              item={item}
            />
          ))
        )}
      </div>
    </Container>
  );
}
export default ExpensesManagement;
