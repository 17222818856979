import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
// External components
import { Tab } from "rbx";
// Components
import { toast } from "react-toastify";
import {
  IvaReport,
  ClientListReport,
  ProductsReport,
  SellsReport,
  ElectronicBillReport,
  EmmitedDocumentReport,
} from "..";
// Context
import { useAuth } from "../../context";
// Routes
import { InventoryRequiredRoute } from "../../routes";
// SCSS
import "./MenuReports.scss";

function MenuReports() {
  const [toggleState, setToggleState] = useState(1);
  const { permissions } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const { haveActionPermission, noAccessMessage } = permissions;

  const canRead = haveActionPermission("Read", "/reports");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Reportes"));
    history.push(`/`);
  }

  useEffect(() => {
    const routes = [
      { route: "/reports/report01", value: 1 },
      { route: "/reports/report02", value: 2 },
      { route: "/reports/report03", value: 3 },
      { route: "/reports/report04", value: 4 },
      { route: "/reports/report05", value: 5 },
      { route: "/reports/report06", value: 6 },
    ];
    setToggleState(
      routes.find(index => index.route === location.pathname)?.value || 1,
    );
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div className="mb-5">
        <Tab.Group align="undefined" kind="boxed">
          <Tab
            as={Link}
            className={toggleState === 1 ? "active" : "tabs"}
            to="/reports/report01"
            onClick={() => setToggleState(1)}
          >
            <span>Declaración de IVA(D-104)</span>
          </Tab>
          <Tab
            as={Link}
            className={toggleState === 2 ? "active" : "tabs"}
            to="/reports/report02"
            onClick={() => setToggleState(2)}
          >
            <span>Lista de clientes (PDF)</span>
          </Tab>
          <Tab
            as={Link}
            className={toggleState === 3 ? "active" : "tabs"}
            to="/reports/report03"
            onClick={() => setToggleState(3)}
          >
            <span>Detalles de productos (PDF)</span>
          </Tab>
          <Tab
            as={Link}
            className={toggleState === 4 ? "active" : "tabs"}
            to="/reports/report04"
            onClick={() => setToggleState(4)}
          >
            <span>Informe de ventas (CSV)</span>
          </Tab>
          <Tab
            as={Link}
            className={toggleState === 5 ? "active" : "tabs"}
            to="/reports/report05"
            onClick={() => setToggleState(5)}
          >
            <span>Factura Electrónica(PDF)</span>
          </Tab>

          <Tab
            as={Link}
            className={toggleState === 6 ? "active" : "tabs"}
            to="/reports/report06"
            onClick={() => setToggleState(6)}
          >
            <span>Documentos Emitidos (zip)</span>
          </Tab>
        </Tab.Group>
      </div>
      <Switch>
        <Route path="/reports/report01">
          <IvaReport />
        </Route>
        <Route path="/reports/report02">
          <ClientListReport />
        </Route>
        <InventoryRequiredRoute
          component={ProductsReport}
          path="/reports/report03"
        />
        <InventoryRequiredRoute
          component={SellsReport}
          path="/reports/report04"
        />
        <InventoryRequiredRoute
          component={ElectronicBillReport}
          path="/reports/report05"
        />
        <InventoryRequiredRoute
          component={EmmitedDocumentReport}
          path="/reports/report06"
        />
        <Route path="/reports">
          <IvaReport />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
export default MenuReports;
