import { format } from "date-fns";
import PropTypes from "prop-types";
// External components
import { Button, Card } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Utils
import { formatCurrency } from "../../utils";
// SCSS
import "./EBResumeCard.scss";

const EBResumeCard = ({ item, actions }) => (
  <Card
    key={item.id}
    className="card-content"
    style={
      item?.ElectronicBillDocumentType?.id === "1"
        ? { backgroundColor: "#f5f9ff" }
        : { backgroundColor: "rgb(232 237 243)" }
    }
  >
    <div className="body-card">
      <p>
        Documento: &nbsp;{" "}
        <span className="bold-text">
          {" "}
          {item?.ElectronicBillDocumentType?.Description}{" "}
        </span>{" "}
      </p>
      <div className="content-invoice">
        <p>
          Emisor: &nbsp;{" "}
          <span className="bold-text">{item.Place?.Name_Place} </span>
        </p>
      </div>
      {item?.ElectronicBillDocumentType?.id === "1" && (
        <div>
          <p>
            Receptor: &nbsp;{" "}
            <span className="bold-text">
              {" "}
              {item.Bill?.User?.Person?.Name_Person}{" "}
            </span>{" "}
          </p>
          <p>
            Cédula Receptor: &nbsp;{" "}
            <span className="bold-text">
              {item.Bill?.User?.Person?.DocumentId_Person}
            </span>
          </p>{" "}
        </div>
      )}
      <p>
        Moneda:<span className="bold-text">{item?.Coin?.Name_Coin} </span>{" "}
      </p>
      <p>
        Total: &nbsp;
        <span className="bold-text">
          {formatCurrency(
            parseFloat(item.TotalBill, 10),
            item?.Coin?.Code_Coin ? item?.Coin?.Code_Coin : "CRC",
          )}
        </span>
      </p>

      {item?.EmitedDay && (
        <p className="text-centered">
          Fecha: &nbsp;{" "}
          <span className="bold-text">
            {format(new Date(item?.EmitedDay), "dd/MM/yyyy hh:mm a")}
          </span>
        </p>
      )}
    </div>
    <div className="button-fixed">
      {actions.map(action =>
        action.isIcon ? (
          <FontAwesomeIcon
            icon={action.icon}
            size="2x"
            title={action.text}
            onClick={action.onClick}
          />
        ) : (
          <Button
            key={item.id}
            outlined
            className="btn-animate"
            color="primary"
            onClick={action.onClick}
          >
            {action.text}
          </Button>
        ),
      )}
    </div>
  </Card>
);

EBResumeCard.propTypes = {
  item: PropTypes.object.isRequired,
  actions: PropTypes.array,
};
EBResumeCard.defaultProps = {
  actions: [],
};

export default EBResumeCard;
