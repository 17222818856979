/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
// External component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Control, Label, Card, Icon } from "rbx";
import { components } from "react-select";
// Graphql
import { useApolloClient } from "@apollo/client";
import { customToast as toast } from "../../utils";
import { GET_CLIENTS_BY_CRITERIA } from "../../graphql";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "../ProductSelect/ProductSelect.scss";
import "../PaymentComponet/Receiver/SelectProfile/SelectProfile.scss";

function BillingClientSelect({ register, placeId, clientList }) {
  const { LineDetailPlace } = useElectronic();
  const client = useApolloClient();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleChange = newValue => {
    const clientExist = clientList?.find(
      index => index?.BillingProfile?.ID_Number === newValue?.ID_Number,
    );
    if (!clientExist) {
      register(newValue);
    } else {
      toast.error(
        "Ya existe un usuario con el número de identificación proporcionado asociado al grupo.",
      );
    }
  };

  const selectIcon = TypeId => (TypeId === "1" ? "user" : "building");

  const CustomOption = value => {
    const { data } = value;
    return (
      <components.Option {...value}>
        {data && (
          <Container className="favorite-container">
            <Card key={data.ID_Number} className="favorite-card ">
              <div className="flex">
                <Icon>
                  <FontAwesomeIcon
                    icon={selectIcon(data.MH_PersonType?.id)}
                    size="lg"
                  />
                </Icon>
                &nbsp;&nbsp;
                <h3>{data.Name},</h3>
                &nbsp;
                <h3>{data.ID_Number}</h3>
              </div>
            </Card>
          </Container>
        )}
      </components.Option>
    );
  };

  const loadOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_CLIENTS_BY_CRITERIA,
      variables: {
        criteria: inputValue,
        FK_Place: LineDetailPlace?.id,
      },
    });

    const clients = data?.clients;

    const result = Array.isArray(clients)
      ? clients.map(BillingProfile => ({
          id: BillingProfile.id,
          Name: BillingProfile?.Name,
          idUser: BillingProfile?.User?.id,
          ID_Number: BillingProfile?.ID_Number,
          Email: BillingProfile?.Email,
          MH_PersonType: BillingProfile?.MH_PersonType,
          MH_Neighborhood: BillingProfile?.MH_Neighborhood,
          PhoneNumber: BillingProfile?.PhoneNumber,
          PhoneCodCountry: BillingProfile?.PhoneCodCountry,
          value: BillingProfile.id,
          label: "Buscar cliente...",
        }))
      : [];
    setDefaultOptions(result);
    return result;
  };

  return (
    <Control expanded>
      <Label>Agregar cliente existente:</Label>
      <AsyncSelect
        cacheOptions
        components={{ Option: CustomOption }}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Buscar..."
        styles={{
          menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: provided => ({
            ...provided,
            display: "none",
          }),
          container: provided => ({
            fontSize: 13,
          }),
          singleValue: provided => ({
            ...provided,
            top: "38%",
          }),
          control: provided => ({
            ...provided,
            "minHeight": 30,
            "height": 36,
            "width": "100%",
            "minWidth": 180,
            "borderRadius": "4px",
            "border": "0.1rem solid #01234c",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: "pointer",
            },
          }),
          placeholder: provided => ({ ...provided, top: "38%" }),
          menu: provided => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
          menuList: provided => ({
            ...provided,
            display: "flex",
            flexWrap: "wrap",
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        onChange={handleChange}
      />
    </Control>
  );
}

BillingClientSelect.defaultProps = {};

BillingClientSelect.propTypes = {
  register: PropTypes.func.isRequired,
  placeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  clientList: PropTypes.array.isRequired,
};

export default BillingClientSelect;
