import React from "react";
import PropTypes from "prop-types";
// External components
import { Box } from "rbx";
// Components
import BillResumeCard from "../BillResumeCard";
// SCSS
import "./DocumentSearchBill.scss";

const DocumentSearchBill = ({ bills, isLoading, handleDeleteDocument }) => (
  <Box>
    {isLoading ? (
      <div>
        <h1>Cargando...</h1>
      </div>
    ) : (
      <div>
        <div className="grid" style={{ display: bills ? "" : "none" }}>
          {bills?.map(bill => (
            <BillResumeCard
              key={bill.id}
              bill={bill}
              handleDeleteDocument={handleDeleteDocument}
            />
          ))}
        </div>
      </div>
    )}
  </Box>
);

DocumentSearchBill.propTypes = {
  bills: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool.isRequired,
  handleDeleteDocument: PropTypes.func.isRequired,
};

DocumentSearchBill.defaultProps = {
  bills: null,
};

export default DocumentSearchBill;
