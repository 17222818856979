import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// External components
import { Title, Button, Field, Control, Input, Label } from "rbx";
// Graphql
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_CLIENTS_BY_GROUP,
  REGISTER_NEW_CLIENTXGROUP,
  CREATE_NEW_CLIENT,
  DELETE_CLIENTXGROUP,
  UPDATE_CLIENT,
  TAVUEL_USER_BY_BILLING_PROFILE,
} from "../../graphql";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Components
import ClientsCard from "../ClientsCard";
import RegisterReceiver from "../RegisterReceiver";
import SelectBillingClient from "../BillingClientSelect";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "../PaymentComponet/Receiver/ClientReceiver/ClientReceiver.scss";
import "../ClientsGroupsXPlace/ClientsGroupsXPlace.scss";
import "./ClientsXGroup.scss";

import ConfirmationModal from "../ConfirmationModal";

function ClientsXGroup() {
  const [search, setSearch] = useState("");
  const [clientSearch, setClientSearch] = useState([]);
  const [clientList, setClientList] = useState([]);

  const { setModalOpen } = useModal();
  const { id, name } = useParams();
  const { LineDetailPlace } = useElectronic();
  const { state: authState, permissions } = useAuth();

  const { haveActionPermission } = permissions;

  const [registerGroup] = useMutation(REGISTER_NEW_CLIENTXGROUP);
  const [deleteClients] = useMutation(DELETE_CLIENTXGROUP);
  const [updateClients] = useMutation(UPDATE_CLIENT);
  const [registerClient] = useMutation(CREATE_NEW_CLIENT);
  const { setModalOpen: setDeleteConfirmationModal } = useModal();

  const [registerReceiverByBillingProfile] = useMutation(
    TAVUEL_USER_BY_BILLING_PROFILE,
  );

  const {
    loading,
    error,
    data: clientsByIdGroup,
  } = useQuery(GET_ALL_CLIENTS_BY_GROUP, {
    variables: {
      FK_ClientGroup: id,
    },
  });

  const deleteClient = useCallback(
    async idClient => {
      setDeleteConfirmationModal(
        true,
        <ConfirmationModal
          body="¿Desea eliminar este usuario de tu grupo?"
          header="¿Eliminar usuario?"
          onCancel={() => setDeleteConfirmationModal(false)}
          onConfirm={async () => {
            try {
              setModalOpen(false);
              const { data } = await deleteClients({
                variables: {
                  id: idClient,
                },
                refetchQueries: [
                  {
                    query: GET_ALL_CLIENTS_BY_GROUP,
                    variables: {
                      FK_ClientGroup: id,
                    },
                  },
                ],
              });
              if (data) {
                toast.success("¡Cliente eliminado del grupo con éxito!");
              }
            } catch (err) {
              toast.error("Error al eliminar el cliente");
            }
          }}
        />,
      );
    },

    [setDeleteConfirmationModal, setModalOpen, deleteClients, id],
  );

  useEffect(() => {
    setClientList(
      clientsByIdGroup?.clients?.map(index => ({
        ...index,
        selected: false,
      })),
    );
  }, [clientsByIdGroup]);

  const addNewClient = useCallback(
    async profile => {
      try {
        const { data } = await registerReceiverByBillingProfile({
          variables: {
            FK_Place: LineDetailPlace?.id,
            profile,
          },
        });
        if (data) {
          const responseResterGroup = await registerGroup({
            variables: {
              data: { FK_User: data?.BillingProfile?.id, FK_ClientGroup: id },
            },
            refetchQueries: [
              {
                query: GET_ALL_CLIENTS_BY_GROUP,
                variables: {
                  FK_ClientGroup: id,
                },
              },
            ],
          });
          const responseRegisterClient = await registerClient({
            variables: {
              data: {
                Created_By: authState.user.TavuelUser.id,
                FK_User: data?.BillingProfile?.id,
                FK_Place: LineDetailPlace?.id,
              },
            },
            refetchQueries: [
              {
                query: GET_ALL_CLIENTS_BY_GROUP,
                variables: {
                  FK_ClientGroup: id,
                },
              },
            ],
          });
          if (responseRegisterClient && responseResterGroup) {
            toast.success("¡Cliente agregado al grupo con éxito!");
          }
        }
      } catch (err) {
        toast.error("No se pudo agregar el nuevo cliente.");
      }
    },
    [
      LineDetailPlace.id,
      authState.user.TavuelUser.id,
      id,
      registerClient,
      registerGroup,
      registerReceiverByBillingProfile,
    ],
  );

  const editClient = useCallback(
    async (Id, billingProfile) => {
      try {
        const { data } = await updateClients({
          variables: {
            id: Id,
            billingProfile,
            FK_Place: LineDetailPlace.id,
          },
          refetchQueries: [
            {
              query: GET_ALL_CLIENTS_BY_GROUP,
              variables: {
                FK_ClientGroup: id,
              },
            },
          ],
        });
        if (data.client.error) {
          toast.error(data.client.error);
        } else {
          toast.success("¡Datos actualizados con éxito!");
          setModalOpen(false);
        }
      } catch (err) {
        toast.error(err);
      }
    },
    [LineDetailPlace.id, id, setModalOpen, updateClients],
  );

  const handleNewClient = () => {
    setModalOpen(
      true,
      <RegisterReceiver
        newClient
        FK_User={0}
        clientList={clientsByIdGroup?.clients}
        handleChangeAddress={() => null}
        handleCreateNewClient={addNewClient}
        initialReceiver={null}
        setBillingProfile={null}
        setModalOpen={setModalOpen}
        title="Perfil de facturación"
      />,
    );
  };

  const register = useCallback(
    async newClient => {
      const dataInput = {
        FK_User: newClient?.idUser,
        FK_ClientGroup: id,
      };
      const isInList = clientsByIdGroup?.clients?.find(
        index => index?.BillingProfile?.id === newClient?.id,
      );
      if (!isInList) {
        try {
          setModalOpen(false);
          const { data } = await registerGroup({
            variables: {
              data: dataInput,
            },
            refetchQueries: [
              {
                query: GET_ALL_CLIENTS_BY_GROUP,
                variables: {
                  FK_ClientGroup: id,
                },
              },
            ],
          });
          if (data) {
            toast.success("¡Cliente agregado al grupo con éxito!");
          }
        } catch (err) {
          toast.error("Error al agregar el cliente");
        }
      } else {
        toast.success("¡El cliente ya se encuentra registrado!");
      }
    },
    [registerGroup, setModalOpen, id, clientsByIdGroup],
  );

  const handleSearch = value => {
    setSearch(value);
    setClientSearch(
      clientList.filter(
        clientItem =>
          clientItem?.BillingProfile?.Name?.toUpperCase()
            .trim()
            .includes(value?.toUpperCase().trim()) ||
          clientItem?.BillingProfile?.Email?.toUpperCase().includes(
            value?.toUpperCase(),
          ) ||
          clientItem?.BillingProfile?.ID_Number?.toUpperCase().includes(
            value?.toUpperCase(),
          ),
      ),
    );
  };

  return (
    <div>
      <Title className="title-center">{name}</Title>
      {haveActionPermission("Create", "/clients") && (
        <div className="groups-header add-client-container">
          <div className="header-containers">
            <SelectBillingClient
              clientList={clientsByIdGroup?.clients}
              placeId={LineDetailPlace?.id}
              register={register}
            />
          </div>
          <div className="header-containers right-container">
            <Button color="primary" onClick={() => handleNewClient()}>
              Agregar Nuevo Cliente
            </Button>
          </div>
        </div>
      )}
      <div className="list-container">
        <Label className="title-search">Clientes de {name}</Label>
        <Field>
          <Control>
            <Input
              rounded
              color="dark"
              name="search"
              placeholder="Búsqueda clientes..."
              type="text"
              value={search}
              onChange={({ target: { value } }) => handleSearch(value)}
            />
          </Control>
        </Field>
        {clientsByIdGroup?.clients?.length !== 0 &&
          !loading &&
          !error &&
          search?.length !== 0 &&
          (clientSearch.length !== 0 ? (
            <ClientsCard
              clientList={clientSearch}
              deleteClient={deleteClient}
              editClient={editClient}
              setClientList={setClientList}
            />
          ) : (
            <div>
              <Title className="title-center">
                No hay clientes que coincidan con {search}.
              </Title>
            </div>
          ))}
        {search?.length === 0 && (
          <ClientsCard
            clientList={clientList}
            deleteClient={deleteClient}
            editClient={editClient}
            setClientList={setClientList}
          />
        )}
        {(!clientsByIdGroup?.clients?.length || loading || error) && (
          <div>
            <Title className="title-center">
              Aún no hay clientes registrados para {name}.
            </Title>
          </div>
        )}
      </div>
    </div>
  );
}

ClientsXGroup.propTypes = {};

export default ClientsXGroup;
