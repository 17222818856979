import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Container, Notification } from "rbx";
// Context
import { useAuth, useModal } from "../../../../context";
import { useElectronic } from "../../../../context/ElectronicContext";
// Hooks
import useEmail from "../../../../hooks/useEmail";
// Components
import CardAccount from "../CardAccount";
import NewAccount from "../NewAccount";
// Utils
import { customToast as toast } from "../../../../utils";
import getImageEmail from "../../../../utils/GetImageEmail";
// SCSS
import "./AccountManagement.scss";
import { emailLoginFunction } from "../../../../utils/MailConnector";

function AccountManagement({ openModal }) {
  const [accounts, setAccounts] = useState([]);
  const [checkAccountSelected, setCheckAccountSelected] = useState(true);
  const { setModalOpen } = useModal();
  const { getEmailsAccounts, deleteTokenEmail, checkTokenEmail } = useEmail();
  const { accountSelected, setAccountSelected } = useElectronic();
  const { state } = useAuth();
  const { user } = state;

  const checkLoginMail = useCallback(
    async account => {
      try {
        const res = await checkTokenEmail(
          account?.id,
          parseInt(account?.type, 10),
        );
        setCheckAccountSelected(res?.data?.email);
        return res?.data?.email;
      } catch (err) {
        return false;
      }
    },
    [checkTokenEmail],
  );

  const getCssImage = () => {
    const namesCss = {
      1: "img-zoho",
      2: "img-gmail",
      3: "img-outlook",
    };
    return namesCss[accountSelected.type];
  };

  const allAccounts = useCallback(async () => {
    try {
      const res = await getEmailsAccounts(user.googleAuthId);
      setAccounts(res?.data?.data);
    } catch (err) {
      toast.error(err);
    }
  }, [getEmailsAccounts, user.googleAuthId]);

  useEffect(() => {
    user?.googleAuthId && allAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAccountSelected = async account => {
    setAccountSelected(account);
    if (await checkLoginMail(account)) openModal(false);
  };

  const handleLogout = async () => {
    try {
      const { data } = await deleteTokenEmail(
        accountSelected.id,
        Number(accountSelected.type),
      );
      if (data) {
        setAccounts(
          accounts.filter(account => account.id !== accountSelected.id),
        );
        setAccountSelected({ id: 0 });
        allAccounts();
        toast.success("¡Sesión cerrada con éxito!");
      }
    } catch (err) {
      toast.error("¡Ocurrió un error al cerrar sesión!");
    }
  };

  const handleEmailLogIn = () => {
    const login = emailLoginFunction[accountSelected.type];
    window.location.href = login();
  };

  useEffect(() => {
    accountSelected.id !== 0 && checkLoginMail(accountSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {accountSelected.id !== 0 && (
        <Container
          className={
            checkAccountSelected
              ? "container-account-selected"
              : "container-account-selected border-color-container"
          }
        >
          <div className="account-selected">
            <p className="account-selected-email">{accountSelected?.email}</p>
            <img
              alt="icon"
              className={getCssImage()}
              src={getImageEmail(accountSelected.type)}
            />
          </div>
          {checkAccountSelected ? (
            <Button
              className="account-selected-button"
              color="primary"
              onClick={() => handleLogout()}
            >
              Cerrar sesión
            </Button>
          ) : (
            <div className="account-log-out-container">
              <Button
                className="account-selected-button"
                color="primary"
                onClick={() => handleEmailLogIn()}
              >
                Iniciar sesión
              </Button>
              <p className="account-selected-email">
                La sesión ha expirado, inicie seción de nuevo.
              </p>
            </div>
          )}
        </Container>
      )}
      {accounts &&
        accounts.map(account => (
          <CardAccount
            key={account?.id}
            account={account}
            handleChange={handleChangeAccountSelected}
            isSelected={accountSelected?.id === account.id}
          />
        ))}
      {accounts?.length === 0 && (
        <div>
          <Notification className="no-results" textAlign="centered">
            <h2 className="no-results-text">
              Para Iniciar sesión presione &lsquo;Agregar&lsquo;
            </h2>
          </Notification>
        </div>
      )}
      <div className="container-add-new-account">
        <Button
          className="add-new-account"
          color="primary"
          onClick={() =>
            setModalOpen(
              true,
              <NewAccount flow={false} openModal={setModalOpen} />,
            )
          }
        >
          Agregar
        </Button>
      </div>
    </div>
  );
}

AccountManagement.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default AccountManagement;
