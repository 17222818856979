import { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_PRESENTATION_DATA } from "../graphql";

const useMHConfigData = props => {
  const [getMHConfigData, { data }] = useLazyQuery(GET_PRESENTATION_DATA, {
    fetchPolicy: "network-only",
  });
  const getMHData = useCallback(
    (
      Coin_code,
      Canton_id,
      District_id,
      Province_id,
      SellCondition_id,
      PaymentMethod_id,
    ) => {
      getMHConfigData({
        variables: {
          getMHPresentationDataInput: {
            ...(Coin_code && { Coin_code }),
            ...(Canton_id && { Canton_id }),
            ...(District_id && { District_id }),
            ...(SellCondition_id && { SellCondition_id }),
            ...(PaymentMethod_id && { PaymentMethod_id }),
            ...(Province_id && { Province_id }),
          },
        },
      });
    },
    [getMHConfigData],
  );
  return { data, getMHData };
};
useMHConfigData.propTypes = {};
export default useMHConfigData;
