import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// External components
import { Button, Title } from "rbx";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_CREDIT_NOTES } from "../../graphql";
// Components
import { CreditDebitCard } from "../../components";
import { LandingPage } from "../LandingPage";
// Helpers
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";

function CreditNotes() {
  const history = useHistory();
  const [activeNote, setActiveNote] = useState(false);
  const { id } = useParams();

  const { data: creditNotesData, loading: isLoading } = useQuery(
    GET_CREDIT_NOTES,
    {
      variables: {
        whereParams: [["FK_ReferencedElectronicBill", "=", id]],
      },
      fetchPolicy: "no-cache",
      context: {
        clientName: "apiEB",
      },
    },
  );

  const creditNotesSortedAscendant = sortArrayItemsByDate(
    creditNotesData?.getCreditNotesNew,
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "EmitedDay",
    },
  );
  useEffect(() => {
    const active = creditNotesData?.getCreditNotesNew?.find(
      item => item?.DebitNote === null,
    );
    setActiveNote(active);
  }, [creditNotesData]);

  return (
    <div className="creditNote-page">
      <h1 className="title text-center">Notas de crédito</h1>
      {isLoading && <LandingPage />}
      <div className="creditNote-page__row">
        {!activeNote && !isLoading && (
          <Button
            className="btn-animate"
            color="primary"
            onClick={() => history.push(`/electronic-bills/${id}/CreditNote`)}
          >
            Crear nueva
          </Button>
        )}
      </div>
      {creditNotesData?.getCreditNotesNew?.length > 0 ? (
        <div className="grid">
          {creditNotesSortedAscendant.map(item => (
            <CreditDebitCard
              key={item.id}
              actions={{
                text: "Crear nota de débito",
                onClick: () =>
                  history.push(
                    `/electronic-bills/${item.id}/DebitNote`, // debit
                  ),
              }}
              item={item}
            />
          ))}
        </div>
      ) : (
        !isLoading && (
          <div className="creditNote-page__message-no-results">
            <Title className="title-center">
              No se han encontrado notas de crédito
            </Title>
          </div>
        )
      )}
    </div>
  );
}
CreditNotes.propTypes = {};

export default CreditNotes;
