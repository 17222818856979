import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// External components
import { Button, Input, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Utils
import { customToast } from "../../utils";
// Styles
import "./ResetForgotPassword.scss";
import { useAuth } from "../../context";

const ResetForgotPassword = () => {
  const [password, setPassword] = useState("");
  const [passIsViible, setPassIsVisible] = useState(false);
  const queryParams = window.location?.pathname.split("token=");
  const token = queryParams[1];

  const history = useHistory();
  const [tokenDecrypt, setTokenDecrypt] = useState("");

  useEffect(() => {
    if (token) {
      // eslint-disable-next-line global-require
      const jwt = require("jwt-simple");
      setTokenDecrypt(
        jwt.decode(token, process.env.REACT_APP_FRONT_END_DECRYPT_KEY),
      );
    }
  }, [token]);
  const { changePassword, handleLoginWithCustomToken, handleLogout } =
    useAuth();

  const handleClick = async () => {
    // check token and change password
    if (!password) {
      return customToast.success("Por favor ingrese una contraseña");
    }
    if (password.length < 8) {
      return customToast.error("La contraseña debe ser de mínimo 8 dígitos");
    }
    const user = await handleLoginWithCustomToken(tokenDecrypt);
    if (user) {
      // change password
      const passwordUpdated = await changePassword(password);
      if (passwordUpdated) {
        customToast.success("Cambio de contraseña exitoso");
        history.push("/");
        return true;
      }
      // if password was not updated
      return handleLogout();
    }
    return customToast.error("Ha ocurrido un error");
  };
  return (
    <div className="principal-container reset-password">
      <div className="principal-container reset-password-container">
        <Title className="reset-password-title">Restablecer contraseña</Title>
        <div
          className={` places places__password-container ${
            passIsViible
              ? "places__password-container-off"
              : "places__password-container-on"
          }`}
        >
          <Input
            name="Gmail_App"
            placeholder="Contraseña de aplicación Gmail"
            type={passIsViible ? "text" : "password"}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <FontAwesomeIcon
            icon="eye"
            size="2x"
            style={{ color: "#FFF" }}
            onClick={() => setPassIsVisible(!passIsViible)}
          />
        </div>
        <br />
        <div className="landing-page-buttons">
          <Button
            rounded
            className="landing-page-button login-buttons"
            size="large"
            type="button"
            onClick={handleClick}
          >
            &nbsp;&nbsp;&nbsp;&nbsp; Cambiar &nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetForgotPassword;
