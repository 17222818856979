/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_ALL_DOCUMENT_TYPES = gql`
  query getAllDocumentTypes {
    documentTypes: getAllDocumentTypes {
      id
      id_MH
      Value
      Description
    }
  }
`;
