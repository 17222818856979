import PropTypes from "prop-types";
// External components
import { Field, Control, Checkbox as CCheckbox, Label } from "rbx";

const Checkbox = ({ className, label, onChange, defaultValue, name }) => (
  <Field className={className} kind="group">
    <Control expanded style={{ margin: "auto" }}>
      <Label textColor="grey-dark">
        <CCheckbox
          defaultValue={defaultValue}
          name={name}
          onChange={onChange}
        />{" "}
        {label}
      </Label>
    </Control>
  </Field>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  onChange: e => e,
  label: "",
  className: "",
};

export default Checkbox;
