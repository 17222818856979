import { useParams } from "react-router-dom";
import { useEffect } from "react";
// Components
import { Loading } from "../../components";
// Context
import { useAuth } from "../../context/AuthContext";

function ExternalLoginPage() {
  const { token, uid, id } = useParams();
  const { handleUserExternal } = useAuth();

  useEffect(() => {
    if (token) {
      handleUserExternal(token, uid, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <Loading />
    </div>
  );
}

export default ExternalLoginPage;
