/* eslint new-cap: ["error", { "newIsCap": false }] */

import React from "react";
// External components
import { Card, Image, Title, Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// External library
import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";
// Graphql
import { useApolloClient } from "@apollo/client";
import { GET_CLIENTS_BY_PLACE } from "../../graphql";
// Context
import { useAuth } from "../../context";
// utils
import { customToast as toast, downloadCSV } from "../../utils";
// SCSS
import "../Inventories/Inventories.scss";
import "./ClientListReport.scss";
import usePlaces from "../../hooks/usePlaces";

function ClientListReport() {
  const client = useApolloClient();
  const { state } = useAuth();
  const user = state.user.TavuelUser;

  const { GetPLacesByUserIdAndModuleId: getPLaces } = usePlaces();

  const [loading, placesData, error] = getPLaces(user?.id);

  const generatePDF = (clients, namePlace) => {
    const doc = new jsPDF();
    const tableRows = [];

    clients.forEach(index => {
      const ticketData = [
        index?.BillingProfile?.MH_PersonType?.Name_PersonType,
        index?.BillingProfile?.ID_Number,
        index?.BillingProfile?.Name,
        index?.BillingProfile?.Email,
        index?.BillingProfile?.PhoneNumber,
        `${
          index?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Province
            ?.Name
        },${index?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Name}, ${
          index?.BillingProfile?.MH_Neighborhood?.District?.Name
        },${index?.BillingProfile?.MH_Neighborhood?.Name}, ${
          index?.BillingProfile?.OtherSigns !== null
            ? index?.BillingProfile?.OtherSigns
            : ""
        }`,
      ];
      tableRows.push(ticketData);
    });

    doc.autoTable({
      columns: [
        "Tipo de identificación",
        "Identificación",
        "Nombre",
        "Correo electrónico",
        "Teléfono",
        "Dirección",
      ],
      body: tableRows,
      margin: { top: 20 },
      startY: 30,
      styles: { halign: "center" },
      headStyles: { fillColor: [1, 35, 76] },
      alternateRowStyles: { fillColor: [231, 215, 252] },
      tableLineColor: [1, 35, 76],
      tableLineWidth: 0.1,
    });

    doc.setFont("helvetica");
    doc.setFontSize(12);
    doc.text("Conta-Fast", 15, 15);
    doc.text("Lista de clientes", 15, 20);
    doc.text(`${namePlace}`, 15, 25);
    doc.text(`${format(new Date(), "yyyy-MM-dd")}`, 174, 15);

    doc.save(`clientes_${namePlace}.pdf`);

    toast.success("Reporte generado con éxito");
  };

  const handleGenerateReport = async (place, typeDocument) => {
    const { data } = await client.query({
      query: GET_CLIENTS_BY_PLACE,
      variables: {
        FK_Place: place?.id,
      },
      fetchPolicy: "no-cache",
    });

    if (data?.client?.length > 0) {
      // gerate report

      const filteredArr = data?.client.reduce((acc, current) => {
        const x = acc.find(
          item =>
            item.BillingProfile?.ID_Number ===
            current.BillingProfile?.ID_Number,
        );
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);

      if (typeDocument === 1) {
        generatePDF(filteredArr, place?.Name_Place);
      } else {
        const columns = [
          {
            Header: "MH_PersonType",
            id: "MH_PersonType",
            accessor: "index.BillingProfile.MH_PersonType.Name_PersonType",
          },
          {
            Header: "ID_Number",
            id: "ID_Number",
            accessor: "index.BillingProfile.ID_Number",
          },
          {
            Header: "Name",
            id: "Name",
            accessor: "index.BillingProfile.Name",
          },
          {
            Header: "Email",
            id: "Email",
            accessor: "index.BillingProfile.Email",
          },
          {
            Header: "PhoneNumber",
            id: "PhoneNumber",
            accessor: "index.BillingProfile.PhoneNumber",
          },
          {
            Header: "Province",
            id: "Province",
            accessor:
              "index.BillingProfile.MH_Neighborhood.District.Canton.Province.Name",
          },
          {
            Header: "Canton",
            id: "Canton",
            accessor:
              "index.BillingProfile.MH_Neighborhood.District.Canton.Name",
          },
          {
            Header: "District",
            id: "District",
            accessor: "index.BillingProfile.MH_Neighborhood.District.Name",
          },
          {
            Header: "MH_Neighborhood",
            id: "MH_Neighborhood",
            accessor: "index.BillingProfile.MH_Neighborhood.Name",
          },
          {
            Header: "OtherSigns",
            id: "OtherSigns",
            accessor: "index.BillingProfile.OtherSigns",
          },
        ];
        downloadCSV(data?.client, `clientes_${place?.Name_Place}`, columns);
      }
    } else {
      toast.success(`No hay clientes registrados para ${place?.Name_Place} `);
    }
  };

  const getUrl = placeItem => {
    const route = placeItem.Files?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const validateRoute = placeItem => {
    const route = getUrl(placeItem);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
    }
    return route;
  };

  return (
    <div>
      <div>
        <Title className="title-center"> Lugares </Title>
      </div>
      <div className="grid">
        {placesData?.places?.map(placeItem => (
          <Card key={placeItem.id} className="card-content">
            <div className="flex client-report">
              <div className="content-right">
                <div className="product-img ">
                  <Image.Container size="32px sq.">
                    <Image src={validateRoute(placeItem)} />
                  </Image.Container>
                </div>
              </div>
              <div className="title-product">
                <h3>{placeItem.Name_Place}</h3>
              </div>
            </div>
            <div className="download-buttons">
              <Icon>
                <FontAwesomeIcon icon="download" size="lg" />
              </Icon>
              <Button
                outlined
                className="button-download"
                color="primary"
                onClick={() => handleGenerateReport(placeItem, 2)}
              >
                CSV
              </Button>
              <Button
                outlined
                className="button-download"
                color="primary"
                onClick={() => handleGenerateReport(placeItem, 1)}
              >
                PDF
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default ClientListReport;
