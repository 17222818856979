import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Field, Label, Select } from "rbx";
import { useElectronic } from "../../context/ElectronicContext";

const TaxSelect = ({ className, disabled, value, name, onChange }) => {
  const { taxesData } = useElectronic();

  useEffect(() => {
    if (value !== undefined || !taxesData?.taxes) return;
    onChange(name, taxesData.taxes[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxesData, value]);
  return (
    <div>
      <Field className={className}>
        <Control expanded>
          <Label>Tipo de impuesto</Label>
          <Select.Container fullwidth>
            <Select
              disabled={disabled}
              name={name}
              value={value}
              onChange={e => onChange(e.target.name, e.target.value)}
            >
              {taxesData?.taxes?.map(tax => (
                <Select.Option key={tax.id} value={tax.id}>
                  {tax?.Description_Tax}
                </Select.Option>
              ))}
            </Select>
          </Select.Container>
        </Control>
      </Field>
    </div>
  );
};
TaxSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

TaxSelect.defaultProps = {
  disabled: false,
  className: "",
};

export default TaxSelect;
