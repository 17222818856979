/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// External components
import { Button, Container, Control, Field, Icon } from "rbx";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { BottomSheet } from "react-spring-bottom-sheet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useMutation } from "@apollo/client";
import { GET_PRODUCTS_FEATURE_BY_PLACE } from "../../graphql";
import { UPDATE_BILL_DETAIL } from "../../graphql/bill/bill.mutation";
// Components
import ProductCard from "../ProductCard";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Hooks
import useAsyncSearch from "../../hooks/useAsyncSearch";
// Utils
import { customToast as toast } from "../../utils";
import { validateRoute } from "../../utils/helpers";
// SCSS
import "react-spring-bottom-sheet/dist/style.css";
import "./ProductSelect.scss";
import { useAuth } from "../../context";

function ProductSelect({ goBack }) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [list, setList] = useState([]);
  const [height, setHeight] = React.useState(window.innerHeight);
  const { isSideBarOpen } = useAuth();

  const {
    lineDetail,
    setLineDetail,
    LineDetailPlace,
    electronicBill,
    useCoinDetail,
  } = useElectronic();
  const { currentSymbolCoin, toExchangeRate } = useCoinDetail;

  const { getResults, DebouncedLoad } = useAsyncSearch();
  const [saveDetail] = useMutation(UPDATE_BILL_DETAIL);

  const updateHeight = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  });

  useEffect(() => {
    setDefaultOptions([]);
  }, [LineDetailPlace.id]);

  const handleChange = newValue => {
    const options = list.filter(item => item.id === newValue.id);
    if (options.length > 0) {
      toast.error("El producto ya se encuentra en el detalle");
    } else {
      setList(prev => [...prev, newValue]);
    }
  };

  const getDetails = product => {
    let details = null;
    product.details?.forEach(item => {
      if (item?.Value_ProductFeatureDetail !== null) {
        details = !details
          ? item.Value_ProductFeatureDetail
          : `${details} / ${item.Value_ProductFeatureDetail}`;
      }
    });
    return details;
  };

  const CustomOption = value => {
    const { data } = value;
    const option = data;
    return (
      <components.Option {...value}>
        {option && (
          <div className="option-container option-item">
            <div className="option-content-left">
              <div className="product-header">
                <h3>{option.description}</h3>
                <h4>{getDetails(option)}</h4>
                <h2>
                  {currentSymbolCoin}
                  {toExchangeRate(option.unit_price).toFixed(3)}
                </h2>
              </div>
            </div>
            <div className="product-img-option ">
              <img alt="product-pic" src={validateRoute(option)} />
            </div>
          </div>
        )}
      </components.Option>
    );
  };

  const loadOptions = async inputValue => {
    const variables = {
      id: LineDetailPlace.id,
      criteria: inputValue,
      limit: 5,
    };

    const data = await getResults(GET_PRODUCTS_FEATURE_BY_PLACE, variables);

    const products = data?.products;

    const result = Array.isArray(products)
      ? products.map(item => {
          const { Details, Product, ProductPrice } = item;
          return {
            id: item.id,
            description: Product?.Description_Product,
            cabys: ProductPrice?.Cabys,
            Tariff: ProductPrice?.Tariff,
            unit_price: ProductPrice?.Price_ProductPrice.toFixed(3),
            quantity: 1,
            discount: [0],
            tax: ProductPrice?.Tax,
            taxes: [],
            totalTaxes: 0,
            total: ProductPrice?.Price_ProductPrice.toFixed(3),
            unit_Measure: item?.InventoryDetail?.UnitOfMeasurement,
            product_Code: "",
            url: item?.Files,
            details: Details,
            value: item.id,
            label: Product?.Description_Product,
          };
        })
      : [];

    setDefaultOptions(result);
    return result;
  };

  const debouncedLoad = DebouncedLoad(loadOptions, 1000);

  const handleSaveProductDetail = productDetail => {
    if (!electronicBill.id) return;
    saveDetail({
      variables: {
        id: electronicBill.id,
        detail: {
          id: productDetail.detail_id,
          FK_ProductFeature: productDetail.id,
          Quantity_BillDetail: productDetail.quantity,
          Total_BillDetail: productDetail.total,
          SubTotal_BillDetail: productDetail.unit_price,
        },
      },
    });
  };

  const handleAddProducts = () => {
    if (lineDetail.length > 0) {
      const newLineDetail = list.reduce((prev, item) => {
        const oldProductDetail = lineDetail.find(
          product => product.id === item.id,
        );
        if (oldProductDetail) {
          const newProductDetail = {
            ...oldProductDetail,
            quantity: item.quantity + oldProductDetail.quantity,
            total: (
              item.unit_price *
              (oldProductDetail.quantity + item.quantity)
            ).toFixed(3),
          };
          handleSaveProductDetail(newProductDetail);
          prev.push(newProductDetail);
          return prev;
        }
        handleSaveProductDetail(item);
        prev.push({ ...item });
        return prev;
      }, []);
      setLineDetail(prev => [
        ...prev.filter(
          detail =>
            !newLineDetail.map(_detail => _detail.id).includes(detail.id),
        ),
        ...newLineDetail,
      ]);
    } else {
      list.forEach(item => handleSaveProductDetail(item));
      setLineDetail(list);
    }
    goBack();
    setList([]);
  };

  const getTotal = () => {
    let total = 0.0;
    list.forEach(item => {
      total += parseFloat(item.total);
    });
    return `${currentSymbolCoin}${toExchangeRate(total).toFixed(3)}`;
  };

  return (
    <Container>
      <Field>
        <Control>
          <AsyncSelect
            key={LineDetailPlace.id}
            cacheOptions
            components={{ Option: CustomOption }}
            defaultOptions={defaultOptions}
            loadOptions={debouncedLoad}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            placeholder="Buscar..."
            styles={{
              menuPortal: provided => ({
                ...provided,
                zIndex: 9999,
              }),
              indicatorsContainer: provided => ({
                ...provided,
                height: "inherit",
              }),
              indicatorSeparator: provided => ({
                ...provided,
                display: "none",
              }),
              container: provided => ({
                fontSize: 13,
              }),
              singleValue: provided => ({
                ...provided,
                top: "38%",
              }),
              control: provided => ({
                ...provided,
                "minHeight": 30,
                "height": 36,
                "width": "100%",
                "minWidth": 180,
                "borderRadius": "4px",
                "border": "0.1rem solid #01234c",
                "&:hover": {
                  borderColor: "hsl(0, 0%, 90%)",
                  borderRadius: 4,
                  cursor: "pointer",
                },
              }),
              placeholder: provided => ({ ...provided, top: "38%" }),
              menu: provided => ({
                ...provided,
                fontSize: 12,
                zIndex: 999,
              }),
              menuList: provided => ({
                ...provided,
                display: "flex",
                flexWrap: "wrap",
                fontSize: 12,
                zIndex: 999,
                width: "auto",
              }),
              option: provided => ({
                width: "100%",
              }),
            }}
            onChange={handleChange}
          />
          <Button
            className={`add-button ${
              list.length === 0 ? "add-button--hidden" : ""
            }`}
            color="primary"
            onClick={handleAddProducts}
          >
            <h1 className="center-text">
              {list.length} Agregar {getTotal()}
            </h1>
          </Button>
        </Control>
      </Field>
      {list.length > 0 && (
        <BottomSheet
          open
          blocking={false}
          className="bottom-sheet"
          defaultSnap={() => 300}
          header={
            <div className="sheetHeader">
              <Icon className="icon-header" size="large">
                <FontAwesomeIcon icon="shopping-basket" size="2x" />
              </Icon>
              <div className="sheetHeader-quantity">
                <h4 className="quantity-text">{list.length}</h4>
              </div>
            </div>
          }
          snapPoints={() => [height * 0.05, height * 0.3, height * 0.8]}
        >
          <div className={isSideBarOpen && "open-sidebar"}>
            <ProductCard list={list} setDataTable={setList} />
          </div>
        </BottomSheet>
      )}
    </Container>
  );
}

ProductSelect.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default ProductSelect;
