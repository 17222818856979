import React from "react";
// External components
import { Title } from "rbx";
import { MenuExpenses } from "../../components";
// Components

const ReportsPage = () => (
  <div className="report-items">
    <Title className="text-center">Egresos</Title>
    <MenuExpenses />
  </div>
);
export default ReportsPage;
