import React from "react";
import { Switch, Route } from "react-router-dom";
import { ClientsGroupsXPlace, ClientsXGroup } from "../../components";
import ClientsGroupsPage from "./ClientsGroupsPage";

const ClientsGroupRouter = props => (
  <Switch>
    <Route path="/clients-groups/groups/:id/:name/clients/:id/:name/:placeId">
      <ClientsXGroup />
    </Route>
    <Route path="/clients-groups/groups/:id/:name">
      <ClientsGroupsXPlace />
    </Route>
    <Route path="/clients-groups">
      <ClientsGroupsPage />
    </Route>
  </Switch>
);

export default ClientsGroupRouter;
