/* eslint-disable no-param-reassign */
import React, { useState } from "react";
// External components
import { Label, Card, Container, Button } from "rbx";
import { CSVLink } from "react-csv";
// Components
import Loading from "../Loading";
import CustomDatePicker from "../CustomDatePicker";
import IvaReportSubsections from "../IvaReportSubsections/IvaReportSubsections";
import CoinSelector from "../CoinSelector";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Hook
import UseCreateReport from "../../hooks/UseCreateReport";
// Utils
import { d104Headers } from "../../utils/CSVHelper";
// SCSS
import "./SellsReport.scss";

function SellsReport() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const {
    dataToCSV,
    headersToCSV,
    handleGetElectronicBills,
    dataCSV,
    isLoadingBills,
    totals,
    electronicBills,
    services,
    merch,
  } = UseCreateReport();

  const { useCoinDetail } = useElectronic();
  const { currentSymbolCoin } = useCoinDetail;

  const handleSubmit = () => {
    handleGetElectronicBills(selectedDate, 2);
  };

  return (
    <Container>
      <Card className="CardConteiner mb-1">
        <div className="together-elements-container">
          <div>
            <Label>Escoge una fecha para generar el reporte:</Label>
            <CustomDatePicker
              dateFormat="MMMM / yyyy"
              selectedDate={selectedDate}
              onChange={date => setSelectedDate(date)}
            />
          </div>
          <div>
            <CoinSelector />
          </div>
        </div>
        <div className="Report-genered">
          <Button className="csvlink" onClick={handleSubmit}>
            Generar reporte
          </Button>
        </div>
      </Card>
      {isLoadingBills && <Loading />}

      <h1>Resumen de Ventas</h1>
      {electronicBills?.length !== 0 && !isLoadingBills && (
        <IvaReportSubsections
          summarizedData={{
            services,
            merch,
          }}
          symbolCoin={currentSymbolCoin}
          totals={totals}
        />
      )}

      {totals.TotalBill !== 0 ? (
        <div className="CSV-section">
          <Button className="csvlink" color="secondary">
            <CSVLink
              data={dataCSV}
              filename={`Reporte D-104 (${
                selectedDate.getMonth() + 1
              }-${selectedDate.getFullYear()})`}
              headers={d104Headers()}
              style={{
                color: "#01234c",
                cursor: "pointer",
              }}
              target="_blank"
            >
              Descargar CSV
            </CSVLink>
          </Button>
          <Button className="csvlink">
            <CSVLink
              data={dataToCSV}
              filename={`Lista de facturas (${
                selectedDate.getMonth() + 1
              }-${selectedDate.getFullYear()})`}
              headers={headersToCSV}
              style={{
                color: "#dbdbdb",
                cursor: "pointer",
                display: "block",
              }}
              target="_blank"
            >
              Descargar Lista de Facturas Electrónicas
            </CSVLink>
          </Button>
        </div>
      ) : (
        <Card className="Card-summary no-results">
          <h1>No se encontraron resultados</h1>
        </Card>
      )}
    </Container>
  );
}
export default SellsReport;
