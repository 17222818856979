const MAIN_ROUTES = [
  {
    name: "incomes",
    label: "Ingresos",
    icon: "file-upload",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /incomes/.test(p);
    },
    to: "/incomes",
  },
  {
    name: "expenses",
    label: "Egresos",
    icon: "file-download",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /expenses/.test(p);
    },
    to: "/expenses",
  },
  {
    name: "Facturación",
    icon: "cash-register",
    label: "Facturación electrónica",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /electronic-bills/.test(p);
    },
    links: [
      { name: "Factura Electrónica", to: "/electronic-bills" },
      { name: "Nota de Crédito/Débito", to: "/CreditDebitNote" },
      { name: "Factura Electrónica de Compra", to: "/buy-electronic-bills" },
      { name: "Factura Electrónica de Exportación", to: "/ExportationBill" },
      { name: "Proforma Electrónica", to: "/Proforma" },
      { name: "Buscar Documento", to: "/DocumentSearch" },
      { name: "Facturas Programadas", to: "/ScheduledBill" },
    ],
  },
  {
    name: "reports",
    label: "Reportes",
    icon: "file-invoice",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /reports/.test(p);
    },
    to: "/reports",
  },
  {
    name: "places",
    label: "Lugares",
    icon: "store",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /places/.test(p);
    },
    to: "/places",
  },
  {
    name: "products",
    label: "Productos",
    icon: "shopping-basket",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /product/.test(p);
    },
    to: "/products",
  },
  {
    name: "clients",
    label: "Clientes",
    icon: "users",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /clients/.test(p);
    },
    to: "/clients",
  },
  {
    name: "security",
    label: "Seguridad",
    icon: "lock",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /security/.test(p);
    },
    to: "/security",
  },
  {
    name: "profile",
    label: "Configuración",
    icon: "cogs",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /profile/.test(p);
    },
    to: "/profile",
  },
  {
    name: "notifications",
    label: "Notificaciones",
    icon: "bell",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /notifications/.test(p);
    },
    to: "/notifications",
  },
];

export default MAIN_ROUTES;
