/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from "react";
import { Link, Switch, Route, useLocation, useHistory } from "react-router-dom";
// External components
import { Tab, Container } from "rbx";
// Components
import { toast } from "react-toastify";
import { ExpensesManagement, DocumentsAcceptance, ExpensesUpload } from "..";
// Context
import { useAuth } from "../../context";
// SCSS
import "../../pages/IncomePage/IncomePage.scss";
import "../../styles/index.scss";
import "../../pages/ProfilePage/ProfilePage.scss";

function MenuExpenses() {
  const [toggleState, setToggleState] = useState(1);

  const location = useLocation();
  const history = useHistory();

  const { permissions } = useAuth();
  const { haveActionPermission, noAccessMessage } = permissions;
  const canRead = haveActionPermission("Read", "/expenses");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Egresos"));
    history.push(`/`);
  }

  useEffect(() => {
    const routes = [
      { route: "/expenses/upload-expenses", value: 1 },
      { route: "/expenses/documents-acceptance", value: 2 },
      { route: "/expenses/documents-management", value: 3 },
    ];
    setToggleState(
      routes.find(index => index.route === location.pathname)?.value || 1,
    );
  }, [history, location.pathname]);

  return (
    <React.Fragment>
      <div className="mb-5">
        <Tab.Group>
          <Tab
            as={Link}
            className={toggleState === 1 ? "active" : "tabs"}
            to="/expenses/upload-expenses"
          >
            Insertar egresos
          </Tab>
          <Tab
            as={Link}
            className={toggleState === 2 ? "active" : "tabs"}
            to="/expenses/documents-acceptance"
          >
            Aceptación documentos
          </Tab>
          <Tab
            as={Link}
            className={toggleState === 3 ? "active" : "tabs"}
            to="/expenses/documents-management"
          >
            Administración de egresos
          </Tab>
        </Tab.Group>
      </div>
      <Container fluid>
        <Switch>
          <Route path="/expenses/upload-expenses">
            <ExpensesUpload />
          </Route>
          <Route path="/expenses/documents-acceptance">
            <DocumentsAcceptance />
          </Route>
          <Route path="/expenses/documents-management">
            <ExpensesManagement />
          </Route>
          <Route path="/expenses">
            <ExpensesUpload />
          </Route>
        </Switch>
      </Container>
    </React.Fragment>
  );
}

export default MenuExpenses;
