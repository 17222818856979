import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
// External components
import { Button, Card, Image, Title } from "rbx";
// Graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_COMPANIES, REGISTER_NEW_COMPANY } from "../../graphql";
// Context
import { useAuth, useModal } from "../../context";
// Components
import CompanyRegister from "../CompanyRegister";
import PlacesRegister from "../PlacesRegister";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "../../styles/index.scss";
import "../Inventories/Inventories.scss";

function CompanySelect() {
  const [companySelected, setCompanySelected] = useState();

  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();
  const history = useHistory();

  const user = authState.user.TavuelUser;

  const [registerCompany] = useMutation(REGISTER_NEW_COMPANY);

  const {
    loading,
    data: getCompanyData,
    error,
  } = useQuery(GET_ALL_COMPANIES, {
    variables: {
      id: user?.id,
    },
  });

  const getUrl = companyItem => {
    const route = companyItem.Files?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const handleSelectCompany = company => {
    setCompanySelected(company);
  };

  const register = useCallback(
    async newCompany => {
      try {
        setModalOpen(false);
        const { data } = await registerCompany({
          variables: {
            company: newCompany,
          },
          refetchQueries: [
            {
              query: GET_ALL_COMPANIES,
              variables: {
                id: user?.id,
              },
            },
          ],
        });
        if (data) {
          toast.success("¡Compañía registrada con éxito!");
          history.push(
            `companies/place/${data?.company?.id}/${data?.company?.Name_Company}`,
          );
        }
      } catch (err) {
        toast.error("Error al registrar la compañía");
      }
    },
    [registerCompany, setModalOpen, history, user?.id],
  );

  const validateRoute = placeItem => {
    const route = getUrl(placeItem);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
    }
    return route;
  };

  const handleRegisterCompany = () => {
    setModalOpen(
      true,
      <CompanyRegister
        registerCompany={register}
        setCompanySelected={setCompanySelected}
        setModalOpen={setModalOpen}
      />,
    );
  };

  return (
    <div>
      {!companySelected && (
        <div>
          <div>
            <Title className="title-center"> Seleccione una compañía</Title>
          </div>
          <Button
            className="title-center mt-1 "
            color="primary"
            onClick={handleRegisterCompany}
          >
            Registrar Nueva Compañía
          </Button>
          <div className="grid">
            {getCompanyData?.companies?.map(companyItem => (
              <div
                key={companyItem?.id}
                className="hover-table-options principal-container"
                role="button"
                tabIndex={0}
                onClick={() => handleSelectCompany(companyItem)}
                onKeyDown={() => null}
              >
                <Card key={companyItem?.id} className=" card-content">
                  <div className="title-product">
                    <h2>{companyItem?.Name_Company}</h2>
                  </div>

                  <div className="flex">
                    <div className="content-right">
                      <div className="product-img ">
                        <Image.Container size="32px sq.">
                          <Image src={validateRoute(companyItem)} />
                        </Image.Container>
                      </div>
                    </div>

                    <div className="flex middle ">
                      <p className="detail-product ellipsis three-lines">
                        {companyItem?.Description_Company}{" "}
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      )}
      {companySelected && (
        <PlacesRegister
          companyId={companySelected?.id}
          companyName={companySelected?.Name_Company}
          setCompanySelected={setCompanySelected}
        />
      )}
    </div>
  );
}

CompanySelect.propTypes = {};

CompanySelect.defaultProps = {
  disabled: false,
};

export default CompanySelect;
