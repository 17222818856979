import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
// External components
import { Button } from "rbx";
// Components
import MultiSelect from "../MultiSelect";
// Context
import { useAuth } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Enums
import { ElectronicBillAcceptanceStates } from "../../Enums/ElectronicBill";
// Utils
import { formatCurrency } from "../../utils";
import { formatDateString } from "../../utils/helpers";

export default function ElectronicBillExpenseCard(props) {
  const {
    currentElectronicBill,
    initialElectronicBill,
    multiSelectData,
    actions,
  } = props;

  const { handleChange: handleChangeSelect, handleSaveEB } = actions;

  const { LineDetailPlace } = useElectronic();

  const { state: authState } = useAuth();

  const [emitedDayFormatedDate, emitedDayFormatedTime] = formatDateString(
    currentElectronicBill?.EmitedDay,
  );

  return (
    <div key={uuid()} className="card-content">
      <div className="body-card">
        <div className="content-invoice">
          <p>
            Emisor: &nbsp;&nbsp;
            <span className="bold-text">
              {currentElectronicBill?.Place?.Name_Place}{" "}
            </span>
          </p>
        </div>
        <p>
          Receptor: &nbsp;&nbsp;
          <span className="bold-text">
            &nbsp;
            {LineDetailPlace?.Company?.Name_Company}&nbsp;
          </span>
          &nbsp;
        </p>
        <p>
          Cédula Receptor: &nbsp;&nbsp;
          <span className="bold-text">
            {authState.user.TavuelUser.Person.DocumentId_Person}
          </span>
        </p>
        <p>
          Moneda: &nbsp;
          <span className="bold-text">
            {currentElectronicBill?.Coin?.Name_Coin}&nbsp;
          </span>
          &nbsp;
        </p>
        <p>
          Estado de aceptación:&nbsp;
          {initialElectronicBill.FK_AcceptanceState ===
            ElectronicBillAcceptanceStates.ACCEPTED ||
          initialElectronicBill.FK_AcceptanceState ===
            ElectronicBillAcceptanceStates.REJECTED ? (
            <span className="bold-text">
              {
                multiSelectData[
                  initialElectronicBill.FK_AcceptanceState
                    ? initialElectronicBill.FK_AcceptanceState - 1
                    : 0
                ].Name
              }
            </span>
          ) : (
            <MultiSelect
              data={
                multiSelectData &&
                multiSelectData.map(multiSelectItem => ({
                  ...multiSelectItem,
                  initialElectronicBillAcceptanceState:
                    initialElectronicBill.FK_AcceptanceState,
                }))
              }
              name="is_accepted"
              value={
                currentElectronicBill.FK_AcceptanceState ??
                ElectronicBillAcceptanceStates.ACCEPTED
              }
              onChange={(name, value) =>
                handleChangeSelect(name, value, currentElectronicBill.id)
              }
            />
          )}
        </p>
        <p>
          Total: &nbsp;
          <span className="bold-text">
            {formatCurrency(
              parseFloat(currentElectronicBill.TotalBill, 10),
              currentElectronicBill?.Coin?.Code_Coin
                ? currentElectronicBill?.Coin?.Code_Coin
                : "CRC",
            )}
          </span>
        </p>
        <div className="content-invoice">
          <p className="text-centered">
            Fecha: &nbsp;&nbsp;
            <span className="bold-text">
              {emitedDayFormatedDate} - {emitedDayFormatedTime}
            </span>
          </p>
          <Button
            outlined
            className="btn-animate"
            color="primary"
            invisible={
              initialElectronicBill.FK_AcceptanceState ===
                ElectronicBillAcceptanceStates.ACCEPTED ||
              initialElectronicBill.FK_AcceptanceState ===
                ElectronicBillAcceptanceStates.REJECTED ||
              (initialElectronicBill.FK_AcceptanceState ===
                ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED &&
                currentElectronicBill.FK_AcceptanceState ===
                  ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED)
            }
            onClick={() => {
              handleSaveEB(currentElectronicBill);
            }}
          >
            Guardar
          </Button>
        </div>
      </div>
    </div>
  );
}

ElectronicBillExpenseCard.propTypes = {
  currentElectronicBill: PropTypes.array.isRequired,
  initialElectronicBill: PropTypes.array.isRequired,
  multiSelectData: PropTypes.array.isRequired,
  actions: PropTypes.object,
};
ElectronicBillExpenseCard.defaultProps = {
  actions: {},
};
