import React from "react";
import { Link } from "react-router-dom";
// External components
import { Icon, Card } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// SCSS
import "./HomePage.scss";
import { useAuth } from "../../context";
import MAIN_ROUTES from "../../components/Sidebar/routes/main";

const HomePage = () => {
  const { permissions } = useAuth();
  const { isSuperAdminOrHaveAccess } = permissions;

  return (
    <div className="grid">
      {MAIN_ROUTES.map(
        r =>
          r.to !== "/profile" &&
          isSuperAdminOrHaveAccess(r.to ? r.to : "/electronic-bills") && (
            <Card
              key={r.label}
              as={Link}
              className="home-card"
              color="primary"
              to={r.to ?? "/electronic-bills"}
            >
              <Icon>
                <FontAwesomeIcon className="home-icon" icon={r.icon} />
              </Icon>
              <p>{r.label}</p>
            </Card>
          ),
      )}

      <Card as={Link} className="home-card" color="primary" to="/profile">
        <Icon>
          <FontAwesomeIcon className="home-icon" icon="cogs" />
        </Icon>
        <p>Configuración</p>
      </Card>
    </div>
  );
};

export default HomePage;
