import React, { useCallback } from "react";
import PropTypes from "prop-types";
// External components
import { Container, Image } from "rbx";
// Components
import Inventories from "../../../Inventories";
import AccountManagement from "../AccountManagement";
// Hook
import useZoho from "../../../../hooks/useEmail";
// Context
import { useModal } from "../../../../context";
// Utils
import getImageEmail from "../../../../utils/GetImageEmail";
import { customToast as toast } from "../../../../utils";
// SCSS
import "./CardAccount.scss";

const CardAccount = ({ account, isSelected, handleChange }) => {
  const { updatePlaceTokenEmail } = useZoho();

  const { setModalOpen } = useModal();

  const updateAccount = useCallback(async () => {
    const selectPlace = async (FK_Place, bill) => {
      setModalOpen(false);
      const response = await updatePlaceTokenEmail(account?.id, FK_Place);
      if (!response?.data?.email) {
        toast.error("Error al actualizar la cuenta");
        return;
      }
      toast.success("¡Cuenta actualizada!");
      setModalOpen(true, <AccountManagement openModal={setModalOpen} />);
    };
    try {
      setModalOpen(
        true,
        <Inventories
          setModalClose={setModalOpen}
          onClickFunction={selectPlace}
        />,
        "No se seleccionó un nuevo lugar para poder realizar el cambio del lugar.",
        true,
      );
    } catch (err) {
      toast.error(err);
    }
  }, [account?.id, setModalOpen, updatePlaceTokenEmail]);

  const getCssImage = () => {
    const namesCss = {
      1: "img-zoho",
      2: "img-gmail",
      3: "img-outlook",
    };
    return namesCss[account?.type];
  };

  return (
    <div
      className={
        isSelected ? "account-card-selected account-card" : "account-card"
      }
    >
      <Container
        className="account-card-container"
        onClick={() => !isSelected && handleChange(account)}
      >
        <img
          alt="icon"
          className={getCssImage()}
          src={getImageEmail(account?.type)}
        />
        <div className="information-email-container">
          <div className="account-email-container">
            <p>{account?.email}</p>
          </div>
        </div>
      </Container>
      <Container
        className={
          account?.place
            ? "information-place-container"
            : "information-place-none"
        }
        onClick={() => updateAccount()}
      >
        <Image.Container size={48}>
          <Image src={account?.place?.Logo} />
        </Image.Container>
        <p>{account?.place?.Name_Place}</p>
      </Container>
    </div>
  );
};

CardAccount.propTypes = {
  account: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

CardAccount.defaultProps = {
  isSelected: false,
};

export default CardAccount;
