import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

const currencies = [
  {
    name: "USD",
    location: "en-US",
  },
  {
    name: "CRC",
    location: "es-CR",
  },
  {
    name: "EUR",
    location: "de-DE",
  },
];

export const formatCurrency = (value, currency) => {
  if (value) {
    const usedCurrency = currencies.find(c => c.name === currency);

    return new Intl.NumberFormat(
      usedCurrency ? usedCurrency.location : {},
      usedCurrency
        ? {
            style: "currency",
            currency: usedCurrency.name,
          }
        : {},
    ).format(value);
  }
  return "0,00";
};
export const customToast = {
  success: (message, options) => {
    toast.success(message, {
      autoClose: 3000,
      pauseOnHover: false,
      ...options,
    });
  },
  error: (message, options) => {
    toast.error(message, { autoClose: 4000, ...options });
  },
  warning: (message, options) => {
    toast.warning(message, { autoClose: 4000, ...options });
  },
};

export const unmaskCurrency = (maskedValue = "") =>
  parseFloat(maskedValue.replace(/\D/g, "") || 0, 10) / 100;

export const encrypt = data =>
  CryptoJS.AES.encrypt(
    data,
    process.env.REACT_APP_FRONT_END_DECRYPT_KEY,
  ).toString();

export const decrypt = data =>
  CryptoJS.AES.decrypt(
    data,
    process.env.REACT_APP_FRONT_END_DECRYPT_KEY,
  ).toString(CryptoJS.enc.Utf8);

export const itsIntegerInput = e => {
  const valuesToInclude = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Home",
    "End",
    "Delete",
    "Enter",
  ];
  if (
    (e.nativeEvent.data && !valuesToInclude.includes(e.nativeEvent.data)) ||
    (e.nativeEvent.key && !valuesToInclude.includes(e.nativeEvent.key))
  ) {
    e.preventDefault();
  }
};

export const flattenObject = (obj, prefix = "") =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : "";
    if (obj[k] !== null && typeof obj[k] === "object")
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

export const parseQueryResultWithColumns = (x, columns) => {
  const obj = flattenObject(x, "index");
  return columns.reduce((acc, curr) => {
    if (
      obj[`${curr.accessor}`] === null ||
      typeof obj[`${curr.accessor}`] === "undefined" ||
      (typeof obj[`${curr.accessor}`] === "string" &&
        !obj[`${curr.accessor}`].length)
    ) {
      acc[curr.Header] = "";
    } else if (curr.Cell) {
      const result = curr.Cell({
        cell: { value: obj[`${curr.accessor}`], row: { original: x } },
      });

      acc[curr.Header] =
        // if Cell fn returns a react element
        result && typeof result.type === "function"
          ? result.props.value
          : result;
    } else if (
      typeof obj[`${curr.accessor}`] === "string" &&
      obj[`${curr.accessor}`].includes(",")
    ) {
      acc[curr.Header] = String(obj[`${curr.accessor}`]).replace(/"/gi, "''"); // .replace(/,/gi, " ");
    } else {
      acc[curr.Header] = obj[`${curr.accessor}`];
    }
    return acc;
  }, {});
};

export const download = (filename, type, content) => {
  const el = document.createElement("a");
  el.setAttribute(
    "href",
    `data:text/${type};charset=utf-8,${encodeURIComponent(content)}`,
  );
  el.setAttribute("download", `${filename}`);
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};
export const generateCSV = (headers, data) =>
  `${headers.join(",")}\n${data
    .map(d =>
      headers
        .map(h =>
          h.toLowerCase().includes("zip")
            ? `"=""${d[h]}"""`
            : JSON.stringify(d[h]),
        )
        .join(","),
    )
    .join("\n")}`;

export const downloadCSV = async (data, dataName, columns) => {
  const items = data.Payload || data;
  const result =
    items && items.map(x => parseQueryResultWithColumns(x, columns));
  if (result && result[0]) {
    const CSV = generateCSV(Object.keys(result[0]), result);
    return download(`${dataName}.csv`, "csv", CSV);
  }
  toast.error("No Data to Export");
  return null;
};

export const combine = (obj1, obj2) => {
  if (
    obj1 == null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    Array.isArray(obj1) !== Array.isArray(obj2)
  )
    return obj2;
  if (Array.isArray(obj1)) return [...obj1, ...obj2];
  return Object.keys(obj2).reduce(
    (result, key) => ({
      ...result,
      [key]:
        typeof obj2[key] === "object" &&
        !(obj2[key] instanceof File) &&
        !(obj2[key] instanceof Blob)
          ? combine(obj1[key], obj2[key])
          : obj2[key],
    }),
    { ...obj1 },
  );
};

export const validateEmail = email =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const validateIdNumber = (allClientss = [], client) =>
  allClientss.filter(
    clientIteam =>
      client.ID_Number === clientIteam?.BillingProfile?.ID_Number &&
      clientIteam?.BillingProfile?.User?.id !== client.FK_User,
  ).length > 0;

export * from "./ReportUtils";

export const sortBy = (array, property) => {
  const comparison = (a, b, prop) => {
    if (a?.[prop]?.toLowerCase() < b?.[prop]?.toLowerCase()) {
      return -1;
    }
    if (a?.[prop]?.toLowerCase() > b?.[prop]?.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const test = [...array];
  const sortedArray = test?.sort((a, b) => comparison(a, b, property)) || array;
  return sortedArray;
};

export const chunk = (array, size) => {
  if (!array) return [];
  const firstChunk = array.slice(0, size);
  if (!firstChunk.length) {
    return array;
  }
  return [firstChunk].concat(chunk(array.slice(size, array.length), size));
};
