export const normalizeEBillDetails = bill => {
  const temp = { ...bill };
  temp.Bill.TotalBill = bill.Bill.Total_Bill;
  temp.details = bill.Bill.Detail.map(detail => {
    const buffer = { ...detail, Quantity: detail.Quantity_BillDetail };
    delete buffer.Quantity_BillDetail;
    return buffer;
  });
  delete temp.ElectronicBillDetail;
  delete temp.Bill.Total_Bill;
  return temp;
};
export const a = "";
