import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// External components
import { Box } from "rbx";
// components
import EBResumeCard from "../EBResumeCard";
// SCSS
import "./DocumentSearch.scss";
import { EBTypeToTypesEnum } from "../../utils/electronicBill";

function DocumentSearch({ electronicBills, isLoading }) {
  const history = useHistory();

  return (
    <Box className="document-search">
      <div style={{ display: electronicBills ? "" : "none" }}>
        {electronicBills && electronicBills?.Ebills?.length === 0 && (
          <h1>No se han encontrado registros</h1>
        )}
        <div className="grid">
          {electronicBills?.Ebills?.map(item => (
            <EBResumeCard
              key={item.id}
              actions={[
                {
                  isIcon: false,
                  icon: "eye",
                  text: "ver documento",
                  onClick: () =>
                    history.push(
                      `/ElectronicBillView/${item.id}/view/${EBTypeToTypesEnum(
                        item.ElectronicBillDocumentType.Description,
                      )}`,
                    ),
                },
              ]}
              item={item}
            />
          ))}
        </div>
      </div>
      {isLoading && (
        <div>
          <h1>Cargando...</h1>
        </div>
      )}
    </Box>
  );
}

DocumentSearch.propTypes = {
  electronicBills: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool.isRequired,
};

DocumentSearch.defaultProps = {
  electronicBills: null,
};

export default DocumentSearch;
