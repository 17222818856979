import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// External components
import { Button, Card, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Utils
import { formatCurrency } from "../../utils";
// SCSS
import "./BillResumeCard.scss";

function BillResumeCard({ bill, handleDeleteDocument }) {
  const history = useHistory();

  return (
    <Card key={bill?.id} className="card-content">
      <div className="delete-document-container">
        <Button
          outlined
          className="btn-animate"
          color="danger"
          onClick={() => handleDeleteDocument(bill?.id)}
        >
          <Icon size="small">
            <FontAwesomeIcon icon="trash-alt" />
          </Icon>
        </Button>
      </div>
      <div className="content-invoice">
        <p>
          Emisor: &nbsp;{" "}
          <span className="bold-text">{bill.Place.Name_Place || "--"} </span>
        </p>
      </div>
      <p>
        Receptor: &nbsp;{" "}
        <span className="bold-text">
          {" "}
          {bill.User?.Person?.Name_Person || "No seleccionado"}{" "}
        </span>{" "}
      </p>
      <p>
        Cédula Receptor: &nbsp;{" "}
        <span className="bold-text">
          {bill.User?.Person?.DocumentId_Person || "--"}
        </span>
      </p>
      <p>
        Moneda:&nbsp;{" "}
        <span className="bold-text">{bill?.Coin?.Name_Coin || "--"} </span>{" "}
      </p>
      <p>
        Total: &nbsp;
        <span className="bold-text">
          {formatCurrency(
            parseFloat(bill.Total_Bill, 10),
            bill?.Coin?.Code_Coin ? bill?.Coin?.Code_Coin : "CRC",
          )}
        </span>
      </p>
      <div className="button-container">
        <Button
          outlined
          className="btn-animate"
          color="primary"
          onClick={() => history.push(`/electronic-bills/${bill.id}/Draft`)}
        >
          Ver documento
        </Button>
      </div>
    </Card>
  );
}

BillResumeCard.propTypes = {
  bill: PropTypes.object.isRequired,
  handleDeleteDocument: PropTypes.func,
};

BillResumeCard.defaultProps = {
  handleDeleteDocument: e => e,
};

export default BillResumeCard;
