import React from "react";
import PropTypes from "prop-types";
// External components
import { Icon, Card, Label } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// SCSS
import "./SummaryExpenses.scss";

function SummaryExpenses({
  amount,
  expense,
  discount,
  setInfoSummary,
  id,
  iva,
  symbolCoin,
  quantity,
  measurmentUnit,
  total,
  subTotal,
}) {
  const handleDelete = () => {
    setInfoSummary(prev => prev.filter(i => i.id !== id));
  };

  return (
    <Card className="expenses-summary">
      <div className="head-card-expense">
        <div className="card-head-title">
          <p className="title-card-expense">{expense}</p>
        </div>
        <div className="card-head-delete">
          <Icon className="icon-delete" size="small" onClick={handleDelete}>
            <FontAwesomeIcon
              className="font-icon-delete"
              icon="trash-alt"
              size="sm"
            />
          </Icon>
        </div>
      </div>
      <div className="card-expense-body m-1">
        <div className="">
          <p>Precio unitario:</p>
          <Label className="card-space-amount">
            {symbolCoin}
            {parseFloat(amount).toFixed(3)}
          </Label>
        </div>

        <div className="">
          <p>Cantidad:</p>
          <Label className="card-space-amount">
            {`${quantity} ${measurmentUnit.Symbol}`}
          </Label>
        </div>
        <div className="">
          <p>Descuento Total:</p>
          <Label className="card-space-amount">
            {symbolCoin}

            {parseFloat(discount).toFixed(3)}
          </Label>
        </div>

        <div className="">
          <p>IVA:</p>
          <Label className="card-space-amount">
            {symbolCoin}
            {iva}
          </Label>
        </div>
        <div className="">
          <p>SubTotal</p>
          <Label className="card-space-amount">
            {symbolCoin}
            {parseFloat(subTotal).toFixed(3)}
          </Label>
        </div>
        <div className="">
          <p>Total:</p>
          <Label className="card-space-total-amount">
            {symbolCoin}
            {total.toFixed(3)}
          </Label>
        </div>
      </div>
    </Card>
  );
}

SummaryExpenses.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  expense: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  iva: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  symbolCoin: PropTypes.string.isRequired,
  setInfoSummary: PropTypes.func,
  quantity: PropTypes.number.isRequired,
  measurmentUnit: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  subTotal: PropTypes.number.isRequired,
};

SummaryExpenses.defaultProps = {
  setInfoSummary: e => e,
};

export default SummaryExpenses;
