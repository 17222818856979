import PropTypes from "prop-types";
import { useMemo } from "react";
import ReactSelect, { components } from "react-select";

function SelectComponent({ getIcon, options, selected, onChange }) {
  const selectMaxWidth = 100;
  const selectMinWidth = 90;

  // eslint-disable-next-line react/prop-types
  const Control = ({ children, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Control {...props}>
      {getIcon && (
        <img
          alt="Icon"
          className="control-icon"
          src={getIcon(selected?.label?.toLowerCase() || "crc")}
        />
      )}
      {children}
    </components.Control>
  );
  // eslint-disable-next-line react/prop-types
  const Option = ({ children, data, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props} data>
      {getIcon && (
        <img
          alt="Icon"
          className="option-icon"
          // eslint-disable-next-line react/prop-types
          src={getIcon(data.label?.toLowerCase() || "crc")}
        />
      )}
      {children}
    </components.Option>
  );

  const customStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#d9d9d9" : "white",
        color: state.data.color,
      }),
      control: provided => ({
        ...provided,
        maxWidth: selectMaxWidth,
        minWidth: selectMinWidth,
        height: 46,
        fontSize: 20,
        borderRadius: 7,
        border: "2px solid #d9d9d9",
        backgroundColor: "#f4f4f4",
      }),
      menu: provided => ({
        ...provided,
        maxWidth: selectMaxWidth,
        minWidth: selectMinWidth,
      }),
    }),
    [],
  );

  return (
    <ReactSelect
      components={{
        DropdownIndicator: null,
        Control,
        Option,
      }}
      defaultValue={selected}
      options={options}
      styles={customStyles}
      onChange={onChange}
    />
  );
}

SelectComponent.propTypes = {
  getIcon: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.object,
  onChange: PropTypes.func,
};

SelectComponent.defaultProps = {
  getIcon: null,
  options: [],
  selected: null,
  onChange: null,
};

export default SelectComponent;
