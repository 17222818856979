import React from "react";
import PropTypes from "prop-types";
// External components
import { Button, Control, Field, Input, Title } from "rbx";
// Assets
import iconGoogle from "../../assets/googleW.svg";
// Styles
import "./Login.scss";

const Login = ({
  handleChange,
  handleGoogle,
  setLoginSection,
  loginSection,
  openForgotPassword,
}) => (
  <div>
    <Title className="landing-page-title">Iniciar sesión</Title>

    <div className="landing-page-inputs">
      <Field>
        <Control>
          <Input
            name="email"
            placeholder="Correo electrónico"
            size="large"
            type="test"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Input
            name="password"
            placeholder="Contraseña"
            size="large"
            type="password"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Button
            className="button-forgot"
            type="button"
            onClick={openForgotPassword}
          >
            <br />
            <p>Olvidé mi contraseña</p>
          </Button>
        </Control>
      </Field>
    </div>
    <br />
    <div className="landing-page-buttons">
      <Button
        rounded
        className="landing-page-button login-buttons"
        size="large"
        type="submit"
      >
        &nbsp;&nbsp;&nbsp;&nbsp; INICIAR &nbsp;&nbsp;&nbsp;&nbsp;
      </Button>
    </div>
    <div className="landing-page-buttons">
      <Button
        className="landing-page-google  btn-animate login-buttons"
        type="button"
        onClick={handleGoogle}
      >
        <img alt="Logo Google" className="landing-page-img" src={iconGoogle} />
      </Button>
    </div>

    <div className="landing-page-footer">
      <p>¿Aún no tienes una cuenta?</p>
      <div className="landing-page-buttons">
        <Button
          outlined
          rounded
          className="landing-page-button-secondary login-buttons"
          type="button"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setLoginSection(!loginSection);
          }}
        >
          &nbsp;&nbsp; REGÍSTRATE GRATIS &nbsp;&nbsp;
        </Button>
      </div>
    </div>
  </div>
);

Login.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleGoogle: PropTypes.func.isRequired,
  setLoginSection: PropTypes.func.isRequired,
  loginSection: PropTypes.bool.isRequired,
  openForgotPassword: PropTypes.func.isRequired,
};

export default Login;
