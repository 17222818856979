import { Card, Container, Control, Input, Select } from "rbx";
import { React, useState } from "react";
import PropTypes from "prop-types";
// Graphql
import { client_EB_api } from "../../graphql/client";
import { GET_ALL_REFERENCE_TYPE } from "../../graphql";
// SCSS
import "./NotesForm.scss";

function NotesForm({ reason_name, code_name, disabled, noteInfo, onChange }) {
  const [referencesList, setReferencesList] = useState([]);

  const handleLoad = async event => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_REFERENCE_TYPE,
    });
    setReferencesList(data.allReferenceType);
  };

  const handleChange = (variable_name, value) => {
    onChange(e => ({ ...e, [variable_name]: value }));
  };

  return (
    <Container>
      <Card className="card-summary secondary-content payment-container">
        <div className="card-secondary-content-title   ">
          <div className="card-total-width">
            <h3>Detalles de la Nota</h3>
          </div>
        </div>
        <div className="form-body content-input">
          <Control>
            <Select.Container fullwidth>
              <Select
                className="selector"
                disabled={disabled}
                name={code_name}
                value={noteInfo[code_name]}
                onChange={e => handleChange(e.target.name, e.target.value)}
                onFocus={e => handleLoad(e)}
              >
                {referencesList?.map(referenceType => (
                  <Select.Option
                    key={referenceType.id}
                    value={referenceType.id}
                  >
                    {referenceType.Document_Type}
                  </Select.Option>
                ))}
                {referencesList.length === 0 && (
                  <Select.Option key="default" value="default">
                    Seleccione el Codigo
                  </Select.Option>
                )}
              </Select>
            </Select.Container>
          </Control>
          <Input
            disabled={disabled}
            name={reason_name}
            placeholder="reason"
            value={noteInfo[reason_name]}
            onChange={e => handleChange(e.target.name, e.target.value)}
          />
        </div>
      </Card>
    </Container>
  );
}

NotesForm.propTypes = {
  reason_name: PropTypes.string.isRequired,
  code_name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  noteInfo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
NotesForm.defaultProps = {
  disabled: false,
};

export default NotesForm;
