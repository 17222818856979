import React from "react";
import PropTypes from "prop-types";
import "./Badge.scss";

function Badge({ children, value }) {
  return (
    <div className="badged-icon-container">
      {children}
      <div className="icon-badge">{value}</div>
    </div>
  );
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

export default Badge;
