import { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
// GraphQL
import { client_EB_api } from "../graphql/client";
import {
  CREATE_NOTIFICATION,
  GET_NOTIFICATIONS_BY_PLACE_AND_USER,
  SAVE_NOTIFICATION_USER,
} from "../graphql";
// Context
import { useElectronic } from "./ElectronicContext";
import { useAuth } from "./AuthContext";

export const NotificationsContext = createContext([]);

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { LineDetailPlace } = useElectronic();
  const { state: auth } = useAuth();

  const createNotification = async (title, body, icon, clickAction) => {
    await client_EB_api.mutate({
      mutation: CREATE_NOTIFICATION,
      variables: {
        place: LineDetailPlace.id,
        data: JSON.stringify({ title, body, icon, clickAction }),
        user: auth.user.TavuelUser.id,
        module: 11,
      },
    });
  };

  const saveNotificationsUser = async notisUser => {
    try {
      notisUser.forEach(async (element, i) => {
        await client_EB_api.mutate({
          mutation: SAVE_NOTIFICATION_USER,
          variables: {
            ...notisUser[i],
          },
        });
      });
      return "Notifications save finished";
    } catch (error) {
      return null;
    }
  };

  const refetchNotifications = async () => {
    const { data } = await client_EB_api.query({
      query: GET_NOTIFICATIONS_BY_PLACE_AND_USER,
      variables: {
        place: LineDetailPlace.id,
        user: auth.user.TavuelUser.id,
        module: 11,
      },
      fetchPolicy: "no-cache",
    });
    return data.notifications;
  };

  const getNotificationsToOpen = notis =>
    notis.filter(noti => !noti.Notification_User[0].Readed_At);

  const openNotifications = async notis => {
    const notificationsUser = getNotificationsToOpen(notis).reduce(
      (acc, noti) => [
        ...acc,
        ...noti.Notification_User.map(notiUser => ({
          ...notiUser,
          Readed_At: new Date().toISOString().slice(0, 19).replace("T", " "),
        })),
      ],
      [],
    );
    const resp = await saveNotificationsUser(notificationsUser);
    return resp;
  };

  const closedNotifications = useMemo(
    () =>
      notifications.filter(noti => !noti.Notification_User[0].Readed_At).length,
    [notifications],
  );

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        getNotificationsToOpen,
        refetchNotifications,
        createNotification,
        openNotifications,
        saveNotificationsUser,
        isLoading,
        setIsLoading,
        closedNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNotifications = () => useContext(NotificationsContext);

export default NotificationsProvider;
