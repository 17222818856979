import React from "react";
import PropTypes from "prop-types";
// External components
import { Image } from "rbx";
// Components
import Inventories from "../Inventories";
// Context
import { useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { validateRoute } from "../../utils/helpers";
// SCSS
import "./PlaceSelector.scss";

function PlaceSelector({ className }) {
  const { LineDetailPlace } = useElectronic();
  const { setModalOpen } = useModal();

  return (
    <div
      className={`place-selector ${className}`}
      role="button"
      tabIndex="-1"
      onClick={() =>
        setModalOpen(
          true,
          <div className="place-selector__modal-container">
            <Inventories setModalClose={setModalOpen} />{" "}
          </div>,
        )
      }
      onKeyDown={() =>
        setModalOpen(
          true,
          <div className="place-selector__modal-container">
            <Inventories setModalClose={setModalOpen} />{" "}
          </div>,
        )
      }
    >
      {LineDetailPlace.id !== 0 && (
        <Image.Container size={32}>
          <Image
            rounded
            className="place-selector__image"
            src={validateRoute(LineDetailPlace)}
          />
        </Image.Container>
      )}
      <h1>{LineDetailPlace?.Name_Place || "Sin Seleccionar"}</h1>
    </div>
  );
}

PlaceSelector.propTypes = {
  className: PropTypes.string,
};
PlaceSelector.defaultProps = {
  className: "",
};
export default PlaceSelector;
