import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// External components
import { toast } from "react-toastify";
import { Card, Control, Field, Input, Title, Button } from "rbx";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { GET_PRODUCTS_BY_PLACE_AND_CRITERIA } from "../../graphql";
// Components
import Inventories from "../Inventories";
import { CardProduct, NewOrEditProduct } from "./components";
import Loading from "../Loading";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./Products.scss";

function Products() {
  const [search, setSearch] = useState("");

  const { permissions } = useAuth();
  const history = useHistory();

  const { haveActionPermission, noAccessMessage } = permissions;

  const { LineDetailPlace } = useElectronic();
  const { setModalOpen } = useModal();

  const canRead = haveActionPermission("Read", "/products");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Productos"));
    history.push(`/`);
  }

  const [
    getProductsByPlaceAndCriteria,
    { data: productsByPlaceAndCriteria, loading },
  ] = useLazyQuery(GET_PRODUCTS_BY_PLACE_AND_CRITERIA);

  useEffect(() => {
    getProductsByPlaceAndCriteria({
      variables: {
        id: LineDetailPlace.id,
        criteria: search,
      },
    });
  }, [LineDetailPlace.id, getProductsByPlaceAndCriteria, search]);

  const getDates = async value => {
    getProductsByPlaceAndCriteria({
      variables: {
        id: LineDetailPlace.id,
        criteria: value,
      },
    });
  };

  const handleChange = (name, value) => {
    setSearch(value);
    value !== "" && getDates(value);
  };

  const handleNewProduct = () => {
    // if (!haveActionPermission("Edit", "/products")) return;
    setModalOpen(
      true,
      <NewOrEditProduct
        product={null}
        setCloseModal={setModalOpen}
        title="Nuevo Producto"
      />,
    );
  };

  const refetch = {
    id: LineDetailPlace.id,
    criteria: search,
  };

  return (
    <div>
      {LineDetailPlace.id === 0 && <Inventories />}
      {LineDetailPlace.id !== 0 && (
        <div>
          <div className="headerButton">
            {haveActionPermission("Create", "/products") && (
              <Button color="primary" size="medium" onClick={handleNewProduct}>
                Nuevo Producto
              </Button>
            )}
          </div>
          <p className="textSearch">
            <strong> Búsqueda de productos: </strong>
          </p>
          <Field>
            <Control>
              <Input
                rounded
                color="dark"
                name="search"
                placeholder="Búsqueda..."
                type="text"
                value={search}
                onChange={({ target: { value, name } }) =>
                  handleChange(name, value)
                }
              />
            </Control>
          </Field>
          {search !== "" && <Title className="title-center"> Productos </Title>}
          {search === "" && (
            <Title className="title-center"> Resultado de búsqueda </Title>
          )}
          <Card className="SearchProduct">
            {loading && (
              <div>
                <Loading />
              </div>
            )}
            {search !== "" &&
              !loading &&
              (productsByPlaceAndCriteria?.products === null ||
              productsByPlaceAndCriteria?.products === undefined ? (
                <Title className="title-center textResult">
                  {" "}
                  Sin resultados{" "}
                </Title>
              ) : (
                productsByPlaceAndCriteria?.products.map((element, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <CardProduct
                    key={element}
                    product={element}
                    refetch={refetch}
                  />
                ))
              ))}
          </Card>
        </div>
      )}
    </div>
  );
}

export default Products;
