import { Tab } from "rbx";
import React, { useState } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import ScheduledBillSearch from "../../components/ScheduledBillSearch/ScheduledBillSearch";

function ScheduledBillSearchPage() {
  const [tabState, setTabState] = useState(1);

  const routes = [
    { route: "/scheduledBill/pendings", value: 1, displayName: "Pendientes" },
    { route: "/scheduledBill/complete", value: 2, displayName: "Completas" },
    { route: "/scheduledBill/failed", value: 3, displayName: "Fallidas" },
    { route: "/scheduledBill/canceled", value: 4, displayName: "Canceladas" },
  ];

  return (
    <React.Fragment>
      <div>
        <Tab.Group kind="boxed">
          {routes.map(routeItem => (
            <Tab
              key={routeItem.value}
              as={Link}
              className={tabState === routeItem.value ? "active" : "tabs"}
              to={routeItem.route}
              onClick={() => setTabState(routeItem.value)}
            >
              <span>{routeItem.displayName}</span>
            </Tab>
          ))}
        </Tab.Group>
      </div>
      <Switch>
        <Route path="/scheduledBill/pendings">
          <ScheduledBillSearch />
        </Route>
        <Route path="/scheduledBill/complete">
          <ScheduledBillSearch />
        </Route>
        <Route path="/scheduledBill/failed">
          <ScheduledBillSearch />
        </Route>
        <Route path="/scheduledBill/canceled">
          <ScheduledBillSearch />
        </Route>
        <Redirect to="/scheduledBill/pendings" />
      </Switch>
    </React.Fragment>
  );
}

export default ScheduledBillSearchPage;
