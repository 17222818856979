import PropTypes from "prop-types";
// Components
import { ProfileBill } from "./components";

const ProfileBillWrapper = ({
  initialReceiver,
  setBillingProfile,
  setModalOpen,
}) => (
  <ProfileBill
    initialReceiver={initialReceiver}
    setBillingProfile={setBillingProfile}
    setModalOpen={setModalOpen}
  />
);
ProfileBillWrapper.propTypes = {
  initialReceiver: PropTypes.any,
  setModalOpen: PropTypes.func,
  setBillingProfile: PropTypes.func.isRequired,
};
ProfileBillWrapper.defaultProps = {
  initialReceiver: null,
  setModalOpen: () => null,
};
export default ProfileBillWrapper;
