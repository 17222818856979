import { gql } from "@apollo/client";
/* eslint-disable import/prefer-default-export */
export const GET_CREDIT_NOTES = gql`
  query GetCreditNotesNew($whereParams: WhereParamsNew) {
    getCreditNotesNew(WhereParams: $whereParams) {
      id
      FK_Place
      Key
      Response
      Request
      FK_Bill
      Callback
      Created_At
      Updated_At
      TotalGoodsExonerated
      Discount
      TotalExempt
      TotalExonerated
      TotalGoodsExempt
      TotalGoodsTaxed
      TotalNetSell
      TotalSell
      TotalServicesExempt
      TotalServicesExonerated
      TotalServicesTaxed
      TotalTaxed
      TotalTaxes
      TotalBill
      SubTotal_Bill
      Coin {
        Buy_Coin
        Code_Coin
        Name_Coin
        Sell_Coin
        Symbol_Coin
        id
        Updated_At
        Created_At
      }
      FK_Coin
      ActivityCode
      SellCondition
      EmitedDay

      InternConsecutive
      Exchange
      User {
        Person {
          Name_Person
          Lastname_Person
          id
        }
      }
      FK_User
      FK_ElectronicBillDocumentType
      ElectronicBillDocumentType {
        Description
      }
      ReferenceCode
      ReferenceReason
      FK_AcceptanceState
      BillFlowType
      FK_OwnerPlace
      FK_ReferencedElectronicBill
      AcceptanceDate
      FK_PaymentElectronicDocument
      FK_DocumentAcceptance
      BaseTax
      Factor
      PlatformExchange
      CreditNoteDetails {
        id

        FK_ElectronicBill
        Created_At
        Updated_At
        FK_ProductFeature
        Quantity
        Price
        PartidaArancelaria
        Total_BillDetail
        UnitPrice
        discount_BillDetail
        ProductFeature {
          id
          Product {
            Description_Product
          }
        }
      }
      Place {
        id
        Name_Place
        Code
        Company {
          id
          Name_Company
          Description_Company
          User {
            id
            UserBillingProfile {
              Name
              ComercialName
              Email
              ID_Number
              PhoneNumber
              OtherSigns
              MH_Province {
                Name
              }
              MH_Canton {
                Name
              }
              MH_District {
                Name
              }
              MH_Neighborhood {
                Name
              }
            }
          }
        }
      }
    }
  }
`;
