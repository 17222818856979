import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
// External components
import { Card, Container, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import ProductCard from "../ProductCard";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";

function ResumeCard({ type }) {
  const [open, setOpen] = useState(false);
  const { lineDetail, setLineDetail, useCoinDetail } = useElectronic();
  const { currentSymbolCoin, toExchangeRate } = useCoinDetail;

  const fixedList = useMemo(
    () =>
      type === ElectronicBillTypes.CreditNote
        ? Array.isArray(lineDetail) && lineDetail.filter(x => x.checked)
        : lineDetail,
    [type, lineDetail],
  );

  const getSubTotal = () =>
    toExchangeRate(
      Array.isArray(fixedList)
        ? fixedList.reduce((acc, item) => acc + parseFloat(item.total), 0.0)
        : 0,
    ).toFixed(3);

  // I don't understand what is going on here, so i won't touch it
  const getDiscounts = () => {
    let total = 0.0;

    if (Array.isArray(fixedList)) {
      fixedList.forEach(item => {
        item.discountAmount?.forEach(discount => {
          total += parseFloat(discount);
        });
      });
    }

    return total.toFixed(3);
  };

  const getOtherTaxes = () =>
    toExchangeRate(
      Array.isArray(fixedList)
        ? fixedList?.reduce((acc, item) => acc + item?.totalTaxes, 0)
        : 0,
    ).toFixed(3);

  const getIVA = () =>
    toExchangeRate(
      Array.isArray(fixedList)
        ? fixedList?.reduce((acc, item) => acc + item?.Taxed, 0) ||
            fixedList?.reduce(
              (acc, item) => acc + (item?.Tariff?.Percent * item?.total) / 100,
              0,
            )
        : 0,
    ).toFixed(3);

  const getTotal = detail =>
    (
      parseFloat(getSubTotal(detail)) -
      parseFloat(getDiscounts(detail)) +
      parseFloat(getOtherTaxes(detail)) +
      parseFloat(getIVA())
    ).toFixed(3);

  return (
    <Container className="hover-options">
      <Card className="card-summary">
        <div className="card-top">
          <h3>Resumen</h3>
          {type !== ElectronicBillTypes.CreditNote && (
            <Icon className="align-right" size="medium">
              <FontAwesomeIcon
                className="icon-component"
                icon="info-circle"
                size="lg"
                onClick={() => setOpen(!open)}
              />
            </Icon>
          )}
        </div>
        <div className="align-items-right flex">
          <div>
            <p>Subtotal</p>
            <p className="background-discounts">Descuento</p>
            {getIVA() > 0 && <p className="background-discounts">IVA</p>}
            {getOtherTaxes() > 0 && (
              <p className="background-discounts">Otros Imp.</p>
            )}
          </div>
          <div className="container-summary">
            <p>
              {currentSymbolCoin}
              {getSubTotal()}
            </p>
            <p className="background-totals">
              {currentSymbolCoin}
              {getDiscounts()}
            </p>
            {getIVA() > 0 && (
              <p className="background-totals">
                {currentSymbolCoin}
                {getIVA()}
              </p>
            )}
            {getOtherTaxes() > 0 && (
              <p className="background-totals">
                {currentSymbolCoin}
                {getOtherTaxes()}
              </p>
            )}
          </div>
        </div>
        <div className="card-secondary-content flex">
          <div className="card-total-width">
            <h3 className="font-total">Total</h3>
          </div>
          <h3 className="font-total">
            {currentSymbolCoin}
            {getTotal()}
          </h3>
        </div>
      </Card>

      {lineDetail?.length !== 0 && (
        <ProductCard
          carousel
          list={lineDetail}
          selectables={
            type === ElectronicBillTypes.CreditNote ||
            type === ElectronicBillTypes.DebitNote
          }
          setDataTable={setLineDetail}
          type={type}
        />
      )}
    </Container>
  );
}

ResumeCard.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ResumeCard;
