import React from "react";
// External components
import { Title } from "rbx";
// Components
import { MenuReports } from "../../components";

const ReportsPage = () => (
  <div className="report-items">
    <Title className="text-center">Reportes</Title>
    <MenuReports />
  </div>
);
export default ReportsPage;
