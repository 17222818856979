import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Card, Title, Media, Field, Label, Control, Input } from "rbx";
// Graphql
import { client_EB_api } from "../../../../graphql/client";
import { UPDATE_PIN } from "../../../../graphql";
// Utils
import {
  itsIntegerInput,
  encrypt,
  customToast as toast,
} from "../../../../utils/index";

function P12Upload({ onClose, MH_User, onChange }) {
  const [p12, setP12] = useState(null);
  const [p12Pin, setP12Pin] = useState("");

  useEffect(() => {
    if (!MH_User.FileEB) selectP12();
  }, [MH_User.FileEB]);

  const hadleOnSelect = e => {
    const [p12File] = e.target.files;
    setP12(p12File);
    e.target.value = null;
  };

  const selectP12 = e => {
    setP12(null);
    setP12Pin("");
    e.preventDefault();
    document.querySelector("#fileSelectorP12").click();
  };

  const uploadP12 = async e => {
    const fileData = new FormData();
    fileData.append("w", "fileUploader");
    fileData.append("r", "subir_certif");
    fileData.append("sessionKey", MH_User.Session.sessionKey);
    fileData.append("fileToUpload", p12);
    fileData.append("iam", MH_User.userName);
    const data = await fetch(process.env.REACT_APP_FRONT_P12_UPLOAD, {
      method: "POST",
      body: fileData,
    });
    const { resp } = await data.json();
    if (resp.idFile) {
      saveUserData(resp.idFile);
    } else {
      toast.error("Error al intentar cargar el P12");
    }
  };

  const saveUserData = async id => {
    const pin = encrypt(p12Pin);
    try {
      const {
        data: { updatePin },
      } = await client_EB_api.mutate({
        mutation: UPDATE_PIN,
        variables: { id, pin },
      });
      onChange(updatePin);
      onClose();
      toast.success("P12 cargado con éxito");
    } catch (e) {
      toast.error("Error al actualizar el pin del P12");
    }
  };

  return (
    <div>
      <Card>
        <Card.Content>
          <Media>
            <Media.Item>
              <Title as="p" size={4}>
                {MH_User?.FileEB?.name}
              </Title>
              <Title subtitle as="p" size={6}>
                {new Date(MH_User?.FileEB?.timestamp * 1000).toLocaleString()}
              </Title>
            </Media.Item>
          </Media>
        </Card.Content>
        <Card.Footer>
          <Card.Footer.Item as="div">
            <Button rounded color="secondary" onClick={selectP12}>
              {MH_User.FileEB ? "Reemplazar" : "Buscar"}
            </Button>
          </Card.Footer.Item>
        </Card.Footer>
      </Card>

      {p12 && (
        <div className="container-padding">
          <Field>
            <Label>Pin</Label>
            <Control>
              <Input
                placeholder="Pin para P12"
                type="password"
                value={p12Pin}
                onBeforeInput={itsIntegerInput}
                onChange={e => {
                  if (e.target.value?.length <= 4) setP12Pin(e.target.value);
                }}
              />
            </Control>
          </Field>

          <div className="flex middle">
            <Button
              rounded
              color="secondary"
              disabled={p12Pin === ""}
              onClick={uploadP12}
            >
              Subir P12
            </Button>
          </div>
        </div>
      )}
      <Input
        accept=".p12"
        className="input-file"
        id="fileSelectorP12"
        type="file"
        onChange={hadleOnSelect}
      />
    </div>
  );
}

P12Upload.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  MH_User: PropTypes.any.isRequired,
};

export default P12Upload;
