/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
// External components
import { Card, Button, Title } from "rbx";
import { useDropzone } from "react-dropzone";
import * as converter from "xml-js";
import { format } from "date-fns";
// Components
import { Promise as bromise } from "bluebird";
import { BooleanInput, BillUploadDetail } from "../../components";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
// Utils
import { formatCurrency, customToast as toast, chunk } from "../../utils";
import { arrayBill } from "../../utils/helpers";
// Enums
import { ELECTRONIC_BILL_XML_TYPES } from "../../Enums/ElectronicBill";
// SCSS
import "./IncomePage.scss";
import "../ProfilePage/ProfilePage.scss";

function IncomePage() {
  const [invoices, setInvoices] = useState([]);
  const [allSelected, setAllSelected] = useState(true);

  const { state, permissions } = useAuth();
  const { haveActionPermission, noAccessMessage } = permissions;
  const history = useHistory();
  const canRead = haveActionPermission("Read", "/incomes");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Ingresos"));
    history.push(`/`);
  }
  const { LineDetailPlace } = useElectronic();
  const { handleElectronicBillsXML } = useElectronicBill();

  const { setModalOpen: setDetailModalOpen } = useModal();

  const handleSelectAllClick = () => {
    // evento del botón "Seleccionar todas"
    // Selecciona todas las facturas en el estado invoices
    setInvoices(prev =>
      prev.map(invoice => ({ ...invoice, selected: !allSelected })),
    );
    setAllSelected(prev => !prev);
  };

  const handleSelect = (name, value) => {
    setInvoices(prev =>
      prev.map(invoice =>
        invoice.Clave._text === name
          ? { ...invoice, selected: value }
          : invoice,
      ),
    );
  };

  const handleCleanBills = () => {
    setInvoices([]);
  };

  const removeDuplicatedXMLByKey = xmls =>
    xmls.reduce(
      (acc, xml) => {
        if (!acc.dataKeys.includes(xml?.Clave?._text)) {
          acc.data.push(xml);
          acc.dataKeys.push(xml?.Clave?._text);
        }
        return acc;
      },
      { data: [], dataKeys: [] },
    );

  const isEBType = EBType =>
    !!Object.values(ELECTRONIC_BILL_XML_TYPES).find(type => type === EBType);

  const normalizeUploadedDocument = uploadedDocument => {
    const documentType = Object.keys(uploadedDocument).find(isEBType);
    return { ...uploadedDocument[documentType], documentType };
  };

  const xmlFilesToJson = async files => {
    const data = await Promise.allSettled(
      files.map(
        file =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = ({ target: { result } }) => {
              try {
                const bill = normalizeUploadedDocument(
                  JSON.parse(
                    converter.xml2json(result, { compact: true, spaces: 4 }),
                  ),
                );
                resolve(bill);
              } catch (e) {
                reject(e);
              }
            };
            fileReader.onerror = reject;
            fileReader.readAsText(file);
          }),
      ),
    );

    const succses = await data
      .filter(({ status }) => status === "fulfilled")
      .map(({ value }) => value);

    const failed = data.filter(({ status }) => status === "rejected").length;
    failed && toast.error(`No se lograron abrir ${failed} documentos`);

    return succses;
  };

  const validateOwner = dataJSON => {
    try {
      const { DocumentId_Person } = state.user.TavuelUser.Person;
      const DocumentId_Emisor = dataJSON?.Emisor?.Identificacion?.Numero;
      return DocumentId_Person === DocumentId_Emisor._text;
    } catch (err) {
      return false;
    }
  };

  const isAlreadyUploaded = useCallback(
    facturaJSON =>
      invoices
        .map(({ Clave: { _text } }) => _text)
        .includes(facturaJSON?.Clave?._text),
    [invoices],
  );

  const isValidXMLType = type => /text\/(xml)/.test(type);

  const readXml = async files => {
    const { data: uniqueXmls } = removeDuplicatedXMLByKey(
      await xmlFilesToJson(files),
    );

    uniqueXmls.forEach(facturaJSON => {
      if (!validateOwner(facturaJSON)) {
        toast.error(
          "Verifique que todas las facturas coincidan con su perfil de facturación",
        );
        return;
      }
      if (isAlreadyUploaded(facturaJSON)) {
        toast.error(
          `La factura ...${facturaJSON.Clave._text.slice(
            -11,
          )} ya fue subida anteriormente `,
        );
        return;
      }
      setInvoices(prev => [...prev, { ...facturaJSON, selected: true }]);
    });
  };

  const onDrop = Files =>
    readXml(Files.filter(file => isValidXMLType(file.type)));

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ".xml" });

  const handleSaveIncomeBill = async () => {
    if (LineDetailPlace.id === 0) {
      toast.error("No se ha seleccionado un Negocio");
      return;
    }
    const request = chunk(invoices, 5).map(async xChucnk =>
      handleElectronicBillsXML(
        state.user.TavuelUser,
        xChucnk,
        LineDetailPlace.id,
        2,
      ),
    );

    const messages = await bromise.all(request, { concurrency: 1 }).reduce(
      (finalMessages, current) => {
        const { response, message, success } = current;
        if (response === "errors") {
          finalMessages.errors.push(...message);
        }
        if (response === "error") {
          return Object.assign(finalMessages, { error: message });
        }
        if (success) {
          return Object.assign(finalMessages, { success: message });
        }
        return finalMessages;
      },
      { errors: [], sucess: undefined, error: null },
    );

    messages.success && toast.success(messages.success);
    messages.errors.forEach(bill => {
      if (bill !== "") {
        toast.error(
          `La factura:
        ${bill},
        ya se encuentra registrada,
        favor revisar las facturas`,
        );
      }
    });

    messages.error && toast.error(messages.error);
    messages.sucess && toast.success(messages.sucess);
  };

  return (
    <React.Fragment>
      {invoices?.length === 0 ? (
        <div className="profile-page-container">
          <Title className="profile-page-title">Ingresos</Title>
          <div
            {...getRootProps({
              className: `dropzone
              ${isDragAccept && "dropzoneAccept"}
              ${isDragReject && "dropzoneReject"}`,
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Suelte los archivos aquí...</p>
            ) : (
              <p>
                Arrastre los archivos .xml aquí, o click para seleccionar
                archivos
              </p>
            )}
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="card-header-button">
            <Button color="secondary" onClick={handleCleanBills}>
              Limpiar pantalla
            </Button>
            <Button color="secondary" onClick={handleSelectAllClick}>
              {!allSelected ? "Seleccionar todas" : "Deseleccionar todas"}
            </Button>
            <Button color="primary" onClick={handleSaveIncomeBill}>
              Guardar
            </Button>
          </div>
          <div className="grid">
            {invoices.map(bills => (
              <Card key={bills?.Clave?._text} className="card-content">
                <div className="body-card">
                  <div className="content-invoice">
                    <p>
                      Receptor:&nbsp;
                      {bills?.Receptor?.NombreComercial?._text ||
                        bills?.Receptor?.Nombre?._text ||
                        "Sin receptor"}
                      &nbsp;
                    </p>
                    <div className="card-checkbox">
                      <BooleanInput
                        name={bills?.Clave._text}
                        value={bills?.selected}
                        onChange={(name, value) =>
                          handleSelect(name, value ? 1 : 0)
                        }
                      />
                    </div>
                  </div>
                  <p>
                    Cédula:&nbsp;
                    {bills?.Receptor?.Identificacion?.Numero?._text || "---"}
                    &nbsp;
                  </p>
                  <p>
                    Moneda:&nbsp;
                    {
                      bills?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda
                        ?._text
                    }
                    &nbsp;
                  </p>
                  <p>
                    Total:&nbsp;
                    {formatCurrency(
                      parseFloat(
                        bills?.ResumenFactura?.TotalVentaNeta?._text,
                        10,
                      ),
                      bills?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda
                        ?._text || "CRC",
                    )}
                  </p>
                </div>
                <div className="content-invoice">
                  <p>
                    Fecha:&nbsp;
                    {format(
                      new Date(bills?.FechaEmision?._text),
                      "dd/MM/yyyy hh:mm a",
                    )}
                    &nbsp;
                  </p>
                  <Button
                    className=" btn-animate"
                    color="secondary"
                    onClick={() =>
                      setDetailModalOpen(
                        true,
                        <BillUploadDetail
                          electronicDocument={arrayBill(bills)}
                        />,
                      )
                    }
                  >
                    Ver detalle
                  </Button>
                </div>
              </Card>
            ))}
          </div>
          <div className="profile-page-container">
            <div
              {...getRootProps({
                className: `dropzone
                  ${isDragAccept && "dropzoneAccept"}
                  ${isDragReject && "dropzoneReject"}`,
              })}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Suelte los archivos aquí...</p>
              ) : (
                <p>
                  Arrastre los archivos .xml aquí, o click para seleccionar
                  archivos
                </p>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default IncomePage;
