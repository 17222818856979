/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
// External components
import { Control, Label } from "rbx";
import { components } from "react-select";
// Hooks
import useAsyncSearch from "../../hooks/useAsyncSearch";
// Graphql
import { GET_CABYS_BY_CRITERIA } from "../../graphql";
// SCSS
import "../ProductSelect/ProductSelect.scss";

function CabysCodeSelect({ handleChange, defaultValue, selected_value }) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const { getResults, DebouncedLoad } = useAsyncSearch();

  const CustomOption = value => {
    const { data } = value;
    const option = data;
    return (
      <components.Option {...value}>
        {option && (
          <div className="option-container option-item">
            <div className="option-content-left">
              <div className="product-header">
                <p>
                  <b>{option.value}</b>
                </p>
                <p>{option.label}</p>
              </div>
            </div>
          </div>
        )}
      </components.Option>
    );
  };

  const loadOptions = async inputValue => {
    const variables = {
      criteria: inputValue,
    };

    const data = await getResults(GET_CABYS_BY_CRITERIA, variables);

    const codes = data?.cabys;
    const result = Array.isArray(codes)
      ? codes.map(item => ({
          value: item.Name,
          label: item.Code,
          percent: item.Percent,
          id: item.id,
        }))
      : [];
    setDefaultOptions(result);
    return result;
  };

  const debouncedLoad = DebouncedLoad(loadOptions, 1000);

  return (
    <Control expanded>
      <Label>Código Cabys</Label>
      <AsyncSelect
        cacheOptions
        components={{ Option: CustomOption }}
        defaultOptions={defaultOptions}
        defaultValue={defaultValue}
        loadOptions={debouncedLoad}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Buscar..."
        styles={{
          menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: provided => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: provided => ({
            ...provided,
            display: "none",
          }),
          container: provided => ({
            fontSize: 13,
          }),
          singleValue: provided => ({
            ...provided,
            top: "38%",
          }),
          control: provided => ({
            ...provided,
            "minHeight": 30,
            "height": 36,
            "width": "100%",
            "minWidth": 180,
            "borderRadius": "4px",
            "border": "0.1rem solid #01234c",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: "pointer",
            },
          }),
          placeholder: provided => ({ ...provided, top: "38%" }),
          menu: provided => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
            width: "auto",
          }),
          menuList: provided => ({
            ...provided,
            display: "flex",
            flexWrap: "wrap",
            fontSize: 12,
            zIndex: 999,
            width: "auto",
          }),
        }}
        value={selected_value}
        onChange={handleChange}
      />
    </Control>
  );
}

CabysCodeSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  selected_value: PropTypes.object.isRequired,
};
CabysCodeSelect.defaultProps = {
  defaultValue: null,
};

export default CabysCodeSelect;
