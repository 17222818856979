import React from "react";
import { Title } from "rbx";
import PropTypes from "prop-types";
import "../../../../styles/index.scss";

import SelectProduct from "../../../ProductSelect";

const LineDetail = ({ LineDetailPlace, lineDetail, goBack }) => (
  <div className="is-fluid">
    <Title className="title-center">{LineDetailPlace.Name_Place}</Title>
    <SelectProduct goBack={goBack} />
  </div>
);

LineDetail.propTypes = {
  lineDetail: PropTypes.array.isRequired,
  goBack: PropTypes.func.isRequired,
  LineDetailPlace: PropTypes.object.isRequired,
};

export default LineDetail;
