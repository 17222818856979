import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Title, Field, Control, Label, Input, Button } from "rbx";
// Graphql
import { client_EB_api } from "../../../../graphql/client";
import { UPDATE_PROFILE_EB } from "../../../../graphql";
// Utils
import { decrypt, encrypt, customToast as toast } from "../../../../utils";

function MHUser({ onClose, billingProfile, onChange }) {
  const {
    id,
    Name,
    Email,
    MH_PersonType: { Name_PersonType },
    MH_User,
  } = billingProfile;

  const [userMH, setUserMH] = useState({
    idUser: MH_User?.idUser,
    MH_User: MH_User?.MH_User,
    MH_Password: MH_User?.MH_Password && decrypt(MH_User?.MH_Password),
    fullName: Name,
    email: Email,
    about: Name_PersonType,
    country: "52",
    FK_Profile: id,
  });

  const hadleOnChange = e => {
    setUserMH({ ...userMH, [e.target.name]: e.target.value });
  };

  const saveUserData = async () => {
    try {
      const MH_Password = encrypt(userMH.MH_Password);
      const {
        data: { BillingProfile },
      } = await client_EB_api.mutate({
        mutation: UPDATE_PROFILE_EB,
        variables: { userMH: { ...userMH, MH_Password } },
      });
      toast.success("Credenciales de hacienda actulizadas");
      onChange(BillingProfile);
      onClose();
    } catch (e) {
      toast.error("Error al actulizar las credenciales de hacienda");
    }
  };

  return (
    <div>
      <Title className="profile-page-title">Facturación Electrónica</Title>
      <Field kind="group">
        <Control expanded>
          <Label textColor="grey-dark">Usuario</Label>
          <Input
            autoComplete="off"
            defaultValue={userMH.MH_User}
            name="MH_User"
            placeholder="Usuario"
            type="email"
            onChange={hadleOnChange}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label textColor="grey-dark">Contraseña</Label>
          <Input
            autoComplete="off"
            defaultValue={userMH.MH_Password}
            name="MH_Password"
            placeholder="Contraseña"
            type="text"
            onChange={hadleOnChange}
          />
        </Control>
      </Field>

      <div className="profile-page-button">
        <Button rounded color="primary" onClick={saveUserData}>
          Guardar
        </Button>
      </div>
    </div>
  );
}

MHUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  billingProfile: PropTypes.any.isRequired,
};

MHUser.defaultProps = {
  onChange: userEB => null,
};

export default MHUser;
