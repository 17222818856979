import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { Select, Control } from "rbx";
import { useQuery } from "@apollo/client";
import { GET_ALL_COINS } from "../../../../../graphql";

import "../SelectPaymentMethod/SelectPaymentMethod.scss";

const SelectCoin = ({ value, name, onChange, disabled }) => {
  const { data: getCoinsData } = useQuery(GET_ALL_COINS);

  useEffect(() => {
    if (value !== undefined || !getCoinsData?.tariff) return;
    onChange(name, { Name: "SinSeleccionar", id: 0, Percent: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCoinsData, value]);
  return (
    <Control>
      <Select.Container fullwidth>
        <Select
          data-tip
          className="selector"
          data-for="selectCoin"
          disabled={disabled}
          name={name}
          value={value}
          onChange={e => onChange(e.target.name, e.target.value)}
        >
          {getCoinsData?.coins?.map(index =>
            index.Name_Coin !== "Tavuel" ? (
              <Select.Option key={index.id} value={index.id}>
                {index.Name_Coin}
              </Select.Option>
            ) : null,
          )}
        </Select>
      </Select.Container>
      <ReactTooltip id="selectCoin" type="info">
        <span>Tipo de moneda</span>
      </ReactTooltip>
    </Control>
  );
};

SelectCoin.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

SelectCoin.defaultProps = {
  disabled: false,
};

export default SelectCoin;
