import React from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control, Label } from "rbx";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_COINS } from "../../graphql";
// SCSS
import "../CountrySelect/CountrySelect.scss";

function CoinSelect({ value, name, onChange, label, disabled, className }) {
  const { data: getCoins } = useQuery(GET_ALL_COINS);

  return (
    <Control expanded className={className}>
      <Label>{label}</Label>
      <Select.Container fullwidth>
        <Select
          className="selector"
          disabled={disabled}
          name={name}
          value={value}
          onChange={e => onChange(e.target.name, e.target.value)}
        >
          {getCoins?.coins
            ?.filter(index => index.id !== 4 && index.id !== "4")
            .map(coin => (
              <Select.Option key={coin.id} value={coin.id}>
                {coin.Name_Coin}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
}

CoinSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CoinSelect.defaultProps = {
  disabled: false,
  className: "",
};

export default CoinSelect;
