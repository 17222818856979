import React from "react";
import PropTypes from "prop-types";
// SCSS
import "./loading.scss";

const Loading = ({ size }) => (
  <div className={`loading-container ${size}`}>
    <div className="loading-animation" />
  </div>
);
Loading.propTypes = {
  size: PropTypes.string,
};
Loading.defaultProps = {
  size: "",
};

export default Loading;
