import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useHistory } from "react-router-dom";
// External components
import { Icon, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
// Components
import ClickCapture from "../ClickCapture";
import { NestedMenu } from "./components";
import PlaceSelector from "../PlaceSelector";
import Badge from "../Badge";
// Routes
import { getRoutes } from "./routes";
// Context
import { useAuth } from "../../context/AuthContext";
import { useElectronic } from "../../context/ElectronicContext";
import { useNotifications } from "../../context/NotificationsContext";
// SCSS
import "./Sidebar.scss";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  height: fit-content;
  text-align: left;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translate3d(-295px, 0, 0);
  transition: transform 0.3s linear;
  box-shadow: 20px -9px 20px 0px rgb(0 0 0 / 9%);
  color: white;

  @media (max-width: 768px) {
    transform: translate3d(${({ open }) => (open ? "0px" : "-295px")}, 0, 0);
    transition: transform 0.3s linear;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`;

const StyledBurger = styled.button`
  position: absolute;
  top: 15%;
  left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "#EFFFFA")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Container = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  margin-left: 1rem;
  position: relative;
  width: auto;
  max-width: 98%;
  @media screen and (max-width: 900px) {
    max-width: 90%;
  }
`;

function Sidebar({ isSideBarOpen, setIsSideBarOpen }) {
  const [menuActive, setMenuActive] = useState(false);
  const [activeMenus, setActiveMenus] = useState({});
  const [activeRoute, setActiveRoutes] = useState({});

  const history = useHistory();
  const location = useLocation();
  const {
    state: authState,
    handleLogout,
    permissions: userPermissions,
  } = useAuth();
  const { LineDetailPlace, setLineDetailPlace, setAccountSelected } =
    useElectronic();
  const { closedNotifications, setNotifications, refetchNotifications } =
    useNotifications();

  const permissions = ["Main"];
  const routes = getRoutes("Main");

  const { isSuperAdminOrHaveAccess } = userPermissions;

  const availableRoutes = routes.filter(route =>
    Array.isArray(route.permissions)
      ? route.permissions?.some(perm => permissions?.some(x => perm === x))
      : true,
  );

  useEffect(() => {
    const firstNotisLoad = async () => {
      setNotifications(await refetchNotifications());
    };
    firstNotisLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LineDetailPlace.id]);

  useEffect(() => {
    setMenuActive(false);
  }, [location.pathname]);

  const toggleActiveMenus = name =>
    setActiveMenus(prev => ({ ...prev, [name]: !activeMenus[name] }));

  const toggleActiveRoutes = id => {
    const route = availableRoutes[id];
    if (route.to) {
      history.push(route.to);
      setMenuActive(false);
      if (window.innerWidth < 900) {
        setIsSideBarOpen(false);
      }
    } else {
      setActiveRoutes(availableRoutes[id]);
      setMenuActive(true);
    }
  };

  // close sidebar menu when ESC key is pressed
  useEffect(() => {
    const handleKeyDown = e => {
      if (menuActive && e.keyCode === 27) {
        setMenuActive(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  });

  const validateRoute = () => {
    if (location.pathname === "/") {
      return true;
    }
    if (!isSideBarOpen) {
      return false;
    }
    return true;
  };

  const onClickBack = () => {
    if (location.pathname === "/expenses/upload-expenses" || !location.key) {
      history.replace("/");
    } else {
      history.goBack();
    }
  };

  const goBackLeftArrowStyles = {
    position: "relative",
    left: "20px",
    top: "70px",
  };

  return (
    <React.Fragment>
      <nav className="navigation-bar">
        <div className="navbar-container">
          <ClickCapture
            className="navbar-container__left-icon"
            onOutsideClick={() => setMenuActive(false)}
          >
            <Container>
              <div className="navigation">
                <div className="navigation-start">
                  <div className="navigation-item">
                    <NestedMenu
                      activeMenus={activeMenus}
                      isActive={menuActive}
                      links={activeRoute.links}
                      name={activeRoute.name}
                      setMenuActive={setMenuActive}
                      toggleMenus={toggleActiveMenus}
                    />
                  </div>
                </div>
              </div>
            </Container>
            <StyledBurger
              open={isSideBarOpen}
              onClick={async e => {
                e.stopPropagation();
                if (isSideBarOpen) {
                  setMenuActive(false);
                }
                setIsSideBarOpen(prev => !prev);
                !isSideBarOpen &&
                  setNotifications(await refetchNotifications());
              }}
            >
              <div />
              <div />
              <div />
            </StyledBurger>
            <StyledMenu open={isSideBarOpen}>
              <div className="sidebar-header">
                <div className="sidebar-header-item__brand">
                  <Link to="/">
                    <Icon size="large">
                      <FontAwesomeIcon icon="calculator" />
                    </Icon>
                  </Link>
                </div>
              </div>
              <div className="header-container">
                <div>
                  <Title subtitle className="sidebar-title">
                    Hola, {authState?.user?.displayName || ""}
                  </Title>
                  <p className="sidebar-subtitle">
                    {authState?.user?.email || ""}
                  </p>
                </div>
              </div>
              <hr />
              <div className="sidebar-menu">
                {availableRoutes.map(
                  (r, i) =>
                    isSuperAdminOrHaveAccess(r.to ?? "/electronic-bills") && (
                      <div
                        key={r.name}
                        className={`sidebar-menu-item ${
                          activeRoute === routes[i] && menuActive
                            ? "is-active"
                            : ""
                        } ${r.active?.(location) ? "is-current" : ""}`}
                        role="button"
                        tabIndex="0"
                        onClick={() => toggleActiveRoutes(i)}
                        onKeyDown={() => toggleActiveRoutes(i)}
                      >
                        <Icon size="large">
                          {r.name === "notifications" ? (
                            <Badge
                              value={
                                closedNotifications > 0 && closedNotifications
                              }
                            >
                              <FontAwesomeIcon icon={r.icon} />
                            </Badge>
                          ) : (
                            <FontAwesomeIcon icon={r.icon} />
                          )}
                        </Icon>
                        <span>{r.label}</span>
                        {r.links && (
                          <Icon size="medium">
                            <FontAwesomeIcon icon="arrow-right" />
                          </Icon>
                        )}
                      </div>
                    ),
                )}
                <div
                  className="sidebar-menu-item bottom"
                  role="button"
                  tabIndex="-1"
                  onClick={() => {
                    handleLogout();
                    setLineDetailPlace({ id: 0 });
                    setAccountSelected({ id: 0 });
                  }}
                  onKeyDown={() => null}
                >
                  <Icon size="large">
                    <FontAwesomeIcon icon="sign-out-alt" />
                  </Icon>
                  <span>Cerrar sesión</span>
                </div>
              </div>
            </StyledMenu>
          </ClickCapture>
          <PlaceSelector />
        </div>
      </nav>
      {!validateRoute() && (
        <Icon
          className={`align-left ${isSideBarOpen ? "open" : ""}`}
          size="2x"
          style={goBackLeftArrowStyles}
        >
          <FontAwesomeIcon
            className="hover-table-options"
            color="#0D0C1D"
            icon="arrow-left"
            size="2x"
            onClick={onClickBack}
          />
        </Icon>
      )}
    </React.Fragment>
  );
}

Sidebar.propTypes = {
  isSideBarOpen: PropTypes.bool,
  setIsSideBarOpen: PropTypes.func,
};

Sidebar.defaultProps = {
  isSideBarOpen: false,
  setIsSideBarOpen: () => null,
};

export default Sidebar;
