import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "firebase/storage";
// External components
import { Button, Field, Control, Input, Title, Label, Image, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Context
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY } from "../../graphql";
import { useAuth, useModal } from "../../context";
// Components
import CropperPlace from "../CropperPlace";
// Utils
import { customToast as toast } from "../../utils";
// Helpers
import { validateRoute } from "../../utils/helpers";
// SCSS
import "../../pages/ProfilePage/ProfilePage.scss";

function CompanyRegister({ setCompanySelected, company, refetchCompany }) {
  // states
  const { state: authState } = useAuth();

  const [newCompany, setNewCompany] = useState({
    Name_Company: company.Name_Company,
    Description_Company: company.Description_Company,
    FK_User: authState.user.TavuelUser.id,
    FK_Plan: 10,
    id: company.id,
  });
  const [upImg, setUpImg] = useState("");
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const [open, setOpen] = useState(false);

  const { setModalOpen: setModalOpenCropper } = useModal();

  const canvas = useRef(null);

  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const handleChange = (name, value) => {
    setNewCompany(prev => ({ ...prev, [name]: value }));
  };

  const validateImgRoute = () => {
    if (firebaseURL === "") {
      return validateRoute(company);
    }
    return firebaseURL;
  };

  const update = useCallback(
    async companyUpdate => {
      try {
        const { data } = await updateCompany({
          variables: {
            company: companyUpdate,
          },
        });
        if (data) {
          toast.success("¡Compañía actualizada con éxito!");
        }
      } catch (err) {
        toast.error("Error al actualizar la compañía");
      }
    },
    [updateCompany],
  );

  const handleSubmit = e => {
    e.preventDefault();
    if (
      newCompany.Name_Company !== "" &&
      newCompany.Description_Company !== ""
    ) {
      update(newCompany);
      refetchCompany();
      setCompanySelected("");
    } else {
      toast.error(
        "Debe completar toda la información solicitada para el registro.",
      );
    }
  };

  const handleCancel = () => {
    setCompanySelected("");
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSelectClick = e => {
    e.preventDefault();
    document.querySelector("#fileSelectorImg").click();
  };

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setNewCompany(prev => ({
        ...prev,
        Files: [
          {
            Route_File: firebaseURL,
            FirebaseId: firebaseID,
            State_File: "New",
            FileType: {
              id: 2,
            },
          },
        ],
      }));
    }
  }, [firebaseURL, firebaseID]);

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpenCropper(
        true,
        <CropperPlace
          isCompany
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setModalOpenCropper(false)}
        />,
      );
      setOpen(true);
    }
  }, [open, setModalOpenCropper, upImg]);
  return (
    <div>
      <form className="profile-page-container" onSubmit={handleSubmit}>
        <div>
          <Title className="profile-page-title">Actualizar Compañía</Title>
          <Field kind="group">
            <Control expanded>
              <div className=" title-center">
                <input
                  accept="image/*"
                  className="input-file"
                  id="fileSelectorImg"
                  type="file"
                  onChange={hadleOnSelectFile}
                />
                <div className="profilepic">
                  <Image.Container size="64px sq.">
                    <Image
                      className="profilepic__image"
                      height="150"
                      src={validateImgRoute()}
                      width="150"
                    />
                  </Image.Container>
                  <div className="profilepic__content">
                    <Icon className="hover-table-options ">
                      <FontAwesomeIcon
                        icon="camera"
                        size="1x"
                        onClick={handleSelectClick}
                      />
                    </Icon>
                  </div>
                </div>
              </div>
              <Label textColor="grey-dark">Nombre: </Label>
              <Input
                name="Name_Company"
                placeholder="Nombre"
                type="text"
                value={newCompany.Name_Company}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label textColor="grey-dark">Descripción:</Label>
              <Input
                name="Description_Company"
                placeholder="Descripción"
                type="text"
                value={newCompany.Description_Company}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <div className="profile-page-button">
            <Button rounded color="secondary" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button rounded color="primary" type="submit">
              Actualizar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

CompanyRegister.propTypes = {
  setCompanySelected: PropTypes.func,
  company: PropTypes.object.isRequired,
  refetchCompany: PropTypes.func.isRequired,
};

CompanyRegister.defaultProps = {
  setCompanySelected: () => null,
};

export default CompanyRegister;
