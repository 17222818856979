import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
// External components
import { Button, Container } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useApolloClient, useQuery, useMutation } from "@apollo/client";
import { client_EB_api } from "../../graphql/client";
import { GET_BILL_BY_ID, GET_EB_BY_ID, RESEND_EMAIL } from "../../graphql";
import { UPDATE_CONFIGURATION_BILL } from "../../graphql/billConfiguration/billConfiguration.mutation";
// Components
import OptionCard from "../OptionCard";
import ScheduledBillCard from "../ScheduledBillCard";
import NotesForm from "../NotesForm";
import Loading from "../Loading";
import ResumeCard from "../ResumeCard";
import ClientCard from "../ClientCard";
import PaymentDataCard from "../PaymentDataCard";
import CustomLoading from "../CustomLoading";
import SendBillScreen from "../SendBillScreen";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
import useCreditNote from "../../hooks/useCreditNote";
import useDebitNote from "../../hooks/useDebitNote";
// Context
import { useAuth, useModal } from "../../context";
import { useNotifications } from "../../context/NotificationsContext";
import { useElectronic } from "../../context/ElectronicContext";
// Enums
import {
  BILL_TYPES_URL_PARAMS,
  ElectronicBillTypes,
  ELECTRONIC_BILLS_DOCUMENT_TYPES,
} from "../../Enums/ElectronicBill";
import { ElectronicBillErrors, UserErrors } from "../../Enums/Errors";
// Utils
import { customToast as toast } from "../../utils";
import { Checkbox } from "../BooleanInput/components";

import { billConfigurationEmptyState } from "../../InitialStates/ElectronicContext";

const submitText = {
  CreditNote: "Enviar Nota de crédito",
  DebitNote: "Enviar nota de débito",
};

function PaymentComponent() {
  const [noteInfo, setNoteInfo] = useState({ code: "", reason: "" });
  const [sendingBill, setSendingBill] = useState(null);
  const [registeredTicket, setRegisteredTicket] = useState(false);
  const [sentDraft, setSentDraft] = useState(false);
  const [loadState, setLoadState] = useState({
    loadingState: "loading",
    isLoading: false,
  });

  const { id, billType } = useParams();
  const { saveCreditNote, loadCreditNote, canSaveCN } = useCreditNote();
  const { canSaveDN, saveDebitNote } = useDebitNote();
  const {
    saveTempralBill: saveTemporalBill,
    saveEBillFormBill,
    loadElectronicBill,
    loadDraftBill,
  } = useElectronicBill();

  const client = useApolloClient();

  const {
    lineDetail,
    LineDetailPlace,
    receiver,
    electronicBill,
    setElectronicBill,
    setLineDetail,
    useCoinDetail,
    isBilling,
    setIsBilling,
    resetReceiver,
    resetElectronicBill,
    scheduledBill,
    setScheduledBill,
  } = useElectronic();
  const { resetDefaultCoin } = useCoinDetail;
  const { permissions } = useAuth();
  const history = useHistory();
  const { haveActionPermission, noAccessMessage } = permissions;
  const { setModalOpen } = useModal();
  const { createNotification, refetchNotifications, setNotifications } =
    useNotifications();

  const canRead = haveActionPermission("Read", "/electronic-bills");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Fac Electrónica"));
    history.push(`/`);
  }
  const { loading } = useQuery(GET_BILL_BY_ID, {
    variables: {
      id: sendingBill?.id,
    },
    skip: !sendingBill?.id,
  });
  const [updateConfigurationBill] = useMutation(UPDATE_CONFIGURATION_BILL);
  const isCreditNoteView = useMemo(
    () => billType === BILL_TYPES_URL_PARAMS.CREDIT_NOTE,
    [billType],
  );
  const isDebitNoteView = useMemo(
    () => billType === BILL_TYPES_URL_PARAMS.DEBIT_NOTE,
    [billType],
  );
  const isNote = useMemo(
    () => isCreditNoteView || isDebitNoteView,
    [isDebitNoteView, isCreditNoteView],
  );

  const isDraft = useMemo(
    () => billType === BILL_TYPES_URL_PARAMS.DRAFT,
    [billType],
  );

  const handleSaveAsTemp = async () => {
    if (lineDetail.length === 0) return;
    const bill = await saveTemporalBill();

    setElectronicBill(prev => ({ ...prev, id: bill.id }));
    setLineDetail(prev =>
      prev.map(detail => ({
        ...detail,
        detail_id: bill.Detail.find(
          _detail => _detail?.ProductFeature?.id === detail?.id,
        )?.id,
      })),
    );
    setSentDraft(true);
  };

  const resetBill = useCallback(() => {
    resetElectronicBill();
    resetDefaultCoin();
    setLineDetail([]);
    resetReceiver();
  }, [resetDefaultCoin, resetElectronicBill, resetReceiver, setLineDetail]);

  useEffect(() => {
    if (!isBilling) resetBill();
    if (!id) {
      setScheduledBill(billConfigurationEmptyState);
      return;
    }
    if (isDraft) {
      loadDraftBill(id);
      return;
    }
    if (isDebitNoteView) {
      loadCreditNote(id);
    } else {
      loadElectronicBill(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(
    () => {
      if (isBilling) setIsBilling(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBilling],
  );

  const getSaveButtonText = useMemo(
    () =>
      electronicBill.id
        ? `La factura será guardada automáticamente`
        : "Guardar en borrador",
    [electronicBill.id],
  );

  const SubmitInvoiceText = useMemo(() => {
    if (billType && !isDraft) {
      return submitText[billType];
    }
    if (receiver.id) {
      return "Generar Factura Electrónica";
    }
    return "Generar Tiquete Electronico";
  }, [billType, isDraft, receiver.id]);
  // this needs improvements

  const ToastsFactory = error => {
    switch (error.message) {
      case UserErrors.EMPTY_CHECKED_PRODUCTS:
        toast.error("Seleccione al menos un producto");
        break;
      default:
        toast.error("Error desconocido");
    }
  };

  const sendCreditNote = async () => {
    try {
      const validation = canSaveCN();
      if (validation.hasError) {
        ToastsFactory(validation);
        return;
      }
      const {
        data: { createCreditNote },
      } = await saveCreditNote(noteInfo);
      if (createCreditNote?.error === ElectronicBillErrors.ACCESS_TOKEN) {
        toast.error("Error al registrar el tiquete, revise sus credenciales");
        resetBill();
        await handleCreateNotification(
          "Error - Nota de Crédito",
          "Error al registrar el tiquete, revise sus credenciales",
          "",
          id ? `/electronic-bills/${id}/CreditNote` : "/electronic-bills/",
        );
        setLoadState(prev => ({ loadingState: "error", isLoading: false }));
        return;
      }
      toast.success("¡Nota de Crédito guardada con éxito!");
      await handleCreateNotification(
        "Error - Nota de Crédito",
        "¡Nota de Crédito guardada con éxito!",
        "",
        `/ElectronicBillView/${createCreditNote.id}/created/${BILL_TYPES_URL_PARAMS.CREDIT_NOTE}`,
      );
      history.push(
        `/ElectronicBillView/${createCreditNote.id}/created/${BILL_TYPES_URL_PARAMS.CREDIT_NOTE}`,
      );
    } catch {
      toast.success("Ha ocurrido un error");
      await handleCreateNotification(
        "Exito - Nota de Crédito",
        "Ha ocurrido un error al enviar la nota de crédito",
        "",
        id ? `/electronic-bills/${id}/CreditNote` : "/electronic-bills/",
      );
    }
  };

  const sendDebitNote = async () => {
    try {
      const validation = canSaveDN();
      if (validation.hasError) {
        ToastsFactory(validation);
        return;
      }
      const {
        data: { createDebitNote },
      } = await saveDebitNote(noteInfo);
      if (createDebitNote?.error === ElectronicBillErrors.ACCESS_TOKEN) {
        toast.error("Error al registrar el tiquete, revise sus credenciales");
        resetBill();
        setLoadState(prev => ({ loadingState: "error", isLoading: false }));
        return;
      }
      toast.success("¡Nota de Débito guardada con éxito!");
      history.push(
        `/ElectronicBillView/${createDebitNote.id}/created/${BILL_TYPES_URL_PARAMS.DEBIT_NOTE}`,
      );
    } catch {
      toast.success("Ha ocurrido un error");
    }
  };
  const handleSendBill = async () => {
    setLoadState(prev => ({ loadingState: "loading", isLoading: true }));
    try {
      if (billType === BILL_TYPES_URL_PARAMS.CREDIT_NOTE) {
        sendCreditNote();
        return;
      }
      if (billType === BILL_TYPES_URL_PARAMS.DEBIT_NOTE) {
        sendDebitNote();
        return;
      }
      if (!billType || isDraft) {
        const bill = !isDraft ? await saveTemporalBill() : electronicBill;
        const resp = await saveEBillFormBill(bill.id);
        if (resp.error) {
          handleShowError(resp.error);
          await handleCreateNotification(
            "Error - Factura",
            "Ha ocurrido un error al intentar registrar la factura",
            "",
            bill?.id
              ? `/electronic-bills/${bill.id}/Draft`
              : "/electronic-bills",
          );
          return;
        }
        if (bill && resp.id) {
          setSendingBill(bill);
          toast.success("¡Factura  guardada con éxito!");
          handleCreateNotification(
            "Éxito - Factura",
            "¡Factura Guardada Exitosamente!",
            "",
            `/ElectronicBillView/${resp.id}/created/${BILL_TYPES_URL_PARAMS.ELECTRONIC_BILL}}`,
          );
          history.push(
            `/ElectronicBillView/${resp.id}/created/${BILL_TYPES_URL_PARAMS.ELECTRONIC_BILL}`,
          );
        }
        setRegisteredTicket(true);
      }
    } catch (err) {
      toast.error("Error al registrar el tiquete");
      await handleCreateNotification(
        "Error - Factura",
        "Error al registrar el tiquete",
        "",
        "/electronic-bills",
      );
      resetBill();
    }
    setLoadState(prev => ({ ...prev, isLoading: false }));
    setNotifications(await refetchNotifications());
  };

  const getBill = async idEB => {
    const bill = await client.query({
      query: GET_EB_BY_ID,
      variables: {
        id: idEB,
      },
    });
    return bill;
  };

  const handleSendEmails = async receivers => {
    let idElectronicBill = 0;
    let features = [];

    if (billType !== BILL_TYPES_URL_PARAMS.DRAFT) {
      let bill = await getBill(electronicBill.id);
      idElectronicBill = bill?.data?.EBill?.Bill?.id;

      if (
        bill?.data?.EBill?.ElectronicBillDocumentType?.id ===
        ELECTRONIC_BILLS_DOCUMENT_TYPES.CREDIT_NOTE.toString()
      ) {
        bill = await getBill(bill?.data?.EBill?.ReferencedDocument?.id);
        idElectronicBill = bill?.data?.EBill?.Bill?.id;
        bill?.data?.EBill?.ElectronicBillDetail.map(detail =>
          features.push(detail.id),
        );
      }
    } else {
      features = lineDetail.map(({ value }) => value);
    }

    await client_EB_api.mutate({
      mutation: RESEND_EMAIL,
      variables: {
        id:
          billType === BILL_TYPES_URL_PARAMS.DRAFT || sentDraft
            ? electronicBill.id
            : idElectronicBill,
        idElectronicBillFeatures: features,
        to: receivers,
      },
    });
    toast.success("Email enviado con éxito!");
  };

  const openSendEmailModal = () => {
    setModalOpen(
      true,
      <SendBillScreen
        receptorRequired
        billingEmail={receiver?.Receiver_Email}
        handleCloseModal={() => setModalOpen(false)}
        handleSend={handleSendEmails}
        place={LineDetailPlace}
      />,
    );
  };

  const handleShowError = error => {
    if (error === ElectronicBillErrors.ACCESS_TOKEN) {
      toast.error("Error al registrar el tiquete, revise sus credenciales");
      setLoadState(prev => ({ loadingState: "error", isLoading: false }));
      history.replace("/electronic-bills");
    }
  };

  const handleCreateNotification = async (title, body, icon, clickAction) => {
    await createNotification(title, body, icon, clickAction);
    setNotifications(await refetchNotifications());
  };

  const getType = () => {
    if (isNote)
      return isDebitNoteView
        ? ElectronicBillTypes.DebitNote
        : ElectronicBillTypes.CreditNote;
    return ElectronicBillTypes.FacturaElectronica;
  };

  const availableSendDocument = () => {
    const { code, reason } = noteInfo;

    const selected =
      Array.isArray(lineDetail) && lineDetail.length > 0
        ? lineDetail.filter(p => p.checked === true)
        : [];

    if ((!code || !reason || !selected.length) && isNote) {
      return true;
    }
    if (!isNote) {
      return !lineDetail.length;
    }

    return false;
  };

  const updateValues = (name, value) => {
    if (id) {
      updateConfigurationBill({
        variables: {
          billConfiguration: {
            FK_Bill: id,
            [name]: value,
          },
        },
      });
    }
  };

  return !loadState.isLoading ? (
    <div>
      <div className={loading ? "loading" : ""}>
        {loading && (
          <div className="container-padding">
            <Loading />
          </div>
        )}
        {!loading && (
          <div className="container-padding">
            <div className="container grid">
              {isNote || (
                <Button
                  className="add-Button grid-top"
                  color="primary"
                  disabled={!lineDetail.length || electronicBill.id}
                  type="button"
                  onClick={() => handleSaveAsTemp()}
                >
                  {getSaveButtonText}
                </Button>
              )}
              {!registeredTicket ? (
                <Button
                  className="add-Button"
                  color="primary"
                  disabled={availableSendDocument()}
                  type="button"
                  onClick={() => handleSendBill()}
                >
                  {SubmitInvoiceText}
                </Button>
              ) : (
                <Button
                  className="add-Button"
                  color="primary"
                  disabled={!lineDetail.length}
                  type="button"
                  onClick={() => history.push("/CreditDebitNote")}
                >
                  Crear nota de crédito
                </Button>
              )}
              {electronicBill.id && !isNote && (
                <Button color="primary" onClick={() => openSendEmailModal()}>
                  <FontAwesomeIcon icon="envelope" />
                  <h3> &nbsp; Enviar Correo</h3>
                </Button>
              )}
            </div>

            <div className="electronic-options">
              {isNote && (
                <NotesForm
                  code_name="code"
                  noteInfo={noteInfo}
                  reason_name="reason"
                  onChange={setNoteInfo}
                />
              )}
              <OptionCard
                mainComponent
                disabled={isNote}
                icon="shopping-basket"
                title="Añadir productos"
              />
              <ResumeCard type={getType()} />

              <ClientCard
                optional={
                  receiver.Receiver_Id === ""
                    ? "Click para buscar (opcional)"
                    : ` ${receiver?.Receiver_Name}, 
                        ${receiver?.Receiver_Id}`
                }
                optional2={
                  receiver?.Receiver_Email === "" &&
                  receiver?.Receiver_PhoneNumber === ""
                    ? ""
                    : ` ${receiver?.Receiver_Email}, 
                      ${receiver?.Receiver_PhoneNumber}`
                }
                type={
                  isNote
                    ? ElectronicBillTypes.CreditNote
                    : ElectronicBillTypes.FacturaElectronica
                }
              />
              <PaymentDataCard
                type={
                  isNote
                    ? ElectronicBillTypes.CreditNote
                    : ElectronicBillTypes.FacturaElectronica
                }
              />
              <OptionCard
                goBack
                disabled={isNote}
                icon="store"
                optional={LineDetailPlace.Name_Place}
                title="Inventarios"
              />
              <Container className="mt-2">
                <Checkbox
                  checked={!!scheduledBill?.Active}
                  defaultValue={false}
                  label="Factura programada"
                  name="scheduledBill"
                  onChange={value => {
                    setScheduledBill(prev => ({
                      ...prev,
                      Active: !prev.Active,
                    }));
                    updateValues("Active", value.target.checked);
                  }}
                />
                {scheduledBill?.Active ? (
                  <ScheduledBillCard id={id} updateValues={updateValues} />
                ) : null}
              </Container>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <CustomLoading
      centered
      vCentered
      errorTexts={["Algo ha fallado"]}
      loadingState={loadState.loadingState}
      loadingTexts={["Estamos enviando su factura", "Por favor, espere..."]}
    />
  );
}
export default PaymentComponent;
