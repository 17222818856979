import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { Field, Control, Input, Label } from "rbx";
import Select from "react-select";

function PhoneInput({
  className,
  label,
  placeholder,
  onChange,
  disabled,
  countries,
  phone,
  code,
  codeKey,
  phoneKey,
}) {
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState(null);

  useEffect(() => {
    countries &&
      setCountryList(
        countries.map(({ Name_Country, PhoneCode_Country, ...rest }) => ({
          label: `+(${PhoneCode_Country}) ${Name_Country}`,
          value: PhoneCode_Country,
          PhoneCode_Country,
          Name_Country,
          ...rest,
        })),
      );
  }, [countries]);

  useEffect(() => {
    if (countryList && code) {
      setCountry(
        countryList.find(({ PhoneCode_Country }) => PhoneCode_Country === code),
      );
    }
  }, [countryList, setCountry, code]);

  const formatOptionLabel = (
    { value, label: xLabel, Iso_Country },
    { context },
  ) => (
    <div className="phone-input-icon">
      <img
        alt={country?.Name_Country}
        className="phone-input-flag"
        loading="lazy"
        src={`https://flagcdn.com/32x24/${Iso_Country.toLowerCase()}.png`}
      />
      {context === "value" ? `+${value}` : xLabel}
    </div>
  );

  const countrySelected = (c, { name }) => {
    setCountry(c);
    onChange(name, c.PhoneCode_Country);
  };

  return (
    <Field className={className} kind="group">
      <Control expanded>
        <Label textColor="grey-dark">{label}</Label>
        <div className="phone-input">
          <Select
            className="select-phone"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            formatOptionLabel={formatOptionLabel}
            name={codeKey}
            options={countryList}
            placeholder={placeholder}
            styles={{
              menu: (provided, state) => ({
                ...provided,
                width: "300px",
                borderBottom: "1px dotted pink",
                color: state.selectProps.menuColor,
                padding: 20,
              }),
            }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "transparent",
                neutral20: "transparent",
                neutral30: "transparent",
              },
            })}
            value={country}
            onChange={countrySelected}
          />
          <Input
            disabled={disabled}
            name={phoneKey}
            placeholder={placeholder ?? label}
            type="number"
            value={phone}
            onChange={e => onChange(e.target.name, e.target.value)}
          />
        </div>
      </Control>
    </Field>
  );
}

PhoneInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  phone: PropTypes.string,
  code: PropTypes.string,
  codeKey: PropTypes.string,
  phoneKey: PropTypes.string,
  disabled: PropTypes.bool,
  countries: PropTypes.array,
};

PhoneInput.defaultProps = {
  onChange: e => e,
  label: "",
  className: "",
  placeholder: null,
  disabled: false,
  countries: [],
  code: "",
  phone: "",
  codeKey: "",
  phoneKey: "",
};

export default PhoneInput;
