import { format } from "date-fns";
import PropTypes from "prop-types";
// External components
import { Button, Card } from "rbx";
// Utils
import { formatCurrency } from "../../utils";
// Styles
import "./CreditDebitCard.scss";

const CreditDebitCard = ({ item, actions }) => (
  <Card key={item.id} className="card-content">
    <div className="body-card">
      <p>
        Key:<span className="bold-text break-text">{item?.Key} </span>{" "}
      </p>
      <p>
        Moneda:<span className="bold-text"> {item?.Coin?.Name_Coin} </span>{" "}
      </p>
      <p>
        Total: &nbsp;
        <span className="bold-text">
          {formatCurrency(
            parseFloat(item.TotalBill, 10),
            item?.Coin?.Code_Coin ? item?.Coin?.Code_Coin : "CRC",
          )}
        </span>
      </p>
      <div className="button-fixed-container">
        <div className="content-invoice">
          {item?.EmitedDay && (
            <p className="text-centered">
              Fecha: &nbsp;{" "}
              <span className="bold-text">
                {format(new Date(item?.EmitedDay), "dd/MM/yyyy hh:mm a")}
              </span>
            </p>
          )}
        </div>
        <div className="button-container">
          {!item?.DebitNote ? (
            <Button
              key={item.id}
              outlined
              className="btn-animate"
              color="primary"
              onClick={actions.onClick}
            >
              {actions.text}
            </Button>
          ) : (
            <span className="bold-text">Nota de débito aplicada</span>
          )}
        </div>
      </div>
    </div>
  </Card>
);

CreditDebitCard.propTypes = {
  item: PropTypes.object.isRequired,
  actions: PropTypes.object,
};
CreditDebitCard.defaultProps = {
  actions: {},
};

export default CreditDebitCard;
