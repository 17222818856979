/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Container, Title } from "rbx";
// Graphql
import { useMutation } from "@apollo/client";
import { USER_BY_BILLING_RECEIVER } from "../../graphql";
// Components
import MultiSelect from "../MultiSelect";
import { Input, Checkbox, PhoneInput } from "../Common";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { customToast as toast, validateEmail } from "../../utils";
// SCSS
import "./RegisterReceiver.scss";

function RegisterReceiver({
  setClient,
  setModalOpen,
  handleChangeAddress,
  clientList,
  initialReceiver,
  title,
  FK_User,
  setBillingProfile,
  newClient,
  handleCreateNewClient,
}) {
  const [receiver, setReceiver] = useState({
    id: null,
    FK_User,
    Name: "",
    IDForeign: "",
    Lastname: "",
    MH_PersonType: "1",
    ID_Number: "",
    Email: "",
    PhoneNumber: "",
    PhoneCodCountry: "506",
    MH_Province: "",
    MH_Canton: "",
    MH_District: "",
    MH_Neighborhood: "",
    OtherSigns: "",
  });
  const [IDForeign, setIDForeign] = useState(!!initialReceiver?.IDForeign);

  const {
    personTypeList,
    provinces,
    cantones,
    districts,
    neighborhoods,
    getDistricts,
    getNeighborhoods,
    getCantons,
    patchBillingProfile,
    reverseBillingName,
    getUserLocation,
    countries,
  } = useElectronic();

  const [registerReceiver] = useMutation(USER_BY_BILLING_RECEIVER);

  useEffect(() => {
    if (initialReceiver) {
      const { Name, Lastname } = reverseBillingName(initialReceiver?.Name);
      setReceiver({
        FK_User,
        id: initialReceiver?.id,
        Name: Name || "",
        Lastname: Lastname || "",
        MH_PersonType: initialReceiver?.MH_PersonType?.id || "1",
        IDForeign: initialReceiver?.IDForeign || "",
        ID_Number: initialReceiver?.ID_Number || "",
        Email: initialReceiver?.Email || "",
        PhoneNumber: initialReceiver?.PhoneNumber || "",
        PhoneCodCountry: initialReceiver?.PhoneCodCountry || "",
        MH_Province: initialReceiver?.MH_Province?.id || "",
        MH_Canton: initialReceiver?.MH_Canton?.id || "",
        MH_District: initialReceiver?.MH_District?.id || "",
        MH_Neighborhood: initialReceiver?.MH_Neighborhood?.id || "",
        OtherSigns: initialReceiver?.OtherSigns || "",
      });
      setIDForeign(!!initialReceiver?.IDForeign);
      getUserLocation(
        initialReceiver?.MH_Province?.id,
        initialReceiver?.MH_Canton?.id,
        initialReceiver?.MH_District?.id,
      );
    }
  }, [initialReceiver, FK_User]);

  useEffect(() => {
    setReceiver(prev => ({ ...prev, FK_User }));
  }, [FK_User]);

  const handleChange = (name, value) => {
    setReceiver(prev => ({ ...prev, [name]: value }));
    if (name === "MH_Province") {
      getCantons(value);
    } else if (name === "MH_Canton") {
      getDistricts(value);
    } else if (name === "MH_District") {
      getNeighborhoods(value);
    }
  };

  const handleSave = async e => {
    e.preventDefault();
    if (
      receiver.Name === "" ||
      receiver.Lastname === "" ||
      receiver.MH_PersonType === "" ||
      receiver.ID_Number === "" ||
      receiver.Email === "" ||
      receiver.PhoneNumber === "" ||
      receiver.PhoneCodCountry === "" ||
      receiver.MH_Province === "" ||
      receiver.MH_Canton === "" ||
      receiver.MH_District === "" ||
      receiver.MH_Neighborhood === "" ||
      receiver.OtherSigns === ""
    ) {
      toast.error("Debe completar todos los datos solicitados.");
    } else if (!validateEmail(receiver.Email)) {
      toast.error("El correo electrónico no tiene la estructura correcta.");
    } else if (FK_User) {
      receiver.Name = `${receiver.Name} ${receiver.Lastname}`;
      delete receiver.Lastname;
      const BillingProfile = await patchBillingProfile(receiver);
      setBillingProfile(BillingProfile);
      toast.success("Perfil de facturación actulizado.", {
        toastId: "billingProfile",
      });
      setModalOpen(false, "");
    } else if (!newClient) {
      register();
    } else if (newClient) {
      const isInList = clientList.find(
        index => index?.BillingProfile?.ID_Number === receiver.ID_Number,
      );
      if (isInList) {
        toast.error(
          "Ya existe un usuario con el número de identificación proporcionado asociado al grupo.",
        );
      } else {
        setModalOpen(false, "");
        handleCreateNewClient(receiver);
      }
    }
  };

  const register = async () => {
    try {
      const { data } = await registerReceiver({
        variables: {
          profile: receiver,
        },
      });

      setClient(prev => ({
        ...prev,
        id: data.billingProfile.id,
        Receiver_Id: receiver.ID_Number,
        Receiver_Name: `${receiver.Name} ${receiver.Lastname}`,
        Receiver_Email: receiver.Email,
        Receiver_PhoneCode: receiver.PhoneCodCountry,
        Receiver_PhoneNumber: receiver.PhoneNumber,
        Receiver: "",
        Receiver_PersonType: receiver.MH_PersonType,
      }));
      handleChangeAddress(
        "Province",
        data.billingProfile.MH_Neighborhood.District.Canton.Province.Name,
      );
      handleChangeAddress(
        "Canton",
        data.billingProfile.MH_Neighborhood.District.Canton.Name,
      );
      handleChangeAddress(
        "District",
        data.billingProfile.MH_Neighborhood.District.Name,
      );
      handleChangeAddress(
        "Neighborhood",
        data.billingProfile.MH_Neighborhood.Name,
      );
      handleChangeAddress("Other", data.billingProfile.OtherSigns);
      setModalOpen(false, "");
      toast.success("¡Cliente registrado con éxito!");
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Container fluid>
      <form className="edit-container" onSubmit={handleSave}>
        <div className="container-header">
          <Title aling="center" size={4}>
            {title}
          </Title>
        </div>
        <div className="evenly">
          <MultiSelect
            className="billing-input"
            data={personTypeList}
            label="Tipo de identificación:"
            name="MH_PersonType"
            value={receiver?.MH_PersonType}
            onChange={handleChange}
          />

          <Input
            className="billing-input"
            defaultValue={receiver?.ID_Number}
            label="Identificación"
            name="ID_Number"
            type="number"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <Input
            className="billing-input"
            defaultValue={receiver?.Name}
            label="Nombre:"
            name="Name"
            placeholder="Nombre"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <Input
            className="billing-input"
            defaultValue={receiver?.Lastname}
            label="Apellidos:"
            name="Lastname"
            placeholder="Apellidos"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <Checkbox
            className="billing-input"
            defaultValue={IDForeign}
            label="Identificación Extranjero"
            name="IDForeign"
            onChange={e => {
              setIDForeign(e.target.checked);
              handleChange(e.target.name, "");
            }}
          />

          <Input
            className="billing-input"
            defaultValue={receiver?.IDForeign}
            disabled={!IDForeign}
            label="Identificación Extranjero:"
            name="IDForeign"
            placeholder="Identificación Extranjero"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <Input
            requred
            className="billing-input"
            defaultValue={receiver?.Email}
            label="Correo Electrónico:"
            name="Email"
            placeholder="Correo Electrónico"
            type="email"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <PhoneInput
            className="billing-input"
            code={receiver?.PhoneCodCountry}
            codeKey="PhoneCodCountry"
            countries={countries}
            label="Número telefonico:"
            phone={receiver?.PhoneNumber}
            phoneKey="PhoneNumber"
            onChange={handleChange}
          />

          <MultiSelect
            className="billing-input"
            data={provinces}
            label="Provincia"
            name="MH_Province"
            value={receiver?.MH_Province}
            onChange={handleChange}
          />

          <MultiSelect
            className="billing-input"
            data={cantones}
            label="Cantón"
            name="MH_Canton"
            value={receiver?.MH_Canton}
            onChange={handleChange}
          />

          <MultiSelect
            className="billing-input"
            data={districts}
            label="Distrito"
            name="MH_District"
            value={receiver?.MH_District}
            onChange={handleChange}
          />

          <MultiSelect
            className="billing-input"
            data={neighborhoods}
            label="Barrio"
            name="MH_Neighborhood"
            value={receiver?.MH_Neighborhood}
            onChange={handleChange}
          />

          <Input
            textarea
            className="billing-input"
            defaultValue={receiver?.OtherSigns}
            label="Otras señas:"
            name="OtherSigns"
            placeholder="Otras señas"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <div className="profile-page-button">
            <Button rounded className="button" color="primary" type="submit">
              Guardar
            </Button>
          </div>
        </div>
      </form>
    </Container>
  );
}

RegisterReceiver.propTypes = {
  handleChangeAddress: PropTypes.func.isRequired,
  setClient: PropTypes.func,
  setModalOpen: PropTypes.func.isRequired,
  clientList: PropTypes.any,
  initialReceiver: PropTypes.any,
  title: PropTypes.any.isRequired,
  FK_User: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setBillingProfile: PropTypes.func.isRequired,
  newClient: PropTypes.bool,
  handleCreateNewClient: PropTypes.func,
};

RegisterReceiver.defaultProps = {
  initialReceiver: null,
  FK_User: null,
  clientList: null,
  handleCreateNewClient: () => null,
  setClient: () => null,
  newClient: false,
};

export default RegisterReceiver;
