import React from "react";
// Components
import { Products } from "../../components";

const ProductsPage = () => (
  <div>
    <Products />
  </div>
);

export default ProductsPage;
