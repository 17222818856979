/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable func-names */
/* eslint new-cap: ["error", { "newIsCap": false }] */

import React, { useEffect, useState } from "react";
// External components
import { Container, Control, Field } from "rbx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
// Graphql
import { useApolloClient } from "@apollo/client";
import { GET_PRODUCTS_BY_PLACE_AND_CRITERIA } from "../../../../graphql";
// Context
import { useAuth } from "../../../../context";
import { useElectronic } from "../../../../context/ElectronicContext";
// Utils
import { customToast as toast } from "../../../../utils";
// SCSS
import "react-spring-bottom-sheet/dist/style.css";
import "../../../ProductSelect/ProductSelect.scss";

function SelectFilterProduct() {
  const [defaultOptions, setDefaultOptions] = useState([]);

  const client = useApolloClient();

  const { LineDetailPlace } = useElectronic();
  const { state, formatAmount, formatAmountWithSymbol } = useAuth();
  const user = state.user.TavuelUser;
  const { DefaultCoin } = user;
  const isColon = DefaultCoin?.Symbol_Coin === "₡";

  useEffect(() => {
    setDefaultOptions([]);
  }, [LineDetailPlace.id]);

  const getUrl = product => {
    const route = product.url?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const validateRoute = product => {
    const route = getUrl(product);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
    }
    return route;
  };

  const getDetails = product => {
    let details = null;
    product.details?.forEach(item => {
      if (item?.Value_ProductFeatureDetail !== null) {
        details = !details
          ? item.Value_ProductFeatureDetail
          : `${details} / ${item.Value_ProductFeatureDetail}`;
      }
    });
    return details;
  };

  const newImage = src => {
    const image = document.createElement("img");
    if (src === "") {
      image.src =
        "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
      return image;
    }
    image.src = src;
    return image;
  };

  const validateData = data => {
    if (data && data !== "") {
      return data;
    }
    return "Sin datos";
  };

  const getImage = product => {
    const source = validateRoute(product);
    const img = newImage(source);
    return img;
  };

  const generatePDF = product => {
    const doc = new jsPDF();

    doc.setFont("times");
    doc.setFontSize(12);
    doc.text("Conta-Fast", 15, 15);
    doc.text("Información de producto", 15, 20);
    doc.text(`${state?.user?.displayName}`, 15, 25);
    doc.text(`${format(new Date(), "yyyy-MM-dd")}`, 174, 15);
    doc.text("", 15, 30);
    doc.text("", 15, 35);

    doc.text(`Nombre: ${validateData(product?.name)}`, 15, 40);
    doc.text(`Código Cabys: ${validateData(product?.cabys?.Code)} `, 15, 45);
    doc.text(`Descripción: ${validateData(product?.description)}`, 15, 50);

    doc.text(
      `Unidad de medida: ${validateData(product?.unit_Measure)}`,
      15,
      55,
    );
    const titlePrice = isColon ? "Precio en colones: " : "Precio: ";
    doc.text(
      `${titlePrice}${validateData(
        isColon
          ? formatAmount(product?.price)
          : formatAmountWithSymbol(product?.price),
      )}`,
      15,
      60,
    );

    doc.text(`IVA: ${validateData(product?.tariff?.Percent)} %`, 15, 65);

    const details = product.details?.filter(
      item =>
        item?.Value_ProductFeatureDetail &&
        item?.Value_ProductFeatureDetail !== null &&
        item.Value_ProductFeatureDetail !== "",
    );

    const tableRows = [];
    details.forEach(detail => {
      const ticketData = [detail?.Value_ProductFeatureDetail];
      tableRows.push(ticketData);
    });
    doc.autoTable({
      columns: ["Detalles del producto"],
      body: tableRows,
      margin: { top: 80 },
      startY: 80,
      styles: { halign: "center" },
      headStyles: { fillColor: [1, 35, 76] },
      alternateRowStyles: { fillColor: [231, 215, 252] },
      tableLineColor: [1, 35, 76],
      tableLineWidth: 0.1,
    });

    const image = getImage(product);

    doc.addImage(image, 150, 30, 50, 50);

    doc.save(
      `producto_${validateData(product?.description)}_${
        state?.user?.displayName
      }.pdf`,
    );

    toast.success("Reporte generado con éxito");
  };

  const handleChange = newValue => {
    generatePDF(newValue);
  };

  const CustomOption = value => {
    const { data } = value;

    return (
      <components.Option {...value}>
        {data && (
          <div className="option-container option-item">
            <div className="option-content-left">
              <div className="product-header title-product ">
                <h2 className="title-product ellipsis one-line ">
                  {data?.name}
                </h2>
                <h3 className="title-product ellipsis one-line ">
                  {data?.description}
                </h3>
                <div>
                  <p className="details-product ellipsis one-line">
                    {getDetails(data)}
                  </p>
                  <p>
                    {data?.unit_Measure} {formatAmount(data?.price)}
                  </p>
                  <p>Cabys: {data?.cabys?.Code}</p>
                  <p>Imp: {data?.tariff?.Percent}%</p>
                </div>
              </div>
            </div>

            <div className="product-img-option ">
              <img alt="product-pic" src={validateRoute(data)} />
            </div>
          </div>
        )}
      </components.Option>
    );
  };

  const loadSelectOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_PRODUCTS_BY_PLACE_AND_CRITERIA,
      variables: {
        id: LineDetailPlace.id,
        criteria: inputValue,
      },
    });

    const products = data?.products;

    const result = Array.isArray(products)
      ? products.map(item => {
          const { Details, Product, ProductPrice } = item;

          return {
            id: item.id,
            name: Product?.Name_Product,
            description: Product?.Description_Product,
            cabys: ProductPrice?.Cabys,
            tariff: ProductPrice?.Tariff,
            price: ProductPrice?.Price_ProductPrice.toFixed(3),
            quantity: 1,
            discount: [0],
            tax: ProductPrice?.Tax,
            taxes: [],
            totalTaxes: 0,
            total: ProductPrice?.Price_ProductPrice.toFixed(3),
            unit_Measure: Product?.InventoryDetail?.UnitOfMeasurement?.Symbol,
            product_Code: "",
            url: item?.Files,
            details: Details,
            value: item.id,
            label: Product?.Description_Product,
          };
        })
      : [];

    setDefaultOptions(result);
    return result;
  };

  return (
    <Container>
      <Field>
        <Control>
          <AsyncSelect
            key={LineDetailPlace.id}
            cacheOptions
            components={{ Option: CustomOption }}
            defaultOptions={defaultOptions}
            loadOptions={loadSelectOptions}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            placeholder="Buscar..."
            styles={{
              menuPortal: provided => ({
                ...provided,
                zIndex: 9999,
              }),
              indicatorsContainer: provided => ({
                ...provided,
                height: "inherit",
              }),
              indicatorSeparator: provided => ({
                ...provided,
                display: "none",
              }),
              container: provided => ({
                fontSize: 13,
              }),
              singleValue: provided => ({
                ...provided,
                top: "38%",
              }),
              control: provided => ({
                ...provided,
                "minHeight": 30,
                "height": 30,
                "width": "100%",
                "minWidth": 180,
                "borderRadius": "4px",
                "border": "0.1rem solid #01234c",
                "&:hover": {
                  borderColor: "hsl(0, 0%, 90%)",
                  borderRadius: 4,
                  cursor: "pointer",
                },
              }),
              placeholder: provided => ({ ...provided, top: "38%" }),
              menu: provided => ({
                ...provided,
                fontSize: 12,
                zIndex: 999,
              }),
              menuList: provided => ({
                ...provided,
                display: "flex",
                flexWrap: "wrap",
                fontSize: 12,
                zIndex: 999,
              }),
            }}
            onChange={handleChange}
          />
        </Control>
      </Field>
    </Container>
  );
}

export default SelectFilterProduct;
