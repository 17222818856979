/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
// External components
import { useTable, useSortBy, useExpanded } from "react-table";
import { Checkbox, Table as RbxTable } from "rbx";
// SCSS
import "../../DataTable.scss";
/**
 * colums: [{type="number|any"}]
 */
function Table({
  columns,
  data,
  onRowClick,
  initialSortBy,
  onChangeSort,
  renderRowSubComponent,
  isSubRow,
  hiddenColumns,
  selectedRows,
  setSelectedRows,
  selectable,
  selectField,
}) {
  const tableState = {
    pageIndex: 0,
    sortBy: initialSortBy,
    hiddenColumns,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
    },
    useSortBy,
    useExpanded,
  );

  const renderTableHeaderSort = column => {
    if (column.isSorted) {
      return column.isSortedDesc ? " ▼" : " ▲";
    }
    return "";
  };

  useEffect(() => {
    onChangeSort(sortBy);
  }, [onChangeSort, sortBy]);

  return (
    <div
      className="data-table-container"
      style={{ width: isSubRow ? "110%" : "100%" }}
    >
      <div className="table-container">
        <RbxTable {...getTableProps()} bordered fullwidth hoverable narrow>
          <RbxTable.Head>
            {headerGroups.map((headerGroup, i) => (
              <RbxTable.Row
                key={`head-row-${i}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {selectable && (
                  <RbxTable.Heading id="select-all-bills">
                    <Checkbox
                      checked={data.every(row =>
                        selectedRows.includes(row[selectField]),
                      )}
                      onChange={() =>
                        setSelectedRows(
                          selectedRows.length === 0
                            ? data.map(val => val[selectField])
                            : [],
                        )
                      }
                    />
                  </RbxTable.Heading>
                )}
                {headerGroup.headers.map((column, j) => (
                  <RbxTable.Heading
                    key={`heading-${j}`}
                    className={`${
                      column.type === "number" ? "right" : "left"
                    } has-text-white has-text-weight-bold`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      ...(typeof column.maxWidth === "string"
                        ? { width: column.maxWidth }
                        : { maxWidth: column.maxWidth || 400 }),
                      backgroundColor: "#0c3c77",
                    }}
                  >
                    {column.render("Header")}
                    <span>{renderTableHeaderSort(column)}</span>
                  </RbxTable.Heading>
                ))}
              </RbxTable.Row>
            ))}
          </RbxTable.Head>
          <RbxTable.Body {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={`body-row-${i}`}>
                  <RbxTable.Row
                    {...row.getRowProps()}
                    style={
                      row.isExpanded
                        ? { backgroundColor: "rgba(36, 168, 100, 30%)" }
                        : null
                    }
                    onClick={() => onRowClick(row.original)}
                  >
                    {selectable && (
                      <RbxTable.Cell>
                        <Checkbox
                          checked={selectedRows.includes(
                            row.original[selectField],
                          )}
                          onChange={() =>
                            setSelectedRows([row.original[selectField]])
                          }
                        />
                      </RbxTable.Cell>
                    )}
                    {row.cells.map((cell, j) => (
                      <RbxTable.Cell
                        key={`body-cell-${j}`}
                        className={
                          cell.column?.type === "number"
                            ? "right break-text"
                            : "left break-text"
                        }
                        data-title={cell?.column?.Header || ""}
                        style={
                          typeof cell.column?.maxWidth === "string"
                            ? { width: cell.column.maxWidth }
                            : { maxWidth: cell.column?.maxWidth || 400 }
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </RbxTable.Cell>
                    ))}
                  </RbxTable.Row>
                  {row.isExpanded ? (
                    <RbxTable.Row {...row.getRowProps()}>
                      {renderRowSubComponent({ row })}
                    </RbxTable.Row>
                  ) : null}
                </React.Fragment>
              );
            })}
          </RbxTable.Body>
        </RbxTable>
      </div>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  initialSortBy: PropTypes.array,
  onChangeSort: PropTypes.func,
  renderRowSubComponent: PropTypes.func,
  isSubRow: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  selectedRows: PropTypes.arrayOf(PropTypes.bool),
  setSelectedRows: PropTypes.func,
  selectable: PropTypes.bool,
  selectField: PropTypes.string,
};

Table.defaultProps = {
  columns: [],
  data: [],
  initialSortBy: [],
  onRowClick: () => null,
  onChangeSort: () => null,
  renderRowSubComponent: () => null,
  isSubRow: false,
  hiddenColumns: [],
  selectedRows: [],
  setSelectedRows: () => null,
  selectable: false,
  selectField: "",
};
export default Table;
