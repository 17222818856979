import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import {
  Container,
  Title,
  Button,
  Field,
  Control,
  Label,
  Input,
  Select,
} from "rbx";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
// GraphQL
import { useMutation, useQuery } from "@apollo/client";
import {
  REGISTER_USER_AND_SEND_EMAIL,
  UPDATE_USER_ROLE,
  GET_ROLES_BY_PLACE,
  REGISTER_USER_TO_PLACE_WITH_EXISTS_ACCOUNT,
  DELETE_USER_ROLE_BY_PLACE_AND_ROLE,
} from "../../../../graphql";
// Components
import SelectCountry from "../../../CountrySelect";
// Context
import { useElectronic } from "../../../../context/ElectronicContext";
import { useAuth } from "../../../../context";
// Utils
import { encrypt } from "../../../../utils";
// SCSS
import "./AddNewUser.scss";

function AddNewUser({
  setCloseModal,
  title,
  refetch,
  componentMode,
  editUser,
}) {
  const isEditMode = componentMode === "edit";
  const isAddUserWithAccount = componentMode === "UserExists";

  const [userRole, setUserRole] = useState("0");
  const [stateUserReg, setstateUserReg] = useState({
    Given_Name: "",
    Email: "",
    Password: encrypt(uuid().substring(0, 8)),
    Family_Name: "",
    BirthDate_Person: "",
    Phone_Number: "",
    PhoneCodCountry: "506",
    FK_Coin: "1",
    FK_Country: "52",
    AcceptTerms: true,
  });

  const { permissions } = useAuth();
  const { LineDetailPlace } = useElectronic();

  const { isSuperAdmin } = permissions;

  // queries
  const { data: rolesByPlaceData } = useQuery(GET_ROLES_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  // mutation
  const [registerUserAndSendEmail] = useMutation(REGISTER_USER_AND_SEND_EMAIL);
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE);
  const [deleteUserRoleByPlaceAndRole] = useMutation(
    DELETE_USER_ROLE_BY_PLACE_AND_ROLE,
  );

  const [registerUserToPlaceWithExistsAccount] = useMutation(
    REGISTER_USER_TO_PLACE_WITH_EXISTS_ACCOUNT,
  );

  useEffect(() => {
    if (isEditMode) {
      setstateUserReg({
        Given_Name: editUser?.FirebaseUser?.Name,
        Email: editUser?.FirebaseUser?.Email,
      });
      setUserRole(editUser.Roles[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    if (isAddUserWithAccount) {
      setstateUserReg({
        Given_Name: editUser.Name,
        Email: editUser.Email,
      });
      setUserRole("0");
    }
  }, [editUser, isAddUserWithAccount]);

  const handleChangeRegister = (name, value) => {
    setstateUserReg(prev => ({ ...prev, [name]: value }));
  };

  const handleCancel = useCallback(() => {
    setCloseModal(false);
  }, [setCloseModal]);

  const handleRegisterUser = useCallback(async () => {
    try {
      const {
        data: { registeredUser },
      } = await registerUserAndSendEmail({
        variables: {
          user: {
            ...stateUserReg,
            Locale: window.navigator.userLanguage || window.navigator.language,
            BirthDate_Person: new Date(stateUserReg.BirthDate_Person),
          },
          FK_Role: userRole,
        },
      });
      if (registeredUser) {
        toast.success("¡Usuario registrado con éxito!");
        handleCancel();
        refetch();
      } else {
        toast.error("Error al registrar el usuario");
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, [registerUserAndSendEmail, stateUserReg, userRole, handleCancel, refetch]);

  const handleUpdateRoleUser = useCallback(async () => {
    const NewInfo = {
      FK_User: editUser.id,
      FK_Role: userRole,
      FK_GeneralState: 1,
    };
    const idUserRole = editUser?.UserRoles?.find(e => e.FK_Role);
    try {
      const { data } = await updateUserRole({
        variables: {
          id: idUserRole.id,
          NewInfo,
        },
      });
      if (data) {
        toast.success("¡Rol de Usuario actualizado con éxito!");
        handleCancel();
        refetch();
      } else {
        toast.error("Error al actualizar rol de usuario");
      }
    } catch (err) {
      toast.error("Error al actualizar rol de usuario");
    }
  }, [
    editUser.id,
    editUser?.UserRoles,
    userRole,
    updateUserRole,
    handleCancel,
    refetch,
  ]);

  const handleDeleteRoleUser = useCallback(async () => {
    try {
      const { data } = await deleteUserRoleByPlaceAndRole({
        variables: {
          user: editUser.id,
          role: userRole,
        },
      });
      if (data) {
        toast.success("¡Rol de Usuario eliminado con éxito!");
        handleCancel();
        refetch();
      } else {
        toast.error("Error al eliminar rol de usuario");
      }
    } catch (err) {
      toast.error("Error al eliminar rol de usuario");
    }
  }, [
    editUser.id,
    deleteUserRoleByPlaceAndRole,
    userRole,
    handleCancel,
    refetch,
  ]);

  const handleSaveAndUser = async e => {
    e.preventDefault();
    if (
      stateUserReg.Given_Name === "" ||
      stateUserReg.Email === "" ||
      stateUserReg.Family_Name === "" ||
      stateUserReg.BirthDate_Person === "" ||
      stateUserReg.Phone_Number === "" ||
      userRole === 0
    ) {
      toast.error("Debe completar los datos solicitados.");
    } else if (!isEditMode) {
      await handleRegisterUser();
      refetch();
    } else {
      refetch();
    }
  };

  const handleUpdateUser = async e => {
    e.preventDefault();
    if (userRole === 0) {
      toast.error("Debe completar los datos solicitados.");
    } else {
      await handleUpdateRoleUser();
      refetch();
    }
  };

  const handleDeleteUserRole = async e => {
    e.preventDefault();
    await handleDeleteRoleUser();
    refetch();
  };

  const showSuperAdminRole = Name_Role => {
    if (Name_Role !== "Superadmin") {
      return true;
    }
    if (Name_Role === "Superadmin" && !isSuperAdmin) {
      return false;
    }
    return true;
  };

  const handleAddUserExists = async e => {
    e.preventDefault();
    if (userRole === 0) {
      toast.error("Debe completar los datos solicitados.");
      return;
    }
    const { data } = await registerUserToPlaceWithExistsAccount({
      variables: {
        FK_Role: userRole,
        FK_User: editUser.User.id,
      },
    });
    refetch();
    if (data) {
      toast.success("¡Usuario registrado con éxito!");
      handleCancel();
    }
  };

  return (
    <Container styles={{ "background-color": "powderblue" }}>
      <Title className="profile-page-title" textColor="grey-dark">
        {title}
      </Title>
      <div className="landing-page-inputs">
        <Field>
          <Control>
            <Label textColor="grey-dark">Correo electrónico: </Label>
            <Input
              required
              autoComplete="off"
              className={
                (isEditMode || isAddUserWithAccount) && "no-border-bottom"
              }
              disabled={isEditMode || isAddUserWithAccount}
              name="Email"
              placeholder="Correo electrónico"
              size="large"
              textColor="black"
              type="email"
              value={stateUserReg.Email}
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
          </Control>
        </Field>

        <Field>
          <Control>
            <Label textColor="grey-dark">Nombre del usuario(a): </Label>
            <Input
              autoComplete="off"
              className={
                (isEditMode || isAddUserWithAccount) && "no-border-bottom"
              }
              disabled={isEditMode || isAddUserWithAccount}
              name="Given_Name"
              placeholder="Nombre del encargado(a)"
              size="large"
              textColor="black"
              type="text"
              value={stateUserReg.Given_Name}
              onChange={e =>
                handleChangeRegister(e.target.name, e.target.value)
              }
            />
          </Control>
        </Field>
        {!isEditMode && !isAddUserWithAccount && (
          <div>
            <Field>
              <Control>
                <Label textColor="grey-dark">Apellidos del usuario(a): </Label>
                <Input
                  autoComplete="off"
                  disabled={isEditMode}
                  name="Family_Name"
                  placeholder="Apellidos del encargado(a)"
                  size="large"
                  textColor="black"
                  type="text"
                  value={stateUserReg.Family_Name}
                  onChange={e =>
                    handleChangeRegister(e.target.name, e.target.value)
                  }
                />
              </Control>
            </Field>

            <Field>
              <Control className="new-user-select">
                <Label textColor="grey-dark">Seleccione un país: </Label>
                <SelectCountry
                  disabled={isEditMode}
                  name="FK_Country"
                  textColor="black"
                  value={stateUserReg.FK_Country}
                  onChange={handleChangeRegister}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <Label textColor="grey-dark">Número de teléfono: </Label>
                <Input
                  autoComplete="off"
                  disabled={isEditMode}
                  name="Phone_Number"
                  placeholder="Número de teléfono"
                  size="large"
                  textColor="black"
                  type="number"
                  value={stateUserReg.Phone_Number}
                  onChange={e =>
                    handleChangeRegister(e.target.name, e.target.value)
                  }
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <Label className="label" textColor="grey-dark">
                  Fecha de nacimiento:
                </Label>
                <Input
                  disabled={isEditMode}
                  name="BirthDate_Person"
                  textColor="black"
                  type="date"
                  value={stateUserReg.BirthDate_Person}
                  onChange={e =>
                    handleChangeRegister(e.target.name, e.target.value)
                  }
                />
              </Control>
            </Field>
          </div>
        )}
        <Field>
          <Control>
            <Label className="label">Seleccione un rol:</Label>
            <Select.Container className="select">
              <Select
                textColor="black"
                value={userRole}
                onChange={e => setUserRole(e.target.value)}
              >
                <Select.Option textColor="white" value={0}>
                  Seleccione un rol...
                </Select.Option>
                {rolesByPlaceData?.getRolesByPlace.map(
                  role =>
                    showSuperAdminRole(role.Name_Role) && (
                      <Select.Option
                        key={role.id}
                        textColor="white"
                        value={role.id}
                      >
                        {role.Name_Role}
                      </Select.Option>
                    ),
                )}
              </Select>
            </Select.Container>
            {userRole !== "0" && isEditMode && (
              <Button
                color="danger"
                size="medium"
                onClick={handleDeleteUserRole}
              >
                Eliminar Rol
              </Button>
            )}
          </Control>
        </Field>
      </div>
      <Container className="newProductButtons">
        <Button className="edit-button" size="medium" onClick={handleCancel}>
          Cancelar
        </Button>
        {isAddUserWithAccount ? (
          <Button color="primary" size="medium" onClick={handleAddUserExists}>
            Guardar
          </Button>
        ) : (
          <Button
            color="primary"
            size="medium"
            onClick={isEditMode ? handleUpdateUser : handleSaveAndUser}
          >
            {isEditMode ? "Actualizar" : "Guardar"}
          </Button>
        )}
      </Container>
    </Container>
  );
}

AddNewUser.propTypes = {
  setCloseModal: PropTypes.func,
  title: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  componentMode: PropTypes.string.isRequired,
  editUser: PropTypes.object,
};

AddNewUser.defaultProps = {
  setCloseModal: e => e,
  editUser: {},
};

export default AddNewUser;
