import { client_EB_api } from "../graphql/client";
import {
  CHECK_TOKEN_EMAIL,
  GET_EMAILS_WITH_ATTACHED,
  GET_TOKEN_EMAIL,
  DELETE_TOKEN_EMAIL,
  GET_EMAILS_BY_GOOGLE_AUTH,
  UPDATE_PLACE_TOKEN_EMAIL,
} from "../graphql";

const useEmail = props => {
  const checkTokenEmail = (idEmail, type) => {
    const resp = client_EB_api.mutate({
      mutation: CHECK_TOKEN_EMAIL,
      variables: {
        idEmail,
        type,
      },
    });
    return resp;
  };

  const getTokenEmail = (googleAuthId, code, type, place) => {
    const resp = client_EB_api.mutate({
      mutation: GET_TOKEN_EMAIL,
      variables: {
        googleAuthId,
        code,
        type,
        place,
      },
    });
    return resp;
  };

  const getEmails = async (
    idEmail,
    fromDate,
    toDate,
    limit,
    start,
    nextPage,
    type,
  ) => {
    const res = client_EB_api.query({
      query: GET_EMAILS_WITH_ATTACHED,
      variables: {
        idEmail,
        fromDate,
        toDate,
        ...(limit !== "Todas" && { limit: parseInt(limit, 10) }),
        start,
        nextPage,
        type,
      },
      fetchPolicy: "network-only",
    });
    return res;
  };

  const deleteTokenEmail = (idEmail, type) => {
    const resp = client_EB_api.mutate({
      mutation: DELETE_TOKEN_EMAIL,
      variables: {
        idEmail,
        type,
      },
    });
    return resp;
  };

  const updatePlaceTokenEmail = (idEmail, FK_Place) => {
    const resp = client_EB_api.mutate({
      mutation: UPDATE_PLACE_TOKEN_EMAIL,
      variables: {
        idEmail,
        FK_Place,
      },
    });
    return resp;
  };

  const getEmailsAccounts = async googleAuthId => {
    const res = await client_EB_api.query({
      query: GET_EMAILS_BY_GOOGLE_AUTH,
      variables: {
        googleAuthId,
      },
      fetchPolicy: "network-only",
    });
    return res;
  };

  return {
    checkTokenEmail,
    getTokenEmail,
    getEmails,
    deleteTokenEmail,
    getEmailsAccounts,
    updatePlaceTokenEmail,
  };
};

useEmail.propTypes = {};

export default useEmail;
