import React from "react";
// External components
import { Box, Card, Column, Field } from "rbx";
// Components
import { Input } from "../../components/Common";
import {
  Divider,
  ClientCard,
  PaymentDataCard,
  OptionCard,
} from "../../components";
// Context
import { useElectronic } from "../../context/ElectronicContext";
import { useAuth } from "../../context";

function ExportationBillPage(props) {
  const { receiver, LineDetailPlace } = useElectronic();
  const {
    state: { user },
  } = useAuth();

  return (
    <div>
      <Card.Content>
        <h1>Encabezado</h1>
        <Divider type="shadow" />

        <h3>
          <small>Nombre:</small> {user.displayName}
        </h3>
        <h3>
          <small>Nombre del Local:</small> {LineDetailPlace.Name_Place}
        </h3>
        <h3>
          <small>Identificacion:</small> {user.userid}
        </h3>
        <h3>
          <small>Telefono</small> {user.phoneNumber}
        </h3>
        <h3>
          <small>Fax: </small>---
        </h3>
        <h3>
          <small>Correo Electronico: </small> {LineDetailPlace.Gmail}
        </h3>
        <h3>
          <small>Dirreccion: </small> Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Consectetur, sunt!
        </h3>
      </Card.Content>
      <Divider type="transparent" />
      <Box>
        <h2>Información Básica</h2>

        <Column.Group>
          <Column>
            <Input
              disabled
              label="Codigo interno (codigo auto generado)"
              placeholder="Codigo interno (codigo auto generado)"
            />
          </Column>
          <Column>
            <Input
              label="Codigo de La actividad"
              palceholder="Codigo de La actividad "
            />
          </Column>
          <Column>
            <Input label="Fecha de Vencimiento:" type="date" />
          </Column>
        </Column.Group>
        <Column.Group>
          <Column>
            <Field>
              <Input label="Orden de compra" placeholder="Orden de compra" />
            </Field>
          </Column>
          <Column>
            <Field>
              <Input
                label="Partida Arancelaria ( obligatorio en caso de venta de productos)"
                placeholder="Orden de compra"
              />
            </Field>
          </Column>
        </Column.Group>

        <Divider type="shadow" />
        <OptionCard
          mainComponent
          icon="shopping-basket"
          title="Añadir productos"
        />
        <ClientCard
          optional={
            receiver.Receiver_Id === ""
              ? "Click para buscar (opcional)"
              : ` ${receiver?.Receiver_Name}, 
              ${receiver?.Receiver_Id}`
          }
          optional2={
            receiver?.Receiver_Email === "" &&
            receiver?.Receiver_PhoneNumber === ""
              ? ""
              : ` ${receiver?.Receiver_Email}, 
            ${receiver?.Receiver_PhoneNumber}`
          }
        />
        <PaymentDataCard />
        <Divider type="transparent" />
      </Box>
    </div>
  );
}

ExportationBillPage.propTypes = {};

export default ExportationBillPage;
