import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./NotificationCard.scss";

function NotificationCard({
  tabIndex,
  title,
  icon,
  body,
  link,
  date,
  isOpened,
}) {
  const history = useHistory();
  const onCardClicked = () => {
    if (link === "") return;
    history.push(link);
  };

  return (
    <div
      aria-hidden="true"
      className={`card-content notification-card ${
        link !== "" && "is-clickable"
      } ${!isOpened && "is-not-opened"}`}
      role="button"
      tabIndex={tabIndex}
      onClick={onCardClicked}
    >
      <h2 className="notification-header">{title}</h2>
      <br />
      <div className="notification-content">{body}</div>
      <br />
      <div className="notification-footer">{date}</div>
    </div>
  );
}

NotificationCard.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.string,
  date: PropTypes.string,
  isOpened: PropTypes.bool,
};

NotificationCard.defaultProps = {
  body: "",
  link: "",
  icon: "",
  date: "dd/mm/yyyy",
  isOpened: false,
};

export default NotificationCard;
