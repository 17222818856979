import React from "react";
import { useHistory } from "react-router-dom";
// External components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Icon } from "rbx";
import { toast } from "react-toastify";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ROLES_BY_PLACE } from "../../../graphql/role/role.queries";
// Context
import { useAuth, useModal } from "../../../context";
import { useElectronic } from "../../../context/ElectronicContext";
// Components
import NewOrEditRole from "./NewOrEditRole/NewOrEditRole";

function Roles() {
  const { setModalOpen } = useModal();
  const { LineDetailPlace } = useElectronic();
  const { data: rolesByPlaceData, refetch } = useQuery(GET_ROLES_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  const { permissions } = useAuth();
  const history = useHistory();

  const { haveActionPermission, noAccessMessage } = permissions;

  const canRead = haveActionPermission("Read", "/security");
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Roles"));
    history.push(`/`);
  }

  const handleNewRole = e => {
    e.preventDefault();
    setModalOpen(
      true,
      <NewOrEditRole
        componentMode="create"
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Agregar Rol"
      />,
    );
  };

  const handleUpdateRole = role => {
    if (!haveActionPermission("Edit", "/security")) return;
    setModalOpen(
      true,
      <NewOrEditRole
        componentMode="update"
        editRole={role}
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Actualizar Rol"
      />,
    );
  };

  return (
    <div>
      {haveActionPermission("Create", "/security") && (
        <div className="headerButton">
          <Button color="primary" size="medium" onClick={e => handleNewRole(e)}>
            Agregar Nuevo Rol
          </Button>
        </div>
      )}
      <div className="grid">
        {rolesByPlaceData?.getRolesByPlace.map(role => (
          <Card
            key={role.id}
            className="card-content-group groups-content"
            onClick={() => handleUpdateRole(role)}
          >
            <div className="flex">
              <div className="product-img ">
                <Icon size="large">
                  <FontAwesomeIcon
                    className="hover-table-options"
                    icon="cog"
                    size="3x"
                  />
                </Icon>
              </div>
            </div>
            <div className="flex middle ">
              <p className="detail-product ellipsis three-lines">
                {role.Name_Role}
              </p>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Roles;
