/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import PropTypes from "prop-types";
// External components
import { Button, Checkbox, Input, Select } from "rbx";
import { useTable, usePagination } from "react-table";
// Components
// SCSS
import "./Paginated.scss";

function Paginated({
  columns,
  data,
  selectables,
  selectedRows,
  setSelectedRows,
  selectField,
  getExtraComponent,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
  );

  const { pageIndex, pageSize } = state;

  return (
    <div className="table_container">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {selectables && (
                <th>
                  {" "}
                  <Checkbox
                    checked={data.every(row =>
                      selectedRows.includes(row[selectField]),
                    )}
                    onChange={() =>
                      setSelectedRows(
                        selectedRows.length === 0
                          ? data.map(val => val[selectField])
                          : [],
                      )
                    }
                  />
                </th>
              )}
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{ width: column.width }}
                >
                  {column.render("Header")}
                </th>
              ))}
              {getExtraComponent && <th style={{ width: 30 }}> </th>}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {selectables && (
                  <td>
                    <Checkbox
                      checked={selectedRows.includes(row.original[selectField])}
                      onChange={() =>
                        setSelectedRows([row.original[selectField]])
                      }
                    />
                  </td>
                )}
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.render("Cell").props.cell.value !== undefined &&
                    cell.render("Cell").props.cell.value !== null
                      ? cell.render("Cell")
                      : "Sin datos"}
                  </td>
                ))}
                {getExtraComponent && <td>{getExtraComponent(row)}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <Button
          color="primary"
          disabled={!canPreviousPage}
          onClick={() => gotoPage(0)}
        >
          {"<<"}
        </Button>{" "}
        <Button
          color="secondary"
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
        >
          Anterior
        </Button>{" "}
        <Button
          color="secondary"
          disabled={!canNextPage}
          onClick={() => nextPage()}
        >
          Siguiente
        </Button>{" "}
        <Button
          color="primary"
          disabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        >
          {">>"}
        </Button>{" "}
        <span>
          Página{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Ir a la página:{" "}
          <Input
            defaultValue={pageIndex + 1}
            style={{ width: 50 }}
            type="number"
            onChange={e => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
          />
        </span>{" "}
        <Select.Container>
          <Select
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map(size => (
              <Select.Option key={size} value={size}>
                Mostrar {size}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
      </div>
    </div>
  );
}

Paginated.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  selectables: PropTypes.bool,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  selectField: PropTypes.string,
  getExtraComponent: PropTypes.func,
};
Paginated.defaultProps = {
  selectables: false,
  selectedRows: [],
  setSelectedRows: e => null,
  selectField: "",
  getExtraComponent: null,
};

export default Paginated;
