import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "./CustomDatePicker.scss";

const CustomDatePicker = ({
  className,
  dateFormat,
  placeholderText,
  selectedDate,
  showFullDatePicker,
  onChange,
}) => {
  const getNavigatorLanguage = () => {
    const { language } = navigator;
    return language[0].toUpperCase() + language[1];
  };
  return (
    <ReactDatePicker
      showFullMonthYearPicker
      className={`date-picker has-text-centered ${className}`}
      dateFormat={dateFormat}
      locale={getNavigatorLanguage()}
      maxDate={new Date()}
      placeholderText={placeholderText}
      selected={selectedDate}
      showMonthYearPicker={!showFullDatePicker}
      onChange={onChange}
    />
  );
};

CustomDatePicker.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  placeholderText: PropTypes.string,
  selectedDate: PropTypes.object,
  showFullDatePicker: PropTypes.bool,
  onChange: PropTypes.func,
};

CustomDatePicker.defaultProps = {
  className: "",
  dateFormat: "MMMM / yyyy",
  placeholderText: "Seleccione una Fecha",
  selectedDate: null,
  showFullDatePicker: false,
  onChange: null,
};

export default CustomDatePicker;
