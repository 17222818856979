import React, { useState, useEffect } from "react";
// Components
import { Loading, Login, Register, ForgotPassword } from "../../components";
// Context
import { useModal, useAuth } from "../../context";
// Utils
import { customToast as toast } from "../../utils";

function LandingPage() {
  const [state, setState] = useState({ email: "", password: "" });
  const [loginSection, setLoginSection] = useState(true);
  const [stateUserReg, setstateUserReg] = useState({
    Given_Name: "",
    Email: "",
    Password: "",
    PasswordConfirm: "",
    Family_Name: "",
    BirthDate_Person: "",
    Phone_Number: "",
    PhoneCodCountry: "506",
    FK_Coin: "1",
    FK_Country: "52",
    AcceptTerms: false,
  });

  const {
    handleLogin,
    handleCheckAuth,
    handleLoginGoogle,
    handleRegisterUser,
    checking,
    isLoading,
  } = useAuth();

  const { setModalOpen } = useModal();

  const handleChangeRegister = (name, value) => {
    setstateUserReg(prev => ({ ...prev, [name]: value }));
  };

  const handleChange = (name, value) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleValidations = () => {
    const { Password, PasswordConfirm, Phone_Number, AcceptTerms } =
      stateUserReg;
    try {
      if (Password.length < 8) {
        toast.error("La contraseña debe ser de mínimo 8 dígitos");
      } else if (Password !== PasswordConfirm) {
        toast.error(
          "La contraseña y confirmación de contraseñas deben coincidir",
        );
      } else if (Phone_Number.length !== 8) {
        toast.error("El número de telefono debe ser de exacatamente 8 dígitos");
      } else if (!AcceptTerms) {
        toast.error(
          "Debe aceptar los términos y condiciones para poder realizar el registro",
        );
      } else if (Password === PasswordConfirm) {
        const confPassword = stateUserReg.PasswordConfirm;
        delete stateUserReg.PasswordConfirm;
        handleRegisterUser({ ...stateUserReg });
        setstateUserReg(prev => ({ ...prev, PasswordConfirm: confPassword }));
      }
    } catch (err) {
      toast.error("Error al registrar");
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (loginSection) {
      handleLogin(state.email, state.password);
    } else {
      handleValidations();
    }
  };

  const handleChangeAcceptTerms = (name, value) => {
    try {
      if (value === 1) {
        setstateUserReg(prev => ({ ...prev, [name]: true }));
      } else {
        setstateUserReg(prev => ({ ...prev, [name]: false }));
      }
    } catch (err) {
      toast.error("Error al registrar");
    }
  };

  const handleGoogle = e => {
    if (loginSection) {
      handleLoginGoogle();
    }
  };

  const openForgotPassword = () => {
    setModalOpen(true, <ForgotPassword setModalOpen={setModalOpen} />);
  };

  useEffect(() => {
    handleCheckAuth();
  }, [handleCheckAuth]);

  if (checking || isLoading) {
    return <Loading />;
  }

  return (
    <div className="principal-container landing-page">
      <form
        className={
          loginSection
            ? "principal-container landing-page-container"
            : "principal-container landing-page-container-register"
        }
        onSubmit={handleSubmit}
      >
        {loginSection && (
          <Login
            handleChange={handleChange}
            handleGoogle={handleGoogle}
            loginSection={loginSection}
            openForgotPassword={openForgotPassword}
            setLoginSection={setLoginSection}
          />
        )}
        {!loginSection && (
          <Register
            handleChangeAcceptTerms={handleChangeAcceptTerms}
            handleChangeRegister={handleChangeRegister}
            loginSection={loginSection}
            setLoginSection={setLoginSection}
            stateUserReg={stateUserReg}
          />
        )}
      </form>
    </div>
  );
}

LandingPage.propTypes = {};

export default LandingPage;
