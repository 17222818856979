import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import {
  Button,
  Column,
  Help,
  Input,
  Notification,
  Progress,
  Select,
} from "rbx";
import { toast } from "react-toastify";
import * as converter from "xml-js";
// Hooks
import useZoho from "../../../../hooks/useEmail";
// Context
import { useElectronic } from "../../../../context/ElectronicContext";
// Utils
import { toZohoMonths } from "../../../../utils/helpers";
// Enum
import EMAIL_PROVIDERS from "../../../../Enums/EmailToken";
// SCSS
import "./ZohoSearch.scss";

function ZohoSearch({
  setIsLogged,
  setXmls,
  quantityEmails,
  setQuantityEmails,
  nextPage,
  setNextPage,
}) {
  const [isLoadingBills, setIsLoadingBills] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [checkAccountSelected, setCheckAccountSelected] = useState(true);
  const limitSelectValues = ["10", "20", "30", "40", "50", "Todas"];

  const { dates, setDates, accountSelected } = useElectronic();
  const { getEmails, checkTokenEmail } = useZoho();

  const checkLoginZoho = useCallback(
    async account => {
      try {
        const res = await checkTokenEmail(
          account?.id,
          parseInt(account?.type, 10),
        );
        setCheckAccountSelected(res?.data?.email);
        return res?.data?.email;
      } catch (err) {
        return false;
      }
    },
    [checkTokenEmail],
  );

  const searchDocuemnts = async () => {
    setNoResults(false);
    if (isInvalidForm()) {
      toast.error("Hay errores, por favor revise las fechas");
      return;
    }
    setIsLoadingBills(true);
    const { data: isTokenValid } = await checkTokenEmail(
      accountSelected?.id,
      parseInt(accountSelected?.type, 10),
    );
    if (!isTokenValid.zoho) {
      setIsLogged(false);
    }
    const { data } = await getEmails(
      parseInt(accountSelected?.id, 10),
      accountSelected?.type === EMAIL_PROVIDERS.Zoho
        ? toZohoMonths(dates.startDate.value)
        : dates.startDate.value.replaceAll("-", "/"),
      accountSelected?.type === EMAIL_PROVIDERS.Zoho
        ? toZohoMonths(dates.endDate.value)
        : dates.endDate.value.replaceAll("-", "/"),
      quantityEmails,
      1,
      "0",
      parseInt(accountSelected?.type, 10),
    );
    const xmls = data.data.map(email => getXMLFromEmailFiles(email.files));
    if (accountSelected?.type === EMAIL_PROVIDERS.Gmail && xmls?.length > 0) {
      setNextPage(data.data[0].nextPage?.toString());
    }
    setXmls(xmls.flat());
    setIsLoadingBills(false);
    if (data.data.length === 0) {
      setNoResults(true);
    }
  };

  const getXMLFromEmailFiles = emailFiles =>
    emailFiles
      .map(file => {
        try {
          const xml = {
            ...JSON.parse(
              converter.xml2json(
                accountSelected.type === EMAIL_PROVIDERS.Gmail ||
                  accountSelected.type === EMAIL_PROVIDERS.Outlook
                  ? decodeBase64(file.content)
                  : file.content,
                { compact: true },
              ),
            ),
            isOutOfDateRange: false,
          };
          const invoiceDate = new Date(
            xml?.FacturaElectronica?.FechaEmision?._text.split("T")[0],
          );
          const startDate = new Date(dates.startDate.value);
          const endDate = new Date(dates.endDate.value);
          if (
            isInvalidDate(invoiceDate) ||
            invoiceDate > endDate ||
            invoiceDate < startDate
          ) {
            xml.isOutOfDateRange = true;
          }
          return xml;
        } catch (e) {
          return { error: true };
        }
      })
      .filter(xmlJSON => !xmlJSON.error && !xmlJSON.MensajeHacienda);

  const decodeBase64 = str => {
    const response = str.replace(/_/g, "/").replace(/-/g, "+");
    return atob(response);
  };

  const handleSetDate = (name, date) => {
    setNoResults(false);
    setDates(prev => ({ ...prev, [name]: { ...prev[name], value: date } }));
  };

  const handleSetErrors = (name, error) => {
    setDates(prev => ({ ...prev, [name]: { ...prev[name], errors: error } }));
  };

  const handleSetFormErrors = (name, value) => {
    if (isEmptyDate(name)) {
      handleSetErrors(name, "El campo no puede estar vacio");
      return true;
    }
    if (isInvalidDate()) {
      handleSetErrors(
        name,
        "La fecha de inicio debe de ser menor o igual a la final",
      );
      return true;
    }

    if (checkIsFutureDate(value)) {
      handleSetErrors(
        name,
        "No se puede buscar un documento a una fecha futura a la actual",
      );
      return true;
    }

    handleSetErrors(name, "");
    return false;
  };

  const isInvalidForm = () =>
    handleSetFormErrors("startDate") || handleSetFormErrors("endDate");

  const isEmptyDate = name => dates[name].value === "";

  const isInvalidDate = () =>
    new Date(dates.endDate.value).getTime() <
    new Date(dates.startDate.value).getTime();

  const checkIsFutureDate = dateText =>
    new Date().getTime() < new Date(dateText).getTime();

  const areThereAnyFutureDate =
    checkIsFutureDate(dates.startDate.value) ||
    checkIsFutureDate(dates.endDate.value);

  useEffect(() => {
    accountSelected.id !== 0 && checkLoginZoho(accountSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelected]);

  return (
    <React.Fragment>
      <Column.Group centered mobile multiline className="ml-1 mr-1 card">
        <Column size="3">
          <h1>desde</h1>
          <Input
            className="is-align-content-end"
            color={dates.startDate.errors === "" ? "" : "danger"}
            name="startDate"
            type="date"
            value={dates.startDate.value}
            onBlur={({ target: { name, value } }) => {
              handleSetFormErrors(name, value);
              !isInvalidDate() &&
                handleSetFormErrors("endDate", dates.endDate.value);
            }}
            onChange={({ target: { name, value } }) => {
              handleSetDate(name, value);
            }}
          />
          <Help color={`${dates.startDate.errors ? "danger" : "success"}`}>
            {dates.startDate.errors || "Fecha de inicio"}
          </Help>
        </Column>
        <Column size="3">
          <h1>hasta</h1>
          <Input
            color={dates.endDate.errors === "" ? "" : "danger"}
            name="endDate"
            type="date"
            value={dates.endDate.value}
            onBlur={({ target: { name, value } }) => {
              handleSetFormErrors(name, value);
              !isInvalidDate() &&
                handleSetFormErrors("startDate", dates.startDate.value);
            }}
            onChange={({ target: { name, value } }) =>
              handleSetDate(name, value)
            }
          />
          <Help color={`${dates.endDate.errors ? "danger" : "success"}`}>
            {dates.endDate.errors || "Fecha final"}
          </Help>
        </Column>
        <Column size="3">
          <h1>Cantidad de facturas</h1>
          <Select.Container fullwidth>
            <Select
              name="quantityEmails"
              value={quantityEmails}
              onChange={({ target: { name, value } }) => {
                setQuantityEmails(value);
              }}
            >
              {limitSelectValues.map(index => (
                <Select.Option key={index} value={index}>
                  {index}
                </Select.Option>
              ))}
            </Select>
          </Select.Container>
        </Column>
        <Column size="3">
          <div className="zoho-search__button-container">
            <Button
              className="zoho-search__button"
              color="primary"
              disabled={
                isLoadingBills || areThereAnyFutureDate || !checkAccountSelected
              }
              size="large"
              onClick={() => searchDocuemnts()}
            >
              Buscar
            </Button>
            {!checkAccountSelected && (
              <Help color="danger">
                La sesión ha expirado, inicie seción de nuevo.
              </Help>
            )}
          </div>
        </Column>
      </Column.Group>
      <Column.Group centered>
        {noResults && (
          <Column size="8">
            <Notification className="no-results" textAlign="centered">
              <h2 className="no-results-text">No se encontraron resultados</h2>
              <h2 className="no-results-text">
                para los parámetros selecionados.
              </h2>
            </Notification>
          </Column>
        )}
        {isLoadingBills && (
          <Column size="11">
            <Progress color="info" size="small" />
          </Column>
        )}
      </Column.Group>
    </React.Fragment>
  );
}

ZohoSearch.propTypes = {
  setIsLogged: PropTypes.func.isRequired,
  setXmls: PropTypes.func.isRequired,
  quantityEmails: PropTypes.string.isRequired,
  setQuantityEmails: PropTypes.func.isRequired,
  nextPage: PropTypes.string.isRequired,
  setNextPage: PropTypes.func.isRequired,
};

export default ZohoSearch;
