import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
// External components
import { Label, Control, Field, Select } from "rbx";
import { ElectronicContext } from "../../context/ElectronicContext";

function IvaSelect({ value, name, onChange, disabled, className }) {
  const { tariff } = useContext(ElectronicContext);
  useEffect(() => {
    if (value !== undefined || !tariff?.tariff) return;
    onChange(name, { Name: "SinSeleccionar", id: 0, Percent: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariff, name, value]);

  return (
    <div>
      <Field className={className}>
        <Control expanded>
          <Label>Iva %</Label>
          <Select.Container fullwidth>
            <Select
              disabled={disabled}
              name={name}
              value={value}
              onChange={e => onChange(e.target.name, e.target.value)}
            >
              <Select.Option key={0} value={0}>
                Sin Seleccionar
              </Select.Option>
              {tariff?.tariff?.map(index => (
                <Select.Option key={index.id} value={index.id}>
                  {index.Name}
                </Select.Option>
              ))}
            </Select>
          </Select.Container>
        </Control>
      </Field>
    </div>
  );
}

IvaSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

IvaSelect.defaultProps = {
  disabled: false,
  className: "",
};

export default IvaSelect;
