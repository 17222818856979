import React, { useEffect, useState } from "react";
// External component
import { Title } from "rbx";
// SCSS
import "./NotificationsPage.scss";
// Components
import { toast } from "react-toastify";
import CustomLoading from "../../components/CustomLoading";
import NotificationCard from "../../components/NotificationCard";
import Inventories from "../../components/Inventories";
// Context
import { useElectronic } from "../../context/ElectronicContext";
import { useNotifications } from "../../context/NotificationsContext";

function NotificationsPage() {
  const [pageNotifications, setPageNotifications] = useState([]);

  const { LineDetailPlace } = useElectronic();
  const {
    isLoading: isLoadingNotifications,
    setIsLoading: setIsLoadingNotifications,
    setNotifications,
    refetchNotifications,
    openNotifications,
  } = useNotifications();

  useEffect(() => {
    loadAndOpenNotifications();
    return async () => setNotifications(await refetchNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LineDetailPlace.id]);

  const loadAndOpenNotifications = async () => {
    setIsLoadingNotifications(true);
    const response = await refetchNotifications();
    if (!response) return toast.error("Error obteniendo notificaciones");
    setPageNotifications(response);
    setNotifications(pageNotifications);
    openNotifications(response);
    return setIsLoadingNotifications(false);
  };

  return (
    <React.Fragment>
      <Title className="text-center">Notificaciones</Title>
      {!LineDetailPlace.id && <Inventories />}
      {isLoadingNotifications ? (
        <CustomLoading centered vCentered />
      ) : (
        <div className="notifications-container">
          {pageNotifications.map((x, i) => (
            <NotificationCard
              key={x.id}
              body={JSON.parse(x.Data).body || JSON.parse(x.Data).Body}
              date={new Date(x.Updated_At).toLocaleDateString().toString()}
              icon={JSON.parse(x.Data).icon || JSON.parse(x.Data).Icon}
              isOpened={!!x.Notification_User[0]?.Readed_At}
              link={
                JSON.parse(x.Data).clickAction || JSON.parse(x.Data).clickAction
              }
              tabIndex={i}
              title={JSON.parse(x.Data).title || JSON.parse(x.Data).Title}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
}

export default NotificationsPage;
