import React, { useState } from "react";
import PropTypes from "prop-types";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { formatNumber } from "../../utils/ReportUtils";

function ExpansionPanelHeader({ bill }) {
  const [isActive, setIsActive] = useState(false);
  const { useCoinDetail } = useElectronic();
  const { toExchangeRate } = useCoinDetail;

  return (
    <div className="accordion-item">
      <div
        key={bill.InternConsecutive}
        className="accordion-title"
        role="button"
        tabIndex="0"
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => setIsActive(!isActive)}
      >
        <div className="iva-report-sub-bill">
          <p>{formatNumber(toExchangeRate(bill?.TotalBill))}</p>
          <p>{formatNumber(toExchangeRate(bill?.TotalTaxes))}</p>
        </div>

        <div>{isActive ? "-" : "+"}</div>
      </div>
      {isActive && (
        <div className="accordion-content">
          <div className="total-details-bill">
            <p>
              Total Mercancías Exentas: &nbsp;
              {formatNumber(toExchangeRate(bill?.TotalGoodsExempt))}
            </p>
            <p>
              Total Mercancías Gravadas: &nbsp;
              {formatNumber(toExchangeRate(bill?.TotalGoodsTaxed))}
            </p>
            <p>
              Total Servicios Exentos: &nbsp;
              {formatNumber(toExchangeRate(bill?.TotalServicesExempt))}
            </p>
            <p>
              Total Servicios Gravados: &nbsp;
              {formatNumber(toExchangeRate(bill?.TotalServicesTaxed))}
            </p>
            <p>
              Total Gravado: {formatNumber(toExchangeRate(bill?.TotalTaxed))}
            </p>
            <p>
              Total Exento: {formatNumber(toExchangeRate(bill?.TotalExempt))}
            </p>
            <p>Impuestos: {formatNumber(toExchangeRate(bill?.TotalTaxes))}</p>
            <p>
              Total Comprobante: {formatNumber(toExchangeRate(bill?.TotalBill))}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
ExpansionPanelHeader.propTypes = {
  bill: PropTypes.object.isRequired,
};
export default ExpansionPanelHeader;
