/* eslint-disable react/jsx-props-no-spreading */
import { useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { components } from "react-select";
import PropTypes from "prop-types";
import { Container, Control, Field, Card, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsyncSelect from "react-select/async";
import { GET_CLIENTS_BY_CRITERIA } from "../../../../graphql";
import "react-spring-bottom-sheet/dist/style.css";
import "../../../ProductSelect/ProductSelect.scss";
import "./SelectProfile.scss";
import { useElectronic } from "../../../../context/ElectronicContext";

const SelectProfile = ({
  clientList,
  handleSaveNewClient,
  deleteFavoriteClient,
}) => {
  const client = useApolloClient();
  const history = useHistory();
  const { LineDetailPlace, setReceiver, setAddress, setClientGroup } =
    useElectronic();
  const [defaultOptions, setDefaultOptions] = useState([]);

  // handles
  const handleChange = newValue => {
    // select client
    const billingProfile = {
      id: newValue.id,
      Receiver_Id: newValue.ID_Number,
      Receiver_Name: newValue.Name,
      Receiver_Email: newValue.Email,
      Receiver_PhoneCode: newValue.PhoneCodCountry,
      Receiver_PhoneNumber: newValue.PhoneNumber,
      Receiver_PersonType: newValue.MH_PersonType,
      Receiver_UserId: newValue.idUser,
    };
    // Add address
    const address = {
      Province: newValue.MH_Neighborhood.District.Canton.Province,
      Canton: newValue.MH_Neighborhood.District.Canton,
      District: newValue.MH_Neighborhood.District,
      Neighborhood: newValue.MH_Neighborhood,
      Other: newValue.OtherSigns,
    };
    setAddress(address);
    setReceiver(billingProfile);
    setClientGroup({ id: 0 });
    history.goBack();
  };

  // functions
  const selectIcon = TypeId => {
    // star icon
    if (TypeId === "1") {
      return "user";
    }
    return "building";
  };

  const CustomOption = value => {
    const { data } = value;
    const option = data;

    return (
      <components.Option {...value}>
        {option && (
          <Container className="favorite-container">
            <Card key={option.ID_Number} className="favorite-card ">
              <div className="flex">
                <Icon>
                  <FontAwesomeIcon
                    icon={selectIcon(option.MH_PersonType?.id)}
                    size="lg"
                  />
                </Icon>
                <div className="column">
                  <h3>{option.Name},</h3>
                  <h3>{option.ID_Number}</h3>
                </div>
              </div>
            </Card>
          </Container>
        )}
      </components.Option>
    );
  };

  const loadOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_CLIENTS_BY_CRITERIA,
      variables: {
        criteria: inputValue,
        FK_Place: LineDetailPlace?.id,
      },
    });

    const profiles = data?.clients;

    const result = Array.isArray(profiles)
      ? profiles.map(BillingProfile => ({
          id: BillingProfile.id,
          Name: BillingProfile?.Name,
          idUser: BillingProfile?.User?.id,
          ID_Number: BillingProfile?.ID_Number,
          Email: BillingProfile?.Email,
          MH_PersonType: BillingProfile?.MH_PersonType,
          MH_Neighborhood: BillingProfile?.MH_Neighborhood,
          PhoneNumber: BillingProfile?.PhoneNumber,
          PhoneCodCountry: BillingProfile?.PhoneCodCountry,
          value: BillingProfile.id,
          label: "Buscar...",
        }))
      : [];

    setDefaultOptions(result);
    return result;
  };

  return (
    <div>
      <Field>
        <Control>
          <AsyncSelect
            cacheOptions
            components={{ Option: CustomOption }}
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            placeholder="Buscar..."
            styles={{
              menuPortal: provided => ({
                ...provided,
                zIndex: 9999,
              }),
              indicatorsContainer: provided => ({
                ...provided,
                height: "inherit",
              }),
              indicatorSeparator: provided => ({
                ...provided,
                display: "none",
              }),
              container: provided => ({
                fontSize: 13,
              }),
              singleValue: provided => ({
                ...provided,
                top: "38%",
              }),
              control: provided => ({
                ...provided,
                "minHeight": 30,
                "height": 30,
                "width": "100%",
                "minWidth": 180,
                "borderRadius": "4px",

                "&:hover": {
                  borderColor: "hsl(0, 0%, 90%)",
                  borderRadius: 4,
                  cursor: "pointer",
                },
              }),
              placeholder: provided => ({ ...provided, top: "38%" }),
              menu: provided => ({
                ...provided,
                fontSize: 12,
                zIndex: 999,
              }),
            }}
            onChange={handleChange}
          />
        </Control>
      </Field>
    </div>
  );
};
SelectProfile.defaultProps = {
  clientList: [],
};
SelectProfile.propTypes = {
  clientList: PropTypes.array,
  handleSaveNewClient: PropTypes.func.isRequired,
  deleteFavoriteClient: PropTypes.func.isRequired,
};

export default SelectProfile;
