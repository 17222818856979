import React from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
// External components
import { Card, Container, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./OptionCard.scss";

function OptionCard({
  title,
  icon,
  icon2,
  optional,
  optional2,
  mainComponent,
  summary,
  disabled,
  selectabledItems,
}) {
  const history = useHistory();

  const { setIsBilling } = useElectronic();

  const location = useLocation();

  const handleSetRoute = () => {
    if (location.pathname === "/buy-electronic-bills") {
      if (title === "Proveedor") {
        setIsBilling(true);
        history.push("/buy-electronic-bills/client");
      }
      if (title === "Tienda") {
        setIsBilling(true);
        history.push("/buy-electronic-bills/store");
      }
    }
    if (title === "Cliente") {
      history.push("/electronic-bills/client");
    } else if (title === "Inventarios") {
      setIsBilling(true);
      history.push("/electronic-bills/inventories");
    } else if (title === "Añadir productos") {
      setIsBilling(true);
      history.push("/electronic-bills/details");
    }
  };

  return (
    <Container
      className="hover-options"
      onClick={!disabled ? handleSetRoute : null}
    >
      <Card
        className={`
         ${
           mainComponent
             ? "card-products flex middle principal-content"
             : "card-summary secondary-content"
         } ${disabled && "disabled"}`}
      >
        {!mainComponent && (
          <div className="card-secondary-content-title flex">
            <div className="card-total-width">
              <h3>{title}</h3>
            </div>
          </div>
        )}
        {mainComponent && <h3>{title}</h3>}
        <div className="content-option flex">
          <Icon size="medium">
            <FontAwesomeIcon icon={icon} size="lg" />
          </Icon>
          <div className="flex middle">
            <h4>{optional}</h4>
          </div>
        </div>
      </Card>
    </Container>
  );
}

OptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  optional: PropTypes.string,
  mainComponent: PropTypes.bool,
  summary: PropTypes.bool,
  icon2: PropTypes.string,
  optional2: PropTypes.string,
  disabled: PropTypes.bool,
  selectabledItems: PropTypes.bool,
};

OptionCard.defaultProps = {
  mainComponent: false,
  optional: "",
  optional2: "",
  icon2: "",
  summary: false,
  disabled: false,
  selectabledItems: false,
};

export default OptionCard;
